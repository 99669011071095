export const earningHeaders = [
  {
    header: "Name",
    key: "name",
  },
  {
    header: "Action",
    key: "action",
  },
];

export const deductionHeaders = [
  { header: "Name", key: "name" },
  { header: "Deduction Type", key: "deduction_type_display" },
  { header: "Action", key: "action" },
];

export const ComponentTypeDetails = [
  {
    label: "Earnings",
  },
  {
    label: "Deduction",
  },
  {
    label: "Expense",
  },
];

export const overTime = [
  {
    label: "Hour",
  },
  {
    label: "Day",
  },
];

export const PayRollEmployeeHeader = [
  {
    header: "Employee Name",
    key: "employee name",
  },
  {
    header: "Employee Id",
    key: "employeeid",
  },
  {
    header: "Cost to company (per year)",
    key: "",
  },
  {
    header: "Payroll Group",
    key: "payroll_group",
  },
  {
    header: "Status",
    key: "status",
  },
  {
    header: "Action",
    key: "",
  },
];

export const PaymentMode = [
  {
    label: "NEFT",
  },
  {
    label: "Cash",
  },
  {
    label: "Cheque",
  },
];

export const summaryDetails = [
  {
    label: "Cost Structure",
  },
  {
    label: "Attendance Timeline",
  },
  {
    label: "Over Time",
  },
  // {
  //   label: "Salary Revision",
  // },
];

export const salaryRevisionKeyPair = [
  {
    name: "Employee Name",
  },
  {
    name: "Employee Id",
  },
  {
    name: "Old CTC",
  },
  {
    name: "Revised CTC",
  },
  {
    name: "Revision Month",
  },
];

export const overTimeKeyPair = [
  {
    name: "Action",
  },
  {
    name: "Employee Name",
  },
  {
    name: "Employee Id",
  },
  {
    name: "Overtime Hours",
  },
  {
    name: "Per Hour Rate",
  },
  {
    name: "Total Amount",
  },
];

export const salaryStatementReportPart1 = [
  {
    name: "Employee Name",
    key: "user_name",
  },
  {
    name: "Employee ID",
    key: "employee_id",
  },
  {
    name: "Monthly CTC",
    key: "monthly_ctc",
  },
  {
    name: "Mode of payment",
    key: "mode_of_payment",
  },
  {
    name: "Per hour salary",
    key: "per_hour_salary",
  },
  {
    name: "Per day salary",
    key: "per_day_salary",
  },
  {
    name: "Payable Days",
    key: "paid_days",
  },
  {
    name: "Shift Duration",
    key: "shift_duration",
  },
  {
    name: "Hours",
    key: "total_hours",
  },
  {
    name: "LOP",
    key: "lop",
  },
  {
    name: "LOP Amount",
    key: "lop_amount",
  },
  {
    name: "Gross",
    key: "gross_pay",
  },
];

export const salaryStatementReportPart2 = [
  {
    name: "Total Deductions",
    key: "total_deductions",
  },
  {
    name: "Net Pay",
    key: "net_pay",
  },
  {
    name: "Department",
    key: "department",
  },
  {
    name: "Bank",
    key: "bank",
  },
];

export const ESIReportKeys = [
  {
    name: "Employee Name",
    key: "user_name",
  },
  {
    name: "Employee ID",
    key: "employee_id",
  },
  {
    name: "ESI No",
    key: "esi_no",
  },
  {
    name: "Gross salaray",
    key: "gross_pay",
  },
  {
    name: "Per day salary",
    key: "per_day_salary",
  },
  {
    name: "Number of days in ESI",
    key: "paid_days",
  },
  {
    name: "Employee contribution",
    key: "employee_esi",
  },
  {
    name: "Company contribution",
    key: "company_esi",
  },
  {
    name: "Total esi amount",
    key: "total_esi",
  },
];

export const EPFReportKeys = [
  {
    name: "Employee Name",
    key: "user_name",
  },
  {
    name: "Employee ID",
    key: "employee_id",
  },
  {
    name: "PF No",
    key: "pf_no",
  },
  {
    name: "Employee Contribution",
    key: "employee_pf",
  },
  {
    name: "Company Contribution",
    key: "employer_pf",
  },
  {
    name: "Total Contribution",
    key: "total_contribution",
  },
];

export const IncentiveHeader = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Cycle",
    key: "cycle",
  },
  {
    name: "Incentive Type",
    key: "incentive_type",
  },
  {
    name: "Days/Percentage",
    key: "criteria_value",
  },
  {
    name: "Incentive Mode",
    key: "incentive_mode",
  },
  {
    name: "Incentive Amount",
    key: "amount",
  },
  {
    name: "Type",
    key: "criteria_type",
  },
  {
    name: "Effective From",
    key: "effective_from",
  },
  {
    name: "Actions",
    key: "total_contribution",
  },
];

export const incentiveMode = [
  { label: "fixed" },
  // { label: "percentage" },
  // { label: "unit" },
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const runPayrollGroupHeaders = [
  { name: "Group name", key: "group_name" },
  { name: "No. of Employeee", key: "number_of_employees" },
  { name: "Payment Bank", key: "payment_bank" },
  { name: "Payment Mode", key: "payment_mode" },
  { name: "Payroll cost", key: "group_payroll_cost" },
  { name: "Total Deduction", key: "group_total_deductions" },
  { name: "Group net salary", key: "group_gross_pay" },
  // { name: "Add deduction", key: "additional_deductions" },
  // // { name: "Advances", key: "advance" },
  { name: "Net pay", key: "group_net_pay" },
];
export const payCycle = [
  {
    id: 1,
    label: "Weekly",
  },
  {
    id: 2,
    label: "Bi-Weekly",
  },
  {
    id: 3,
    label: "Monthly",
  },
];

export const days_of_week = [
  { label: "Monday" },
  { label: "Tuesday" },
  { label: "Wednesday" },
  { label: "Thursday" },
  { label: "Friday" },
  { label: "Saturday" },
  { label: "Sunday" },
];

export const days_of_week2 = [
  { label: "Sunday" },
  { label: "Monday" },
  { label: "Tuesday" },
  { label: "Wednesday" },
  { label: "Thursday" },
  { label: "Friday" },
  { label: "Saturday" },
];

export const GroupEmployee = [
  {
    name: "Employee name",
    key: "user_name",
  },
  {
    name: "Employee id",
    key: "employee_id",
  },
  {
    name: "Paid days",
    key: "paid_days",
  },
  {
    name: "Grosses Pay",
    key: "gross_pay",
  },
  {
    name: "Deduction",
    key: "total_deduction",
  },
  {
    name: "Net Salary",
    key: "net_pay",
  },
  // {
  //   name: "Add. Deduction",
  //   key: "new_value",
  // },
  // // {
  // //   name: "Advance",
  // //   key: "new_value",
  // // },
  // {
  //   name: "Net Pay",
  //   key: "new_value",
  // },
];

export const payRunTableHeader = [
  {
    name: "Payrun Name",
    key: "name",
  },
  {
    name: "Start Date",
    key: "start_date",
  },
  {
    name: "End Date",
    key: "end_date",
  },
  {
    name: "Number of Employee",
    key: "num_of_employees",
  },
  {
    name: "Pay Date",
    key: "run_date",
  },
  {
    name: "Group count",
    key: "group_count",
  },
  {
    name: "Status",
    key: "status",
  },
];

export const payrunCycleTableFilterKeys = [
  "pay_run_cycle_type",
  "date_of_month",
  "day_of_week",
  "payment_basis",
  "name",
];
