import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { customAPI } from "services/utils/CustomAPI";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import sessionHandling from "services/utils/notificationUtils";
import moment from "moment";
import { Info } from "@mui/icons-material";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const Apply = () => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { result: response } = useFetch(`${domain}overtime-approval/`);
  const users = response?.users || [];
  // const checkout = "12/01/2025";

  const [handleCheckInError, setHandleCheckInError] = useState("");
  const [apiCall, setApiCall] = useState(false);
  const [shiftDetails, setShiftDetails] = useState();
  const checkout = shiftDetails?.check_in_data?.check_out_date;
  const formattedCheckout = checkout ? dayjs(checkout) : dayjs();
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [date, setDate] = useState(null);
  const [shiftStartTimeFormated, setShiftStartTimeFormated] = useState(null);
  const [shiftEndTimeFormated, setShiftEndTimeFormated] = useState(null);
  const [shiftEndDate, setShiftEndDate] = useState(formattedCheckout);
  const [dateError, setDateError] = useState(false);
  const [checkinError, setCheckinError] = useState(false);
  const [sametimeError, setSameTimeError] = useState(false);
  const [otError, setOtError] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleDate = (value) => {
    if (value && dayjs(value).isValid()) {
      setDate(value);
      setShiftDetails("");
      setShiftStartTimeFormated(null);
      setShiftEndTimeFormated(null);
      setApiCall(true);
    } else {
      setApiCall(false);
      setDateError(true);
      setDate(null);
    }
  };

  const formatTime = (timestamp) => {
    if (timestamp !== null) {
      const date = new Date(timestamp);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return null;
    }
  };

  const formattedCheckIn = formatTime(shiftStartTimeFormated);
  const formattedCheckOut = formatTime(shiftEndTimeFormated);

  const onSubmit = async (data) => {
    if (
      date &&
      date.toString() !== "Invalid Date" &&
      shiftStartTimeFormated !== null &&
      shiftEndTimeFormated !== null &&
      formattedCheckIn !== formattedCheckOut &&
      (shiftEndDate.format("YYYY-MM-DD") !==
        shiftDetails?.check_in_data?.check_out_date ||
        shiftStartTimeFormated < shiftEndTimeFormated) &&
      formattedCheckIn >= shiftDetails?.over_time_data?.ot_start_time
    ) {
      setDateError(false);
      setCheckinError(false);
      setOtError(false);
      setSameTimeError(false);
      const response = await customAPI(`${domain}overtime-approval/`, "POST", {
        user_id: selectedUserId,
        ot_id: shiftDetails.over_time_data.ot_id,
        date: date.format("DD-MM-YYYY"),
        ot_start_time: formattedCheckIn,
        ot_end_time: formattedCheckOut,
        shift_name: shiftDetails?.shift_data?.shift_name,
        check_in_id: shiftDetails?.check_in_data?.id,
      });
      if (response.ok) {
        showToast("success", "Over time approved successfully");
        setButtonLoader(false);
      }
    } else {
      if (
        ((date === null || date === "undefined") &&
          shiftStartTimeFormated === null &&
          shiftEndTimeFormated === null) ||
        (formattedCheckIn === formattedCheckOut &&
          formattedCheckIn > formattedCheckOut)
      ) {
        setDateError(true);
        setCheckinError(true);
        setOtError(false);
      } else if (
        formattedCheckIn < shiftDetails?.over_time_data?.ot_start_time
      ) {
        setOtError(true);
      } else if (date === null || date === "undefined") {
        setDateError(true);
      } else if (
        (shiftStartTimeFormated === null && shiftEndTimeFormated === null) ||
        formattedCheckIn === formattedCheckOut
      ) {
        setCheckinError(true);
      } else if (formattedCheckIn > formattedCheckOut) {
        setSameTimeError(true);
        setCheckinError(false);
        setOtError(false);
      }
    }
    setButtonLoader(false);
  };

  const updateUserName = (event, data) => {
    if (data) {
      setValue("userName", data?.label);
      setValue("user_id", data?.user_id);
      setSelectedUserName(data?.label);
      setSelectedUserId(data?.user_id);
      setApiCall(true);
    } else {
      setValue("userName", null);
      setSelectedUserName(null);
      setSelectedUserId(null);
      setApiCall(false);
    }
  };

  const handleDateOnChange = (value) => {
    if (value) {
      // setShiftStartDate(value);
    } else {
      setHandleCheckInError("Invalid check-in format");
    }
  };

  const handleCheckOutDateOnChange = (value) => {
    if (value) {
      setShiftEndDate(value);
    } else {
      setHandleCheckInError("Invalid check-out format");
    }
  };

  useEffect(() => {
    const fectchDetails = async () => {
      try {
        let formatDate = date.format("DD-MM-YYYY");
        const response = await fetch(
          `${domain}overtime-approval/${selectedUserId}/?date=${formatDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setShiftDetails(res);
          setDisable(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setDisable(true);
      }
    };
    if (
      apiCall &&
      selectedUserId &&
      date &&
      date.toString() !== "Invalid Date"
    ) {
      setApiCall(false);
      fectchDetails();
    }
  }, [apiCall, date, domain, selectedUserId, token]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid
        container
        sx={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
        }}
      >
        <Mui.Grid
          container
          sx={{
            padding: 1,
            backgroundColor: themes.primary,
            borderRadius: "10px 10px 0 0",
            border: `2px solid ${themes.sidebarDivider}`,
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography
            sx={{
              fontSize: 24,
              fontWeight: 800,
              font: "bold",
              textAlign: "center",
            }}
          >
            Apply Over Time
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: -1,
            border: `2px solid ${themes.sidebarDivider}`,
            padding: 2,
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  fontWeight: "bold",
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 1,
                }}
              >
                Employee Name &nbsp;
                <Mui.Tooltip title=".">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <CustomAutoComplete
                dataList={users?.map((obj) => ({
                  ...obj,
                  label: obj.user_name,
                }))}
                selectedValue={selectedUserName}
                rules={{
                  ...register("userName", {
                    required: "User name is required",
                  }),
                }}
                updateDataFunction={updateUserName}
                placeHolder="Select the name"
                errorMessage={
                  errors?.userName?.message ? errors?.userName?.message : ""
                }
                textBoxError={errors.userName ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  paddingTop: 1,
                  paddingBottom: 1,
                  fontWeight: "bold",
                  paddingLeft: 1,
                }}
              >
                Date &nbsp;
                <Mui.Tooltip title=".">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    size="small"
                    slotProps={{
                      textField: {
                        size: "small",
                        inputProps: { readOnly: true },
                      },
                    }}
                    value={date}
                    format="DD/MM/YYYY"
                    onChange={handleDate}
                    sx={{ width: "100%" }}
                    maxDate={dayjs()}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        size="small"
                        fullWidth
                        sx={{ pointerEvents: "none" }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {dateError ? (
                <h6
                  style={{
                    paddingTop: 2,
                    color: themes.helperTextColor,
                    fontSize: 12,
                  }}
                >
                  Date is required
                </h6>
              ) : null}
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            sx={{ border: `1px solid ${themes.sidebarDivider}`, mt: 2 }}
            container
          >
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    paddingTop: 1,
                    paddingBottom: 1,
                    fontWeight: "bold",
                    paddingLeft: 1,
                  }}
                >
                  Shift Name&nbsp;
                  <Mui.Tooltip title=".">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Typography>
                  {shiftDetails?.shift_data?.shift_name}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    paddingTop: 1,
                    paddingBottom: 1,
                    fontWeight: "bold",
                    paddingLeft: 1,
                  }}
                >
                  Working Hours&nbsp;
                  <Mui.Tooltip title=".">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={2.5}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  Check-in
                </Mui.Typography>
                <Mui.Typography>
                  {shiftDetails?.check_in_data?.check_in_date} -{" "}
                  {shiftDetails?.check_in_data?.check_in_time}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={2.5}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  Check-out
                </Mui.Typography>
                <Mui.Typography>
                  {shiftDetails?.check_in_data?.check_out_date} -{" "}
                  {shiftDetails?.check_in_data?.check_out_time}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={2.5}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  Ot Start Time
                </Mui.Typography>
                <Mui.Typography>
                  {shiftDetails?.over_time_data?.ot_start_time}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            sx={{ border: `1px solid ${themes.sidebarDivider}`, mt: 2 }}
            container
          >
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 2 }}>
                <Mui.Typography
                  sx={{ fontSize: 20, fontWeight: "bold", paddingLeft: 1 }}
                >
                  Over Time&nbsp;
                  <Mui.Tooltip title=".">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Grid container>
                  <Mui.Grid xs={1} sx={{ paddingTop: 3 }}>
                    <Mui.Typography>Start</Mui.Typography>
                  </Mui.Grid>

                  <Mui.Grid xs={5.5} sx={{ padding: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          size="small"
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          sx={{ minWidth: "60%" }}
                          value={formattedCheckout}
                          format="DD/MM/YYYY"
                          minDate={formattedCheckout}
                          maxDate={formattedCheckout}
                          onChange={handleDateOnChange}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              size="small"
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                            />
                          )}
                          disabled={true}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Mui.Grid>
                  <Mui.Grid xs={5.5} sx={{ padding: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <div sx={{ width: 4 }}>
                          <TimePicker
                            ampm={false}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            value={shiftStartTimeFormated || null}
                            slotProps={{
                              textField: {
                                size: "small",
                                width: 15,
                              },
                            }}
                            onChange={(newValue) => {
                              if (newValue) {
                                const timeWithDate = newValue
                                  ? newValue.toDate()
                                  : new Date();
                                let minTime = moment.utc(timeWithDate);
                                setShiftStartTimeFormated(
                                  dayjs()
                                    .set("hour", minTime._d.getHours())
                                    .set("minute", minTime._d.getMinutes())
                                    .set("second", minTime._d.getSeconds())
                                );
                              } else {
                                setShiftStartTimeFormated(null);
                              }
                            }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                    {checkinError ? (
                      <h6
                        style={{
                          paddingTop: 2,
                          color: themes.helperTextColor,
                          fontSize: 12,
                        }}
                      >
                        Both check_in and check-out are required
                      </h6>
                    ) : null}
                    {otError ? (
                      <h6
                        style={{
                          paddingTop: 2,
                          color: themes.helperTextColor,
                          fontSize: 12,
                        }}
                      >
                        Overtime checkin time should be greater than ot start
                        time
                      </h6>
                    ) : null}
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 2 }}></Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Grid container>
                  <Mui.Grid xs={1} sx={{ paddingTop: 3 }}>
                    <Mui.Typography>End</Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={5.5} sx={{ padding: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          size="small"
                          slotProps={{
                            textField: {
                              size: "small",
                              inputProps: { readOnly: true },
                            },
                          }}
                          sx={{ minWidth: "80%" }}
                          value={formattedCheckout}
                          minDate={formattedCheckout}
                          maxDate={dayjs(formattedCheckout).add(1, "day")}
                          format="DD/MM/YYYY"
                          onChange={handleCheckOutDateOnChange}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              size="small"
                              sx={{ pointerEvents: "none" }}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                            />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <Mui.Typography
                      sx={{
                        paddingTop: 1,
                        color: themes.redColor,
                        fontSize: 12,
                      }}
                    >
                      {handleCheckInError ===
                      "The check-out date can only be set to the selected date or the following day."
                        ? "The check-out date can only be set to the selected date or the following day."
                        : null}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={5.5} sx={{ padding: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <div sx={{ width: 4 }}>
                          <TimePicker
                            ampm={false}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            value={shiftEndTimeFormated || null}
                            slotProps={{
                              textField: {
                                size: "small",
                                width: 15,
                              },
                            }}
                            onChange={(newValue) => {
                              if (newValue) {
                                const timeWithDate = newValue
                                  ? newValue.toDate()
                                  : new Date();
                                let minTime = moment.utc(timeWithDate);
                                setShiftEndTimeFormated(
                                  dayjs()
                                    .set("hour", minTime._d.getHours())
                                    .set("minute", minTime._d.getMinutes())
                                    .set("second", minTime._d.getSeconds())
                                );
                              } else {
                                setShiftEndTimeFormated(null);
                              }
                            }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                    {checkinError ? (
                      <h6
                        style={{
                          paddingTop: 2,
                          color: themes.helperTextColor,
                          fontSize: 12,
                        }}
                      >
                        Both check_in and check-out are required
                      </h6>
                    ) : null}
                    {sametimeError ? (
                      <h6
                        style={{
                          paddingTop: 2,
                          color: themes.helperTextColor,
                          fontSize: 12,
                        }}
                      >
                        Check-in time to be earlier than check-out time.
                      </h6>
                    ) : null}
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {/* <Mui.Grid item>
              <CustomButton
                actionName="Clear"
                typeName="button"
                actionFuntion={clearAllData}
              />
            </Mui.Grid> */}
            <Mui.Grid
              item
              sx={{
                pointerEvents: disable ? "none" : "auto",
                opacity: disable ? 0.5 : 1,
              }}
            >
              <CustomButton
                actionName="Apply"
                typeName="submit"
                disableAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
