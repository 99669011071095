import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { Person, MoreVert } from "@mui/icons-material"; // Person4
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { defaultAllSelection } from "services/constants";
import { hrmsEmployeeFliter } from "services/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { setScreenUserId, setEditPage } from "services/Redux/HRPortal";
import { Loader } from "components/Loader";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
// import { setEmployeeScreenAction } from "services/Redux/TeamZone";
import { DownloadForOffline } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";
import {
  setAdminCompanyName,
  setAdminCompanyId,
} from "services/Redux/userToken";
const employeeHeader = [
  "Employee name",
  "Employee id",
  "User Code",
  "Company Name",
  "Email id",
  "Status",
  "Actions",
];
export const EmployeeDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { domain, token, globalSearchValue, adminCompanyName, adminCompanyId } =
    useSelector((state) => state.tokenReducer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDetails, setEditDetails] = useState(null);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [openDeactivatePopup, setOpenDeactivatePopup] = useState(false);
  const [openActivatePopup, setOpenActivatePopup] = useState(false);
  const { result: designation } = useFetch(`${domain}designation/`);
  const { result: employmentType } = useFetch(`${domain}employment-type/`);
  const [masterEmployee, setMasterEmployee] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [fetchUserDetails, setFetchUserDetails] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}list/employee?company_id=${adminCompanyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setLoading(false);
          setEmployeeDetails(res);
          setMasterEmployee(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (fetchUserDetails) {
      fetchEmployeeDetails();
      setFetchUserDetails(false);
    }
  }, [adminCompanyId, domain, fetchUserDetails, token]);

  useEffect(() => {
    const getCompany = async () => {
      try {
        const response = await fetch(`${domain}get-active-company/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          if (res.length === 0) {
            setOpenDialogBox(true);
          }
          const companyDetailsWithLabel = res.map((obj) => ({
            ...obj,
            label: obj.companyname,
          }));
          const allCompanies = {
            id: defaultAllSelection,
            label: "All Companies",
            companyname: "All Companies",
          };
          setCompanyDetails([allCompanies, ...companyDetailsWithLabel]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    getCompany();
  }, [domain, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showCardActions = () => {
    if (employeeDetails?.length === 0) {
      if (designation.length !== 0 && employmentType.length !== 0) {
        setShowCardDetails(true);
      } else {
        setOpenDialogBox(true);
      }
    } else {
      setShowCardDetails(true);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const editDetailsConfigurations = () => {
    setAnchorEl(null);
    history.push("/admin/hr/edit/employee");
    dispatch(setScreenUserId(editDetails.user_id));
    localStorage.setItem("hrScreenUserId", editDetails.user_id);
    dispatch(setEditPage(1));
    dispatch(setGlobalSearchValue(""));
  };

  const deactivateEmployee = async () => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}deactivate/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_id: editDetails.user_id,
          status: "Inactive",
        }),
      });
      const res = await response.json();
      if (response.status === 201 && response.ok) {
        setAnchorEl(null);
        showToast("error", "User inactivated successfully");
        const field = "status";
        const updatedUsers = employeeDetails.map((element) => {
          if (element.user_id === editDetails.user_id) {
            return { ...element, [field]: "Inactive" };
          }
          return element;
        });
        setEmployeeDetails(updatedUsers);
        handleDeactivateDialogBox();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      handleDeactivateDialogBox();
    } finally {
      setButtonLoader(false);
    }
  };

  const activateEmployee = async () => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}deactivate/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_id: editDetails.user_id,
          status: "Active",
        }),
      });
      const res = await response.json();
      if (response.status === 201 && response.ok) {
        setAnchorEl(null);
        showToast("error", "User activated successfully");
        const field = "status";
        const updatedUsers = employeeDetails.map((element) => {
          if (element.user_id === editDetails.user_id) {
            return { ...element, [field]: "Active" };
          }
          return element;
        });
        setEmployeeDetails(updatedUsers);
        handleActivateDialogBox();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      handleActivateDialogBox();
    } finally {
      setButtonLoader(false);
    }
  };

  const handleDeactivateDialogBox = () => {
    setOpenDeactivatePopup(false);
    setAnchorEl(null);
  };

  const handleActivateDialogBox = () => {
    setOpenActivatePopup(false);
    setAnchorEl(null);
  };

  const handleCloseInfoDialog = () => {
    setOpenDialogBox(false);
  };

  useEffect(() => {
    const filteredResults = masterEmployee.filter((item) =>
      hrmsEmployeeFliter.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setEmployeeDetails(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterEmployee, page]);

  const handleCompanyName = (event, data) => {
    if (data) {
      dispatch(setAdminCompanyName(data.label));
      dispatch(setAdminCompanyId(data.id));
      localStorage.setItem("adminCompanyId", data.id);
      localStorage.setItem("adminCompanyname", data.label);
      setFetchUserDetails(true);
    } else {
      dispatch(setAdminCompanyName(""));
    }
  };

  const navigateBulkImport = () => {
    dispatch(setGlobalSearchValue(""));
    history.push("/admin/teamzone/bulk/import");
  };

  const getEmployeeDetails = () => {
    if (employeeDetails.length !== 0) {
      let dataPreparation = [];

      employeeDetails?.forEach((element) => {
        let overAllData = {
          employeename: element?.user_name,
          employeeid: element?.employee_id,
          usercode: element?.user_code,
          ...(adminCompanyName === "All Companies" && {
            companyname: element?.company_name,
          }),
          emailid: element?.email ? element.email : "N/A",
          status: element?.status ? element?.status : "N/A",
        };
        dataPreparation.push(overAllData);
      });

      if (dataPreparation.length !== 0) {
        const company = ["Company Name"];
        company.push(adminCompanyName);

        const TableHead = [
          "Employee Name",
          "Employee Id",
          "User Code",
          "Email Id",
          "Status",
        ];

        if (adminCompanyName === "All Companies") {
          TableHead.splice(3, 0, "Company Name");
        }

        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object.keys(dataPreparation[0]);
        let csv = dataPreparation.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        );

        csv.unshift(TableHead.join(","));
        csv.unshift(",");
        csv.unshift(company.join(","));
        csv = csv.join("\r\n");

        const ConvertData = csv;
        const element = document.createElement("a");
        const file = new Blob([ConvertData], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "EmployeeReports.csv";
        document.body.appendChild(element);
        element.click();
      } else {
        showToast("error", "No data available");
      }
    } else {
      showToast("error", "No employee details available");
    }
  };

  return !showCardDetails ? (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid item xs={2} sx={{ padding: 1, paddingTop: 3 }}>
          <Mui.Typography>Select company</Mui.Typography>
          <CustomAutoComplete
            dataList={companyDetails}
            selectedValue={adminCompanyName}
            updateDataFunction={handleCompanyName}
            placeHolder="Select company name"
          />
        </Mui.Grid>
        <Mui.Grid item xs={6.3}></Mui.Grid>
        <Mui.Grid
          item
          xs={3.2}
          sx={{
            paddingTop: 2,
            padding: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            actionFuntion={navigateBulkImport}
            actionName="Bulk Import"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={showCardActions}
            actionName="Add Employee"
            typeName="button"
          />
        </Mui.Grid>
        <Mui.Grid
          item
          xs={0.4}
          sx={{
            paddingTop: 2.5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Mui.IconButton
            onClick={() => {
              getEmployeeDetails();
            }}
            disabled={employeeDetails?.length === 0 ? true : false}
          >
            <DownloadForOffline
              size="large"
              sx={{ color: "#25B7D3", fontSize: 40 }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>

      {employeeDetails.length !== 0 && !loading ? (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ width: "100%", maxHeight: { md: 840, lg: 840, xl: 840 } }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
                "& .MuiTableCell-root": {
                  height: "15px",
                  padding: "6px 35px",
                },
              }}
            >
              <Mui.TableRow maxWidth="xl" align="left">
                {employeeHeader.map((header) =>
                  header !== "Company Name" ? (
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      {header}
                    </Mui.TableCell>
                  ) : adminCompanyName === "All Companies" ? (
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      {header}
                    </Mui.TableCell>
                  ) : (
                    false
                  )
                )}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {employeeDetails
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <Mui.TableRow
                    key={data.id}
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "15px",
                        padding: "6px 35px",
                      },
                    }}
                  >
                    <>
                      <Mui.TableCell align="left">
                        <Mui.Stack direction="Row">
                          <Mui.Avatar
                            sx={{ width: 20, height: 20 }}
                            alt="Remy Sharp"
                            src={data.image_url}
                          />
                          <Mui.Typography
                            sx={{
                              paddingLeft: 1,
                            }}
                          >
                            {data.user_name ? data.user_name : "N/A"}
                          </Mui.Typography>
                        </Mui.Stack>
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.employee_id ? data?.employee_id : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.user_code ? data?.user_code : "N/A"}
                      </Mui.TableCell>
                      {adminCompanyName === "All Companies" ? (
                        <Mui.TableCell align="left">
                          {data?.company_name ? data?.company_name : "N/A"}
                        </Mui.TableCell>
                      ) : (
                        false
                      )}
                      <Mui.TableCell align="left">
                        {data?.email ? data?.email : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.status ? data?.status : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        <Mui.IconButton
                          size="small"
                          onClick={(e) => {
                            setEditDetails(data);
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVert
                            size="small"
                            sx={{ color: themes.headLine, strokeWidth: 2 }}
                          />
                        </Mui.IconButton>

                        <Mui.Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          {
                            <Mui.MenuItem
                              onClick={() => editDetailsConfigurations()}
                            >
                              Edit
                            </Mui.MenuItem>
                          }
                          {editDetails?.status === "Active" ? (
                            <Mui.MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenDeactivatePopup(true);
                              }}
                            >
                              Deactivate
                            </Mui.MenuItem>
                          ) : (
                            <Mui.MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenActivatePopup(true);
                              }}
                            >
                              Activate
                            </Mui.MenuItem>
                          )}
                        </Mui.Menu>
                      </Mui.TableCell>
                    </>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {employeeDetails.length > 25 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={employeeDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      ) : loading || employeeDetails.length !== 0 ? (
        <Loader info="Loading..." />
      ) : (
        <Mui.Alert severity="info">There is a no available employees</Mui.Alert>
      )}
      <GlobalDialogBox
        open={openDialogBox}
        handleCloseDialog={handleCloseInfoDialog}
        title="Warning"
      >
        <Mui.Typography>
          `To onboard an employee, please create a designation and leave the
          group before proceeding. Designations can be created by going to
          Administration {">"} Masters {">"} Add Designation. Leave group can be
          created by going to Team Zone {">"}Leave Group {">"}Add Leave Group`
        </Mui.Typography>
        <Mui.Grid container sx={{ justifyContent: "flex-end", paddingTop: 1 }}>
          <Mui.Button
            variant="contained"
            size="small"
            sx={{
              background: themes.primaryIndicator,
              color: themes.headLine,
              padding: 1,
            }}
            onClick={() => handleCloseInfoDialog()}
          >
            Ok
          </Mui.Button>
        </Mui.Grid>
      </GlobalDialogBox>
      <GlobalDialogBox
        open={openDeactivatePopup}
        handleCloseDialog={handleDeactivateDialogBox}
        title={"Deactivate the user"}
      >
        <Mui.Typography sx={{ fontSize: 22 }}>
          Do you want to deactivate the employee ?
        </Mui.Typography>
        <Mui.Grid
          container
          spacing={2}
          sx={{ justifyContent: "flex-end", paddingTop: 1 }}
        >
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => handleDeactivateDialogBox()}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => deactivateEmployee()}
              actionName="Yes"
              typeName="submit"
              disableAction={
                openDeactivatePopup && buttonLoader ? buttonLoader : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      <GlobalDialogBox
        open={openActivatePopup}
        handleCloseDialog={handleActivateDialogBox}
        title={"Activate the user"}
      >
        <Mui.Typography sx={{ fontSize: 22 }}>
          Do you want to activate the user ?
        </Mui.Typography>
        <Mui.Grid
          container
          spacing={2}
          sx={{ justifyContent: "flex-end", paddingTop: 1 }}
        >
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => handleActivateDialogBox()}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => activateEmployee()}
              actionName="Yes"
              disableAction={
                openActivatePopup && buttonLoader ? buttonLoader : false
              }
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  ) : (
    <Mui.Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        padding: "0 16px",
        "@media (max-width:600px)": {
          minHeight: "50vh",
        },
      }}
    >
      <Mui.Stack direction="Row">
        {/* <Mui.Card
          spacing={2}
          className=" max-w-fit mt-5 w-96 flex space-x-1 shadow-lg"
          variant="outlined"
          sx={{
            width: "15vw",
            transitionDuration: "0.3s",
            height: "10vw",
            border: `1px solid ${themes.shadowColor}`,
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(setEmployeeScreenAction("Existing User"));
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/hr/edit/employee");
            dispatch(setEditPage(1));
          }}
        >
          <Mui.CardContent
            sx={{
              justifyContent: "center",
              alignContent: "center",
              minHeight: "20vh",
            }}
          >
            <Mui.Typography name="Existing User" sx={{ textAlign: "center" }}>
              <Person4 />
              {"  "}Existing User
            </Mui.Typography>
          </Mui.CardContent>
        </Mui.Card> */}
        &nbsp;&nbsp;&nbsp;
        <Mui.Card
          spacing={2}
          className=" max-w-fit mt-5 w-96 flex space-x-1 shadow-lg"
          variant="outlined"
          sx={{
            width: "15vw",
            transitionDuration: "0.3s",
            height: "10vw",
            paddingLeft: 2,
            border: `1px solid ${themes.shadowColor}`,
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/hr/create/employee");
          }}
        >
          <Mui.CardContent
            sx={{
              justifyContent: "center",
              alignContent: "center",
              minHeight: "20vh",
            }}
          >
            <Mui.Typography name="New Employee" sx={{ textAlign: "center" }}>
              <Person />
              {"  "}New Employee
            </Mui.Typography>
          </Mui.CardContent>
        </Mui.Card>
      </Mui.Stack>
    </Mui.Grid>
  );
};
