import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import { themes } from "services/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useDispatch } from "react-redux";
import { setEditPage } from "services/Redux/HRPortal";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
export const TopBar = ({
  pageno,
  employeeDetails,
  setUpdateUserDetails,
  isFieldFilled,
}) => {
  const pages = [1, 2, 3];
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const handlePopState = () => {
      if (isFieldFilled) {
        setOpenDialog(true);
        window.history.pushState(null, "", window.location.href);
      } else {
        history.push("/admin/hr/employee");
      }
    };
    window.addEventListener("popstate", handlePopState);
    window.history.pushState(null, "", window.location.href);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isFieldFilled, setOpenDialog, history]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid xs={4}>
          <Mui.Grid container>
            <Mui.Stack direction="row">
              <BackArrow
                actionFunction={() => {
                  if (isFieldFilled) {
                    setOpenDialog(true);
                  } else {
                    history.push("/admin/hr/employee");
                  }
                }}
              />{" "}
              &nbsp;
              <Mui.Typography
                sx={{
                  paddingTop: 1,
                  fontSize: 23,
                  fontWeight: "bold",
                  color: themes.headLine,
                  paddingLeft: 1,
                }}
              >
                Edit Employee
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid xs={4}>
          <Mui.Grid container>
            {pages?.map((item) => (
              <>
                <Mui.Grid xs={1}>
                  <TimelineSeparator>
                    <TimelineDot
                      sx={{
                        backgroundColor:
                          pageno === item
                            ? themes.primaryIndicator
                            : themes.whiteColor,
                        border: `3px solid ${themes.colorShadow}`,
                      }}
                      onClick={() => {
                        dispatch(setEditPage(item));
                        setUpdateUserDetails(true);
                      }}
                    >
                      <Mui.Stack
                        sx={{
                          display: "flex",
                          width: "35px",
                          height: "35px",
                          textAlign: "center",
                          textAlignLast: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Mui.Typography
                          sx={{
                            fontSize: 20,
                            paddingTop: 0.5,
                            color: themes.headLine,
                          }}
                        >
                          {item}
                        </Mui.Typography>
                      </Mui.Stack>
                    </TimelineDot>
                  </TimelineSeparator>
                </Mui.Grid>
                {item !== 3 ? (
                  <Mui.Grid xs={3} sx={{ padding: 3, paddingTop: 4 }}>
                    <Mui.Divider sx={{ borderBottomWidth: "3px" }} />
                  </Mui.Grid>
                ) : null}
              </>
            ))}
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Mui.Typography
            sx={{
              paddingTop: 3,
              fontSize: 15,
              color: themes.headLine,
              paddingBottom: 0.5,
              fontWeight: "bold",
            }}
          >
            {employeeDetails.user_name} <br />
            {employeeDetails.company_name}
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Confirmation Required"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 20, font: "bold" }}>
          Are you sure you want to go back? Clicking 'Yes' will result in losing
          all entered data.
        </Mui.Typography>

        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionName="Yes"
              typeName="button"
              actionFuntion={() => history.push("/admin/hr/employee")}
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
