import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Mui from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { themes } from "services/constants";
import { setCurrentScreen } from "services/Redux/userManagament";
import { setGlobalSearchValue } from "services/Redux/userToken";
import {
  setDepartmentId,
  setDepartmentLeadId,
  setDepartmentName,
  setShiftCategoryId,
} from "services/Redux/userManagament";
import { setReloadHRMSApi } from "services/Redux/leaveManagement";

export const CardView = ({ departmentList, setLeavePage, Page }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(25);

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {departmentList?.length > 0 ? (
        <>
          <Mui.Grid container spacing={0} sx={{ justifyContent: "center" }}>
            {departmentList
              .slice(Page * rowsPerPage, Page * rowsPerPage + rowsPerPage)
              .map((department, index) => (
                <Mui.Grid
                  item
                  key={department.id}
                  sx={{ paddingLeft: 3 }}
                  spacing={5}
                >
                  <Mui.Card
                    className=" min-h-full max-w-fit mt-5 w-96 flex space-x-1 shadow-lg "
                    variant="outlined"
                    sx={{
                      borderRadius: 2,
                      marginLeft: 3,
                      marginTop: 5,
                    }}
                  >
                    <Mui.CardContent>
                      <Mui.Grid container>
                        <Mui.Grid xs={11}>
                          <Mui.Tooltip title={department.department_name}>
                            <Mui.Typography
                              sx={{
                                color: themes.primaryIndicator,
                                fontSize: { xs: 15, sm: 17, md: 20, lg: 28 },
                                // fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {department.department_name.length > 10
                                ? department.department_name.substr(0, 10) +
                                  "..."
                                : department.department_name}
                            </Mui.Typography>
                          </Mui.Tooltip>
                        </Mui.Grid>
                        <Mui.Grid xs={1}>
                          {department.department_name === "All" ? null : (
                            <Mui.IconButton
                              size="small"
                              onClick={(e) => {
                                dispatch(
                                  setDepartmentId(department.department_id)
                                );
                                dispatch(
                                  setDepartmentLeadId(
                                    department.department_lead_id
                                  )
                                );
                                dispatch(
                                  setDepartmentName(department.department_name)
                                );
                                dispatch(
                                  setShiftCategoryId(
                                    department.shift_category_id
                                  )
                                );
                                handleMenuOpen(e, index);
                              }}
                              disabled={department.department_id ? false : true}
                            >
                              <MoreVertIcon size="small" />
                            </Mui.IconButton>
                          )}
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.Stack>
                              <Mui.Stack>
                                <Mui.MenuItem
                                  onClick={() => {
                                    dispatch(setGlobalSearchValue(""));
                                    history.push(
                                      "/admin/attendance/view/members"
                                    );
                                  }}
                                >
                                  View Members
                                </Mui.MenuItem>
                              </Mui.Stack>
                            </Mui.Stack>
                            <Mui.Stack>
                              <Mui.MenuItem
                                onClick={() => {
                                  dispatch(setCurrentScreen("Shift"));
                                  localStorage.setItem(
                                    "currentSubMenu",
                                    "Shift"
                                  );
                                  dispatch(setGlobalSearchValue(""));
                                  history.push("/admin/teamzone/plan-shift");
                                }}
                              >
                                Plan Shift
                              </Mui.MenuItem>
                            </Mui.Stack>
                          </Mui.Menu>
                        </Mui.Grid>
                      </Mui.Grid>
                      <div
                        style={{ width: 250 }}
                        onClick={() => {
                          dispatch(setGlobalSearchValue(""));
                          dispatch(
                            setDepartmentId(
                              department.department_id
                                ? department.department_id
                                : 99999
                            )
                          );
                          dispatch(
                            setDepartmentLeadId(
                              department?.department_lead_id
                                ? department?.department_lead_id
                                : 99999
                            )
                          );
                          dispatch(
                            setDepartmentName(department?.department_name)
                          );
                          dispatch(
                            setShiftCategoryId(
                              department?.shift_category_id
                                ? department?.shift_category_id
                                : 99999
                            )
                          );
                          localStorage.setItem(
                            "approveLeaveDepartmentId",
                            department?.department_id
                              ? department?.department_id
                              : 99999
                          );
                          localStorage.setItem(
                            "approveLeaveDepartmentName",
                            department?.department_name
                          );
                          dispatch(setReloadHRMSApi(false));
                          history.push("/admin/teamzone/status");
                        }}
                      >
                        <Mui.Grid container sx={{ paddingBottom: 1 }}>
                          <Mui.Grid xs={10}>
                            <Mui.Typography>Total no of staffs</Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={2}>
                            <Mui.Typography sx={{ fontWeight: "bold" }}>
                              {department.total_staff_count}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container sx={{ paddingBottom: 1 }}>
                          <Mui.Grid xs={10}>
                            <Mui.Typography>
                              No of staffs planned
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={2}>
                            <Mui.Typography sx={{ fontWeight: "bold" }}>
                              {department.staff_planned_count}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container sx={{ paddingBottom: 1 }}>
                          <Mui.Grid xs={10}>
                            <Mui.Typography>
                              No of staffs present
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={2}>
                            <Mui.Typography sx={{ fontWeight: "bold" }}>
                              {department.staff_present_count}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container sx={{ paddingBottom: 1 }}>
                          <Mui.Grid xs={10}>
                            <Mui.Typography>
                              No of staffs in overtime
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={2}>
                            <Mui.Typography sx={{ fontWeight: "bold" }}>
                              {department.staff_overtime_count}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container sx={{ paddingBottom: 1 }}>
                          <Mui.Grid xs={10}>
                            <Mui.Typography>
                              {" "}
                              No of staffs missing
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={2}>
                            <Mui.Typography sx={{ fontWeight: "bold" }}>
                              {department.staff_missing_count
                                ? department.staff_missing_count
                                : "0"}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container sx={{ paddingBottom: 1 }}>
                          <Mui.Grid xs={10}>
                            <Mui.Typography>
                              {" "}
                              No of staffs absent
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={2}>
                            <Mui.Typography sx={{ fontWeight: "bold" }}>
                              {department.staff_absent_count}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                        <Mui.Grid container sx={{ paddingBottom: 1 }}>
                          <Mui.Grid xs={10}>
                            <Mui.Typography>
                              No of staffs unassigned
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid xs={2}>
                            <Mui.Typography sx={{ fontWeight: "bold" }}>
                              {department.unassigned_members}
                            </Mui.Typography>
                          </Mui.Grid>
                        </Mui.Grid>
                      </div>
                    </Mui.CardContent>
                  </Mui.Card>
                </Mui.Grid>
              ))}
          </Mui.Grid>
        </>
      ) : (
        <Mui.Container maxWidth="xl" sx={{ marginTop: 2 }}>
          <Mui.Alert severity="info">No data available</Mui.Alert>
        </Mui.Container>
      )}
      {departmentList.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={departmentList.length}
          rowsPerPage={rowsPerPage}
          page={Page}
          onPageChange={handleChangeLeavePage}
          onRowsPerPageChange={handleChangeLeaveRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
