import React, { useState } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { useFetch } from "services/hooks/useFetch";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { onlyNumberRegex } from "services/constants/patterns";
import { showToast } from "services/utils/Status";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { customAPI } from "services/utils/CustomAPI";

export const Apply = () => {
  const { domain, userDetails } = useSelector((state) => state.tokenReducer);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const today = dayjs().format("YYYY-MM-DD");
  const [selectedAdvanceType, setSelectedAdvanceType] = useState("");
  const [selectedAdvanceLoanAmount, setSelectedAdvanceLoanAmount] =
    useState("");
  const [selectedReasonForAmount, setSelectedReasonForAmount] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [paymentDate, setPaymentDate] = useState(today);
  const { result: expenseData } = useFetch(`${domain}expense-types/`);

  const onSubmit = async (data) => {
    setButtonLoader(true);
    const response = await customAPI(`${domain}expense/`, "POST", {
      user: userDetails?.id,
      amount: data?.expense_amount,
      description: data?.reason_for_amount,
      date_incurred: data?.from_date,
      expense_type: data?.expenseId,
    });
    if (response.ok) {
      showToast(
        "success",
        "Expense request received! You'll get a notification when it's approved."
      );
      setPaymentDate(today);
      reset();
      handleClearButtonClick();
      setButtonLoader(false);
    }
    setButtonLoader(false);
  };

  const updateExpenseType = (event, data) => {
    if (data) {
      setValue("expense_type", data?.label);
      setValue("expenseId", data?.id);
      setSelectedAdvanceType(data?.label);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedAdvanceType("");
    setPaymentDate(today);
    setSelectedAdvanceLoanAmount("");
    setSelectedReasonForAmount("");
  };

  const updateAdvanceAmount = (event) => {
    if (
      event.target.value.length <= 8 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("expense_amount", event.target.value);
      setSelectedAdvanceLoanAmount(event.target.value);
    }
  };

  const updateReasonForAmount = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("reason_for_amount", event.target.value);
      setSelectedReasonForAmount(event.target.value);
    }
  };

  const validatePaymentDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate < currentDate) {
      return "Date cannot be in the past.";
    }
    return true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 13 }}>
      <Mui.Grid sx={{ paddingLeft: 10, paddingRight: 10, mt: 8 }}>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
            >
              Apply Expense
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          sx={{
            borderRadius: "0 0 10px 10px",
            border: `2px solid ${themes.sidebarDivider}`,
            padding: 2,
          }}
        >
          <Mui.Grid container>
            <Mui.Grid item xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                  fontWeight: "bold",
                }}
              >
                Expense Type
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={8} sx={{ padding: 1 }}>
              <CustomAutoComplete
                dataList={expenseData?.map((obj) => ({
                  ...obj,
                  label: obj?.type,
                }))}
                rules={{
                  ...register("expense_type", {
                    required: "Expense type is required",
                  }),
                }}
                selectedValue={selectedAdvanceType}
                updateDataFunction={updateExpenseType}
                placeHolder="Select the expense type"
                errorMessage={
                  errors?.expense_type?.message
                    ? errors?.expense_type?.message
                    : ""
                }
                textBoxError={errors?.expense_type ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid container>
            <Mui.Grid item xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                  fontWeight: "bold",
                }}
              >
                Expense Amount
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={8} sx={{ padding: 1 }}>
              <CustomTextBox
                rules={{
                  ...register("expense_amount", {
                    required: "Amount is required",
                  }),
                }}
                selectedValue={selectedAdvanceLoanAmount}
                updateDataFunction={updateAdvanceAmount}
                placeHolder="Enter expense amount"
                errorMessage={
                  errors?.expense_amount?.message
                    ? errors?.expense_amount?.message
                    : ""
                }
                textBoxError={errors?.expense_amount ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid container>
            <Mui.Grid item xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                  fontWeight: "bold",
                }}
              >
                Date
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={8} sx={{ padding: 1 }}>
              <Mui.TextField
                fullWidth
                size="small"
                type="date"
                id="from_date"
                value={paymentDate}
                {...register("from_date", {
                  required: "Date is required.",
                  validate: validatePaymentDate,
                })}
                error={errors.from_date}
                helperText={errors.from_date && errors.from_date.message}
                onChange={(event) => {
                  setPaymentDate(event.target.value);
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid item xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                  fontWeight: "bold",
                  paddingTop: 2,
                }}
              >
                Reason For Expense Amount
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={8} sx={{ padding: 1 }}>
              <CustomTextBox
                type="multiline"
                rules={{
                  ...register("reason_for_amount", {
                    required: "Reason for amount is required",
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else if (value?.trim().length > 255 && value !== "") {
                          return "Maximum length is 255 characters";
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                selectedValue={selectedReasonForAmount}
                updateDataFunction={updateReasonForAmount}
                placeHolder="Provide a reason for expense"
                errorMessage={
                  errors?.reason_for_amount?.message
                    ? errors?.reason_for_amount?.message
                    : ""
                }
                textBoxError={errors?.reason_for_amount ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              padding: 2,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionFuntion={handleClearButtonClick}
                actionName="Clear"
                typeName="button"
              />
            </Mui.Grid>
            <Mui.Grid item>
              <CustomButton
                actionName="Apply"
                typeName="submit"
                disableAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
