import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";

export const MyProjectContext = createContext({});

export const MyProjectContextProvider = ({ children }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [clientFilter, setClientFilter] = useState("All Client");
  const [currentTap, setCurrentTap] = useState("Active");
  const [clientFilterId, setClientFilterId] = useState(0);
  const [allClients, setAllClients] = useState([]);
  const [reloadClientApi, setReloadClientApi] = useState(true);
  const [getClientDetails, setGetClientDetails] = useState(true);
  const [loading, setLoading] = useState(true);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [projects, setProjects] = useState([]);
  const [overAllProjects, setOverAllProjects] = useState([]);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(`${domain}client/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const allClients = res.map((obj) => ({
            ...obj,
            label: obj.clientname,
          }));
          const createButton = {
            clientid: 9999,
            label: "All Client",
            clientname: "All Client",
          };
          let clients = [...allClients, createButton];
          setAllClients(
            clients.sort(function (a, b) {
              if (a.clientname?.toLowerCase() < b.clientname?.toLowerCase())
                return -1;
              if (a.clientname?.toLowerCase() > b.clientname?.toLowerCase())
                return 1;
              return 0;
            })
          );
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (reloadClientApi) {
      setReloadClientApi(false);
      fetchClientDetails();
    }
  }, [domain, reloadClientApi, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${domain}project/?status=${currentTap}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const data = res.map((obj) => ({
            ...obj,
            clientName: obj?.client?.clientname,
          }));
          let filterData = [];
          if (currentTap === "All") {
            filterData = data;
          } else {
            filterData = data.filter((projectData) => {
              return projectData.status.status === currentTap;
            });
          }
          setOverAllProjects(data);
          setMasterDataForSearch(filterData);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (getClientDetails) {
      fetchData();
      setGetClientDetails(false);
    }
  }, [currentTap, domain, getClientDetails, token]);

  return (
    <MyProjectContext.Provider
      value={{
        setReloadClientApi,
        allClients,
        clientFilter,
        setClientFilter,
        domain,
        token,
        loading,
        projects,
        overAllProjects,
        setProjects,
        setClientFilterId,
        clientFilterId,
        setGetClientDetails,
        currentTap,
        setCurrentTap,
        masterDataForSearch,
      }}
    >
      {children}
    </MyProjectContext.Provider>
  );
};
