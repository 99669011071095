import React, { useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
// import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { customAPI } from "services/utils/CustomAPI";
import { workUnitTypeList } from "pages/Administration/AdministrationHeaders";
import {
  // specialCharacterRestrict,
  // onlyNumberRegex,
  rgxWithDot,
} from "services/constants/patterns";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { showToast } from "services/utils/Status";

export const AddWorkUnit = ({ setPayUnit, reCallApi, setShowTopBar }) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const { result: department } = useFetch(
    `${domain}work-unint-based-department/`
  );
  const { result: workUnit } = useFetch(`${domain}work_units/`);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,

    formState: { errors },
  } = useForm({
    defaultValues: { default_unit: 0, amount: 0 },
  });
  const [workUnitName, setWorkUnitName] = useState("");
  const [workUnitType, setWorkUnitType] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [defaultWorkUnit, setDefaultWorkUnit] = useState(0);
  const [numberOfWorkUnit, setNumberOfWorkUnit] = useState(0);
  const [departmentName, setDepartmentName] = useState("");
  const [workUnitAmount, setWorkUnitAmount] = useState(0);

  const onSubmit = async (data) => {
    if (data) {
      setButtonDisabled(true);
      let payload = {
        ...data,
        amount: data.unit_type === "Fixed" ? data.amount : null,
        default_unit: data.unit_type === "Fixed" ? null : data.default_unit,
      };
      const response = await customAPI(
        `${domain}work_unit_pay/`,
        "POST",
        payload
      );
      if (response.ok) {
        showToast("success", "Work unit pay created successfully");
        setPayUnit(false);
        setShowTopBar(true);
        reCallApi(true);
        reset();
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const handleWorkUnit = (event, data) => {
    if (data) {
      setValue("name", data?.label);
      setValue("work_unit", data?.id);
      setWorkUnitName(data?.label);
    } else {
      setValue("name", "");
      setValue("work_unit", null);
      setWorkUnitName("");
    }
  };

  const handleWorkUnitPaymentType = (event, data) => {
    if (data) {
      setValue("unit_type", data.label);
      setWorkUnitType(data.label);
      setDefaultWorkUnit(0);
      setValue("default_unit", 0);
      setValue("amount", 0);
      setWorkUnitAmount(0);
    }
  };

  const totalNumberOfUnitValue = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 4) {
      setNumberOfWorkUnit(event.target.value);
      setValue("total_number_of_work_unit", event.target.value);
    }
  };

  const updateDefaultWorkUnitNumber = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 6) {
      setDefaultWorkUnit(event.target.value);
      setValue("default_unit", event.target.value);
    }
  };

  const updateDefaultWorkUnitAmount = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 6) {
      setValue("amount", event.target.value);
      setWorkUnitAmount(event.target.value);
    }
  };

  const handleDepartment = (event, data) => {
    if (data) {
      setValue("department_name", data?.label);
      setValue("department", data?.id);
      setDepartmentName(data?.label);
    } else {
      setValue("department_name", "");
      setValue("department", null);
      setDepartmentName("");
    }
  };
  const validateStartDate = (value) => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (!value) {
      return "Date is required.";
    } else if (value < currentDate) {
      return "Date cannot be in the past.";
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setPayUnit(false);
            setShowTopBar(true);
          }}
        />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
        >
          Work Unit / Add WorkUnit Pay
        </Mui.Typography>
      </Mui.Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: 20 }}
        noValidate
      >
        <Mui.Grid
          container
          sx={{ paddingLeft: 40, paddingRight: 40, paddingTop: 5 }}
        >
          <Mui.Grid container component={Mui.Paper}>
            <Mui.Grid
              xs={12}
              sx={{
                padding: 1,
                backgroundColor: themes.primary,
                borderRadius: "10px 10px 0 0",
                border: `2px solid ${themes.sidebarDivider}`,
                borderBottom: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
              >
                Add WorkUnit Pay
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            sx={{
              paddingTop: 1,
              padding: 3,
              borderRadius: "0 0 10px 10px",
              border: `2px solid ${themes.sidebarDivider}`,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Department
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <CustomAutoComplete
                    dataList={department.map((obj) => ({
                      ...obj,
                      label: obj.department,
                    }))}
                    selectedValue={departmentName}
                    rules={{
                      ...register("department_name", {
                        required: "Department name is required",
                      }),
                    }}
                    updateDataFunction={handleDepartment}
                    placeHolder="Select the department name"
                    errorMessage={
                      errors?.department_name?.message
                        ? errors?.department_name?.message
                        : ""
                    }
                    textBoxError={errors?.department_name ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  {" "}
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    WorkUnit Name
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <CustomAutoComplete
                    dataList={workUnit.map((obj) => ({
                      ...obj,
                      label: obj.name,
                    }))}
                    selectedValue={workUnitName}
                    rules={{
                      ...register("name", {
                        required: "Work unit name is required",
                      }),
                    }}
                    updateDataFunction={handleWorkUnit}
                    placeHolder="Select the work unit name"
                    errorMessage={
                      errors?.name?.message ? errors?.name?.message : ""
                    }
                    textBoxError={errors?.name ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Total Number of WorkUnit
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <CustomTextBox
                    selectedValue={numberOfWorkUnit}
                    rules={{
                      ...register("total_number_of_work_unit", {
                        required: "Total number of workunit is required",
                      }),
                    }}
                    updateDataFunction={totalNumberOfUnitValue}
                    placeHolder="Enter amount"
                    errorMessage={
                      errors?.total_number_of_work_unit?.message
                        ? errors?.total_number_of_work_unit?.message
                        : ""
                    }
                    textBoxError={
                      errors.total_number_of_work_unit ? true : false
                    }
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Type of WorkUnit Payment
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <CustomAutoComplete
                    dataList={workUnitTypeList}
                    selectedValue={workUnitType}
                    rules={{
                      ...register("unit_type", {
                        required: "Type of workunit payment is required",
                      }),
                    }}
                    updateDataFunction={handleWorkUnitPaymentType}
                    placeHolder="Select the workunit payment"
                    errorMessage={
                      errors?.unit_type?.message
                        ? errors?.unit_type?.message
                        : ""
                    }
                    textBoxError={errors.unit_type ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
              {workUnitType === "Default" ? (
                <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Default Number of WorkUnit
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomTextBox
                      selectedValue={defaultWorkUnit}
                      rules={{
                        ...register("default_unit", {
                          required: "Default number of workunit is required",
                        }),
                      }}
                      updateDataFunction={updateDefaultWorkUnitNumber}
                      placeHolder="Enter default work unit"
                      errorMessage={
                        errors?.default_unit?.message
                          ? errors?.default_unit?.message
                          : ""
                      }
                      textBoxError={errors.default_unit ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              ) : (
                <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Amount Per WorkUnit
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <CustomTextBox
                      selectedValue={workUnitAmount}
                      rules={{
                        ...register("amount", {
                          required: "Default number of workunit is required",
                        }),
                      }}
                      updateDataFunction={updateDefaultWorkUnitAmount}
                      placeHolder="Enter amount per work unit"
                      errorMessage={
                        errors?.amount?.message ? errors?.amount?.message : ""
                      }
                      textBoxError={errors.amount ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              )}
              <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Effective From
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={8}>
                  <Mui.TextField
                    id="from-date"
                    type="date"
                    size="small"
                    {...register("effective_from", {
                      required: "Date is required.",
                      validate: validateStartDate,
                    })}
                    error={Boolean(errors.effective_from)}
                    helperText={
                      errors.effective_from?.message &&
                      errors.effective_from.message
                    }
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      },
                    }}
                    onBlur={() => trigger("effective_from")}
                    fullWidth
                    disableOpenPicker
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid
                container
                sx={{
                  paddingTop: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setPayUnit(false);
                    setShowTopBar(true);
                  }}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                <CustomButton
                  actionName="Create"
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
