import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { PayrunPeriodDateRange } from "./PayrunPeriodRange";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
const keys = ["user"];
export const AttendanceTimeLine = ({
  viewPayrunDetails,
  setDownloadReportDetails,
  downloadReportDetails,
  summaryName,
  companyName,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [leavePage, setLeavePage] = useState(0);
  const [attendanceDataDetails, setAttendanceDataDetails] = useState([]);
  const {
    result: attendanceDetails,
    setLoad: reCallPayScheduleApi,
    loading,
  } = useFetch(
    `${domain}pay-schedule/attendance-report?pay_schedule_id=${viewPayrunDetails.id}`
  );

  useEffect(() => {
    if (attendanceDetails?.attendance_data?.length !== 0) {
      setAttendanceDataDetails(attendanceDetails?.attendance_data);
    }
  }, [attendanceDetails?.attendance_data]);

  useEffect(() => {
    const downloadReports = () => {
      if (attendanceDataDetails?.length !== 0) {
        let dataPreparation = [];
        attendanceDataDetails?.forEach((element) => {
          let overAllData = {};
          element.attendance.forEach((attendance_data) => {
            overAllData = {
              ...overAllData,
              [attendance_data.date]: attendance_data.legend,
            };
          });
          overAllData = {
            ...overAllData,
            Present: element.status_counts.Present,
          };
          overAllData = {
            user_name: element.user,
            ...overAllData,
            Present: element.status_counts.Present,
            Leave: element.status_counts.Leave,
            Absent: element.status_counts.Absent,
            halfDay: 0,
            Permission: element.status_counts.Permission,
          };
          dataPreparation = [...dataPreparation, overAllData];
        });
        const company_Name = ["Company Name"];
        const pay_ScheduleName = ["PaySchedule Name"];
        const paySchedulePeriod = ["PaySchedule Period"];
        const TableHead = ["Employee Name"];
        attendanceDetails?.headers?.forEach((element) => {
          TableHead.push(element.day_number);
        });
        TableHead.push("P");
        TableHead.push("L");
        TableHead.push("A");
        TableHead.push("HL");
        TableHead.push("PR");
        company_Name.push(companyName);
        pay_ScheduleName.push(viewPayrunDetails.name);
        paySchedulePeriod.push(
          viewPayrunDetails.start_date + "  to  " + viewPayrunDetails.end_date
        );
        const items = dataPreparation;
        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object?.keys(items[0]).filter(
          (field) => field !== "break_details"
        );
        let csv = items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        );
        csv.unshift(TableHead.join(","));
        csv.unshift(paySchedulePeriod.join(","));
        csv.unshift(pay_ScheduleName.join(","));
        csv.unshift(company_Name.join(","));
        csv = csv.join("\r\n");
        const ConvertData = csv;
        const element = document.createElement("a");
        const file = new Blob([ConvertData], {
          type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = "AttendanceTimeline.csv";
        document.body.appendChild(element);
        element.click();
      } else {
        showToast("error", "No data available");
      }
    };
    if (downloadReportDetails && summaryName === "Attendance Timeline") {
      downloadReports();
      setDownloadReportDetails(false);
    }
  }, [
    attendanceDataDetails,
    attendanceDetails?.headers,
    companyName,
    downloadReportDetails,
    setDownloadReportDetails,
    summaryName,
    viewPayrunDetails.end_date,
    viewPayrunDetails.name,
    viewPayrunDetails.start_date,
  ]);

  useEffect(() => {
    if (
      attendanceDetails?.attendance_data?.length !== 0 ||
      attendanceDetails?.attendance_data?.length === 0
    ) {
      const filteredResults = attendanceDetails?.attendance_data?.filter(
        (item) =>
          keys.some((key) =>
            item[key]
              ?.toString()
              .toLowerCase()
              .includes(globalSearchValue.toString().toLowerCase())
          )
      );
      setAttendanceDataDetails(filteredResults);
      setLeavePage(0);
    }
  }, [globalSearchValue, attendanceDetails]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader info="Loading..." />
      ) : (
        <PayrunPeriodDateRange
          setLeavePage={setLeavePage}
          leavePage={leavePage}
          attendanceDetails={attendanceDetails}
          attendanceDataDetails={attendanceDataDetails}
          payScheduleId={viewPayrunDetails.id}
          reCallPayScheduleApi={reCallPayScheduleApi}
          viewPayrunDetails={viewPayrunDetails}
        />
      )}
    </React.Fragment>
  );
};
