import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
// import { WorkUnitWise } from "./WorkUnitWise";
import { DownloadForOffline } from "@mui/icons-material";
import { showToast } from "services/utils/Status";
import dayjs from "dayjs";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { themes } from "services/constants";
import { DepartmentWise } from "./DepartmentWise";
import { ShiftWise } from "./ShiftWise";
// const keys = ["user"];globalSearchValueuseEffect
const today = dayjs().format("YYYY-MM-DD");
export const Reports = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { domain, userDetails } = useSelector((state) => state.tokenReducer);
  const [shiftCategoryName, setShiftCategoryName] =
    useState("All ShiftCategory");
  const [shiftCategoryId, setShiftCategoryId] = useState("99999");
  const { result: shiftCategoryList } = useFetch(
    `${domain}user-based-shiftcategory/?user_id=${userDetails?.id}`
  );
  const [departmentName, setDepartmentName] = useState("All Department");
  const [departmentId, setDepartmentId] = useState("99999");
  const [startDate, setStartDate] = useState(today);
  const [leavePage, setLeavePage] = useState(0);
  // const [attendanceDataDetails, setAttendanceDataDetails] = useState([]);
  const [screen, setScreen] = useState("Department wise");
  const [selectedComponent, setSelectedComponent] = useState("Department wise");
  const { result: allDepartmentDetails } = useFetch(
    `${domain}get-department/?user_id=${userDetails?.id}`
  );
  const departmentDetails = [
    {
      id: 99999,
      department_name: "All Department",
    },
    ...allDepartmentDetails,
  ];
  const shiftCategoryDetails = [
    {
      id: 99999,
      shift_category_name: "All ShiftCategory",
    },
    ...shiftCategoryList,
  ];
  const {
    result: shiftReports,
    setLoad: reCallShiftApi,
    loading: shiftLoading,
  } = useFetch(
    `${domain}shift-details-report/?user_id=${userDetails?.id}&date=${startDate}&shift_category_id=${shiftCategoryId}`
  );
  const {
    result: departmentReports,
    setLoad: reCallDepartmentApi,
    loading,
  } = useFetch(
    `${domain}department-details-report/?department_id=${departmentId}&user_id=${userDetails?.id}&date=${startDate}`
  );

  // useEffect(() => {
  //   if (departmentReports?.length !== 0 || departmentReports?.length === 0) {
  //     const filteredResults = departmentReports?.filter((item) =>
  //       keys.some((key) =>
  //         item[key]
  //           ?.toString()
  //           .toLowerCase()
  //           .includes(globalSearchValue.toString().toLowerCase())
  //       )
  //     );
  //     // setAttendanceDataDetails(filteredResults);
  //     setLeavePage(0);
  //   }
  // }, [globalSearchValue, departmentReports]);

  const handleDepartmentName = (event, data) => {
    if (data) {
      setDepartmentId(data?.id);
      setDepartmentName(data?.label);
      setTimeout(function () {
        reCallDepartmentApi(true);
      }, 150);
    } else {
      setDepartmentId("");
      setDepartmentName("");
    }
  };

  const dispatch = useDispatch();

  const handleChange = (event, newAlignment) => {
    setScreen(newAlignment);
    setSelectedComponent(newAlignment);
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Department wise":
        return (
          <DepartmentWise
            departmentReports={departmentReports}
            loading={loading}
            setLeavePage={setLeavePage}
            leavePage={leavePage}
          />
        );
      case "Shift wise":
        return (
          <ShiftWise
            shiftReports={shiftReports}
            shiftLoading={shiftLoading}
            setLeavePage={setLeavePage}
            leavePage={leavePage}
          />
        );
      // case "WorkUnit wise":
      //   return (
      //     <WorkUnitWise
      //       shiftReports={shiftReports}
      //       shiftLoading={shiftLoading}
      //       setLeavePage={setLeavePage}
      //       leavePage={leavePage}
      //     />
      //   );
      default:
        break;
    }
  };

  const updateCategoryName = (event, data) => {
    if (data) {
      setShiftCategoryName(data?.label);
      setShiftCategoryId(data?.shift_category_id);
      setTimeout(function () {
        reCallShiftApi(true);
      }, 1000);
    }
  };

  const downloadDepartmentSummarized = () => {
    if (departmentReports.length !== 0) {
      let dataPreparation = [];
      departmentReports?.forEach((element) => {
        let overAllData = {
          department: element?.department_name,
          total_assigned: element?.total_assigned,
          present: element?.present,
          missing: element?.missing,
          leave: element?.leave,
          absent: element?.absent,
          overtime: element?.overtime,
          early_exit: element?.early_exit,
          late_entry: element?.late_entry,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const Department = ["Department Name"];
      const Date = ["Date"];
      Department.push(departmentName);
      Date.push(startDate);
      const items = dataPreparation;
      const TableHead = ["Department Name"];
      TableHead.push("Total Assigned");
      TableHead.push("Present");
      TableHead.push("Missing");
      TableHead.push("Leave");
      TableHead.push("Absent");
      TableHead.push("Over Time");
      TableHead.push("Early Exit");
      TableHead.push("Late Entry");
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter((field) => field !== "type");
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(Date.join(","));
      csv.unshift(Department.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "SummarizedDepartmentWise.csv";
      document.body.appendChild(element);
      element.click();
    } else {
      showToast("error", "No data available");
    }
  };

  const downloadShiftSummarized = () => {
    if (shiftReports.length !== 0) {
      let dataPreparation = [];
      shiftReports?.forEach((element) => {
        let overAllData = {
          shift_name: element?.shift_name,
          total_assigned: element?.total_assigned,
          present: element?.present,
          missing: element?.missing,
          leave: element?.leave,
          absent: element?.absent,
          overtime: element?.overtime,
          early_exit: element?.early_exit,
          late_entry: element?.late_entry,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const Department = ["Shift Category Name"];
      const Date = ["Date"];
      Department.push(shiftCategoryName);
      Date.push(startDate);
      const items = dataPreparation;
      const TableHead = ["Shift Name"];
      TableHead.push("Total Assigned");
      TableHead.push("Present");
      TableHead.push("Missing");
      TableHead.push("Leave");
      TableHead.push("Absent");
      TableHead.push("Over Time");
      TableHead.push("Early Exit");
      TableHead.push("Late Entry");
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter((field) => field !== "type");
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(Date.join(","));
      csv.unshift(Department.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "SummarizedShiftWise.csv";
      document.body.appendChild(element);
      element.click();
    } else {
      showToast("error", "No data available");
    }
  };
  const downloadDepartmentDetailed = () => {
    if (departmentReports.length !== 0) {
      let dataPreparation = [];
      departmentReports?.forEach((element) => {
        element.shift_details.forEach((shift) => {
          let overAllData = {
            department_name: element?.department_name,
            shifftname: shift?.shift_name,
            total_assigned: shift?.total_assigned,
            present: shift?.present,
            missing: shift?.missing,
            leave: shift?.leave,
            absent: shift?.absent,
            overtime: shift?.overtime,
            early_exit: shift?.early_exit,
            late_entry: shift?.late_entry,
          };
          dataPreparation = [...dataPreparation, overAllData];
        });
      });
      const Department = ["Department Name"];
      const Date = ["Date"];
      Department.push(departmentName);
      Date.push(startDate);
      const items = dataPreparation;
      const TableHead = ["Department Name"];
      TableHead.push("Shift Name");
      TableHead.push("Total Assigned");
      TableHead.push("Present");
      TableHead.push("Missing");
      TableHead.push("Leave");
      TableHead.push("Absent");
      TableHead.push("Over Time");
      TableHead.push("Early Exit");
      TableHead.push("Late Entry");
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter((field) => field !== "type");
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(Date.join(","));
      if (departmentName === "All Department") {
        csv.unshift(Department.join(","));
      }
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "DetailedDepartmentWise.csv";
      document.body.appendChild(element);
      element.click();
    } else {
      showToast("error", "No data available");
    }
  };
  const downloadShiftDetailed = () => {
    if (shiftReports.length !== 0) {
      let dataPreparation = [];
      shiftReports?.forEach((element) => {
        element?.department?.forEach((dep) => {
          let overAllData = {
            department_name: dep?.department_name,
            shift_name:element?.shift_name,
            total_assigned: dep?.total_assigned,
            present: dep?.present,
            missing: dep?.missing,
            leave: dep?.leave,
            absent: dep?.absent,
            overtime: dep?.overtime,
            early_exit: dep?.early_exit,
            late_entry: dep?.late_entry,
          };
          dataPreparation = [...dataPreparation, overAllData];
        });
      });
      const Department = ["Shift Category Name"];
      const Date = ["Date"];
      Department.push(shiftCategoryName);
      Date.push(startDate);
      const items = dataPreparation;
      const TableHead = ["Department Name"];
      TableHead.push('Shift Name');
      TableHead.push("Total Assigned");
      TableHead.push("Present");
      TableHead.push("Missing");
      TableHead.push("Leave");
      TableHead.push("Absent");
      TableHead.push("Over Time");
      TableHead.push("Early Exit");
      TableHead.push("Late Entry");
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter((field) => field !== "type");
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(Date.join(","));
      csv.unshift(Department.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "DetailedShiftWise.csv";
      document.body.appendChild(element);
      element.click();
    } else {
      showToast("error", "No data available");
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 3,
        }}
      >
        <Mui.Grid xs={7.2}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 320,
              height: 40,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Mui.ToggleButtonGroup
              value={screen}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: themes.lightBrown,
              }}
            >
              <Mui.ToggleButton
                value="Department wise"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 15,
                  fontWeight: 600,
                  border: "none",
                  color:
                    screen === "Department wise"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    screen === "Department wise"
                      ? themes.primaryIndicator
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.primaryIndicator,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.primaryIndicator,
                      color: themes.whiteColor,
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      screen === "Status"
                        ? themes.primaryIndicator
                        : themes.lightBrown,
                    color:
                      screen === "Department wise"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                  "&:focus": {
                    backgroundColor:
                      screen === "Department wise"
                        ? themes.primaryIndicator
                        : themes.lightBrown,
                    color:
                      screen === "Department wise"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setSelectedComponent("Department wise");
                }}
              >
                Department
              </Mui.ToggleButton>
              <Mui.ToggleButton
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setSelectedComponent("Shift wise");
                }}
                value="Shift wise"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 15,
                  fontWeight: 600,
                  border: "none",
                  color:
                    screen === "Shift wise"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    screen === "Shift wise"
                      ? themes.primaryIndicator
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.primaryIndicator,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.primaryIndicator,
                      color: themes.whiteColor,
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      screen === "Shift wise"
                        ? themes.primaryIndicator
                        : themes.lightBrown,
                    color:
                      screen === "Shift wise"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
              >
                Shift
              </Mui.ToggleButton>
              {/* <Mui.ToggleButton
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setSelectedComponent("WorkUnit wise");
                }}
                value="WorkUnit wise"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 15,
                  fontWeight: 600,
                  border: "none",
                  color:
                    screen === "WorkUnit wise"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    screen === "WorkUnit wise"
                      ? themes.primaryIndicator
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.primaryIndicator,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.primaryIndicator,
                      color: themes.whiteColor,
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      screen === "WorkUnit wise"
                        ? themes.primaryIndicator
                        : themes.lightBrown,
                    color:
                      screen === "WorkUnit wise"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
              >
                WorkUnit
              </Mui.ToggleButton> */}
            </Mui.ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid xs={2} sx={{ padding: 0.5 }}>
          {selectedComponent === "Shift wise" ? (
            <CustomAutoComplete
              dataList={shiftCategoryDetails?.map((obj) => ({
                ...obj,
                label: obj?.shift_category_name,
              }))}
              selectedValue={shiftCategoryName}
              updateDataFunction={updateCategoryName}
            />
          ) : (
            <CustomAutoComplete
              dataList={departmentDetails.map((obj) => ({
                ...obj,
                label: obj.department_name,
              }))}
              selectedValue={departmentName}
              updateDataFunction={handleDepartmentName}
              placeHolder="Please select the department name"
            />
          )}
        </Mui.Grid>

        <Mui.Grid xs={2.3} sx={{ padding: 0.5 }}>
          <Mui.TextField
            size="small"
            type="date"
            id="from_date"
            value={startDate}
            onChange={(event) => {
              setStartDate(event.target.value);
              if (screen === "Department wise") {
                reCallDepartmentApi(true);
              } else {
                reCallShiftApi(true);
              }
            }}
            fullWidth
          />
        </Mui.Grid>
        <Mui.Grid xs={0.5}>
          <Mui.IconButton
            onClick={(e) => {
              setAnchorEl(e?.currentTarget);
            }}
            sx={{
              backgroundColor: themes.primary,
              "&:hover": {
                backgroundColor: themes.primary,
              },
            }}
          >
            <DownloadForOffline
              fontSize="large"
              sx={{
                color: themes.blueColor,
              }}
            />
          </Mui.IconButton>
          <Mui.Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            elevation={1}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <Mui.MenuItem
              sx={{ width: "100%" }}
              onClick={() => {
                if (screen === "Department wise") {
                  downloadDepartmentSummarized();
                } else if (screen === "Shift wise") {
                  downloadShiftSummarized();
                }
                // setAnchorEl(null);
              }}
            >
              Summarized Reports
            </Mui.MenuItem>
            <Mui.MenuItem
              sx={{ width: "100%" }}
              onClick={() => {
                if (screen === "Department wise") {
                  downloadDepartmentDetailed();
                } else if (screen === "Shift wise") {
                  downloadShiftDetailed();
                }
                // setAnchorEl(null);
              }}
            >
              Detailed Reports
            </Mui.MenuItem>
          </Mui.Menu>
        </Mui.Grid>
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
