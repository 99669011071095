import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { summaryDetails } from "pages/PayRoll/PayRollHeaders";
import { getStatusColor } from "services/utils/Status";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { ConstStructure } from "../CostStructure";
import { OverTime } from "../OverTime";
import { SalaryRevision } from "../SalaryRevision";
import { AttendanceTimeLine } from "../AttendanceTimeLine";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { PayrunLog } from "../ApprovalPayroll/PayrunLog";
import { amountNumberFormat } from "services/utils/numberFormat";
import { DownloadForOffline } from "@mui/icons-material";

export const ViewPayRun = ({
  setViewPayRunScreen,
  companyId,
  companyName,
  viewPayrunDetails,
}) => {
  const [summaryName, setSummaryName] = useState("Cost Structure");
  const [payrollCost, setPayrollCost] = useState(0);
  const [employeeNetPay, setEmployeeNetPay] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [openSidePeek, setOpenSidePeek] = useState(false);
  const [downloadReportDetails, setDownloadReportDetails] = useState(false);
  const [reloadUserPayrollDetails, setReloadUserPayrollDetails] =
    useState(false);
  const handleSummaryDetails = (event, data) => {
    setSummaryName(data?.label);
  };
  const dispatch = useDispatch();

  const renderComponent = () => {
    switch (summaryName) {
      case "Cost Structure":
        return (
          <ConstStructure
            viewPayrunDetails={viewPayrunDetails}
            setPayrollCost={setPayrollCost}
            setEmployeeNetPay={setEmployeeNetPay}
            setTotalDeduction={setTotalDeduction}
            setTotalWorkingHours={setTotalWorkingHours}
            companyId={companyId}
            companyName={companyName}
            reloadUserPayrollDetails={reloadUserPayrollDetails}
            setReloadUserPayrollDetails={setReloadUserPayrollDetails}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
            summaryName={summaryName}
          />
        );
      case "Attendance Timeline":
        return (
          <AttendanceTimeLine
            viewPayrunDetails={viewPayrunDetails}
            companyName={companyName}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
            summaryName={summaryName}
          />
        );
      case "Over Time":
        return (
          <OverTime
            viewPayrunDetails={viewPayrunDetails}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
          />
        );
      case "Salary Revision":
        return (
          <SalaryRevision
            viewPayrunDetails={viewPayrunDetails}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
          />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Grid xs={8}>
          <Mui.Stack direction="Row">
            <BackArrow
              actionFunction={() => {
                setViewPayRunScreen(false);
                dispatch(setGlobalSearchValue(""));
              }}
            />
            <Mui.Typography
              sx={{
                paddingTop: 1,
                fontSize: 25,
                fontWeight: "bold",
              }}
            >
              Pay runs - {companyName} -{summaryName}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid xs={4}>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 1,
            }}
          >
            <Mui.Box
              sx={{
                width: 120,
                height: 15,
                backgroundColor: getStatusColor(viewPayrunDetails.status),
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: themes.whiteColor,
                p: 2,
                fontSize: 13,
              }}
            >
              {viewPayrunDetails.status}
            </Mui.Box>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        {summaryName === "Cost Structure" ? (
          <Mui.Grid xs={5}>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={8}>
                <Mui.Stack direction="row">
                  <Mui.Typography
                    sx={{ fontSize: 20, color: themes.colorShadow }}
                  >
                    Period :{" "}
                  </Mui.Typography>
                  <span
                    style={{
                      fontSize: 18,

                      padding: 2,
                      fontWeight: "bold",
                    }}
                  >
                    {viewPayrunDetails.start_date +
                      " to " +
                      viewPayrunDetails.end_date}
                  </span>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Stack direction="row">
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    {totalWorkingHours}
                  </Mui.Typography>{" "}
                  &nbsp;
                  <Mui.Typography
                    sx={{
                      paddingTop: 0.3,
                      fontSize: 18,
                      color: themes.colorShadow,
                    }}
                  >
                    Working days
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              component={Mui.Paper}
              elevation={4}
              sx={{ border: `2px solid ${themes.primaryIndicator}` }}
            >
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(payrollCost)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Payroll cost
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(employeeNetPay)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Employee's net pay
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(totalDeduction)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Total Deductions
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        ) : (
          <Mui.Grid xs={5}>
            <Mui.Grid container>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{ fontSize: 18, color: themes.colorShadow }}
                >
                  Payroll Period
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{ fontSize: 18, color: themes.colorShadow }}
                >
                  Total Working Days
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              component={Mui.Paper}
              elevation={4}
              sx={{ border: `2px solid ${themes.primaryIndicator}` }}
            >
              <Mui.Grid
                xs={6}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Stack direction="Row">
                  <Mui.Typography
                    sx={{
                      textAlign: "center",
                      padding: 3,
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {viewPayrunDetails.start_date} {" To "}
                    <span sx={{ paddingLeft: 1 }}>
                      {viewPayrunDetails.end_date}
                    </span>
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{
                    textAlign: "center",
                    padding: 3,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {totalWorkingHours}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        )}
        <Mui.Grid xs={7}>
          <Mui.Stack>
            <Mui.Grid
              container
              sx={{
                padding: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenSidePeek(true);
                }}
                actionName="View Log"
                typeName="button"
              />
            </Mui.Grid>
            <Mui.Grid
              container
              sx={{
                padding: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Mui.Grid xs={7}></Mui.Grid>
              <Mui.Grid xs={4}>
                <CustomAutoComplete
                  dataList={summaryDetails}
                  selectedValue={summaryName}
                  updateDataFunction={handleSummaryDetails}
                  placeHolder="Please select the summary"
                />
              </Mui.Grid>
              <Mui.Grid xs={1}>
                &nbsp;&nbsp;
                <Mui.IconButton
                  size="small"
                  onClick={() => setDownloadReportDetails(true)}
                >
                  <DownloadForOffline
                    size="large"
                    sx={{ color: "#25B7D3", fontSize: 35 }}
                  />
                </Mui.IconButton>
                &nbsp;
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
          paddingBottom: 1,
        }}
      >
        <Mui.Grid xs={9}></Mui.Grid>
        <Mui.Grid
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        ></Mui.Grid>
        <Mui.Grid
          xs={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        ></Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container>{renderComponent()}</Mui.Grid>
      {openSidePeek ? (
        <PayrunLog
          setOpenSidePeek={setOpenSidePeek}
          openSidePeek={openSidePeek}
          viewPayrunDetails={viewPayrunDetails}
        />
      ) : null}
    </React.Fragment>
  );
};
