import React from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomButton } from "components/CustomComponents/CustomButton";

export const AskProjectWorkFlow = ({
  setPage,
  setWorkflowId,
  workflowId,
  setCreateProject,
}) => {
  return (
    <React.Fragment>
      <Mui.Grid
        container
        spacing={2}
        sx={{
          padding: 5,
          paddingBottom: 0,
          paddingTop: 4,
          paddingLeft: 1,
        }}
        item
        direction="row"
        rowGap={1}
      >
        <Mui.Grid xs={0.5} sm={0.2} md={0.2} lg={0.2}></Mui.Grid>
        <Mui.Grid
          xs={12}
          sm={0.8}
          md={0.8}
          lg={0.8}
          sx={{ paddingTop: { xs: 1, sm: 0.8, md: 1, lg: 1 } }}
        >
          <BackArrow
            actionFunction={() => {
              setPage("Create Project");
            }}
          />
          &nbsp;
        </Mui.Grid>
        <Mui.Grid xs={10} sm={12} md={8} lg={6}>
          <Mui.Typography
            color={themes.headLine}
            sx={{
              fontWeight: "bold",
              paddingBottom: 3,
              fontFamily: "Popins",
              fontSize: { xs: 20, sm: 30, md: 42, lg: 45 },
              paddingLeft: { xs: 3, sm: 3 },
            }}
          >
            Select your project workflow...
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid xs={1} sm={0} md={3} lg={5}></Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Grid xs={0} sm={1} md={2} lg={2.5}></Mui.Grid>
        <Mui.Grid xs={12} sm={10} md={8} lg={7}>
          <Mui.Card
            sx={{
              borderWidth: 2,
              border: `4px solid ${themes.formBackground}`,
              marginTop: 4,
              borderRadius: 5,
            }}
            elevation={4}
          >
            <Mui.CardContent sx={{ paddingTop: 5 }}>
              <Mui.Grid container>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>ToDo</Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid xs={0.2}></Mui.Grid>
                <Mui.Grid item xs={3.2}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.2}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    InProgress
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 10px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={0.2}></Mui.Grid>
                <Mui.Grid item xs={3.2}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.2}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    Complete
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 10px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={0.3}></Mui.Grid>
                <Mui.Grid item xs={0.5}>
                  <Mui.Radio
                    checked={1 === workflowId}
                    onChange={() => setWorkflowId(1)}
                    value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 50,
                        marginTop: -1,
                      },
                    }}
                  />
                </Mui.Grid>
                <Mui.Grid item xs={1.0}></Mui.Grid>
              </Mui.Grid>
            </Mui.CardContent>
          </Mui.Card>
          <Mui.Card
            sx={{
              borderWidth: 2,
              border: `4px solid ${themes.formBackground}`,
              marginTop: 4,
              borderRadius: 5,
            }}
            elevation={4}
          >
            <Mui.CardContent sx={{ paddingTop: 5 }}>
              <Mui.Grid container>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>ToDo</Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={1.9}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.1}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    InProgress
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 10px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={1.9}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.2}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    Review
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={2}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.2}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    Complete
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={0.2}></Mui.Grid>
                <Mui.Grid item xs={0.5}>
                  <Mui.Radio
                    value="b"
                    checked={2 === workflowId}
                    onChange={() => setWorkflowId(2)}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 50,
                        marginTop: -1,
                      },
                    }}
                  />
                  <Mui.Grid item xs={1.0}></Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.CardContent>
          </Mui.Card>

          <Mui.Card
            sx={{
              borderWidth: 2,
              border: `4px solid ${themes.formBackground}`,
              marginTop: 4,
              borderRadius: 5,
            }}
            elevation={4}
          >
            <Mui.CardContent sx={{ paddingTop: 5 }}>
              <Mui.Grid container>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>ToDo</Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={1.2}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.1}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    InProgress
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={1.2}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.1}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    Review
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={0.1}></Mui.Grid>
                <Mui.Grid item xs={1.2}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.1}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    Testing
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={1.2}>
                  <div style={{ paddingTop: 28 }}>
                    <div
                      style={{ borderBottom: `3px solid ${themes.headLine}` }}
                    ></div>
                  </div>
                </Mui.Grid>
                <Mui.Grid item xs={0.1}></Mui.Grid>
                <Mui.Grid item xs={1}>
                  <Mui.Typography style={{ fontSize: 15 }}>
                    Complete
                  </Mui.Typography>
                  <Mui.Box
                    sx={{
                      width: "70%",
                      padding: "5px 5px",
                      border: "1px solid rgba(0, 0, 0, 0.6)",
                      borderRadius: 4,
                      backgroundColor: themes.proWrkFlwClr,
                    }}
                  ></Mui.Box>
                </Mui.Grid>
                <Mui.Grid item xs={0.2}></Mui.Grid>
                <Mui.Grid item xs={0.5}>
                  <Mui.Radio
                    value="b"
                    name="radio-buttons"
                    checked={3 === workflowId}
                    onChange={() => setWorkflowId(3)}
                    inputProps={{ "aria-label": "A" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 50,
                        marginTop: -1,
                      },
                    }}
                  />
                  <Mui.Grid item xs={1.0}></Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.CardContent>
          </Mui.Card>
        </Mui.Grid>
        <Mui.Grid xs={0} sm={1} md={2} lg={2.5}></Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingTop: 5 }}>
        <Mui.Grid xs={9}></Mui.Grid>
        <Mui.Grid xs={2}>
          <CustomButton
            actionFuntion={() => {
              setCreateProject(true);
            }}
            typeName="submit"
            actionName="Add"
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
