import React, { useContext, useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { TaskContext } from "./TaskContext";
import { taskPriority, taskTypeArr } from "services/constants";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { showToast } from "services/utils/Status";
import { projectError } from "services/constants/ErrorMessages";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { projectSuccess } from "services/constants/SuccessMessages";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { setGlobalSearchValue } from "services/Redux/userToken";
import sessionHandling from "services/utils/notificationUtils";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import {
  setEditProject,
  setProjectStatus,
} from "services/Redux/leaveManagement";
import {
  setTaskNameFilter,
  setAssigneeFilter,
  setTaskownerFilter,
  setStartDateFilter,
  setEndDateFilter,
  setPriorityFilter,
  setTasktypeFilter,
  setLabelnameFilter,
} from "services/Redux/projectManagement";
import {
  FilterAlt,
  FormatListBulleted,
  GridView,
  Info,
  Timer,
  Close,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@mui/icons-material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import moment from "moment";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles({
  tableCell: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.headLine }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const TaskTopBar = ({ activeTap, setActiveTap }) => {
  const { register, formState, handleSubmit, reset, getValues, setValue } =
    useForm({
      mode: "onBlur",
    });
  const { errors } = formState;
  const classes = useStyles();
  const {
    editProject,
    projectDetails,
    setFetchTask,
    setEditProjectId,
    setSprintId,
    projectSprints,
    setProjectSprints,
    setSprintDetails,
    sprintDetails,
    allUsers,
    setProjectTasks,
    projectMasterTaskData,
    domain,
    token,
    editProjectId,
    taskLabels,
  } = useContext(TaskContext);
  const history = useHistory();
  const {
    taskNameFilter,
    assigneeFilter,
    taskownerFilter,
    startDateFilter,
    endDateFilter,
    priorityFilter,
    taskTypeFilter,
    labelNameFilter,
  } = useSelector((state) => state.projectReducer);
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };
  const [selectedProject, setselectedProject] = useState(
    editProject ? editProject?.projectname : ""
  );
  const sprName = sprintDetails?.name ? sprintDetails?.name : "Backlog";
  const sprId = projectSprints?.filter((spr) => spr.name === sprName);
  const [selectedSprint, setSelectedSprint] = useState(sprName);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [priority, setPriority] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTask, setSearchTask] = useState("");
  const [userName, setUserName] = useState([]);
  const [taskOwner, setTaskOwner] = useState([]);
  const [taskType, setTaskType] = useState("All");
  const [labelName, setLabelName] = useState("All");
  const [priProjMembers, setPriProjMembers] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [task_userName, setTaskUserName] = useState(null);
  const [task_sprintName, setTaskSprintName] = useState(sprName);
  const [task_sprintId, setTaskSprintId] = useState(sprId[0].id);
  const [task_startDate, setTaskStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const isDueDate =
    sprintDetails?.end_date !== null
      ? new Date(formatDate(sprintDetails?.end_date))
          .toISOString()
          .substring(0, 10)
      : new Date().toISOString().substring(0, 10);
  const [task_dueDate, setTaskDueDate] = useState(isDueDate);
  const [userId, setUserId] = useState(null);
  const [task_priority, setTaskPriority] = useState("Medium");
  const [taskName, setTaskName] = useState(null);
  const [taskError, setTaskError] = useState("");
  const [isDisAddTask, setIsDisAddTask] = useState(false);
  const [taskLabeslList, setTaskLabelsList] = useState(taskLabels);

  const isOnlySpaces = (str) => /^\s*$/.test(str);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const UpdateSprint = (item) => {
    setSprintDetails(item);
    setSprintId(item.id);
    setFetchTask(true);
  };

  const filterCommonValues = (arr1, arr2) => {
    const valuesInArr2 = arr2.map((item) => item["username"]);
    return arr1.filter((item) => valuesInArr2.includes(item["username"]));
  };

  useEffect(() => {
    const sprName = sprintDetails?.name ? sprintDetails?.name : "Backlog";
    const sprId = projectSprints?.filter((spr) => spr.name === sprName);
    setTaskSprintName(sprName);
    setTaskSprintId(sprId[0].id);
    const isDueDate =
      sprintDetails?.end_date !== null
        ? new Date(formatDate(sprintDetails?.end_date))
            .toISOString()
            .substring(0, 10)
        : new Date().toISOString().substring(0, 10);
    setTaskDueDate(isDueDate);
  }, [sprintDetails?.name, projectSprints, sprintDetails?.end_date]);

  useEffect(() => {
    setValue("duedate", task_dueDate);
  }, [task_dueDate, setValue]);

  useEffect(() => {
    if (editProject?.projectType === "Private") {
      const filteredMembers = filterCommonValues(
        allUsers,
        editProject?.members
      );
      setPriProjMembers(filteredMembers);
    }
  }, [editProject?.members, allUsers, editProject?.projectType]);

  useEffect(() => {
    const filterLabel = taskLabels?.filter(
      (labelDet) => labelDet?.label !== "Add label"
    );
    let addLabel = {
      id: 99999,
      label_name: "All",
      label: "All",
    };
    setTaskLabelsList([addLabel, ...filterLabel]);
  }, [taskLabels]);

  useEffect(() => {
    const filterData = () => {
      let filtered = projectMasterTaskData.filter(
        (item) =>
          (assigneeFilter && userName.length > 0
            ? userName.some((assignee) =>
                item?.current_assignee?.username.includes(assignee.username)
              )
            : true) &&
          (taskownerFilter && taskOwner.length > 0
            ? taskOwner.some((assignee) =>
                item?.task_owner?.username.includes(assignee.username)
              )
            : true) &&
          (startDateFilter && startDate
            ? new Date(item.start_date).toDateString() ===
              new Date(startDate).toDateString()
            : true) &&
          (endDateFilter && dueDate
            ? new Date(item.date).toDateString() ===
              new Date(dueDate).toDateString()
            : true) &&
          (priorityFilter && priority
            ? item.priority.name.includes(priority)
            : true) &&
          (taskTypeFilter && taskType
            ? item.task_type.includes(taskType === "All" ? "" : taskType)
            : true) &&
          (labelNameFilter && labelName
            ? labelName === "All"
              ? true
              : item?.labels?.some((label) => label.label_name === labelName)
            : true)
      );
      if (taskNameFilter) {
        const filedName = ["taskname"];
        const filteredResults = filtered.filter((item) =>
          filedName.some((key) =>
            item[key]
              ?.toString()
              .toLowerCase()
              .includes(searchTask.toString().toLowerCase())
          )
        );
        setProjectTasks(filteredResults);
      } else {
        setProjectTasks(filtered);
      }
    };
    filterData();
  }, [
    projectMasterTaskData,
    userName,
    startDate,
    endDateFilter,
    priority,
    setProjectTasks,
    dueDate,
    taskNameFilter,
    searchTask,
    assigneeFilter,
    taskOwner,
    taskownerFilter,
    startDateFilter,
    priorityFilter,
    taskType,
    taskTypeFilter,
    labelName,
    labelNameFilter,
  ]);

  useEffect(() => {
    const sprName = sprintDetails?.name ?? "";
    setSelectedSprint(sprName);
  }, [sprintDetails]);

  const id = undefined;

  const setClearFilter = () => {
    dispatch(setTaskNameFilter(false));
    dispatch(setAssigneeFilter(false));
    dispatch(setTaskownerFilter(false));
    dispatch(setStartDateFilter(false));
    dispatch(setEndDateFilter(false));
    dispatch(setPriorityFilter(false));
    dispatch(setTasktypeFilter(false));
    dispatch(setLabelnameFilter(false));
    setStartDate(null);
    setDueDate(null);
    setPriority(null);
    setSearchTask("");
    setUserName([]);
    setTaskOwner([]);
  };

  const onSubmit = async (data) => {
    if (taskError === "") {
      setIsDisAddTask(true);
      if (addTask) {
        try {
          const response = await fetch(`${domain}create_task/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              assignee: userId,
              priority: task_priority,
              due_date: task_dueDate,
              task_name: taskName,
              status: "ToDo",
              task_owner_id: userId,
              project_id: editProjectId,
              sprint_id: task_sprintId,
              start_date: task_startDate,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setFetchTask(true);
            showToast("success", projectSuccess.taskCreation);
            cancelAddTasketails();
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        } finally {
          setIsDisAddTask(false);
        }
      } else {
        setAddTask(true);
        setIsDisAddTask(false);
      }
    }
  };

  const setAddTaskDetails = () => {
    setAddTask(true);
  };

  const cancelAddTasketails = () => {
    setAddTask(false);
    setTaskUserName(null);
    setTaskSprintName(sprName);
    setTaskSprintId(sprId[0].id);
    setTaskStartDate(new Date().toISOString().substring(0, 10));
    const isDueDate =
      sprId[0].end_date !== null
        ? new Date(formatDate(sprId[0].end_date)).toISOString().substring(0, 10)
        : new Date().toISOString().substring(0, 10);
    setTaskDueDate(isDueDate);
    setUserId(null);
    setTaskPriority("Medium");
    setTaskName(null);
    setTaskError("");
    reset();
  };

  const compareDates = (d1, d2) => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (date1 < date2) {
      return true;
    } else if (date1 > date2) {
      return false;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: themes.whiteColor,
          position: "sticky",
          top: "50px",
          zIndex: 10,
        }}
      >
        <Mui.Grid
          container
          spacing={0}
          sx={{
            pt: 2,
            pr: "0px",
            marginRight: "0px",
            top: "60px",
            transition: "opacity 0.3s ease-in-out",
            backgroundColor: "transparent",
          }}
        >
          <Mui.Grid item xs={12} sm={6} md={6} lg={3}>
            <Mui.ButtonGroup variant="contained">
              {activeTap === "ListView" || activeTap === "KanbanView" ? (
                <Mui.Tooltip title="Go back">
                  <BackArrow
                    actionFunction={() => {
                      dispatch(setGlobalSearchValue(""));
                      history.push("/admin/project-management");
                    }}
                  />
                </Mui.Tooltip>
              ) : null}
              <Mui.Tooltip title="List View">
                <Mui.IconButton
                  onClick={() => {
                    setActiveTap("ListView");
                  }}
                >
                  <FormatListBulleted
                    sx={{
                      color:
                        activeTap === "ListView"
                          ? themes.primaryIndicator
                          : "none",
                    }}
                  />
                </Mui.IconButton>
              </Mui.Tooltip>
              <Mui.Tooltip title="KanBan View">
                <Mui.IconButton
                  onClick={() => {
                    setActiveTap("KanbanView");
                  }}
                >
                  <GridView
                    sx={{
                      color:
                        activeTap === "KanbanView"
                          ? themes.primaryIndicator
                          : "none",
                    }}
                  />
                </Mui.IconButton>
              </Mui.Tooltip>
              <Mui.Tooltip title="Filter">
                <Mui.IconButton onClick={handleClick}>
                  <FilterAlt
                    sx={{
                      color:
                        assigneeFilter ||
                        taskownerFilter ||
                        taskNameFilter ||
                        startDateFilter ||
                        endDateFilter ||
                        priorityFilter ||
                        taskTypeFilter ||
                        labelNameFilter
                          ? themes.greenColor
                          : themes.redColor,
                    }}
                  />
                </Mui.IconButton>
              </Mui.Tooltip>

              <Mui.Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Mui.Card
                  sx={{
                    maxWidth: "1000px",
                    width: "100%",
                    fontSize: 14,
                    paddingRight: 2,
                  }}
                >
                  <Mui.CardHeader
                    style={{ paddingBottom: "0px" }}
                    action={
                      <Mui.Button aria-label="settings" onClick={handleClose}>
                        X
                      </Mui.Button>
                    }
                    title="Filter"
                  />
                  <Mui.DialogContent
                    style={{
                      paddingTop: "0px",
                      paddingBottom: "5px",
                      paddingRight: "0px",
                    }}
                  >
                    <Mui.Grid container spacing={1.5}>
                      <Mui.Grid item xs={12} style={{ paddingBottom: "0px" }}>
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="Task Name"
                          onClick={() => {
                            setSearchTask("");
                            dispatch(setTaskNameFilter(!taskNameFilter));
                          }}
                          checked={taskNameFilter}
                        />
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="Task Owner"
                          onClick={() => {
                            setTaskOwner([]);
                            dispatch(setTaskownerFilter(!taskownerFilter));
                          }}
                          checked={taskownerFilter}
                        />
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="Assignee"
                          onClick={() => {
                            setUserName([]);
                            dispatch(setAssigneeFilter(!assigneeFilter));
                          }}
                          checked={assigneeFilter}
                        />
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="Start Date"
                          onClick={() => {
                            setStartDate(null);
                            dispatch(setStartDateFilter(!startDateFilter));
                          }}
                          checked={startDateFilter}
                        />
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="End Date"
                          onClick={() => {
                            setDueDate(null);
                            dispatch(setEndDateFilter(!endDateFilter));
                          }}
                          checked={endDateFilter}
                        />
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="Priority"
                          onClick={() => {
                            setPriority(null);
                            dispatch(setPriorityFilter(!priorityFilter));
                          }}
                          checked={priorityFilter}
                        />
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="Task Type"
                          onClick={() => {
                            setTaskType("All");
                            dispatch(setTasktypeFilter(!taskTypeFilter));
                          }}
                          checked={taskTypeFilter}
                        />
                        <Mui.FormControlLabel
                          control={<Mui.Checkbox />}
                          label="Label"
                          onClick={() => {
                            setLabelName("All");
                            dispatch(setLabelnameFilter(!labelNameFilter));
                          }}
                          checked={labelNameFilter}
                        />
                      </Mui.Grid>
                      {taskNameFilter ? (
                        <Mui.Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingTop: "0px" }}
                          sx={{ mb: 2 }}
                        >
                          <Mui.TextField
                            type="text"
                            onChange={(event) => {
                              setSearchTask(event.target.value);
                            }}
                            size="small"
                            label="Task name"
                            placeholder="Enter task name"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Mui.Grid>
                      ) : null}
                      {taskownerFilter ? (
                        <Mui.Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingTop: "0px" }}
                          sx={{ mb: 2 }}
                        >
                          <Mui.Autocomplete
                            id="checkboxes-tags-demo"
                            fullWidth
                            multiple
                            limitTags={1}
                            value={taskOwner}
                            disableCloseOnSelect
                            options={
                              editProject?.projectType === "Private"
                                ? priProjMembers
                                : allUsers
                            }
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.username
                                  ?.toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <li {...props}>
                                  <Mui.Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  <Mui.Avatar
                                    sx={{ width: 25, height: 25 }}
                                    alt={option.username
                                      .charAt(0)
                                      .toUpperCase()}
                                    src={option.image_url}
                                  />{" "}
                                  &nbsp;{option.username}
                                </li>
                              );
                            }}
                            componentsProps={{
                              popper: { style: { width: "fit-content" } },
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                setTaskOwner(value);
                              } else {
                                setTaskOwner([]);
                              }
                            }}
                            sx={{ minWidth: "100%" }}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                label="Task Owner"
                                value={taskOwner}
                                size="small"
                                sx={{ background: themes.whiteColor }}
                                name="username"
                                placeholder="Select owner"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </Mui.Grid>
                      ) : null}
                      {assigneeFilter ? (
                        <Mui.Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingTop: "0px" }}
                          sx={{ mb: 2 }}
                        >
                          <Mui.Autocomplete
                            id="checkboxes-tags-demo"
                            fullWidth
                            multiple
                            limitTags={1}
                            value={userName}
                            disableCloseOnSelect
                            options={
                              editProject?.projectType === "Private"
                                ? priProjMembers
                                : allUsers
                            }
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.username
                                  ?.toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            ListboxProps={{ style: { maxHeight: "150px" } }}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <li {...props}>
                                  <Mui.Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  <Mui.Avatar
                                    sx={{ width: 25, height: 25 }}
                                    alt={option.username
                                      .charAt(0)
                                      .toUpperCase()}
                                    src={option.image_url}
                                  />
                                  &nbsp;{option.username}
                                </li>
                              );
                            }}
                            componentsProps={{
                              popper: { style: { width: "fit-content" } },
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                setUserName(value);
                              } else {
                                setUserName([]);
                              }
                            }}
                            sx={{ minWidth: "100%" }}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                label="Assignee"
                                value={userName}
                                size="small"
                                sx={{ background: themes.whiteColor }}
                                name="username"
                                placeholder="Select assignee"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </Mui.Grid>
                      ) : null}
                      {startDateFilter ? (
                        <Mui.Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingTop: "0px" }}
                          sx={{ mb: 2 }}
                        >
                          <Mui.TextField
                            id="from-date"
                            type="date"
                            size="small"
                            label="Start Date"
                            value={startDate}
                            sx={{ background: themes.whiteColor }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event) => {
                              setStartDate(event.target.value);
                            }}
                            fullWidth
                            disableOpenPicker
                            inputProps={{
                              max: "9999-12-31",
                            }}
                          />
                        </Mui.Grid>
                      ) : null}
                      {endDateFilter ? (
                        <Mui.Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingTop: "0px" }}
                          sx={{ mb: 2 }}
                        >
                          <Mui.TextField
                            id="to-date"
                            type="date"
                            size="small"
                            label="End Date"
                            value={dueDate}
                            sx={{ background: themes.whiteColor }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event) => {
                              setDueDate(event.target.value);
                            }}
                            fullWidth
                            disableOpenPicker
                            inputProps={{
                              max: "9999-12-31",
                            }}
                          />
                        </Mui.Grid>
                      ) : null}
                      {priorityFilter ? (
                        <Mui.Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingTop: "0px" }}
                          sx={{ mb: 2 }}
                        >
                          <Mui.Autocomplete
                            id="combo-box-demo"
                            fullWidth
                            value={priority}
                            options={taskPriority}
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.priority
                                  ?.toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderOption={(props, option) => {
                              return <li {...props}>{option.priority}</li>;
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                setPriority(value.priority);
                              } else {
                                setPriority(null);
                              }
                            }}
                            sx={{ minWidth: "100%" }}
                            componentsProps={{
                              popper: { style: { width: "fit-content" } },
                            }}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                value={priority}
                                label="Priority"
                                size="small"
                                sx={{ background: themes.whiteColor }}
                                name="priority"
                                placeholder="Select priority"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </Mui.Grid>
                      ) : null}
                      {taskTypeFilter ? (
                        <Mui.Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingTop: "0px" }}
                          sx={{ mb: 2 }}
                        >
                          <Mui.Autocomplete
                            id="combo-box-demo"
                            fullWidth
                            value={taskType}
                            options={taskTypeArr}
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.label
                                  ?.toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderOption={(props, option) => {
                              return <li {...props}>{option.label}</li>;
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                setTaskType(value.label);
                              } else {
                                setTaskType("All");
                              }
                            }}
                            sx={{ minWidth: "100%" }}
                            componentsProps={{
                              popper: { style: { width: "fit-content" } },
                            }}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                value={taskType}
                                label="Task Type"
                                size="small"
                                sx={{ background: themes.whiteColor }}
                                name="tasktype"
                                placeholder="Select Tasktype"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </Mui.Grid>
                      ) : null}
                      {labelNameFilter ? (
                        <Mui.Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingTop: "0px" }}
                          sx={{ mb: 2 }}
                        >
                          <Mui.Autocomplete
                            id="combo-box-demo"
                            fullWidth
                            value={labelName}
                            options={taskLabeslList}
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.label
                                  ?.toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderOption={(props, option) => {
                              return <li {...props}>{option.label}</li>;
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                setLabelName(value.label);
                              } else {
                                setLabelName("All");
                              }
                            }}
                            sx={{ minWidth: "100%" }}
                            componentsProps={{
                              popper: { style: { width: "fit-content" } },
                            }}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                value={labelName}
                                label="Label"
                                size="small"
                                sx={{ background: themes.whiteColor }}
                                name="label"
                                placeholder="Select Label"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </Mui.Grid>
                      ) : null}

                      <Mui.Grid item xs={12}>
                        <Mui.Button
                          variant="outlined"
                          size="small"
                          onClick={setClearFilter}
                        >
                          Clear Filters
                        </Mui.Button>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.DialogContent>
                </Mui.Card>
              </Mui.Popover>
            </Mui.ButtonGroup>
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
            <Mui.Tooltip
              title={
                sprintDetails?.start_date + " to " + sprintDetails?.end_date
              }
            >
              {sprintDetails &&
              sprintDetails?.name !== "Backlog" &&
              sprintDetails?.status === "Current" ? (
                <Mui.Stack direction="row" sx={{ mt: 1 }}>
                  {sprintDetails?.remaining_days >= 0 ? (
                    <>
                      <Timer sx={{ color: themes.primaryIndicator }} />
                      <Mui.Typography sx={{ fontSize: 14 }}>
                        {sprintDetails?.remaining_days} days remaining
                      </Mui.Typography>
                    </>
                  ) : sprintDetails?.status === "Current" &&
                    compareDates(
                      sprintDetails?.end_date,
                      moment(new Date()).format("DD-MM-YYYY")
                    ) ? (
                    <>
                      <Timer sx={{ color: themes.redColor }} />
                      <Mui.Typography
                        sx={{
                          fontSize: 14,
                          color: themes.redColor,
                          mt: 0.3,
                          ml: 0.5,
                        }}
                      >
                        Deadline Expired
                      </Mui.Typography>
                    </>
                  ) : null}
                </Mui.Stack>
              ) : null}
            </Mui.Tooltip>
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={6} md={6} lg={3}>
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={selectedProject}
              options={projectDetails.filter(
                (pro) => pro.status?.status === "Active"
              )}
              style={{ fontSize: 14, padding: "0px" }}
              sx={{ width: "75%", fontSize: 14, padding: "0px" }}
              ListboxProps={{ style: { maxHeight: "150px" } }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.label
                    ?.toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.label}</li>;
              }}
              onChange={(event, value) => {
                if (value) {
                  const curSprint = value?.sprints?.filter(
                    (spr) => spr.status === "Current"
                  );
                  if (curSprint.length === 0) {
                    setSprintId(value.sprints[value.sprints.length - 1].id);
                    setSprintDetails(value.sprints[value.sprints.length - 1]);
                  } else {
                    setSprintId(curSprint[0].id);
                    setSprintDetails(curSprint[0]);
                  }
                  setselectedProject(value.label);
                  setFetchTask(true);
                  setEditProjectId(value.id);
                  setProjectSprints(value.sprints);
                  let status = [];
                  value?.workflow?.statuses.forEach((element) => {
                    status.push(element.name);
                  });
                  dispatch(setProjectStatus(status));
                  localStorage.setItem(
                    "currentEditProject",
                    JSON.stringify(value)
                  );
                  dispatch(setEditProject(value));
                }
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  value={selectedProject}
                  label="Project"
                  placeholder={projectPlaceholder.selectProject}
                  sx={{
                    background: themes.whiteColor,
                    borderRadius: 2,
                    fontSize: 14,
                    padding: "0px",
                  }}
                  size="small"
                />
              )}
            />
          </Mui.Grid>
          {projectSprints?.length > 0 ? (
            <>
              <Mui.Grid item xs={12} sm={6} md={6} lg={3}>
                <Mui.Stack direction="row">
                  <Mui.FormControl fullWidth>
                    <Mui.InputLabel id="sprint-select">Sprint</Mui.InputLabel>
                    <Mui.Select
                      labelId="sprint-select"
                      id="sprint-select-label"
                      value={selectedSprint}
                      label="Sprint"
                      size="small"
                      fullWidth
                      sx={{ width: "75%", fontSize: 14, padding: "1px" }}
                      onChange={(event) => {
                        setSelectedSprint(event.target.value);
                        const selSpr = projectSprints?.filter(
                          (spr) => spr.name === event.target.value
                        );
                        UpdateSprint(selSpr[0]);
                      }}
                      renderValue={(selected) => selected}
                      placeholder={projectPlaceholder.selectSprint}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            minHeight: "200px",
                            maxHeight: "400px",
                            maxWidth: "50px",
                          },
                        },
                      }}
                    >
                      {projectSprints
                        ?.filter((sprints) => sprints.is_completed === false)
                        .map((prospr) => (
                          <Mui.MenuItem value={prospr.name} key={prospr.id}>
                            <Mui.ListItemText primary={prospr.name} />
                          </Mui.MenuItem>
                        ))}
                    </Mui.Select>
                  </Mui.FormControl>
                  <Mui.Tooltip title="Show Sprint Details">
                    <Mui.IconButton
                      sx={{ color: themes.blueColor, ml: -10 }}
                      onClick={() => {
                        localStorage.setItem("sprDashboard", true);
                        localStorage.setItem(
                          "sprDashData",
                          JSON.stringify(sprintDetails)
                        );
                        dispatch(setGlobalSearchValue(""));
                        history.push("/admin/project/sprint/dashboard");
                      }}
                    >
                      <Info />
                    </Mui.IconButton>
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
            </>
          ) : (
            ""
          )}
        </Mui.Grid>

        {activeTap === "ListView" ? (
          <>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={9} sm={8} md={10} lg={10}></Mui.Grid>
              <Mui.Grid
                xs={5}
                sm={4}
                md={2}
                lg={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 3,
                }}
              >
                {editProject &&
                editProject?.status?.status !== "Completed" &&
                editProject?.status?.status !== "Inactive" &&
                sprintDetails?.status !== "Completed" ? (
                  <>
                    {!addTask ? (
                      <CustomButton
                        actionFuntion={setAddTaskDetails}
                        actionName="Add Task"
                      />
                    ) : null}
                  </>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            <div>
              <GlobalDialogBox
                handleCloseDialog={cancelAddTasketails}
                open={addTask}
                title={"Add Task"}
                minWidth={750}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid container xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 19,
                          color: themes.headLine,
                          padding: 1,
                          fontWeight: "bold",
                        }}
                      >
                        Task Name
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={8}>
                      <Mui.TextField
                        type="text"
                        size="small"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          fontSize: 14,
                          width: "100%",
                        }}
                        InputProps={{
                          inputProps: { maxLength: 512 },
                        }}
                        value={taskName}
                        {...register("taskname", {
                          required: "Please enter taskname",
                          validate: (value) =>
                            !isOnlySpaces(value) ||
                            "Task name cannot be only spaces",
                        })}
                        placeholder={projectPlaceholder.taskName}
                        onChange={(e) => {
                          setTaskName(e.target.value);
                          if (e.target.value === "") {
                            setTaskError(projectError.taskNameError);
                          } else if (isOnlySpaces(e.target.value)) {
                            setTaskError("Task name cannot be only spaces");
                          } else {
                            setTaskError("");
                          }
                        }}
                        error={errors.taskname}
                        fullWidth
                        helperText={
                          errors.taskname ? errors.taskname.message : ""
                        }
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 19,
                          color: themes.headLine,
                          padding: 1,
                          fontWeight: "bold",
                        }}
                      >
                        User Name
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={8}>
                      <Mui.Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        fullWidth
                        value={task_userName}
                        options={
                          editProject?.projectType === "Private"
                            ? priProjMembers
                            : allUsers
                        }
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            option.username
                              ?.toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          );
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props}>
                              <Mui.Avatar
                                sx={{ width: 25, height: 25 }}
                                alt={option.username.charAt(0).toUpperCase()}
                                src={option.image_url}
                              />{" "}
                              &nbsp;{option.username}
                            </li>
                          );
                        }}
                        onChange={(event, value) => {
                          if (value) {
                            setTaskUserName(value.username);
                            setUserId(value.id);
                          } else {
                            setTaskUserName(null);
                            setUserId(null);
                          }
                        }}
                        sx={{ minWidth: "100%" }}
                        renderInput={(params) => (
                          <Mui.TextField
                            {...params}
                            value={task_userName}
                            {...register("assignee", {
                              required: false,
                            })}
                            error={errors.assignee}
                            helperText={
                              errors.assignee ? errors.assignee.message : ""
                            }
                            size="small"
                            sx={{ background: themes.whiteColor }}
                            name="username"
                            placeholder={projectPlaceholder.selectUser}
                          />
                        )}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 19,
                          color: themes.headLine,
                          padding: 1,
                          fontWeight: "bold",
                        }}
                      >
                        Sprint
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={8}>
                      <Mui.Select
                        value={task_sprintName}
                        size="small"
                        fullWidth
                        sx={{ minWidth: "100%", background: themes.whiteColor }}
                        onChange={(event) => {
                          setTaskSprintName(event.target.value);
                          const selSpr = projectSprints?.filter(
                            (spr) => spr.name === event.target.value
                          );
                          setTaskSprintId(selSpr[0].id);
                        }}
                        renderValue={(selected) => selected}
                        placeholder={projectPlaceholder.selectSprint}
                      >
                        {projectSprints
                          .filter((element) => {
                            return element.is_completed === false;
                          })
                          .map((prospr) => (
                            <Mui.MenuItem value={prospr.name} key={prospr.id}>
                              <Mui.ListItemText primary={prospr.name} />
                            </Mui.MenuItem>
                          ))}
                      </Mui.Select>
                    </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 19,
                          color: themes.headLine,
                          padding: 1,
                          fontWeight: "bold",
                        }}
                      >
                        Start Date
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={8}>
                      <Mui.TextField
                        id="from-date"
                        type="date"
                        size="small"
                        value={task_startDate}
                        {...register("startdate", {
                          required: false,
                        })}
                        sx={{
                          background: themes.whiteColor,
                          fontSize: 14,
                        }}
                        onChange={(event) =>
                          setTaskStartDate(event.target.value)
                        }
                        fullWidth
                        disableOpenPicker
                        inputProps={{
                          max: "9999-12-31",
                        }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 19,
                          color: themes.headLine,
                          padding: 1,
                          fontWeight: "bold",
                        }}
                      >
                        Due Date
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={8}>
                      <Mui.TextField
                        value={task_dueDate}
                        type="date"
                        size="small"
                        sx={{ background: themes.whiteColor }}
                        {...register("duedate", {
                          required: false,
                          validate: {
                            noDigitsOrSpecial: (fieldValue) => {
                              if (
                                new Date(fieldValue) <
                                new Date(getValues("startdate"))
                              ) {
                                return "Due date is lesser then start date";
                              } else {
                                return true;
                              }
                            },
                          },
                        })}
                        onChange={(event) => {
                          setTaskDueDate(event.target.value);
                        }}
                        fullWidth
                        error={errors.duedate ? true : false}
                        helperText={
                          errors.duedate ? errors.duedate.message : ""
                        }
                        inputProps={{
                          max: "9999-12-31",
                        }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 19,
                          color: themes.headLine,
                          padding: 1,
                          fontWeight: "bold",
                        }}
                      >
                        Priority
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={8}>
                      <Mui.Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        fullWidth
                        value={task_priority}
                        options={taskPriority}
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            option.priority
                              ?.toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          );
                        }}
                        renderOption={(props, option) => {
                          return <li {...props}>{option.priority}</li>;
                        }}
                        onChange={(event, value) => {
                          if (value) {
                            setTaskPriority(value.priority);
                          } else {
                            setTaskPriority(null);
                          }
                        }}
                        renderInput={(params) => (
                          <Mui.TextField
                            {...params}
                            {...register("priority", {
                              required: false,
                            })}
                            value={task_priority}
                            size="small"
                            sx={{ background: themes.whiteColor }}
                            name="priority"
                            placeholder={projectPlaceholder.selectPriority}
                            fullWidth
                          />
                        )}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid xs={8}></Mui.Grid>
                    <Mui.Grid
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <CustomButton
                        actionFuntion={cancelAddTasketails}
                        actionName="Cancel"
                        typeName="button"
                      />
                      &nbsp;
                      <CustomButton
                        actionName="Add"
                        typeName="submit"
                        disableAction={isDisAddTask}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </form>
              </GlobalDialogBox>
            </div>
            <Mui.Grid container sx={{ paddingRight: 1 }}>
              <Mui.TableContainer
                component={Mui.Paper}
                style={{ paddingTop: 4, paddingBottom: 2 }}
                sx={{
                  mt: 1,
                  backgroundColor: themes.primary,
                  borderBottom: "1.5px solid transparent",
                }}
              >
                <Mui.Table
                  style={{
                    tableLayout: "auto",
                    borderBottom: "1.5px solid transparent",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  sx={{ backgroundColor: themes.primary }}
                >
                  <Mui.TableHead
                    sx={{
                      backgroundColor: themes.primary,
                    }}
                  >
                    <Mui.TableRow
                      maxWidth="xl"
                      align="left"
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "15px",
                          padding: "4px 15px",
                          backgroundColor: themes.primary,
                          borderBottom: "1.5px solid transparent",
                        },
                      }}
                    >
                      <Mui.TableCell
                        className={classes.tableCell}
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "30%",
                          borderRight: 1,
                          borderColor: "transparent",
                        }}
                      >
                        Task
                      </Mui.TableCell>
                      <Mui.TableCell
                        className={classes.tableCell}
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "13%",
                          borderRight: 1,
                          borderColor: "transparent",
                        }}
                      >
                        Label
                      </Mui.TableCell>
                      <Mui.TableCell
                        className={classes.tableCell}
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "13%",
                          borderRight: 1,
                          borderColor: "transparent",
                        }}
                      >
                        Assignee
                      </Mui.TableCell>
                      <Mui.TableCell
                        className={classes.tableCell}
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "11%",
                          borderRight: 1,
                          borderColor: "transparent",
                        }}
                      >
                        Hours
                      </Mui.TableCell>
                      <Mui.TableCell
                        className={classes.tableCell}
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "13%",
                          borderRight: 1,
                          borderColor: "transparent",
                        }}
                      >
                        Task Owner
                      </Mui.TableCell>
                      <Mui.TableCell
                        className={classes.tableCell}
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "10%",
                          borderRight: 1,
                          borderColor: "transparent",
                        }}
                      >
                        Due Date
                      </Mui.TableCell>
                      <Mui.TableCell
                        className={classes.tableCell}
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "10%",
                          borderRight: 1,
                          borderColor: "transparent",
                        }}
                      >
                        Priority
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableHead>
                </Mui.Table>
              </Mui.TableContainer>
            </Mui.Grid>
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
};
