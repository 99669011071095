import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { NewPaySchedule } from "./NewPaySchedule";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { PayScheduleTable } from "./PayScheduleTable";
import { EditPaySchedule } from "./EditPaySchedule";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
const keys = [
  "name",
  "start_date",
  "end_date",
  "run_date",
  "num_of_employees",
  "status",
  "total_net_pay",
];

const PaysheduleKeys = ["name", "start_date", "end_date", "run_date", "status"];

export const PaySchedule = ({ companyName, companyId, setViewPayRunScreen }) => {
  const { domain, globalSearchValue, token } = useSelector(
    (state) => state.tokenReducer
  );
  const [createNewPaySchedule, setCreateNewPaySchedule] = useState(false);
  const [editPayScheduleDetails, setEditPayScheduleDetails] = useState({});
  const [editPaySchedule, setEditPaySchedule] = useState(false);
   const [updatePayrunCycle, setUpdatePayrunCycle] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [paySheduleData, setPaySheduleData] = useState([]);
  const [currentPaySheduleData, setCurrentPaySheduleData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [listViewPage, setListViewPage] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {
    result: payScheduleDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}payschedule/?company=${companyId}` : null
  );

  const {
    result: currentPaySchedule,
    setLoad: reCallApiWithApproval,
    loading: approvalLoading,
  } = useFetch(
    companyId !== 0 ? `${domain}past-pay-schedules/?company=${companyId}` : null
  );

  useEffect(() => {
    if (updateData && payScheduleDetails?.length !== 0 && payScheduleDetails) {
      setPaySheduleData(payScheduleDetails);
      setUpdateData(false);
    }
  }, [payScheduleDetails, updateData]);

  useEffect(() => {
    if (payScheduleDetails?.length !== 0 || payScheduleDetails?.length === 0) {
      const filteredResults = payScheduleDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setPaySheduleData(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payScheduleDetails]);

  useEffect(() => {
    if (updateData && currentPaySchedule?.length !== 0 && currentPaySchedule) {
      setCurrentPaySheduleData(currentPaySchedule);
      setUpdateData(false);
    }
  }, [currentPaySchedule, updateData]);

  useEffect(() => {
    if (currentPaySchedule?.length !== 0 || currentPaySchedule?.length === 0) {
      const filteredResults = currentPaySchedule?.filter((item) =>
        PaysheduleKeys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setCurrentPaySheduleData(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, currentPaySchedule]);

  const updateDeletePayrun = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(
        `${domain}payschedule/${editPayScheduleDetails?.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({ isdeleted: true }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Deleted successfully");
        reCallApi(true);
        setOpenDialog(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <React.Fragment>
      {!createNewPaySchedule && !editPaySchedule ? (
        <React.Fragment>
          {loading || approvalLoading ? (
            <Loader />
          ) : (
            <Mui.Grid container>
              <Mui.Grid container>
                <Mui.Grid xs={5}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                    Upcoming PaySchedule
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid
                  xs={7}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingRight: 1,
                  }}
                >
                  <CustomButton
                    actionFuntion={() => {
                      setCreateNewPaySchedule(true);
                      setViewPayRunScreen(true);
                    }}
                    actionName="Add Payrun"
                    typeName="button"
                  />
                </Mui.Grid>
              </Mui.Grid>
              <PayScheduleTable
                buttonDisabled={buttonDisabled}
                listViewPage={listViewPage}
                setListViewPage={setListViewPage}
                payrunDetails={paySheduleData}
                setEditPaySchedule={setEditPaySchedule}
                setEditPayScheduleDetails={setEditPayScheduleDetails}
                editPayScheduleDetails={editPayScheduleDetails}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                updateDeletePayrun={updateDeletePayrun}
                buttonNotify="Upcoming PaySchedule"
                reCallApi={reCallApi}
                reCallApiWithApproval={reCallApiWithApproval}
                setViewPayRunScreen={setViewPayRunScreen}
                setUpdatePayrunCycle={setUpdatePayrunCycle}
              />
              <Mui.Typography
                sx={{ paddingTop: 2, fontWeight: "bold", fontSize: 20 }}
              >
                Current PaySchedule
              </Mui.Typography>
              <PayScheduleTable
                buttonDisabled={buttonDisabled}
                listViewPage={listViewPage}
                setListViewPage={setListViewPage}
                payrunDetails={currentPaySheduleData}
                setEditPaySchedule={setEditPaySchedule}
                setEditPayScheduleDetails={setEditPayScheduleDetails}
                editPayScheduleDetails={editPayScheduleDetails}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                updateDeletePayrun={updateDeletePayrun}
                buttonNotify="Current PaySchedule"
                reCallApi={reCallApi}
                reCallApiWithApproval={reCallApiWithApproval}
                setViewPayRunScreen={setViewPayRunScreen}
                setUpdatePayrunCycle={setUpdatePayrunCycle}
              />
            </Mui.Grid>
          )}
        </React.Fragment>
      ) : null}

      {createNewPaySchedule ? (
        <NewPaySchedule
          companyId={companyId}
          companyName={companyName}
          setCreateNewPaySchedule={setCreateNewPaySchedule}
          setEditPaySchedule={setEditPaySchedule}
          setEditPayScheduleDetails={setEditPayScheduleDetails}
          reCallApi={reCallApi}
          reCallApiWithApproval={reCallApiWithApproval}
          setViewPayRunScreen={setViewPayRunScreen}
        />
      ) : null}
      {editPaySchedule ? (
        <EditPaySchedule
          setEditPaySchedule={setEditPaySchedule}
          setCreateNewPaySchedule={setCreateNewPaySchedule}
          setEditPayScheduleDetails={setEditPayScheduleDetails}
          editPayScheduleDetails={editPayScheduleDetails}
          reCallApiWithApproval={reCallApiWithApproval}
          reCallApi={reCallApi}
          companyName={companyName}
          companyId={companyId}
          setViewPayRunScreen={setViewPayRunScreen}
          updatePayrunCycle={updatePayrunCycle}
          setUpdatePayrunCycle={setUpdatePayrunCycle}
        />
      ) : null}
    </React.Fragment>
  );
};
