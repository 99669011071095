import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes, tableContainerSxWithCompany } from "services/constants";
import { MoreVert } from "@mui/icons-material";
import { AddPayRollGroup } from "./AddPayRollGroup";
import { EditPayRollGroup } from "./EditPayRollGroup";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { ViewPayRollGroup } from "./ViewPayRollGroup";
export const PayRollGroup = ({
  companyId,
  companyChange,
  setCompanyChange,
  setAddAction,
}) => {
  const [displayScreen, setDisplayScreen] = useState("ListView");
  const { domain } = useSelector((state) => state.tokenReducer);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [actionRowData, setActionRowData] = useState(null);

  const { result: payRollGroup, setLoad: reCallApi } = useFetch(
    `${domain}payroll-group/?company=${companyId}`
  );
  const [rowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (companyChange) {
      reCallApi(true);
      setCompanyChange(false);
    }
  }, [companyChange, reCallApi, setCompanyChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        {displayScreen === "AddPayRoll" ? (
          <AddPayRollGroup
            setDisplayScreen={setDisplayScreen}
            reCallApi={reCallApi}
            companyId={companyId}
            setAddAction={setAddAction}
          />
        ) : displayScreen === "EditPayRoll" ? (
          <EditPayRollGroup
            setDisplayScreen={setDisplayScreen}
            reCallApi={reCallApi}
            actionRowData={actionRowData}
            companyId={companyId}
            setAddAction={setAddAction}
          />
        ) : displayScreen === "ViewPayroll" ? (
          <ViewPayRollGroup
            setDisplayScreen={setDisplayScreen}
            actionRowData={actionRowData}
            companyId={companyId}
            setAddAction={setAddAction}
            reCallApi={reCallApi}
          />
        ) : (
          <Mui.Grid container>
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                padding: 1,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setDisplayScreen("AddPayRoll");
                  setAddAction(true);
                }}
                actionName="Add Group"
                typeName="submit"
              />
            </Mui.Grid>
            <Mui.TableContainer sx={tableContainerSxWithCompany}>
              <Mui.Table sx={{ border: `3px solid ${themes.sidebarDivider}` }}>
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.primary,
                    position: "sticky",
                    top: -1,
                    zIndex: 10,
                  }}
                >
                  <Mui.TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "10px",
                        padding: "6px 5px",
                      },
                    }}
                  >
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      Payroll Group Name
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Payrun cycle
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Payment Bank
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Payment Mode
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Employee Count
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Action
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {payRollGroup?.length === 0 ? (
                    <Mui.TableRow>
                      <Mui.TableCell colSpan={12} align="center">
                        <Mui.Alert severity="info">No data available</Mui.Alert>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ) : (
                    payRollGroup
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((payrollGroupData, index) => (
                        <React.Fragment>
                          <Mui.TableRow
                            sx={{
                              "& .MuiTableCell-root": {
                                height: "7px",
                                padding: "6px 5px",
                              },
                            }}
                          >
                            <Mui.TableCell>
                              <Mui.Tooltip title={payrollGroupData?.name}>
                                {payrollGroupData?.name?.length > 40
                                  ? payrollGroupData?.name.substr(0, 40) + "..."
                                  : payrollGroupData?.name}
                              </Mui.Tooltip>
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {payrollGroupData?.pay_run_cycle_name
                                ? payrollGroupData?.pay_run_cycle_name
                                : "N/A"}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {payrollGroupData?.source_bank_detail_name}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {payrollGroupData?.mode_of_payment}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {payrollGroupData?.users_detail?.length}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              <Mui.IconButton
                                onClick={(event) => {
                                  setActionRowData(payrollGroupData);
                                  setAnchorElMenu(event.currentTarget);
                                }}
                              >
                                <MoreVert />
                              </Mui.IconButton>
                              <Mui.Menu
                                anchorEl={anchorElMenu}
                                open={Boolean(anchorElMenu)}
                                onClose={() => {
                                  setAnchorElMenu(null);
                                }}
                                elevation={1}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <Mui.MenuItem
                                  onClick={() => {
                                    setDisplayScreen("ViewPayroll");
                                    setAddAction(true);
                                    setAnchorElMenu(null);
                                  }}
                                >
                                  View
                                </Mui.MenuItem>
                                <Mui.MenuItem
                                  sx={{ width: "100%" }}
                                  onClick={() => {
                                    setDisplayScreen("EditPayRoll");
                                    setAnchorElMenu(null);
                                    setAddAction(true);
                                  }}
                                >
                                  Edit
                                </Mui.MenuItem>
                              </Mui.Menu>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        </React.Fragment>
                      ))
                  )}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
            <Mui.Grid
              container
              sx={{
                paddingTop: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              {payRollGroup.length > 25 ? (
                <Mui.TablePagination
                  className="custom-pagination"
                  component="div"
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  count={payRollGroup.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : null}
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
