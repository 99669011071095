export const departmentWiseHeader = [
  {
    name: "Department",
    key: "department_name",
  },
  {
    name: "Total Assigned",
    key: "total_assigned",
  },
  {
    name: "Present",
    key: "present",
  },
  {
    name: "Missing",
    key: "missing",
  },
  {
    name: "Leave",
    key: "leave",
  },
  {
    name: "Absent",
    key: "absent",
  },
  {
    name: "Over Time",
    key: "overtime",
  },
  {
    name: "Early Exit",
    key: "early_exit",
  },
  {
    name: "Late Entry",
    key: "late_entry",
  },
];

export const departmentExpandHeader = [
  {
    name: "Shift Name",
    key: "shift_name",
  },
  {
    name: "Total Assigned",
    key: "total_assigned",
  },
  {
    name: "Present",
    key: "present",
  },
  {
    name: "Missing",
    key: "missing",
  },
  {
    name: "Leave",
    key: "leave",
  },
  {
    name: "Absent",
    key: "absent",
  },
  {
    name: "Over Time",
    key: "over_time",
  },
  {
    name: "Early Exit",
    key: "early_exit",
  },
  {
    name: "Late Entry",
    key: "late_entry",
  },
];

export const shiftCategoryWise = [
  {
    name: "Shift Name",
    key: "shift_name",
  },
  {
    name: "Total Assigned",
    key: "total_assigned",
  },
  {
    name: "Present",
    key: "present",
  },
  {
    name: "Missing",
    key: "missing",
  },
  {
    name: "Leave",
    key: "leave",
  },
  {
    name: "Absent",
    key: "absent",
  },
  {
    name: "Over Time",
    key: "overtime",
  },
  {
    name: "Early Exit",
    key: "early_exit",
  },
  {
    name: "Late Entry",
    key: "late_entry",
  },
];

export const shiftWiseExpandDetails = [
  {
    name: "Department Name",
    key: "department_name",
  },
  {
    name: "Total Assigned",
    key: "total_assigned",
  },
  {
    name: "Present",
    key: "present",
  },
  {
    name: "Missing",
    key: "missing",
  },
  {
    name: "Leave",
    key: "leave",
  },
  {
    name: "Absent",
    key: "absent",
  },
  {
    name: "Over Time",
    key: "overtime",
  },
  {
    name: "Early Exit",
    key: "early_exit",
  },
  {
    name: "Late Entry",
    key: "late_entry",
  },
];

export const workUnitWise = [
  {
    name: "Date",
    key: "date",
  },
  {
    name: "Shift Name",
    key: "shift_name",
  },
  {
    name: "Department",
    key: "department",
  },
  {
    name: "Unit Name",
    key: "department",
  },
  {
    name: "Total Number of work units",
    key: "total_assigned",
  },
  {
    name: "Assigned Default Unit",
    key: "present",
  },
  {
    name: "Assigned Additional Unit",
    key: "missing",
  },
  {
    name: "Total Assigned Unit",
    key: "leave",
  },
  {
    name: "Unassigned",
    key: "absent",
  },
];

export const workUnitWiseDetails = [
  {
    name: "Shift Name",
    key: "shift_name",
  },
  {
    name: "Department",
    key: "department",
  },
  {
    name: "Unit Name",
    key: "department",
  },
  {
    name: "Total Number of work units",
    key: "total_assigned",
  },
  {
    name: "Assigned Default Unit",
    key: "present",
  },
  {
    name: "Assigned Additional Unit",
    key: "missing",
  },
  {
    name: "Total Assigned Unit",
    key: "leave",
  },
  {
    name: "Unassigned",
    key: "absent",
  },
];