import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { Approve } from "./Approve";
import { History } from "./History";
import { Approved } from "./Reject";

export const MyZoneOverTime = () => {
  const [overTimeScreen, setOverTimeScreen] = useState("Approve");
  const [currentScreen, setCurrentScreen] = useState("Approve");
  const dispatch = useDispatch();
  const handleChange = (event, newAlignment) => {
    setOverTimeScreen(newAlignment);
  };
  const renderComponent = () => {
    switch (currentScreen) {
      case "Approve":
        return <Approve />;
      case "History":
        return <History />;
      case "Reject":
        return <Approved />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 5, paddingLeft: 3 }}>
        <Mui.Grid xs={4.4}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
              height: 55,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Mui.ToggleButtonGroup
              value={overTimeScreen}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "30px",
                backgroundColor: themes.lightBrown,
              }}
            >
              <Mui.ToggleButton
                value="Approve"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "Approve"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    overTimeScreen === "Approve"
                      ? themes.headLine
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.headLine,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "Approve"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Approve"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setCurrentScreen("Approve");
                }}
              >
                Pending
              </Mui.ToggleButton>
              <Mui.ToggleButton
                value="History"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "History"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    overTimeScreen === "History"
                      ? themes.headLine
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.headLine,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "History"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "History"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setCurrentScreen("History");
                }}
              >
                Approved
              </Mui.ToggleButton>
              <Mui.ToggleButton
                value="Reject"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "Reject"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    overTimeScreen === "Reject"
                      ? themes.headLine
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.headLine,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "Reject"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Reject"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                  "&:focus": {
                    backgroundColor:
                      overTimeScreen === "Reject"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Reject"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
                onClick={() => {
                  dispatch(setGlobalSearchValue(""));
                  setCurrentScreen("Reject");
                }}
              >
                Rejected
              </Mui.ToggleButton>
            </Mui.ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>{renderComponent()}</Mui.Grid>
    </React.Fragment>
  );
};
