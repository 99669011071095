import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { tableRowSx } from "services/constants";
import { advanceSalaryHeader } from "../Headers";

const keys = [
  "status",
  "user_name",
  "repayment_date",
  "next_repayment_date",
  "amount",
  "advance_type",
  "description",
  "reason",
];

export const History = ({ setLeaveChange, leaveChange }) => {
  const { domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    result: advanceSalaryDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}advances/?selected_user_id=${userDetails?.id}`);
  const [advanceSalaryData, setAdvanceSalaryData] =
    useState(advanceSalaryDetails);

  useEffect(() => {
    if (leaveChange) {
      reCallApi(true);
      setLeaveChange(false);
    }
  }, [leaveChange, reCallApi, setLeaveChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const filteredResults = advanceSalaryDetails?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setAdvanceSalaryData(filteredResults);
    setPage(0);
  }, [globalSearchValue, advanceSalaryDetails]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Mui.TableContainer component={Mui.Paper} sx={{ mt: 2, width: "100%" }}>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow sx={tableRowSx}>
                {advanceSalaryHeader?.map((value) => (
                  <Mui.TableCell
                    sx={{ color: themes.headLine, fontWeight: "bold" }}
                  >
                    {value?.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {advanceSalaryData?.length === 0 ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell colSpan={12}>
                    <Mui.Alert
                      severity="info"
                      sx={{ margin: 2, paddingTop: 2 }}
                    >
                      No data available
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                advanceSalaryData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((value) => (
                    <Mui.TableRow sx={tableRowSx}>
                      {advanceSalaryHeader.map((head) => (
                        <Mui.TableCell>
                          {value[head.key] ? value[head.key] : "N/A"}
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Grid>

      {advanceSalaryData?.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={advanceSalaryData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
