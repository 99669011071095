import React, { useState, useContext } from "react";
import * as Mui from "@mui/material";
import { TaskContext } from "../TaskContext";
import { showToast } from "services/utils/Status";
import { themes } from "services/constants";
import { projectError } from "services/constants/ErrorMessages";
import PropTypes from "prop-types";
import { Send, Close } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";
const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.headLine }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const Comments = ({ selectedTaskid, mentionMember }) => {
  const [comments, setComments] = useState("");
  const { domain, token, userDetails } = useContext(TaskContext);
  const [editableBtn, setEditableBtn] = useState(false);
  const [selEdtCmtId, setSelEdtCmtId] = useState(null);
  const [selDelCmtId, setSelDelCmtId] = useState(null);
  const [editComments, setEditComments] = useState("");
  const [cnfmDelComments, setCnfmDelComments] = useState(false);
  const { result: commentsDetails,setLoad:setCommentsApi } = useFetch(
    `${domain}comments/?task_id=${selectedTaskid}`
  );

  const AddCommentSharp = async () => {
    if (comments !== "") {
      try {
        const response = await fetch(`${domain}comments/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            task: selectedTaskid,
            description: comments,
            user: userDetails.id,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setComments("");
          setCommentsApi(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      showToast("error", projectError.enterError);
    }
  };

  const onUpdateComments = async () => {
    if (editComments !== "") {
      try {
        const response = await fetch(`${domain}comments/${selEdtCmtId}/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            description: editComments,
            task: selectedTaskid,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setEditComments("");
          setSelEdtCmtId(null);
          setEditableBtn(false);
          setCommentsApi(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      showToast("error", projectError.enterError);
    }
  };

  const onCancelComments = () => {
    setSelEdtCmtId(null);
    setEditableBtn(false);
    setEditComments("");
  };

  const onDeleteComments = (data) => {
    setSelDelCmtId(data.id);
    setEditComments(data.description);
    setCnfmDelComments(true);
  };

  const handleDeleteCommentsClose = () => {
    setCnfmDelComments(false);
    setSelEdtCmtId(null);
    setEditComments("");
    setEditableBtn(false);
    setSelDelCmtId(null);
  };

  const handleDeleteComments = async () => {
    try {
      const response = await fetch(`${domain}comments/${selDelCmtId}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
          task: selectedTaskid,
          description: editComments,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        handleDeleteCommentsClose();
        setCommentsApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <Mui.Box
        sx={{
          ml: 3,
          mr: 3,
        }}
      >
        {commentsDetails.map((data) => (
          <Mui.ListItem>
            <Mui.Grid xs={12}>
              <Mui.Stack direction="row">
                <Mui.Tooltip title={data?.username}>
                  <Mui.Avatar
                    alt={data.username?.charAt(0).toUpperCase()}
                    src={data.image_url}
                  />
                </Mui.Tooltip>
                <Mui.Typography
                  sx={{
                    fontWeight: "bold",
                    paddingLeft: 2,
                    paddingTop: 1,
                    fontSize: 15,
                  }}
                >
                  {data?.username}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    paddingTop: 1.3,
                    fontSize: 14,
                    opacity: 0.5,
                  }}
                >
                  {data?.commented_at}
                </Mui.Typography>
              </Mui.Stack>
              {!editableBtn || selEdtCmtId !== data.id ? (
                <Mui.Typography
                  sx={{
                    paddingLeft: 7,
                    maxWidth: "100%",
                    overflowWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    fontWeight: 500,
                  }}
                >
                  {data?.description}
                </Mui.Typography>
              ) : null}
              {selEdtCmtId === data.id ? (
                <Mui.TextField
                  sx={{
                    borderColor: themes.primary,
                    paddingLeft: 7,
                    maxWidth: "100%",
                  }}
                  multiline
                  minRows={1}
                  maxRows={5}
                  value={editComments}
                  onChange={(event) => setEditComments(event.target.value)}
                  fullWidth
                />
              ) : null}
              {data.user === userDetails?.id ? (
                <>
                  {!editableBtn || selEdtCmtId !== data.id ? (
                    <Mui.Stack
                      direction="row"
                      spacing={0}
                      sx={{ overflow: "hidden", m: 1, ml: 5 }}
                    >
                      <Mui.Button
                        variant="text"
                        size="small"
                        style={{
                          opacity: 0.7,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        color="error"
                        onClick={() => onDeleteComments(data)}
                      >
                        Delete
                      </Mui.Button>
                      <Mui.Button
                        variant="text"
                        size="small"
                        style={{
                          opacity: 0.7,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        color="primary"
                        onClick={() => {
                          setEditComments(data.description);
                          setEditableBtn(true);
                          setSelEdtCmtId(data.id);
                        }}
                      >
                        Edit
                      </Mui.Button>
                    </Mui.Stack>
                  ) : null}
                  {selEdtCmtId === data.id ? (
                    <Mui.Stack
                      direction="row"
                      spacing={0}
                      sx={{ overflow: "hidden", m: 1, ml: 6 }}
                    >
                      <Mui.Button
                        variant="text"
                        size="small"
                        style={{
                          opacity: 0.7,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        color="error"
                        onClick={onCancelComments}
                      >
                        Cancel
                      </Mui.Button>
                      <Mui.Button
                        variant="text"
                        size="small"
                        style={{
                          opacity: 0.7,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        color="primary"
                        onClick={onUpdateComments}
                      >
                        Save
                      </Mui.Button>
                    </Mui.Stack>
                  ) : null}
                </>
              ) : null}
            </Mui.Grid>
          </Mui.ListItem>
        ))}
      </Mui.Box>

      <Mui.Grid
        container
        sx={{
          paddingTop: 0,
          paddingBottom: 2,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Mui.TextField
          sx={{
            borderColor: themes.primary,
          }}
          multiline
          minRows={1}
          maxRows={5}
          value={comments}
          onChange={(event) => setComments(event.target.value)}
          InputProps={{
            endAdornment: (
              <Mui.Button
                variant="contained"
                endIcon={<Send />}
                onClick={() => AddCommentSharp()}
              >
                Send
              </Mui.Button>
            ),
          }}
          fullWidth
        />
      </Mui.Grid>
      <div>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={cnfmDelComments}
        >
          <FuncDialogTitle
            id="customized-dialog-title"
            align="center"
            sx={{
              minWidth: 580,
              color: themes.headLine,
              fontWeight: 1000,
              fontSize: "23px",
              background: themes.primary,
            }}
            onClose={handleDeleteCommentsClose}
            title={"Delete Comment"}
          >
            {"Delete Comment"}
          </FuncDialogTitle>
          <Mui.DialogContent dividers>
            <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
              <Mui.Typography sx={{ fontSize: 24 }}>
                Do you want to delete this comment?
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid xs={5}></Mui.Grid>
              <Mui.Grid xs={3}>
                <Mui.Button
                  variant="contained"
                  size="large"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: themes.primaryIndicator,
                    "&:hover": {
                      border: "none",
                      backgroundColor: themes.primaryIndicator,
                    },
                    color: themes.whiteColor,
                    float: "right",
                    width: 150,
                  }}
                  onClick={handleDeleteCommentsClose}
                >
                  No
                </Mui.Button>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Button
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: themes.primaryIndicator,
                    "&:hover": {
                      border: "none",
                      backgroundColor: themes.primaryIndicator,
                    },
                    color: themes.whiteColor,
                    float: "right",
                    width: 150,
                  }}
                  onClick={handleDeleteComments}
                >
                  Yes
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.DialogContent>
        </BootstrapDialog>
      </div>
    </React.Fragment>
  );
};
