import React, { useState, useEffect } from "react";
import { PastLeaveDetails } from "./PastLeaveDetails";
import { useSelector } from "react-redux";
import "./ButtonGroup.css";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import sessionHandling from "services/utils/notificationUtils";

export const MyLeaves = () => {
  const { token, domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [getDataFromApi, setGetDataFromApi] = useState(true);
  const [reUpdatePastLeaveDetails, setReUpdatePastLeaveDetails] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${domain}leavesummary/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          handleVisibilityToggle(res?.leave_count);
          setReUpdatePastLeaveDetails(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (getDataFromApi) {
      fetchData();
      setGetDataFromApi(false);
    }
  }, [domain, getDataFromApi, token, userDetails.id]);

  const handleVisibilityToggle = (user) => {
    setSelectedUser(user);
  };

  return (
    <div>
      <PastLeaveDetails
        selectedUser={selectedUser}
        setReUpdatePastLeaveDetails={setReUpdatePastLeaveDetails}
        reUpdatePastLeaveDetails={reUpdatePastLeaveDetails}
        setSelectedUser={setSelectedUser}
      />
    </div>
  );
};
