import React, { useEffect, useState, useCallback } from "react";
import { TopBar } from "./TopBar";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BasicInfo } from "./BasicInfo";
import { customGetAPI } from "services/utils/CustomAPI";
import { useSelector } from "react-redux";
import { PersonalInfo } from "./PersonalInfo";
import { SalaryInfo } from "./SalaryInfo";
import { Loader } from "components/Loader";

export const EditEmployee = () => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const { pageno, userid } = useSelector((state) => state.hrportal);
  const [getEmployeeDetails, seyGetEmployeeDetails] = useState(true);
  const [userId, setUserId] = useState(userid);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [updateUserDetails, setUpdateUserDetails] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isFieldFilled, setIsFieldFilled] = useState(false);

  const handleFieldCheck = (filled) => {
    setIsFieldFilled(filled);
  };

  const getSelectedUserDetails = useCallback(
    async (id) => {
      setLoader(true);
      const res = await customGetAPI(`${domain}employee/details?user_id=${id}`);
      if (res.ok) {
        setEmployeeDetails(res.responseDetails);
        setUpdateUserDetails(true);
        setLoader(false);
      } else {
        setLoader(false);
      }
    },
    [domain]
  );

  useEffect(() => {
    if (getEmployeeDetails) {
      getSelectedUserDetails(userId);
      // localStorage.setItem("editEmployeeIdNumber", null);
      // localStorage.setItem("editEmployeeDetails", false);
      seyGetEmployeeDetails(false);
    }
  }, [getEmployeeDetails, getSelectedUserDetails, userId]);

  const renderComponent = () => {
    switch (pageno) {
      case 1:
        return (
          <BasicInfo
            pageno={pageno}
            employeeDetails={employeeDetails}
            getSelectedUserDetails={getSelectedUserDetails}
            setUpdateUserDetails={setUpdateUserDetails}
            updateUserDetails={updateUserDetails}
            setUserId={setUserId}
            loader={loader}
            onFieldCheck={handleFieldCheck}
          />
        );
      case 2:
        return (
          <PersonalInfo
            pageno={pageno}
            employeeDetails={employeeDetails}
            getSelectedUserDetails={getSelectedUserDetails}
            setUpdateUserDetails={setUpdateUserDetails}
            updateUserDetails={updateUserDetails}
            setUserId={setUserId}
            onFieldCheck={handleFieldCheck}
          />
        );
      case 3:
        return (
          <SalaryInfo
            pageno={pageno}
            employeeDetails={employeeDetails}
            getSelectedUserDetails={getSelectedUserDetails}
            setUpdateUserDetails={setUpdateUserDetails}
            updateUserDetails={updateUserDetails}
            setUserId={setUserId}
          />
        );
      default:
        break;
    }
  };

  return loader ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2 }}></Mui.Grid>
      <Mui.Grid container>
        <TopBar
          pageno={pageno}
          employeeDetails={employeeDetails}
          setUpdateUserDetails={setUpdateUserDetails}
          isFieldFilled={isFieldFilled}
        />
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          padding: 2,
          width: "99.5%",
          height: "100%",
          borderRadius: 5,
          border: `5px solid ${themes.sidebarDivider}`,
        }}
      >
        <Mui.Grid container>{renderComponent()}</Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
