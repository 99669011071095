import React, { useState, useEffect } from "react";
import { BackArrow } from "components/CustomComponents/BackArrow";
import * as Mui from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
export const AddEmployee = ({
  viewAssignDetails,
  setAddEmployee,
  reCallApi,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [departmentName, setDepartmentName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [leftSideEmployee, setLeftSideEmployee] = useState([]);
  const [rightSideEmployee, setRightSideEmployee] = useState([]);
  const [leftChecked, setLeftChecked] = useState(false);
  const [rightChecked, setRightChecked] = useState(false);
  const [getEmployes, setGetEmployes] = useState(viewAssignDetails?.department);
  // const { result: workUnitGroup } = useFetch(`${domain}work_unit_groups/`);
  // const { result: shiftCategory } = useFetch(
  //   `${domain}workunit-based-shiftcategory/`
  // );

  const { result: department } = useFetch(`${domain}work-unit-department/`);

  // const [shiftCategoryName, setShiftCategoryName] = useState("");

  useEffect(() => {
    setValue("department_name", viewAssignDetails?.department_name);
    setValue("workunit_group", viewAssignDetails?.name);
    setValue("effective_from", viewAssignDetails?.start_date);
    setValue("department", viewAssignDetails.department);
    setValue("id", viewAssignDetails?.id);
    setDepartmentName(viewAssignDetails?.department_name);
    setGroupName(viewAssignDetails?.name);
    // setGroupNameRight(viewAssignDetails?.name);
  }, [
    setValue,
    viewAssignDetails.department,
    viewAssignDetails?.department_name,
    viewAssignDetails?.id,
    viewAssignDetails?.name,
    viewAssignDetails?.start_date,
  ]);

  // const getEmployees = async (department_id) => {
  //   try {
  //     const response = await fetch(
  //       `${domain}list/employee?department_id=${department_id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `token ${token}`,
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       let checkKey = res.map((data) => {
  //         return {
  //           ...data,
  //           checked: false,
  //         };
  //       });
  //       setLeftSideEmployee(checkKey);
  //       setRightSideEmployee([]);
  //     } else if (response.status === 409) {
  //       sessionHandling();
  //     } else {
  //       throw new Error(res.error);
  //     }
  //   } catch (error) {
  //     showToast("error", error.message);
  //   }
  // };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch(
          `${domain}list/basic-details/unit/employee?department_id=${getEmployes}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          let checkKey = res.map((data) => ({
            ...data,
            checked: false,
          }));
          setLeftSideEmployee(checkKey);
          setRightSideEmployee([]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (getEmployes) {
      fetchEmployees();
    }
  }, [domain, getEmployes, token]);

  const handleDepartment = (event, data) => {
    if (data) {
      setValue("department_name", data?.label);
      setValue("id", data?.id);
      setDepartmentName(data?.label);
      setGetEmployes(data?.id);
    } else {
      setValue("department_name", "");
      setValue("id", null);
      setDepartmentName("");
    }
  };

  const handleCheckedRight = () => {
    const data = leftSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = leftSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...rightSideEmployee, ...updateAsFalse];
    setRightSideEmployee(updatedData);
    setLeftSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const handleCheckedLeft = () => {
    const data = rightSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = rightSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...leftSideEmployee, ...updateAsFalse];
    setLeftSideEmployee(updatedData);
    setRightSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  // const handlAssignedTo = (event, data) => {
  //   if (data) {
  //     setValue("id", data?.id);
  //     setGroupName(data?.label);
  //     setValue("workunit_group", data?.label);
  //   } else {
  //     setValue("workunit_group", "");
  //     setValue("id", null);
  //     setGroupName("");
  //   }
  // };

  const onSubmit = async (data) => {
    if (rightSideEmployee.length !== 0) {
      let userId = [];
      rightSideEmployee.forEach((element) => {
        userId.push(element?.user_id);
      });
      try {
        const response = await fetch(`${domain}unit/assign/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            users: userId,
            work_unit_group: data.id,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          setAddEmployee(false);
          reCallApi(true);
          reset();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      showToast("error", "Please select atleast one users");
    }
  };

  const handleToggleAllLeft = (value) => {
    setLeftChecked(false);
    setRightChecked(true);
    if (value) {
      let updatedData = leftSideEmployee.map((data) => {
        return {
          ...data,
          checked: true,
        };
      });
      setLeftSideEmployee(updatedData);
    } else {
      let updatedData = leftSideEmployee.map((data) => {
        return {
          ...data,
          checked: false,
        };
      });
      setLeftSideEmployee(updatedData);
    }
  };

  const numberOfChecked = () => {
    let count = 0;
    leftSideEmployee.forEach((data) => {
      if (data.checked) {
        count = count + 1;
      }
    });
    return count;
  };

  const handleToggleLeft = (value) => () => {
    setLeftChecked(false);
    setRightChecked(true);
    if (value.checked) {
      let updatedData = leftSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: false,
          };
        } else {
          return data;
        }
      });
      setLeftSideEmployee(updatedData);
    } else {
      let updatedData = leftSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: true,
          };
        } else {
          return data;
        }
      });
      setLeftSideEmployee(updatedData);
    }
  };

  const handleToggleRigh = (value) => () => {
    setLeftChecked(true);
    setRightChecked(false);
    if (value.checked) {
      let updatedData = rightSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: false,
          };
        } else {
          return data;
        }
      });
      setRightSideEmployee(updatedData);
    } else {
      let updatedData = rightSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: true,
          };
        } else {
          return data;
        }
      });
      setRightSideEmployee(updatedData);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container>
          <BackArrow
            actionFunction={() => {
              setAddEmployee(false);
            }}
          />
          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
          >
            {viewAssignDetails?.name} / Add Employee
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: 0.5,
            paddingBottom: 1,
            borderBottom: `2px solid ${themes.sidebarDivider}`,
          }}
        >
          <Mui.Grid xs={7}></Mui.Grid>
          {/* <Mui.Grid xs={2.5}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Shift Category
            </Mui.Typography>
            <CustomAutoComplete
              dataList={shiftCategory?.map((obj) => ({
                ...obj,
                label: obj?.shift_category_name,
              }))}
              selectedValue={shiftCategoryName}
              rules={{
                ...register("shift_categoryname", {
                  required: "Shift category name is required",
                }),
              }}
              updateDataFunction={handleShiftCategory}
              placeHolder="Please select the shift category name"
              errorMessage={
                errors?.shift_categoryname?.message
                  ? errors?.shift_categoryname?.message
                  : ""
              }
              textBoxError={errors?.shift_categoryname ? true : false}
            />
          </Mui.Grid> */}
          <Mui.Grid xs={1}></Mui.Grid>
          <Mui.Grid xs={2.5}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Department
            </Mui.Typography>
            <CustomAutoComplete
              dataList={department.map((obj) => ({
                ...obj,
                label: obj.department,
              }))}
              selectedValue={departmentName}
              rules={{
                ...register("department_name", {
                  required: "Department name is required",
                }),
              }}
              updateDataFunction={handleDepartment}
              placeHolder="Please select the department name"
              errorMessage={
                errors?.department_name?.message
                  ? errors?.department_name?.message
                  : ""
              }
              textBoxError={errors?.department_name ? true : false}
            />
          </Mui.Grid>

          {/* <Mui.Grid xs={2}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Effective To
            </Mui.Typography>
            <Mui.TextField
              id="from-date"
              type="date"
              size="small"
              {...register("effective_to", {
                required: "Date is required.",
              })}
              error={Boolean(errors.effective_to)}
              helperText={
                errors.effective_to?.message && errors.effective_to.message
              }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disableOpenPicker
            />
          </Mui.Grid> */}
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "Center",
            alignItems: "Center",
            paddingLeft: 13,
            paddingRight: 13,
          }}
        >
          <Mui.Grid
            container
            component={Mui.Paper}
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "Center",
              alignItems: "Center",
              padding: 2,
              backgroundColor: "#f7f5f6",
            }}
          >
            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "Center",
                    alignItems: "Center",
                  }}
                >
                  <Mui.Grid
                    component={Mui.Paper}
                    sx={{
                      backgroundColor: themes.darkPrimary,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <Mui.Typography
                      sx={{
                        padding: 1,
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                    >
                      Un Assigned
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid sx={{ paddingTop: 2 }}></Mui.Grid>
                <Mui.FormControl fullWidth>
                  <Mui.Grid
                    sx={{
                      paddingTop: 2,
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.Card>
                      <Mui.CardHeader
                        sx={{ px: 2, py: 1 }}
                        avatar={
                          <Mui.Checkbox
                            onClick={(event) =>
                              handleToggleAllLeft(event.target.checked)
                            }
                            inputProps={{
                              "aria-label": "all items selected",
                            }}
                          />
                        }
                        title={"Selected Employees"}
                        subheader={`${numberOfChecked()}/${
                          leftSideEmployee.length
                        }`}
                      />
                      <Mui.Divider />
                      <Mui.List
                        sx={{
                          width: "100%",
                          height: {
                            xs: 200,
                            sm: 300,
                            md: 300,
                            lg: 488,
                          },
                          bgcolor: "background.paper",
                          overflow: "auto",
                        }}
                        dense
                        component="div"
                        role="list"
                      >
                        {leftSideEmployee.length === 0 ? (
                          <Mui.ListItem>
                            <Mui.Alert sx={{ width: "100%" }} severity="info">
                              No employees available under the workunit group
                            </Mui.Alert>
                          </Mui.ListItem>
                        ) : (
                          leftSideEmployee.map((value) => {
                            const labelId = `transfer-list-all-item-${value.user_id}-label`;

                            return (
                              <Mui.ListItemButton
                                key={value.user_id}
                                role="listitem"
                                onClick={handleToggleLeft(value)}
                              >
                                <Mui.ListItemIcon>
                                  <Mui.Checkbox
                                    checked={value.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </Mui.ListItemIcon>
                                <Mui.ListItemAvatar>
                                  <Mui.Avatar
                                    alt={value?.user_name?.charAt(0)}
                                    src={value?.image_url}
                                  />
                                </Mui.ListItemAvatar>
                                <Mui.ListItemText
                                  id={labelId}
                                  primary={`${value.user_name}`}
                                />
                              </Mui.ListItemButton>
                            );
                          })
                        )}
                      </Mui.List>
                    </Mui.Card>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={1.5} lg={1}>
              <Mui.Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Mui.IconButton
                  onClick={handleCheckedRight}
                  disabled={leftChecked}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleRight color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
                <Mui.IconButton
                  onClick={handleCheckedLeft}
                  disabled={rightChecked}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleLeft color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
            {/* Right Side */}
            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "Center",
                    alignItems: "Center",
                  }}
                >
                  <Mui.Grid
                    component={Mui.Paper}
                    sx={{
                      backgroundColor: themes.darkPrimary,
                      paddingLeft: 5.5,
                      paddingRight: 5.5,
                    }}
                  >
                    <Mui.Typography
                      sx={{
                        padding: 1,
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                    >
                      Assigned To
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.FormControl fullWidth>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    WorkUnit Group
                  </Mui.Typography>
                  {/* <CustomAutoComplete
                    disableAction={true}
                    dataList={workUnitGroup.map((obj) => ({
                      ...obj,
                      label: obj.name,
                    }))}
                    selectedValue={groupName}
                    rules={{
                      ...register("workunit_group", {
                        required: "Work unit group is required",
                      }),
                    }}
                    updateDataFunction={handlAssignedTo}
                    placeHolder="Please select workunit group"
                    errorMessage={
                      errors?.workunit_group?.message
                        ? errors?.workunit_group?.message
                        : ""
                    }
                    textBoxError={errors?.workunit_group ? true : false}
                  /> */}

                  <CustomTextBox
                    selectedValue={groupName}
                    disabledValue={true}
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth>
                  <Mui.Grid
                    sx={{
                      paddingTop: 2,
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.FormControl fullWidth>
                      <Mui.Grid
                        sx={{
                          //   paddingTop: 2,
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                        component={Mui.Paper}
                      >
                        <Mui.Card>
                          <Mui.List
                            sx={{
                              width: "100%",
                              height: {
                                xs: 200,
                                sm: 300,
                                md: 300,
                                lg: 488,
                              },
                              bgcolor: "background.paper",
                              overflow: "auto",
                            }}
                            dense
                            component="div"
                            role="list"
                          >
                            {rightSideEmployee.map((value) => {
                              const labelId = `transfer-list-all-item-${value.user_id}-label`;

                              return (
                                <Mui.ListItemButton
                                  key={value.user_id}
                                  role="listitem"
                                  onClick={handleToggleRigh(value)}
                                >
                                  <Mui.ListItemIcon>
                                    <Mui.Checkbox
                                      checked={value.checked}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </Mui.ListItemIcon>
                                  <Mui.ListItemAvatar>
                                    <Mui.Avatar
                                      alt={value?.user_name?.charAt(0)}
                                      src={value?.image_url}
                                    />
                                  </Mui.ListItemAvatar>
                                  <Mui.ListItemText
                                    id={labelId}
                                    primary={`${value.user_name}`}
                                  />
                                </Mui.ListItemButton>
                              );
                            })}
                          </Mui.List>
                        </Mui.Card>
                      </Mui.Grid>
                    </Mui.FormControl>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid container sx={{ mt: 2 }}>
              <Mui.Grid xs={8.5}></Mui.Grid>
              <Mui.Grid
                xs={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton actionName="Add" typeName="submit" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
