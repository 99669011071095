import { createSlice } from "@reduxjs/toolkit";

export const themeReducer = createSlice({
  name: "theme",
  initialState: {
    button:"#00cca3",
    tabIndicator:1,
    allTap:'#065BAA',
    activeTap:'#149611',
    absentTap:'#FF0000',
    headLine:'#000000',
    permissionTap:'#8B198D',
    breakTap:'#EC00F1',
    onleaveTap:'#CB680D',
    lateTap:'#09AEB9',
    whiteColor:'white',
    backgroundShadow:'#D9D9D9',
    cardProjectName:'#3C557A',
    secondryButtonColor:'#9C27B0',
    primaryButtonColor:'#1976D2',
    projectActiveTapColor:'#231F1F',
    activeProject:'#21AB79',
    inactiveProject:'#C35353',
    completedProject:'#F41062',
    holdProject:'#F4CC40',
  },
  reducers: {
    setButton: (state, action) => {
        state.button = action.payload;
    },
  },
});

export const {
    setButton
} = themeReducer.actions;

export default themeReducer.reducer;
