import React, { useState, useEffect } from "react";
import { Button, Grid, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Device } from "components/Navbars/DeviceStatus";
import { Active } from "./Active";
import { Leave } from "./Leave";
import { Break } from "./Break";
import { AllStatus } from "./AllStatus";
import "./Status.css";
import { showToast } from "services/utils/Status";
import {
  setReloadApi,
  setAllStatus,
  setActiveStatus,
  setLeaveStatus,
  setOnBreakStatus,
} from "services/Redux/leaveManagement";
import "react-toastify/dist/ReactToastify.css";
import sessionHandling from "services/utils/notificationUtils";
import { AccountBalance } from "@mui/icons-material";
export const EssStatus = () => {
  const dispatch = useDispatch();
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const theme = useSelector((state) => state.themeReducer);
  let reLoadApi = useSelector((state) => state.leaveReducer.reLoadApi);
  const date = new Date().toISOString().split("T")[0];
  const [deviceOffine, setDeviceOffline] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState("status");
  const [countDetails, setCountDetails] = useState({
    active_count: 0,
    break_count: 0,
    leave_count: 0,
    overall_count: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${domain}checkinfilter/?department_id=${userDetails.department_id}&date=${date}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (response.status === 202) {
            setDeviceOffline(true);
          } else {
            setCountDetails({
              active_count: res?.active_count,
              break_count: res?.break_count,
              leave_count: res?.leave_count,
              overall_count: res?.overall_count,
            });
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchData();
  }, [token, date, domain, userDetails.department_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${domain}checkinfilter/?department_id=${userDetails.department_id}&date=${date}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.user_status_details) {
            dispatch(setReloadApi(false));
            setCountDetails({
              active_count: res?.active_count,
              break_count: res?.break_count,
              leave_count: res?.leave_count,
              overall_count: res?.overall_count,
            });
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (reLoadApi) {
      fetchData();
    }
  }, [token, date, domain, reLoadApi, dispatch, userDetails.department_id]);

  const handleButtonClick = (component) => {
    setSelectedComponent(component);
    switch (component) {
      case "status":
        dispatch(setAllStatus(true));
        break;
      case "leave":
        dispatch(setLeaveStatus(true));
        break;
      case "present":
        dispatch(setActiveStatus(true));
        break;
      case "break":
        dispatch(setOnBreakStatus(true));
        break;
      default:
        break;
    }
  };

  let componentToDisplay;

  if (selectedComponent === "present") {
    componentToDisplay = (
      <Active date={date} departmentid={userDetails.department_id} />
    );
  } else if (selectedComponent === "leave") {
    componentToDisplay = (
      <Leave date={date} departmentid={userDetails.department_id} />
    );
  } else if (selectedComponent === "break") {
    componentToDisplay = (
      <Break date={date} departmentid={userDetails.department_id} />
    );
  } else if (selectedComponent === "status") {
    componentToDisplay = (
      <AllStatus date={date} departmentid={userDetails.department_id} />
    );
  }

  return (
    <>
      {deviceOffine ? (
        ""
      ) : (
        <div className="flex flex-col items-center justify-center">
          <Grid container spacing={5} justifyContent="center">
            <>
              <Grid container sx={{ marginTop: 7 }}>
                <Grid item sx={{ paddingRight: 2, paddingLeft: 5 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: 270,
                      height: 120,
                      backgroundColor: "#FFF8E5",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: 40,
                        height: 40,
                        marginLeft: 4,
                        backgroundColor: "#FFD83A",
                        borderRadius: 50,
                      }}
                    >
                      <AccountBalance
                        style={{ color: "white" }}
                        fontSize="medium"
                      />
                    </Grid>
                    <Grid sx={{ paddingLeft: 2 }}>
                      <div
                        style={{
                          fontSize: "23px",
                          fontWeight: "800",
                          textTransform: "capitalize",
                        }}
                      >
                        {userDetails.department_name ? (
                          userDetails.department_name.length > 10 ? (
                            <Tooltip title={userDetails.department_name}>
                              <span>
                                {`${userDetails.department_name.substring(
                                  0,
                                  10
                                )}...`}
                              </span>
                            </Tooltip>
                          ) : (
                            userDetails.department_name
                          )
                        ) : (
                          "All"
                        )}
                        <br />
                        <Typography
                          sx={{
                            fontSize: 15,
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                        >
                          {countDetails.active_count > 0
                            ? `${countDetails.active_count}`
                            : ""}
                          /&nbsp;
                          {countDetails?.overall_count
                            ? countDetails?.overall_count
                            : ""}
                        </Typography>
                      </div>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <div className="card">
                    <ButtonGroup
                      variant="text"
                      aria-label="text button group"
                      className="button-container"
                      id="button-container"
                    >
                      <Button
                        variant={
                          selectedComponent === "status" ? "contained" : "text"
                        }
                        onClick={() => handleButtonClick("status")}
                        color="primary"
                        className="button "
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          fontSize: "16px",
                          backgroundColor:
                            selectedComponent === "status"
                              ? theme.allTap
                              : theme.whiteColor,
                        }}
                      >
                        {countDetails?.overall_count
                          ? countDetails?.overall_count
                          : ""}
                        &nbsp; &nbsp; All
                      </Button>
                      <Button
                        variant={
                          selectedComponent === "present" ? "contained" : "text"
                        }
                        onClick={() => handleButtonClick("present")}
                        color="success"
                        className="button "
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          fontSize: "16px",
                          backgroundColor:
                            selectedComponent === "present"
                              ? theme.activeTap
                              : theme.whiteColor,
                        }}
                      >
                        {countDetails.active_count > 0
                          ? `${countDetails.active_count}`
                          : ""}
                        &nbsp; &nbsp; Active
                      </Button>
                      <Button
                        variant={
                          selectedComponent === "leave" ? "contained" : "text"
                        }
                        onClick={() => handleButtonClick("leave")}
                        color="warning"
                        className="button "
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          fontSize: "16px",
                          backgroundColor:
                            selectedComponent === "leave"
                              ? theme.onleaveTap
                              : "white",
                        }}
                      >
                        {countDetails.leave_count > 0
                          ? `${countDetails.leave_count}`
                          : ""}
                        &nbsp; &nbsp; On Leave
                      </Button>
                      <Button
                        variant={
                          selectedComponent === "break" ? "contained" : "text"
                        }
                        onClick={() => handleButtonClick("break")}
                        color="secondary"
                        className="button "
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          fontSize: "16px",
                          backgroundColor:
                            selectedComponent === "break"
                              ? theme.breakTap
                              : "white",
                        }}
                      >
                        {countDetails.break_count > 0
                          ? `${countDetails.break_count}`
                          : ""}
                        &nbsp; &nbsp; On Break
                      </Button>
                    </ButtonGroup>
                  </div>
                </Grid>
              </Grid>
            </>
          </Grid>
          {componentToDisplay}
        </div>
      )}
      {deviceOffine ? (
        <Grid sx={{ paddingTop: 20 }}>
          <Device />
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};
