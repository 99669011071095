import React, { useContext, useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { ShiftGroupContext } from "../ShiftManageContext/ShiftGroupContext";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import AddMemberGroup from "./AddShiftGroupEmployee";
import MoveMemberGroup from "./MoveShiftGroupEmployee";
import { useSelector } from "react-redux";
import { filterShiftGrpMem } from "services/constants";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";

const MemberShiftGroup = ({
  updateShowEmpShiftGrp,
  viewGrpName,
  viewDepName,
  viewSftGrpDet,
  viewSftGrpDepDet,
}) => {
  const dispatch = useDispatch();
  const { globalSearchValue } = useSelector((state) => state.tokenReducer);
  const { domain, token, setReldSftGrpApi } = useContext(ShiftGroupContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadMemberApi, setReloadMemberApi] = useState(true);
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showMoveEmployee, setShowMoveEmployee] = useState(false);
  const [allMemDet, setAllMemDet] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchShftGroupMembers = async () => {
      try {
        setIsLoading(true);
        const fetchURL = viewDepName
          ? `${domain}user-shift-group/?department_id=${viewSftGrpDepDet?.id}&shift_group_id=${viewSftGrpDet?.id}`
          : `${domain}user-shift-group/?shift_group_id=${viewSftGrpDet?.id}`;
        const response = await fetch(fetchURL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setAllMemDet(res);
          setMasterDataForSearch(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (reloadMemberApi) {
      fetchShftGroupMembers();
      setReloadMemberApi(false);
    }
  }, [
    domain,
    token,
    reloadMemberApi,
    viewDepName,
    viewSftGrpDepDet?.id,
    viewSftGrpDet?.id,
  ]);

  useEffect(() => {
    const filterShiftGroupData = masterDataForSearch.filter((item) =>
      filterShiftGrpMem.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setAllMemDet(filterShiftGroupData);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch, setAllMemDet]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {!showAddEmployee && !showMoveEmployee ? (
        <>
          <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
            <Mui.Grid item xs={12} sm={12} md={8} lg={8}>
              <Mui.Stack direction="row" sx={{ mt: 1 }}>
                <BackArrow
                  actionFunction={() => {
                    updateShowEmpShiftGrp(false);
                    setReldSftGrpApi(true);
                    dispatch(setGlobalSearchValue(""));
                  }}
                />

                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    mt: 1.5,
                  }}
                >
                  {viewDepName
                    ? `${viewGrpName} / ${viewDepName}`
                    : `${viewGrpName}`}
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 0,
              }}
            >
              <Mui.ButtonGroup
                spacing="0.5rem"
                aria-label="spacing button group"
              >
                <Mui.Button
                  variant={"contained"}
                  sx={{
                    background: themes.primaryButton,
                    textTransform: "capitalize",
                    mr: 2,
                    border: "none",
                    "&:hover": {
                      border: "none",
                      backgroundColor: themes.primaryButton,
                    },
                    color: themes.headLine,
                  }}
                  onClick={() => {
                    setShowAddEmployee(true);
                    dispatch(setGlobalSearchValue(""));
                  }}
                >
                  Add Employee
                </Mui.Button>
                <Mui.Button
                  variant={"contained"}
                  sx={{
                    background: themes.primaryButton,
                    textTransform: "capitalize",
                    mr: 2,
                    border: "none",
                    "&:hover": {
                      border: "none",
                      backgroundColor: themes.primaryButton,
                    },
                    color: themes.headLine,
                  }}
                  onClick={() => {
                    setShowMoveEmployee(true);
                    dispatch(setGlobalSearchValue(""));
                  }}
                >
                  Move Employee
                </Mui.Button>
              </Mui.ButtonGroup>
            </Mui.Grid>
          </Mui.Grid>
          {isLoading ? (
            <Loader info="Loading..." />
          ) : (
            <Mui.TableContainer
              component={Mui.Paper}
              sx={{ mt: 2, width: "100%", mr: 2 }}
            >
              <Mui.Table>
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.primary,
                  }}
                >
                  <Mui.TableRow>
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      Employee Id
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      Name
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      Department
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>

                {allMemDet?.length !== 0 && allMemDet ? (
                  <Mui.TableBody>
                    {allMemDet
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <>
                          <Mui.TableRow
                            maxWidth="xl"
                            align="left"
                            sx={{
                              border: `1px solid ${themes.formBackground}`,
                              cursor: "pointer",
                            }}
                            hover={true}
                          >
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.employee_id ? item?.employee_id : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.username ? item?.username : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.department_name
                                  ? item?.department_name
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        </>
                      ))}
                  </Mui.TableBody>
                ) : (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={5}>
                      <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
                        <Mui.Alert severity="info">No data available</Mui.Alert>
                      </Mui.Stack>
                    </Mui.TableCell>
                  </Mui.TableRow>
                )}
              </Mui.Table>
            </Mui.TableContainer>
          )}
          {allMemDet.length > 10 ? (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={allMemDet.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </>
      ) : showAddEmployee ? (
        <AddMemberGroup
          updateShowAddorMoveEmployee={setShowAddEmployee}
          viewGrpName={viewGrpName}
          viewDepName={viewDepName}
          viewSftGrpDet={viewSftGrpDet}
          setReloadMemberApi={setReloadMemberApi}
        />
      ) : showMoveEmployee ? (
        <MoveMemberGroup
          updateShowAddorMoveEmployee={setShowMoveEmployee}
          viewGrpName={viewGrpName}
          viewDepName={viewDepName}
          viewSftGrpDet={viewSftGrpDet}
          setReloadMemberApi={setReloadMemberApi}
        />
      ) : null}
    </>
  );
};

export default MemberShiftGroup;
