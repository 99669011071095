import React, { useContext, useState, useEffect, useCallback } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
// import { ShiftAssignContext } from "../ShiftManageContext/ShiftAssignContext";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { GlobalDialogBox } from "components/GlobalDialogBox";

let multiApiCallRestriction = true;
const cycleTypes = [
  { id: 1, type: "Weekly" },
  { id: 2, type: "Monthly" },
];

export const EditShiftAssign = ({
  updateShowAddShiftAsgn,
  shftAsgnPro,
  selSftAsgnData,
  setShowTopBar,
  reCallApi,
}) => {
  const dispatch = useDispatch();
  // const { domain, token, setReldSftAsgnApi, allSftCategoryDet } =
  //   useContext(ShiftAssignContext);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [sftAsgnCatName, setSftAsgnCatName] = useState("");
  const [sftAsgnCatId, setSftAsgnCatId] = useState("");
  const [sftAsgnCatTotalShiftGrps, setSftAsgnCatTotalShiftGrps] = useState([]);
  const [sftAsgnCycType, setSftAsgnCycType] = useState("Weekly");
  const [sftAsgnCycStartDate, setSftAsgnCycStartDate] = useState(new Date());
  const [sftAsgnCycStartDorM, setSftAsgnCycStartDorM] = useState("");
  const [sftAsgnCycNumCount, setSftAsgnCycNumCount] = useState(1);
  const [sftAsgnCycRecurType, setSftAsgnCycRecurType] = useState();
  const [sftAsgnCycRecurCount, setSftAsgnCycRecurCount] = useState();
  const [sftAsgnCycRecurUntil, setSftAsgnCycRecurUntil] = useState(new Date());
  const [sftAsgnSftGrpData, setSftAsgnSftGrpData] = useState([]);
  const [sftAsgnSftGrpCombData, setsftAsgnSftGrpCombData] = useState([]);
  const [sftAsgnErr, setSftAsgnErr] = useState("");
  const [dataValidErr, setDataValidErr] = useState("");
  const [sftAsgnCycLabData, setSftAsgnCycLabData] = useState([]);
  const [sftAsgnCycLab, setSftAsgnCycLab] = useState("");
  const [sftAsgnCycCalenDat, setSftAsgnCycCalenDat] = useState([]);
  const [actBtnEnable, setActBtnEnable] = useState(false);
  const [oldAsgnCloseDate, setOldAsgnCloseDate] = useState(new Date());
  const [ediReasgnBtnName, setEdiReasgnBtnName] = useState("");
  const [closeDateSelPopup, setCloseDateSelPopup] = useState(false);
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [reldSftAsgnApi, setReldSftAsgnApi] = useState(true);
  const [allSftAsgn, setAllSftAsgn] = useState([]);
  const [allSftCategoryDet, setAllSftCategoryDet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [shiftGrpId, setShiftGrpId] = useState();
  const [shiftGroupName, setGroupName] = useState("");
  const [enable, setEnable] = useState(true);
  const [handleDate, setHandleDate] = useState(true);

  const initializeEditShiftCycleData = useCallback(
    (res) => {
      const formattedSftAsgnSftGrpData = res?.cycles?.map(
        (cycle, cycleIndex) => {
          const cycleKey = `cycle ${cycleIndex + 1}`;
          const shifts = cycle.shifts.map((shift) => ({
            shift_id: shift.shift_id,
            shift_name: shift.shift_name,
            start_time: shift.start_time,
            end_time: shift.end_time,
            color_code: shift.color_code,
            shift_category_id: res?.shift_category_id,
            groups: shift.groups.map((group) => ({
              id: group.group_id,
              shift_group_name: group.group_name,
              label: group.group_name,
              shift_category_id: res?.shift_category_id,
            })),
          }));

          return {
            [cycleKey]: {
              cycle_id: cycle?.cycle_id,
              shifts: shifts,
            },
          };
        }
      );

      if (
        dayjs(selSftAsgnData?.start_date, "DD-MM-YYYY").toDate() >
        new Date(new Date())
      ) {
        setEdiReasgnBtnName("Edit");
      } else {
        setEdiReasgnBtnName("Re-Assign");
      }

      setSftAsgnCatName(selSftAsgnData?.shift_category_name);
      setSftAsgnCatId(selSftAsgnData?.shift_category_id);
      setSftAsgnCycType(selSftAsgnData?.shift_cycle);
      setSftAsgnCycRecurType(
        selSftAsgnData?.is_year_end ? "recurtype2" : "recurtype1"
      );
      // setSftAsgnCycRecurType(selSftAsgnData?.)
      // setSftAsgnCycRecurType(selSftAsgnData?.)
      setSftAsgnCycStartDate(
        dayjs(selSftAsgnData?.start_date, "DD-MM-YYYY").toDate()
      );
      setSftAsgnCycRecurUntil(
        dayjs(selSftAsgnData?.end_date, "DD-MM-YYYY").toDate()
      );
      setSftAsgnCycNumCount(selSftAsgnData?.no_of_cycle);
      setSftAsgnCycRecurCount(selSftAsgnData?.total_period);
      const shiftcatDepGrps = formattedSftAsgnSftGrpData.map((cycle) => {
        const cycleShifts = Object.values(cycle)[0].shifts;
        return cycleShifts.map((shift) => shift.groups);
      });
      setSftAsgnSftGrpData(formattedSftAsgnSftGrpData);
      setValue("shiftcatDepGrps", shiftcatDepGrps);
      setValue("shiftasgncategory", selSftAsgnData?.shift_category_name);
      setValue("shiftassigncycle", selSftAsgnData?.shift_cycle);
      setValue(
        "shiftStartDate",
        dayjs(selSftAsgnData?.start_date, "DD-MM-YYYY").toDate()
      );
      setValue("shiftCycleCount", 1);
      setValue("occurrencesCount", 1);

      const formattedSftAsgnSftGrpCombData = res?.cycles?.map(
        (sft, cycleIndex) => {
          const cycleCombKey = `cycle ${cycleIndex + 1}`;
          const groupNames = sft.shifts.flatMap((shift) =>
            shift.groups.map((group) => group.group_name)
          );

          return {
            [cycleCombKey]: groupNames,
          };
        }
      );

      setsftAsgnSftGrpCombData(formattedSftAsgnSftGrpCombData);
    },
    [
      selSftAsgnData?.end_date,
      selSftAsgnData?.is_year_end,
      selSftAsgnData?.no_of_cycle,
      selSftAsgnData?.shift_category_id,
      selSftAsgnData?.shift_category_name,
      selSftAsgnData?.shift_cycle,
      selSftAsgnData?.start_date,
      selSftAsgnData?.total_period,
      setValue,
    ]
  );

  useEffect(() => {
    const getDetails = () => {
      if (groupData?.length > 0) {
        const filteredGroups = groupData.filter(
          (group) => group.label !== "Unassigned Shift Group"
        );
        setGroupData(filteredGroups);
        setGroupName(groupData[1]?.label || "");
        setShiftGrpId(groupData[1]?.value || "");
        // setTimeout(function () {
        //   setShiftGrpId(groupData[1]?.value || "");
        // }, 1000);
      }
    };
    if (groupData?.length > 0 && enable) {
      getDetails();
      setEnable(false);
    }
  }, [enable, groupData]);

  useEffect(() => {
    const fetchShiftAssignment = async () => {
      try {
        const response = await fetch(
          `${domain}shift-assignment/?shift_assignment_id=${selSftAsgnData?.shift_assignment_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          initializeEditShiftCycleData(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (selSftAsgnData?.shift_assignment_id) {
      fetchShiftAssignment();
    }
  }, [
    selSftAsgnData?.shift_assignment_id,
    domain,
    token,
    initializeEditShiftCycleData,
  ]);

  useEffect(() => {
    const getSftCatShiftGrpDetails = async () => {
      try {
        const response = await fetch(
          `${domain}shift-group-based-shift-category/?shift_category_id=${sftAsgnCatId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const labels = res.map((obj) => ({
            ...obj,
            label: obj.shift_group_name,
          }));
          setSftAsgnCatTotalShiftGrps([...labels]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (sftAsgnCatId) {
      getSftCatShiftGrpDetails();
    }
  }, [domain, token, sftAsgnCatId]);

  useEffect(() => {
    const cycleData = sftAsgnSftGrpData?.map((cycle) => {
      const cycleKey = Object.keys(cycle)[0];
      return { label: cycleKey, value: cycleKey };
    });
    if (cycleData.length > 0) {
      setSftAsgnCycLabData(cycleData);
      setSftAsgnCycLab(cycleData[0].label);
    }
  }, [sftAsgnSftGrpData]);

  useEffect(() => {
    if (sftAsgnCatTotalShiftGrps?.length > 0) {
      const cycleData = sftAsgnCatTotalShiftGrps.map((shiftGroup) => ({
        label: shiftGroup.shift_group_name,
        value: shiftGroup.id,
      }));
      setGroupData(cycleData);
    }
  }, [sftAsgnCatTotalShiftGrps, sftAsgnCycLab]);

  const validateCount = useCallback(() => {
    if (
      isNaN(parseInt(sftAsgnCycRecurCount)) ||
      parseInt(sftAsgnCycRecurCount) < 1
    ) {
      return "Total period must be a positive number";
    }
    if (sftAsgnCycType === "Weekly" && parseInt(sftAsgnCycRecurCount) > 52) {
      return "Total period must be between 1 and 52";
    }
    if (sftAsgnCycType === "Monthly" && parseInt(sftAsgnCycRecurCount) > 12) {
      return "Total period must be between 1 and 12";
    }
    if (ediReasgnBtnName !== "Edit" || ediReasgnBtnName !== "") {
      if (
        !moment(sftAsgnCycStartDate)
          .startOf("day")
          .isAfter(moment(oldAsgnCloseDate).startOf("day"))
      ) {
        return "Start date set to be only from after current assignment close date";
      }
      if (
        !moment(sftAsgnCycRecurUntil)
          .startOf("day")
          .isAfter(moment(oldAsgnCloseDate).startOf("day"))
      ) {
        return "Until date set to be only from after current assignment close date";
      }
    }
    if (ediReasgnBtnName === "Edit") {
      if (
        !moment(sftAsgnCycStartDate)
          .startOf("day")
          .isSameOrAfter(moment(new Date()).startOf("day"))
      ) {
        return "Start date set to be only from current date";
      }
      if (
        !moment(sftAsgnCycRecurUntil)
          .startOf("day")
          .isSameOrAfter(moment(new Date()).startOf("day"))
      ) {
        return "Until date set to be after start date";
      }
    }
    return "";
  }, [
    sftAsgnCycRecurCount,
    sftAsgnCycType,
    sftAsgnCycStartDate,
    sftAsgnCycRecurUntil,
    ediReasgnBtnName,
    oldAsgnCloseDate,
  ]);

  const getCycleCalendarData = useCallback(async () => {
    if (sftAsgnCycLab !== "" && sftAsgnSftGrpData.length > 0) {
      const errorMessage = validateCount();
      if (!errorMessage && !isNaN(parseInt(sftAsgnCycNumCount))) {
        const selectedCycle = sftAsgnSftGrpData.find(
          (cycle) => Object.keys(cycle)[0] === sftAsgnCycLab
        );

        if (!selectedCycle) {
          return [];
        }

        // const formattedData = selectedCycle[sftAsgnCycLab].shifts.map(
        //   (shift) => ({
        //     shift_id: shift.shift_id,
        //     group_id: shift.groups.map((group) => group.id),
        //   })
        // );

        const formattedData = sftAsgnSftGrpData.flatMap((cycleObj) => {
          const cycleKey = Object.keys(cycleObj)[0];
          const cycle = cycleObj[cycleKey];
          return cycle.shifts.map((shift) => ({
            cycle_id: cycle.cycle_id,
            shift_id: shift.shift_id,
            group_id: shift.groups.map((group) => group.id),
            color_code: shift.color_code,
          }));
        });

        const params = new URLSearchParams({
          shift_cycle: sftAsgnCycType,
          start_date: moment(sftAsgnCycStartDate).format("YYYY-MM-DD"),
          end_date: moment(sftAsgnCycRecurUntil).format("YYYY-MM-DD"),
          no_of_cycle: parseInt(sftAsgnCycNumCount),
          total_period: parseInt(sftAsgnCycRecurCount),
          shift_groups: JSON.stringify(formattedData),
        });

        const allGroupsValid = formattedData.every(
          (item) => item?.group_id?.length > 0
        );

        if (allGroupsValid && multiApiCallRestriction && shiftGrpId) {
          try {
            multiApiCallRestriction = false;
            const response = await fetch(
              `${domain}shift-group-assignment/?${params.toString()}&group_id=${shiftGrpId}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `token ${token}`,
                },
              }
            );
            const res = await response.json();
            if (response.ok) {
              setSftAsgnCycCalenDat(res?.assignments ? res?.assignments : []);
              multiApiCallRestriction = true;
            } else if (response.status === 409) {
              sessionHandling();
            } else {
              throw new Error(res.error);
            }
          } catch (error) {
            setSftAsgnCycCalenDat([]);
            multiApiCallRestriction = true;
          }
        }
      } else {
        setSftAsgnCycCalenDat([]);
      }
    }
  }, [
    domain,
    sftAsgnCycLab,
    sftAsgnCycNumCount,
    sftAsgnCycRecurCount,
    sftAsgnCycRecurUntil,
    sftAsgnCycStartDate,
    sftAsgnCycType,
    sftAsgnSftGrpData,
    shiftGrpId,
    token,
    validateCount,
  ]);

  useEffect(() => {
    getCycleCalendarData();
  }, [getCycleCalendarData]);

  const CalculateDateBasedOnCount = useCallback(
    (count, type) => {
      const currentDate = sftAsgnCycStartDate;
      let futureDate;

      if (type === "Weekly") {
        futureDate = new Date(
          currentDate.getTime() + count * 7 * 24 * 60 * 60 * 1000
        );
        futureDate.setDate(futureDate.getDate() - 1);
        // setSftAsgnCycRecurUntil(new Date("2025-03-31"));
      } else if (type === "Monthly") {
        futureDate = new Date(currentDate);
        futureDate.setMonth(futureDate.getMonth() + Math.ceil(count));
        futureDate.setDate(futureDate.getDate() - 1);
        // setSftAsgnCycRecurUntil(new Date("2025-03-31"));
      }
    },
    [sftAsgnCycStartDate]
  );

  const CalculateCountBasedOnDate = useCallback(
    (endDate, type) => {
      const currentDate = sftAsgnCycStartDate;
      currentDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      let difference;

      if (type === "Weekly") {
        const diffInMs = endDate.getTime() - currentDate.getTime();
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        difference = Math.ceil(diffInDays / 7);
        setSftAsgnCycRecurCount(difference < 1 ? 1 : difference);
      } else if (type === "Monthly") {
        const yearsDifference =
          endDate.getFullYear() - currentDate.getFullYear();
        const monthsDifference = endDate.getMonth() - currentDate.getMonth();
        difference = yearsDifference * 12 + monthsDifference;
        setSftAsgnCycRecurCount(
          Math.ceil(difference) < 1 ? 1 : Math.ceil(difference)
        );
      }
    },
    [sftAsgnCycStartDate]
  );

  useEffect(() => {
    if (sftAsgnCycType === "Weekly") {
      setSftAsgnCycStartDorM(moment(sftAsgnCycStartDate).format("dddd"));
      // setSftAsgnCycRecurCount(1);
      // setSftAsgnCycRecurUntil(new Date("2025-03-31"));
      sftAsgnCycRecurType === "recurtype1"
        ? CalculateDateBasedOnCount(1, "Weekly")
        : CalculateCountBasedOnDate(sftAsgnCycStartDate, "Weekly");
    } else {
      setSftAsgnCycStartDorM(moment(sftAsgnCycStartDate).format("MMMM"));
      // setSftAsgnCycRecurCount(1);
      // setSftAsgnCycRecurUntil(new Date("2025-03-31"));
      sftAsgnCycRecurType === "recurtype1"
        ? CalculateDateBasedOnCount(1, "Monthly")
        : CalculateCountBasedOnDate(sftAsgnCycStartDate, "Monthly");
    }
  }, [
    sftAsgnCycType,
    sftAsgnCycStartDate,
    sftAsgnCycRecurType,
    CalculateDateBasedOnCount,
    CalculateCountBasedOnDate,
    ediReasgnBtnName,
  ]);

  const transformForPayloadData = (data) => {
    const payloadData = data.reduce((acc, cycle) => {
      const cycleKey = Object.keys(cycle)[0];
      const cycleData = cycle[cycleKey];

      cycleData.shifts.forEach((shift) => {
        const groupIds = shift.groups.map((group) => group.id);

        acc.push({
          cycle_id: cycleData.cycle_id,
          shift_id: shift.shift_id,
          group_id: groupIds,
        });
      });

      return acc;
    }, []);
    return payloadData;
  };

  const handleAutocompleteChange = (cycleIndex, shiftIndex, newValue) => {
    const previousValue =
      sftAsgnSftGrpData[cycleIndex][`cycle ${cycleIndex + 1}`].shifts[
        shiftIndex
      ].groups;

    setSftAsgnSftGrpData((prevData) => {
      const updatedData = [...prevData];
      updatedData[cycleIndex][`cycle ${cycleIndex + 1}`].shifts[
        shiftIndex
      ].groups = newValue;
      return updatedData;
    });

    setsftAsgnSftGrpCombData((prevData) => {
      const updatedCombData = [...prevData];
      const currentCycle =
        updatedCombData[cycleIndex][`cycle ${cycleIndex + 1}`];

      const removedItems = previousValue.filter(
        (prevGroup) =>
          !newValue.some(
            (newGroup) =>
              newGroup.shift_group_name === prevGroup.shift_group_name
          )
      );

      const addedItems = newValue.filter(
        (newGroup) =>
          !previousValue.some(
            (prevGroup) =>
              prevGroup.shift_group_name === newGroup.shift_group_name
          )
      );

      removedItems.forEach((removedGroup) => {
        const index = currentCycle.indexOf(removedGroup.shift_group_name);
        if (index > -1) {
          currentCycle.splice(index, 1);
        }
      });

      addedItems.forEach((addedGroup) => {
        currentCycle.push(addedGroup.shift_group_name);
      });

      return updatedCombData;
    });
  };

  const getFilteredOptions = (cycleIndex, shiftIndex) => {
    const selectedValuesInCycle =
      sftAsgnSftGrpData[cycleIndex][`cycle ${cycleIndex + 1}`].shifts;

    const selectedValues = selectedValuesInCycle
      .filter((_, index) => index !== shiftIndex)
      .flatMap((shift) => shift.groups)
      .map((group) => group.id);

    return sftAsgnCatTotalShiftGrps.filter(
      (option) => !selectedValues.includes(option.id)
    );
  };

  const validateCombData = () => {
    const firstRowValues = sftAsgnSftGrpCombData[0][`cycle 1`];
    const firstRowSet = new Set(firstRowValues);

    for (let i = 1; i < sftAsgnSftGrpCombData.length; i++) {
      const currentRowValues = sftAsgnSftGrpCombData[i][`cycle ${i + 1}`];
      const currentRowSet = new Set(currentRowValues);
      if (
        currentRowSet.size !== firstRowSet.size ||
        !Array.from(firstRowSet).every((value) => currentRowSet.has(value))
      ) {
        setSftAsgnErr(
          `Cycle ${i + 1} does not contain exactly the same groups as Cycle 1.`
        );
        return false;
      }
    }
    setSftAsgnErr("");
    return true;
  };

  const updateCloseDateCurAsgn = async (data) => {
    setCloseDateSelPopup(false);
    setSftAsgnCycStartDate(
      dayjs(data.oldassgncloseDate).add(1, "day").toDate()
    );
    setActBtnEnable(true);
  };

  const onSubmit = async (data) => {
    const isCombDataValid =
      sftAsgnSftGrpCombData?.length > 1 ? validateCombData() : true;

    const errorMessage = validateCount();
    if (
      isCombDataValid &&
      !errorMessage &&
      sftAsgnErr === "" &&
      !isNaN(parseInt(sftAsgnCycNumCount)) &&
      multiApiCallRestriction
    ) {
      const apiPayloadSftData = transformForPayloadData(sftAsgnSftGrpData);
      let editPayloadData = {};
      if (ediReasgnBtnName === "Edit") {
        editPayloadData = {
          shift_assignment_id: selSftAsgnData?.shift_assignment_id,
          shift_cycle: sftAsgnCycType,
          start_date: moment(sftAsgnCycStartDate).format("YYYY-MM-DD"),
          end_date: moment(sftAsgnCycRecurUntil).format("YYYY-MM-DD"),
          no_of_cycle: parseInt(sftAsgnCycNumCount),
          total_period:
            sftAsgnCycRecurType === "recurtype2"
              ? 0
              : parseInt(sftAsgnCycRecurCount),
          shift_category_id: sftAsgnCatId,
          shift_groups: apiPayloadSftData,
          is_total_period: sftAsgnCycRecurType === "recurtype1" ? true : false,
          is_year_end: sftAsgnCycRecurType === "recurtype2" ? true : false,
        };
      } else if (ediReasgnBtnName === "Re-Assign") {
        editPayloadData = {
          shift_assignment_id: selSftAsgnData?.shift_assignment_id,
          shift_cycle: sftAsgnCycType,
          start_date: moment(sftAsgnCycStartDate).format("YYYY-MM-DD"),
          end_date: moment(sftAsgnCycRecurUntil).format("YYYY-MM-DD"),
          no_of_cycle: parseInt(sftAsgnCycNumCount),
          total_period:
            sftAsgnCycRecurType === "recurtype2"
              ? 0
              : parseInt(sftAsgnCycRecurCount),
          shift_category_id: sftAsgnCatId,
          shift_groups: apiPayloadSftData,
          expired_date: moment(oldAsgnCloseDate).format("YYYY-MM-DD"),
          is_total_period: sftAsgnCycRecurType === "recurtype1" ? true : false,
          is_year_end: sftAsgnCycRecurType === "recurtype2" ? true : false,
        };
      }

      try {
        multiApiCallRestriction = false;
        const response = await fetch(`${domain}shift-assignment/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify(editPayloadData),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          multiApiCallRestriction = true;
          updateShowAddShiftAsgn(false);
          setReldSftAsgnApi(true);
          reCallApi(true);
          setShowTopBar(true);
          reset();
          setDataValidErr("");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        multiApiCallRestriction = true;
      }
    } else {
      setDataValidErr(errorMessage);
    }
  };

  useEffect(() => {
    const fetchShiftAssignmentDetails = async () => {
      try {
        const response = await fetch(`${domain}shift-assignment/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setAllSftAsgn(res);
          setMasterDataForSearch(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (reldSftAsgnApi) {
      setIsLoading(true);
      setReldSftAsgnApi(false);
      fetchShiftAssignmentDetails();
    }
  }, [domain, reldSftAsgnApi, token]);

  useEffect(() => {
    const fetchShiftCategoryDetails = async () => {
      try {
        const response = await fetch(`${domain}rotational-shiftcategory/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const labels = res.map((obj) => ({
            ...obj,
            label: obj.shift_category_name,
          }));
          setAllSftCategoryDet([...labels]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (reldSftAsgnApi) {
      fetchShiftCategoryDetails();
    }
  }, [domain, reldSftAsgnApi, token]);

  const handleShiftGrp = (event, data) => {
    if (data) {
      setGroupName(data?.label);
      setShiftGrpId(data?.value);
    }
  };

  useEffect(() => {
    const calculateNewDate = () => {
      const count = parseInt(sftAsgnCycRecurCount || "1", 10);
      let newDate = sftAsgnCycRecurUntil;

      if (sftAsgnCycType === "Weekly" && sftAsgnCycRecurType === "recurtype1") {
        const daysToAdd = count * 7;
        newDate = dayjs(sftAsgnCycStartDate).add(daysToAdd, "day").toDate();
      } else if (
        sftAsgnCycType === "Monthly" &&
        sftAsgnCycRecurType === "recurtype1"
      ) {
        newDate = dayjs(sftAsgnCycStartDate).add(count, "month").toDate();
      } else if (sftAsgnCycRecurType === "recurtype2") {
        const currentYear = dayjs().year();
        newDate = dayjs(`${currentYear}-12-31`).endOf("day").toDate();
      }

      setSftAsgnCycRecurUntil(newDate);
    };
    if (handleDate) {
      calculateNewDate();
      setHandleDate(false);
    }
  }, [
    handleDate,
    sftAsgnCycRecurCount,
    sftAsgnCycRecurType,
    sftAsgnCycRecurUntil,
    sftAsgnCycStartDate,
    sftAsgnCycType,
  ]);

  return (
    <>
      <Mui.Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
        <Mui.Grid item xs={12} sm={12} md={10} lg={10}>
          <Mui.Stack direction="row">
            <BackArrow
              actionFunction={() => {
                updateShowAddShiftAsgn(false);
                setShowTopBar(true);
                dispatch(setGlobalSearchValue(""));
              }}
            />
            <Mui.Typography
              sx={{
                fontWeight: "bold",
                fontSize: 27,
                paddingTop: 1,
                borderRadius: 2,
              }}
            >
              Shift / Edit Rotational Shift Assignment
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid
          xs={12}
          sm={12}
          md={2}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 2,
          }}
        >
          {selSftAsgnData?.status !== "completed" ? (
            <>
              {ediReasgnBtnName && !actBtnEnable ? (
                <CustomButton
                  actionFuntion={() => {
                    if (ediReasgnBtnName === "Edit") {
                      setActBtnEnable(true);
                    } else if (ediReasgnBtnName !== "") {
                      setCloseDateSelPopup(true);
                    }
                  }}
                  actionName={ediReasgnBtnName}
                  typeName="button"
                />
              ) : null}{" "}
            </>
          ) : null}
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container spacing={1} sx={{ mt: 1 }}>
        <Mui.Grid item md={1} lg={1}></Mui.Grid>
        <Mui.Grid item xs={12} sm={12} md={10} lg={10}>
          <Mui.Grid
            xs={12}
            sx={{
              backgroundColor: themes.primary,
              padding: 2,
              textAlign: "center",
            }}
          >
            <Mui.Typography
              sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
            >
              Edit Rotational Shift Assignment
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Card sx={{ padding: 5, pt: 2, pb: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {actBtnEnable && ediReasgnBtnName === "Re-Assign" ? (
                <Mui.Grid container spacing={1} sx={{ mt: 1 }}>
                  <Mui.Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    sx={{ paddingBottom: 1 }}
                  >
                    <Mui.Typography sx={{ fontWeight: "bold" }}>
                      Current assignment closed date
                    </Mui.Typography>
                    <Controller
                      name="shiftStartDate"
                      control={control}
                      defaultValue={oldAsgnCloseDate}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            {...field}
                            value={oldAsgnCloseDate}
                            minDate={new Date()}
                            sx={{ minWidth: "100%" }}
                            disabled
                            format="dd/MM/yyyy"
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                sx={{ minWidth: "100%" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              ) : null}

              <Mui.Grid container>
                <Mui.Grid container sx={{ paddingTop: 4 }}>
                  <Mui.Grid item xs={2.2}>
                    <Mui.Typography
                      sx={{ fontWeight: 700, fontSize: 19, paddingTop: 1 }}
                    >
                      Shift Category Name&nbsp;
                      <Mui.Tooltip title="This is the Shift Category Name field. Update the selection by choosing from the previously created rotational shifts.">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={4}>
                    <Mui.Autocomplete
                      id="shift-category"
                      size="small"
                      value={sftAsgnCatName}
                      options={allSftCategoryDet}
                      disabled
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option.label}</li>;
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          {...register("shiftasgncategory", {
                            required: "Shift category is required",
                          })}
                          value={sftAsgnCatName}
                          sx={{ background: themes.whiteColor }}
                          placeholder={"Select only rotational shift"}
                          size="medium"
                          error={errors.shiftasgncategory ? true : false}
                          helperText={
                            errors.shiftasgncategory
                              ? errors.shiftasgncategory.message
                              : ""
                          }
                        />
                      )}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Divider sx={{ mt: 2 }} />
              <Mui.Grid container sx={{ mt: 1 }}>
                <Mui.Typography sx={{ fontWeight: 800, fontSize: 22 }}>
                  Shift Rotation
                </Mui.Typography>
                <Mui.Grid container>
                  <Mui.Grid item xs={2}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        fontWeight: "bold",
                        mt: 1.5,
                      }}
                    >
                      Rotation Period &nbsp;
                      <Mui.Tooltip title="This is the Rotation Period field. Adjust the duration of the rotation cycle, such as Weekly, Bi-Weekly, or Monthly, to reflect the new scheduling requirements.">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3.5}
                    sx={{ paddingBottom: 1 }}
                  >
                    <Controller
                      name="shiftassigncycle"
                      control={control}
                      defaultValue={sftAsgnCycType}
                      rules={{ required: "Select a cycle type" }}
                      disabled={!actBtnEnable}
                      render={({ field }) => (
                        <Mui.Select
                          {...field}
                          value={sftAsgnCycType}
                          sx={{ minWidth: "100%" }}
                          onChange={(e) => setSftAsgnCycType(e.target.value)}
                          renderValue={(selected) => selected}
                          error={errors.shiftassigncycle}
                        >
                          {cycleTypes.map((cyctype) => (
                            <Mui.MenuItem value={cyctype.type} key={cyctype.id}>
                              <Mui.ListItemText primary={cyctype.type} />
                            </Mui.MenuItem>
                          ))}
                        </Mui.Select>
                      )}
                    />
                    {errors.shiftassigncycle && (
                      <Mui.FormHelperText error>
                        {errors.shiftassigncycle.message}
                      </Mui.FormHelperText>
                    )}
                  </Mui.Grid>

                  <Mui.Grid item xs={0.5}></Mui.Grid>
                  <Mui.Grid iten xs={2}>
                    <Mui.Typography
                      sx={{
                        fontSize: 20,
                        fontWeight: "bold",
                        mt: 1.5,
                      }}
                    >
                      No. of Repetition &nbsp;
                      <Mui.Tooltip title="This is the No. of Repetition field. Enter the number of times this shift can be assigned.">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={3.5}>
                    <Controller
                      name="shiftCycleCount"
                      control={control}
                      value={sftAsgnCycNumCount}
                      disabled={!actBtnEnable}
                      rules={{
                        required: "This field is required",
                        min: { value: 1, message: "Minimum value is 1" },
                        pattern: {
                          value: /^\d+$/,
                          message: "Must be a valid number",
                        },
                      }}
                      render={({ field }) => (
                        <Mui.TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            min: 1,
                          }}
                          value={sftAsgnCycNumCount}
                          onChange={(e) => {
                            setSftAsgnCycNumCount(e.target.value);
                            field.onChange(e.target.value);
                          }}
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            fontSize: 14,
                            width: "100%",
                          }}
                          error={errors.shiftCycleCount}
                          helperText={errors.shiftCycleCount?.message}
                        />
                      )}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid item xs={2}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      mt: 1.5,
                    }}
                  >
                    Start From &nbsp;
                    <Mui.Tooltip title="This is the Start From field. Update the start date for this shift assignment. Use this to align with changes in the schedule's starting period.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={3.5}>
                  <Controller
                    name="shiftStartDate"
                    control={control}
                    defaultValue={sftAsgnCycStartDate}
                    rules={{
                      required: "Date is required",
                      validate: (value) => {
                        if (!value || String(value) === "Invalid Date") {
                          return "Date is required";
                        }
                        return true;
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          {...field}
                          value={sftAsgnCycStartDate}
                          minDate={
                            ediReasgnBtnName !== "Edit" ||
                            ediReasgnBtnName !== ""
                              ? sftAsgnCycStartDate
                              : new Date()
                          }
                          sx={{ width: "100%" }}
                          disabled={!actBtnEnable}
                          format="dd/MM/yyyy"
                          onChange={(newValue) => {
                            if (
                              newValue !== null ||
                              String(newValue) === "Invalid Date"
                            ) {
                              setSftAsgnCycRecurUntil(newValue);
                              setSftAsgnCycStartDate(newValue);
                            }
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{ minWidth: "100%" }}
                              error={Boolean(error)}
                              helperText={Boolean(error) && error?.message}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {/* <Mui.TextField
                    sx={{ minWidth: "49%" }}
                    value={sftAsgnCycStartDorM}
                    disabled
                  /> */}
                </Mui.Grid>
                <Mui.Grid xs={0.5}></Mui.Grid>
                <Mui.Grid item xs={2}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      mt: 1.5,
                    }}
                  >
                    Repeat Until &nbsp;
                    <Mui.Tooltip title="This is the Repeat Until field. Update the selection to either Weeks or Year End to define the duration for the shift repetition.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid
                  item
                  xs={1}
                  sx={{
                    pointerEvents: actBtnEnable === false ? "none" : "auto",
                    opacity: actBtnEnable === false ? 1 : 1,
                    paddingTop: 1,
                  }}
                >
                  <Mui.FormControlLabel
                    value="recurtype1"
                    control={
                      <Mui.Radio
                        checked={sftAsgnCycRecurType === "recurtype1"}
                        onChange={(e) => {
                          setSftAsgnCycRecurType(e.target.value);
                          setSftAsgnCycRecurUntil(null);
                          setHandleDate(true);
                        }}
                      />
                    }
                    label="Period"
                  />
                </Mui.Grid>
                <Mui.Grid item xs={2} sx={{ paddingTop: 1 }}>
                  <Mui.Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2, md: 1 }}
                  >
                    {!actBtnEnable ? (
                      <Mui.TextField
                        type="number"
                        size="small"
                        // value={sftAsgnCycRecurCount}
                        value={selSftAsgnData?.total_period}
                        disabled={!actBtnEnable}
                        style={{
                          fontSize: 14,
                          width: "30%",
                        }}
                      />
                    ) : (
                      <Controller
                        name="occurrencesCount"
                        control={control}
                        value={sftAsgnCycRecurCount}
                        // value={selSftAsgnData?.total_period}
                        disabled={
                          sftAsgnCycRecurType === "recurtype2" ? true : false
                        }
                        rules={{
                          required: "This field is required",
                          min: {
                            value: 1,
                            message: "Minimum value is 1",
                          },
                          max: {
                            value: sftAsgnCycType === "Weekly" ? 52 : 12,
                            message:
                              sftAsgnCycType === "Weekly"
                                ? "Maximum value is 52 for Weekly"
                                : "Maximum value is 12 for Monthly",
                          },
                          pattern: {
                            value: /^\d+$/,
                            message: "Must be a valid number",
                          },
                        }}
                        render={({ field }) => (
                          <Mui.TextField
                            {...field}
                            type="number"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              min: 1,
                            }}
                            value={sftAsgnCycRecurCount}
                            onChange={(e) => {
                              setSftAsgnCycRecurCount(e.target.value);
                              // CalculateDateBasedOnCount(
                              //   e.target.value,
                              //   sftAsgnCycType
                              // );
                              field.onChange(e.target.value);
                              setHandleDate(true);
                            }}
                            disabled={sftAsgnCycRecurType === "recurtype2"}
                            style={{
                              fontSize: 14,
                              width: "30%",
                            }}
                            error={errors.occurrencesCount}
                            helperText={errors.occurrencesCount?.message}
                          />
                        )}
                      />
                    )}
                    <Mui.Typography sx={{ paddingTop: 2 }}>
                      {sftAsgnCycType === "Weekly" ? "Weeks" : "Months"}
                    </Mui.Typography>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid
                  container
                  sx={{
                    pointerEvents: actBtnEnable === false ? "none" : "auto",
                    opacity: actBtnEnable === false ? 1 : 1,
                  }}
                >
                  <Mui.Grid item xs={8}></Mui.Grid>
                  <Mui.Grid item xs={2} sx={{ paddingTop: 1 }}>
                    <Mui.FormControlLabel
                      value="recurtype2"
                      control={
                        <Mui.Radio
                          checked={sftAsgnCycRecurType === "recurtype2"}
                          onChange={(e) => {
                            setSftAsgnCycRecurType(e.target.value);
                            setHandleDate(true);
                          }}
                        />
                      }
                      label="Year End"
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.Grid item xs={2}>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      mt: 1.5,
                    }}
                  >
                    End Date &nbsp;
                    <Mui.Tooltip title="This is the End Date field. Once the start date is selected, the end date will be automatically generated and cannot be edited.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={3.5}>
                  {!actBtnEnable ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={dayjs(
                            selSftAsgnData?.end_date,
                            "DD-MM-YYYY"
                          ).toDate()}
                          sx={{ minWidth: "100%" }}
                          format="dd/MM/yyyy"
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{ minWidth: "100%" }}
                            />
                          )}
                          disabled={true}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <Controller
                      name="untilDate"
                      control={control}
                      defaultValue={sftAsgnCycRecurUntil}
                      rules={{
                        required: "Date is required",
                        validate: (value) => {
                          if (!value || String(value) === "Invalid Date") {
                            return "Date is required";
                          }
                          return true;
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            {...field}
                            value={sftAsgnCycRecurUntil}
                            minDate={sftAsgnCycStartDate}
                            maxDate={dayjs(sftAsgnCycStartDate)
                              .add(1, "year")
                              .subtract(1, "day")
                              .toDate()}
                            sx={{ minWidth: "100%" }}
                            format="dd/MM/yyyy"
                            onChange={(newValue) => {
                              if (
                                newValue !== null ||
                                String(newValue) === "Invalid Date"
                              ) {
                                setSftAsgnCycRecurUntil(newValue);
                                CalculateCountBasedOnDate(
                                  newValue,
                                  sftAsgnCycType
                                );
                              }
                              field.onChange(newValue);
                            }}
                            disabled={true}
                            renderInput={(params) => (
                              <Mui.TextField
                                {...params}
                                sx={{ minWidth: "100%" }}
                                error={Boolean(error)}
                                helperText={Boolean(error) && error?.message}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  )}
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Divider sx={{ mt: 2 }} />
              {dataValidErr !== "" ? (
                <h4 style={{ color: themes.helperTextColor, marginTop: 1 }}>
                  {dataValidErr}
                </h4>
              ) : null}
              <Mui.Typography
                sx={{ fontWeight: 800, fontSize: 22, paddingTop: 2 }}
              >
                Rotational Shift Assignment
              </Mui.Typography>

              {sftAsgnSftGrpData?.length > 0 ? (
                <>
                  <Mui.TableContainer
                    component={Mui.Paper}
                    sx={{ width: "100%" }}
                  >
                    <Mui.Table>
                      <Mui.TableHead>
                        <Mui.TableRow>
                          <Mui.TableCell></Mui.TableCell>
                          {sftAsgnSftGrpData[0][`cycle 1`].shifts.map(
                            (shift, colIndex) => (
                              <Mui.TableCell key={colIndex}>
                                <Mui.Grid container spacing={2}>
                                  <Mui.Grid item sx={{ paddingTop: 0.5 }}>
                                    <Mui.Box
                                      sx={{
                                        width: "50px",
                                        height: "24px",
                                        backgroundColor: shift?.color_code,
                                        marginLeft: "8px",
                                        borderRadius: "4px",
                                      }}
                                    />
                                  </Mui.Grid>
                                  <Mui.Grid item>
                                    <Mui.Typography
                                      style={{
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {shift.shift_name}
                                    </Mui.Typography>
                                  </Mui.Grid>
                                </Mui.Grid>

                                {/* <Mui.Typography>
                                    {moment(
                                      shift.start_time,
                                      "HH:mm:ss"
                                    ).format("h:mm a")}{" "}
                                    -{" "}
                                    {moment(shift.end_time, "HH:mm:ss").format(
                                      "h:mm a"
                                    )}
                                  </Mui.Typography> */}
                              </Mui.TableCell>
                            )
                          )}
                        </Mui.TableRow>
                      </Mui.TableHead>
                      <Mui.TableBody>
                        {sftAsgnSftGrpData.map((cycleData, cycleIndex) => {
                          const cycleKey = `cycle ${cycleIndex + 1}`;
                          const cycle = cycleData[cycleKey];

                          return (
                            <Mui.TableRow key={cycleKey}>
                              <Mui.TableCell>
                                <Mui.Typography style={{ fontWeight: "bold" }}>
                                  {cycleKey}
                                </Mui.Typography>
                              </Mui.TableCell>

                              {cycle.shifts.map((shift, shiftIndex) => (
                                <Mui.TableCell key={shiftIndex}>
                                  <Controller
                                    name={`shiftcatDepGrps[${cycleIndex}][${shiftIndex}]`}
                                    control={control}
                                    defaultValue={shift.groups}
                                    rules={{
                                      required: "Select at least one group",
                                    }}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <Mui.Autocomplete
                                        multiple
                                        size="small"
                                        // options={getFilteredOptions(
                                        //   cycleIndex,
                                        //   shiftIndex
                                        // )}
                                        options={getFilteredOptions(
                                          cycleIndex,
                                          shiftIndex
                                        ).filter(
                                          (option) =>
                                            option.label !==
                                            "Unassigned Shift Group"
                                        )}
                                        value={field.value || []}
                                        isOptionEqualToValue={(option, value) =>
                                          option.label === value.label
                                        }
                                        getOptionLabel={(option) =>
                                          option?.label ? option.label : ""
                                        }
                                        filterSelectedOptions
                                        onChange={(event, value) => {
                                          field.onChange(value);
                                          handleAutocompleteChange(
                                            cycleIndex,
                                            shiftIndex,
                                            value
                                          );
                                        }}
                                        disabled={!actBtnEnable}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                          <Mui.TextField
                                            {...params}
                                            sx={{
                                              background: themes.whiteColor,
                                            }}
                                            placeholder="Select groups"
                                            value={
                                              sftAsgnSftGrpData[cycleIndex][
                                                `cycle ${cycleIndex + 1}`
                                              ][cycleData]
                                            }
                                            size="medium"
                                            error={error}
                                            helperText={
                                              error ? error.message : ""
                                            }
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Mui.TableCell>
                              ))}
                            </Mui.TableRow>
                          );
                        })}
                      </Mui.TableBody>
                    </Mui.Table>
                  </Mui.TableContainer>
                  {sftAsgnErr !== "" ? (
                    <h4 style={{ color: themes.helperTextColor, marginTop: 1 }}>
                      {sftAsgnErr}
                    </h4>
                  ) : null}
                </>
              ) : (
                <Mui.Alert severity="info">No shifts available</Mui.Alert>
              )}

              {sftAsgnSftGrpData?.length > 0 &&
              sftAsgnCycLabData?.length > 0 ? (
                <>
                  <Mui.Grid container sx={{ mt: 3 }}>
                    <Mui.Grid item xs={3.5}>
                      <Mui.Typography sx={{ fontWeight: 800, fontSize: 22 }}>
                        Assignment Representation
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={3.3}>
                      {/* <Mui.Select
                        value={sftAsgnCycLab}
                        fullWidth
                        sx={{ minWidth: "100%" }}
                        onChange={(e) => setSftAsgnCycLab(e.target.value)}
                        renderValue={(selected) => selected}
                      >
                        {sftAsgnCycLabData.map((cyc) => (
                          <Mui.MenuItem value={cyc.label} key={cyc.label}>
                            <Mui.ListItemText primary={cyc.label} />
                          </Mui.MenuItem>
                        ))}
                      </Mui.Select> */}
                      {!actBtnEnable && (
                        <CustomTextBox
                          selectedValue={shiftGroupName}
                          disabledValue={!actBtnEnable}
                        />
                      )}
                      {actBtnEnable && (
                        <CustomAutoComplete
                          dataList={groupData.filter(
                            (item) =>
                              item.label !== shiftGroupName &&
                              item.label !== "Unassigned Shift Group"
                          )}
                          selectedValue={shiftGroupName}
                          updateDataFunction={handleShiftGrp}
                          placeHolder="Select the shift type"
                          errorMessage={
                            errors?.shift_type?.message
                              ? errors?.shift_type?.message
                              : ""
                          }
                          textBoxError={errors?.shift_type ? true : false}
                        />
                      )}
                    </Mui.Grid>
                  </Mui.Grid>

                  {sftAsgnCycCalenDat.length > 0 ? (
                    <Mui.Grid container spacing={{ xs: 2, md: 1 }}>
                      {sftAsgnCycCalenDat.map((shift, index) => (
                        <Mui.Grid
                          item
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          key={index}
                          sx={{ borderColor: themes.primary, marginTop: 1 }}
                        >
                          <Mui.Card sx={{ backgroundColor: shift.color_code }}>
                            <Mui.Stack
                              direction="column"
                              width={"100%"}
                              sx={{
                                padding: 2,
                                textAlign: "center",
                              }}
                            >
                              <Mui.Typography
                                sx={{ fontSize: 12, fontWeight: "bold" }}
                              >
                                {sftAsgnCycType === "Weekly"
                                  ? `Week - ${shift?.week_number}`
                                  : `Month - ${shift?.month_number}`}
                              </Mui.Typography>
                              <Mui.Typography
                                sx={{ fontSize: 12, fontWeight: "bold" }}
                              >
                                {shift?.shift_name}
                              </Mui.Typography>
                            </Mui.Stack>
                          </Mui.Card>
                        </Mui.Grid>
                      ))}
                    </Mui.Grid>
                  ) : (
                    <Mui.Alert severity="info">
                      No shift cycle available
                    </Mui.Alert>
                  )}
                </>
              ) : null}

              {actBtnEnable ? (
                <Mui.Grid
                  container
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Mui.Grid item>
                    <Mui.Button
                      variant="contained"
                      sx={{
                        background: themes.primaryButton,
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: themes.primaryButton,
                        },
                        color: themes.headLine,
                      }}
                      onClick={() => updateShowAddShiftAsgn(false)}
                    >
                      Cancel
                    </Mui.Button>
                  </Mui.Grid>
                  <Mui.Grid item>
                    <Mui.Button
                      variant="contained"
                      sx={{
                        background: themes.primaryButton,
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: themes.primaryButton,
                        },
                        color: themes.headLine,
                      }}
                      type="submit"
                    >
                      {ediReasgnBtnName === "Edit" ? "Update" : "Re-Assign"}
                    </Mui.Button>
                  </Mui.Grid>
                </Mui.Grid>
              ) : null}
            </form>
          </Mui.Card>
        </Mui.Grid>
        <Mui.Grid item md={1} lg={1}></Mui.Grid>
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={() => {
          setCloseDateSelPopup(false);
          setValue("oldassgncloseDate", new Date());
          setOldAsgnCloseDate(new Date());
        }}
        open={closeDateSelPopup}
        title={"Close Current Shift Assignment"}
      >
        <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
          <Mui.Typography sx={{ fontSize: 18 }}>
            Please select the current shift assignment close date
          </Mui.Typography>
        </Mui.Grid>
        <form onSubmit={handleSubmit(updateCloseDateCurAsgn)}>
          <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Close Date</Mui.Typography>
            <Controller
              name="oldassgncloseDate"
              control={control}
              defaultValue={oldAsgnCloseDate}
              rules={{
                required: "Date is required",
                validate: (value) => {
                  if (!value || String(value) === "Invalid Date") {
                    return "Date is required";
                  }
                  const dateValue = new Date(value);
                  if (dateValue >= sftAsgnCycRecurUntil) {
                    return "Date cannot be after the allowed maximum date";
                  }

                  if (
                    dateValue < new Date() &&
                    dateValue.toDateString() !== new Date().toDateString()
                  ) {
                    return "Date cannot be before the current date";
                  }
                  return true;
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    {...field}
                    value={oldAsgnCloseDate}
                    minDate={new Date()}
                    maxDate={sftAsgnCycRecurUntil}
                    sx={{ minWidth: "100%" }}
                    format="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setOldAsgnCloseDate(newValue);
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        sx={{ minWidth: "100%" }}
                        error={error}
                        helperText={error ? error.message : ""}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Mui.Grid>

          <Mui.Grid container>
            <Mui.Grid xs={8}></Mui.Grid>
            <Mui.Grid
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setCloseDateSelPopup(false);
                  setValue("oldassgncloseDate", new Date());
                  setOldAsgnCloseDate(new Date());
                }}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;
              <CustomButton actionName="Update" typeName="submit" />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
    </>
  );
};
