import React from "react";
import * as Mui from "@mui/material";
// import { Person, Person4 } from "@mui/icons-material";
import { themes } from "services/constants";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { useDispatch } from "react-redux";
import { BackArrow } from "components/CustomComponents/BackArrow";
export const ReviseSalary = ({ setShowReviseScreen }) => {
  //   const history = useHistory();
  //   const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <BackArrow actionFunction={() => setShowReviseScreen(false)} />
        <Mui.Typography sx={{paddingTop:1.5,fontWeight:'bold',fontSize:22}}>Revise Salary</Mui.Typography>
      </Mui.Grid>

      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
          padding: "0 16px",
          "@media (max-width:600px)": {
            minHeight: "50vh",
          },
        }}
      >
        <Mui.Stack direction="Row">
          <Mui.Card
            spacing={2}
            className=" max-w-fit mt-2 w-96 flex space-x-1 shadow-lg"
            variant="outlined"
            sx={{
              width: "15vw",
              transitionDuration: "0.3s",
              height: "10vw",
              border: `1px solid ${themes.shadowColor}`,
              cursor: "pointer",
              borderRadius: 3,
            }}
            onClick={() => {
              console.log("asfdsd");
            }}
          >
            <Mui.CardContent
              sx={{
                justifyContent: "center",
                alignContent: "center",
                minHeight: "20vh",
              }}
            >
              <Mui.Typography name="Existing User" sx={{ textAlign: "center" }}>
                Template Group
              </Mui.Typography>
            </Mui.CardContent>
          </Mui.Card>
          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          <Mui.Card
            spacing={2}
            className=" max-w-fit mt-2 w-96 flex space-x-1 shadow-lg"
            variant="outlined"
            sx={{
              width: "15vw",
              transitionDuration: "0.3s",
              height: "10vw",
              paddingLeft: 2,
              border: `1px solid ${themes.shadowColor}`,
              cursor: "pointer",
              borderRadius: 3,
            }}
            onClick={() => {
              console.log("asfdsd");
            }}
          >
            <Mui.CardContent
              sx={{
                justifyContent: "center",
                alignContent: "center",
                minHeight: "20vh",
              }}
            >
              <Mui.Typography name="New Employee" sx={{ textAlign: "center" }}>
                Individual
              </Mui.Typography>
            </Mui.CardContent>
          </Mui.Card>
        </Mui.Stack>
      </Mui.Grid>
    </React.Fragment>
  );
};
