import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useFetch } from "services/hooks/useFetch";
import { themes } from "services/constants";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { customAPI } from "services/utils/CustomAPI";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import { customGetAPI } from "services/utils/CustomAPI";
import { AddCircle } from "@mui/icons-material";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomButton } from "components/CustomComponents/CustomButton";

export const RolePermissions = () => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const {
    result: allProducts,
    loading,
    setLoad,
  } = useFetch(`${domain}get-all-product-details/`);
  const productsDetails = useMemo(() => {
    return allProducts?.products ? allProducts.products : [];
  }, [allProducts]);
  const [customName, setCustomName] = useState("");
  const [productName, setProductName] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roles, setRoles] = useState([]);
  const [productId, setProductId] = useState(0);
  const [roleId, setRoleId] = useState(0);
  const [customRole, setCustomRole] = useState(false);
  const [customNameError, setCustomNameError] = useState(false);
  const [expandableRoleId, setExpandableRoleId] = useState(0);
  const [permissionDetails, setPermissionDetails] = useState([]);
  const [defaultValueUpdate, setDefaultValueUpdate] = useState(true);
  const [updateCustomRoleDetails, setCustomRoleDetails] = useState(false);

  const getRolePermissions = useCallback(
    async (proId, RolId) => {
      const res = await customGetAPI(
        `${domain}product-permission/?product_id=${proId}&role_id=${RolId}`
      );
      if (res.ok) {
        setPermissionDetails(res.responseDetails);
      }
    },
    [domain]
  );

  useEffect(() => {
    if (!loading && productsDetails.length !== 0 && defaultValueUpdate) {
      setProductName(productsDetails[0]?.product);
      setProductId(productsDetails[0]?.product_id);
      setRoleId(productsDetails[0]?.roles[0]?.role_id);
      setCustomRole(productsDetails[0]?.roles[0]?.is_custom);
      setRoles(productsDetails[0]?.roles);
      setRoleName(productsDetails[0]?.roles[0]?.role_name);
      getRolePermissions(
        productsDetails[0]?.product_id,
        productsDetails[0]?.roles[0]?.role_id
      );
      setDefaultValueUpdate(false);
    }
  }, [
    defaultValueUpdate,
    getRolePermissions,
    loading,
    productsDetails,
    productsDetails.length,
  ]);

  const handleRoles = (event, data) => {
    if (data) {
      setRoleId(data?.role_id);
      setRoleName(data?.label);
      setCustomRole(data?.is_custom);
      getRolePermissions(productId, data?.role_id);
      setExpandableRoleId(0);
    }
  };

  const handleCheckboxChange = async (per) => {
    const response = await customAPI(
      `${domain}product-role-permission/`,
      "POST",
      {
        product_id: productId,
        role_id: roleId,
        permission: per?.permission,
        check: !per?.check,
      }
    );
    if (response.ok) {
      getRolePermissions(productId, roleId);
    }
  };

  const handleCustomName = (event) => {
    setCustomName(event.target.value);
  };

  useEffect(() => {
    if (updateCustomRoleDetails && !loading) {
      setRoles([]);
      allProducts?.products?.forEach((element) => {
        if (productName === element?.product) {
          element?.roles.forEach((role) => {
            if (role.role_name === roleName) {
              setRoleId(role.role_id);
              setRoles(element?.roles);
              setCustomRole(role?.is_custom);
              getRolePermissions(productId, role.role_id);
            }
          });
        }
      });
      setCustomRoleDetails(false);
    }
  }, [
    allProducts?.products,
    customName,
    getRolePermissions,
    loading,
    productId,
    productName,
    productsDetails,
    roleName,
    updateCustomRoleDetails,
  ]);

  const createNewCustomRole = async () => {
    if (customName.trim().length >= 3) {
      setCustomNameError(false);
      const response = await customAPI(`${domain}add-custom-role/`, "POST", {
        product_id: productId,
        role_name: customName,
      });
      if (response.ok) {
        setLoad(true);
        setRoleName(customName);
        setCustomName("");
        setCustomNameError(false);
        setTimeout(function () {
          setCustomRoleDetails(true);
        }, 150);
      }
    } else {
      setCustomNameError(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2 }}>
        <Mui.Grid xs={2}>
          <Mui.Typography>Products</Mui.Typography>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            value={productName}
            options={productsDetails?.map((obj) => ({
              ...obj,
              label: obj.product,
            }))}
            sx={{ width: "100%" }}
            filterOptions={(options, state) => {
              return options.filter((option) =>
                option.label
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.label}</li>;
            }}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            onChange={(event, value) => {
              if (value) {
                setProductName(value?.label);
                setRoles(value?.roles);
                setRoleName(value?.roles[0]?.role_name);
                setRoleId(value?.roles[0]?.role_id);
                setCustomRole(value?.roles[0]?.is_custom);
                setProductId(value?.product_id);
                getRolePermissions(value?.product_id, value?.roles[0]?.role_id);
                setExpandableRoleId(0);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                sx={{ background: themes.whiteColor }}
                placeholder="Select the product name"
                size="small"
                value={productName}
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid xs={0.2}></Mui.Grid>
        <Mui.Grid xs={2}>
          <Mui.Typography>Roles</Mui.Typography>
          <CustomAutoComplete
            dataList={roles.map((obj) => ({
              ...obj,
              label: obj.role_name,
            }))}
            selectedValue={roleName}
            updateDataFunction={handleRoles}
            placeHolder="Select Role"
          />
        </Mui.Grid>
        <Mui.Grid xs={0.2}></Mui.Grid>
        <Mui.Grid xs={2}>
          {roleName === "Custom" ? (
            <>
              <Mui.Typography>Custom Name</Mui.Typography>
              <CustomTextBox
                selectedValue={customName}
                updateDataFunction={handleCustomName}
                placeHolder="Enter custom role name"
                errorMessage={
                  customNameError ? "Minimum three characters required" : ""
                }
                textBoxError={customNameError}
              />
            </>
          ) : null}
        </Mui.Grid>
        <Mui.Grid xs={0.2}></Mui.Grid>
        <Mui.Grid xs={1}>
          {roleName === "Custom" ? (
            <Mui.Grid container sx={{ paddingTop: 3 }}>
              <CustomButton
                actionFuntion={() => createNewCustomRole()}
                actionName="Create"
                typeName="button"
              />
            </Mui.Grid>
          ) : null}
        </Mui.Grid>
        <Mui.Grid xs={4.6}></Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ padding: 2 }}>
        <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
          Permissions
        </Mui.Typography>
      </Mui.Grid>
      <Mui.Paper
        sx={{
          boxShadow: 3,
          minHeight: { xs: 150, sm: 300, md: 400, lg: 700, xl: 750 },
          maxHeight: { xs: 150, sm: 300, md: 400, lg: 700, xl: 750 },
          overflow: "hidden",
        }}
      >
        <Mui.Grid
          container
          sx={{
            padding: 4,
            height: "100%", // Ensure the Grid fills the Paper
            maxHeight: 750, // Matches Paper's maxHeight
            overflowY: "auto", // Enables vertical scrolling for overflowing content
            scrollbarWidth: "thin", // Thin scrollbar for Firefox
            "&::-webkit-scrollbar": {
              width: "8px", // Chrome, Safari, Edge scrollbar width
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888", // Color of the scrollbar thumb
              borderRadius: "4px", // Rounded scrollbar thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555", // Hover color for scrollbar thumb
            },
          }}
        >
          {permissionDetails?.map((permission) => (
            <Mui.Grid
              container
              sx={{
                paddingTop: 1,
              }}
            >
              <Mui.Typography
                sx={{
                  color: themes.headLine,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                <Mui.IconButton
                  size="medium"
                  sx={{ color: "black" }}
                  onClick={() => {
                    if (expandableRoleId === permission?.group_tag) {
                      setExpandableRoleId(0);
                    } else {
                      setExpandableRoleId(permission?.group_tag);
                    }
                  }}
                >
                  <AddCircle />
                </Mui.IconButton>
                {permission.group_tag}
              </Mui.Typography>
              <Mui.Grid container sx={{ backgroundColor: themes.primary }}>
                {expandableRoleId === permission?.group_tag || customRole
                  ? permission?.product_permissions?.map((per) => (
                      <Mui.Grid container sx={{ paddingLeft: 3 }}>
                        <Mui.Grid xs={0.5}>
                          <Mui.Checkbox
                            color="success"
                            checked={per.check}
                            onChange={() => handleCheckboxChange(per)}
                          />
                        </Mui.Grid>
                        <Mui.Grid xs={11.5}>
                          <Mui.Typography
                            sx={{
                              paddingTop: 1,
                              fontSize: "20",
                              fontWeight: "bold",
                            }}
                          >
                            {per.permission}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                    ))
                  : null}
              </Mui.Grid>
            </Mui.Grid>
          ))}
        </Mui.Grid>
      </Mui.Paper>
    </React.Fragment>
  );
};
