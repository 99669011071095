import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { Close, Info, AddCircleTwoTone } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { onlyNumberRegex } from "services/constants/patterns";
import { tableRowSx } from "services/constants";
let rowId = 1;
export const EditEarning = ({
  earningDetailsData,
  setEarningDetailsData,
  companyId,
  emptyEarning,
  setEmptyEarning,
  monthlySalary,
  setEarningDetailsUpdate,
  userDeductionDetails,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [emptyRow, setEmptyRow] = useState(0);
  const [updateEarning, setUpdateEarning] = useState(true);
  const [error, setError] = useState("");
  const { result: salaryComponent } = useFetch(
    companyId !== 0 ? `${domain}earnings/?company=${companyId}` : null
  );
  const [remainingEarning, setRemainingEarning] = useState([]);

  useEffect(() => {});

  useEffect(() => {
    const updateRemainingEarnings = () => {
      removeSelectedEarning(earningDetailsData);
      // setRemainingEarning(salaryComponent);
      setUpdateEarning(false);
    };
    if (
      updateEarning &&
      remainingEarning.length === 0 &&
      salaryComponent.length !== 0
    ) {
      updateRemainingEarnings();
    }
  });

  const handleSalaryComponent = (data, id) => {
    if (data) {
      let updatedData = earningDetailsData?.map((element) => {
        if (element?.rowId === id) {
          return {
            ...element,
            ...data,
            earning_id: data?.id,
            name: data?.name,
          };
        } else {
          return element;
        }
      });
      setEarningDetailsData(updatedData);
      removeSelectedEarning(updatedData);
    }
  };

  const updateAmount = (item, value) => {
    if (onlyNumberRegex.test(value) && item?.name) {
      let updateSalaryComponent = [];
      if (value === "") {
        updateSalaryComponent = earningDetailsData?.map((element) => {
          if (element?.earning_id === item?.earning_id) {
            return {
              ...element,
              amount: value,
            };
          } else {
            return element;
          }
        });
        setEarningDetailsData(updateSalaryComponent);
      } else {
        updateSalaryComponent = earningDetailsData?.map((element) => {
          if (element?.earning_id === item?.earning_id) {
            return {
              ...element,
              amount: value,
              earning_id: element?.earning_id,
            };
          } else {
            return element;
          }
        });
        calculateSpecialAllowance(updateSalaryComponent);
      }
      setEmptyRow(emptyRow === item?.earning_id ? 0 : emptyRow);
    } else if (!item?.name) {
      setEmptyRow(item?.earning_id);
    }
  };

  const calculateSpecialAllowance = (updateSalaryComponent) => {
    const otherThenSpecialAllowance = updateSalaryComponent.filter((data) => {
      return data.name !== "Special Allowance";
    });
    let TotalAmount = 0;
    otherThenSpecialAllowance.forEach((element) => {
      TotalAmount =
        parseInt(element.amount === "" ? 0 : element.amount) + TotalAmount;
    });
    let updatedSpecialAllowance = updateSalaryComponent?.map((element) => {
      if (element.name === "Special Allowance") {
        //  setSpecialAllowance(parseInt(monthlySalary) - TotalAmount);
        return {
          ...element,
          amount: parseInt(monthlySalary) - TotalAmount,
        };
      } else {
        return element;
      }
    });
    setEarningDetailsData(updatedSpecialAllowance);
    if (userDeductionDetails.length > 0) {
      setEarningDetailsUpdate(true);
    }
  };

  const removeSelectedEarning = (updatedData) => {
    let overAllEarnings = salaryComponent;
    updatedData.forEach((earn) => {
      overAllEarnings = overAllEarnings.filter((data) => {
        return data.name !== earn.name;
      });
    });
    setRemainingEarning(overAllEarnings);
  };

  const addNewRow = () => {
    setError("");
    setEmptyEarning(false);
    const hasZeroAmount = earningDetailsData.some(
      (item) => item.amount === "0" || item.amount === 0
    );

    if (hasZeroAmount) {
      setError("Enter value");
      return;
    }
    const newRow = {
      rowId: earningDetailsData?.length + 1,
      amount: "0",
      name: "",
      earning_id: 0,
    };
    rowId = rowId + 1;
    setEarningDetailsData([...earningDetailsData, newRow]);
  };

  const removeSalaryComponent = (item) => {
    let remaingData = earningDetailsData?.filter((data) => {
      return data?.earning_id !== item?.earning_id;
    });
    setEarningDetailsData(remaingData);
    removeSelectedEarning(remaingData);
    calculateSpecialAllowance(remaingData);
    if (userDeductionDetails.length > 0) {
      setEarningDetailsUpdate(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2 }}>
        <Mui.Typography sx={{ fontSize: 17 }}>
          Earnings&nbsp;
          <Mui.Tooltip title="Additional allowances can be created in the salary components screen.">
            <Info />
          </Mui.Tooltip>
        </Mui.Typography>{" "}
      </Mui.Grid>
      <Mui.TableContainer
        sx={{
          mt: 2,
          width: "100%",
          border: `2px solid ${themes.sidebarDivider}`,
        }}
      >
        <Mui.Table sx={{ border: `1.5 px solid ${themes.sidebarDivider}` }}>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
              <Mui.TableCell
                sx={{
                  color: themes.headLine,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Component
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.headLine,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Amount
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.headLine,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Action
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {earningDetailsData?.map((data, index) => (
              <Mui.TableRow key={data?.earning_id} sx={tableRowSx}>
                <>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={data?.name}
                      options={remainingEarning?.map((obj) => ({
                        ...obj,
                        label: obj?.name,
                      }))}
                      sx={{ width: "100%" }}
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option.label}</li>;
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                        },
                      }}
                      onChange={(event, value) => {
                        if (value) {
                          handleSalaryComponent(value, data?.rowId);
                        }
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          sx={{ background: themes.whiteColor }}
                          placeholder="Select the salary component"
                          size="small"
                          value={data?.name}
                        />
                      )}
                    />
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Stack direaction="row">
                      <Mui.TextField
                        size="small"
                        value={data?.amount}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (/^\d{0,8}$/.test(inputValue)) {
                            updateAmount(data, inputValue);
                          }
                        }}
                        disabled={
                          data.name === "Special Allowance" ? true : false
                        }
                        fullWidth
                      />
                      {data.amount < 0 && data.name === "Special Allowance" ? (
                        <span style={{ color: themes.txtErrorColor }}>
                          Special Allowance should be greater then 0
                        </span>
                      ) : null}
                      {["0", "", 0].includes(data.amount) &&
                      (emptyEarning || error === "Enter value") ? (
                        <span style={{ color: themes.txtErrorColor }}>
                          Enter value
                        </span>
                      ) : null}
                    </Mui.Stack>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    {data?.name === "Basic" ? null : (
                      <Mui.IconButton>
                        <Close onClick={() => removeSalaryComponent(data)} />
                      </Mui.IconButton>
                    )}
                  </Mui.TableCell>
                </>
              </Mui.TableRow>
            ))}
            <Mui.TableRow>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Mui.IconButton
                  size="small"
                  onClick={() => {
                    addNewRow();
                  }}
                  disabled={remainingEarning?.length === 0 ? true : false}
                >
                  <Mui.Tooltip title="Add Earning">
                    <AddCircleTwoTone size="large" />
                  </Mui.Tooltip>
                </Mui.IconButton>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
    </React.Fragment>
  );
};
