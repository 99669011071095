import React, { useState, useEffect } from "react";
import { SettingsContextProvider } from "./SettingsContextProvider";
import { LeaveGroup } from "./Screens/LeaveGroup";
import { LeavePeriod } from "./Screens/LeavePeriod";
import { LeaveType } from "./Screens/LeaveType";
import { Holidays } from "./Screens/Holidays";
import { SettingsMenu } from "./SettingsMenu";
import { Grid, Paper } from "@mui/material";
import { SiteSettings } from "./Screens/SiteSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";

export const TimeOffSettings = () => {
  const { teamZoneSettingScreen } = useSelector(
    (state) => state.teamZoneReducer
  );
  const [currentScreen, setCurrentScreen] = useState(teamZoneSettingScreen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  return (
    <React.Fragment>
      <SettingsContextProvider>
        <Grid container>
          <Grid
            xs={4}
            sm={3}
            md={2}
            lg={2}
            xl={1.5}
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <SettingsMenu
              setCurrentScreen={setCurrentScreen}
              currentScreen={currentScreen}
            />
          </Grid>
          <Grid xs={8} sm={9} md={10} lg={10} xl={10.5} sx={{ padding: 1 }}>
            <Paper
              sx={{
                boxShadow: 3,
                height: "93vh",
              }}
            >
              {currentScreen === "Leave Period" ? <LeavePeriod /> : null}
              {currentScreen === "Leave Type" ? <LeaveType /> : null}
              {currentScreen === "Leave Group" ? <LeaveGroup /> : null}
              {currentScreen === "Site Settings" ? <SiteSettings /> : null}
              {currentScreen === "Holidays" ? <Holidays /> : null}
            </Paper>
          </Grid>
        </Grid>
      </SettingsContextProvider>
    </React.Fragment>
  );
};
