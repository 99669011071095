import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes, tableContainerSx } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
// import { AddBank } from "./AddBank";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { Loader } from "components/Loader";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
// import { EditBank } from "./EditBank";
import { IncentiveHeader } from "pages/PayRoll/PayRollHeaders";
import { AddIncentive } from "./AddIncentive";
import { EditIncentive } from "./EditIncentive";
const keys = [
  "amount",
  "criteria_type",
  "criteria_value",
  "cycle",
  "effective_from",
  "incentive_mode",
  "incentive_type",
  "previous_effective_from",
  "select_type",
];

export const Incentive = ({
  companyId,
  currentScreen,
  companyChange,
  setCompanyChange,
  editIncentive,
  setEditIncentive,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addIncentive, setAddNewIncentive] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIncentiveDetails, setDeleteIncentiveDetails] = useState({});
  const [incentiveDetailsStateData, setIncentiveStateData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const {
    result: IncentiveData,
    setLoad: reCallApi,
    loading,
  } = useFetch(companyId !== 0 ? `${domain}incentives/` : null);

  useEffect(() => {
    if (companyChange && currentScreen === "Incentive") {
      reCallApi(true);
      setCompanyChange(false);
    }
    setEditIncentive(false);
    setAddNewIncentive(false);
  }, [
    companyChange,
    currentScreen,
    reCallApi,
    setCompanyChange,
    setAddNewIncentive,
    setEditIncentive,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const updateDeleteIncentive = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(
        `${domain}incentives/${deleteIncentiveDetails.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            isdeleted: true,
            incentive_type: deleteIncentiveDetails.incentive_type,
            cycle: deleteIncentiveDetails.cycle,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Deleted successfully");
        reCallApi(true);
        setOpenDialog(false);
        setButtonDisabled(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (updateData && IncentiveData?.length !== 0 && IncentiveData) {
      setIncentiveStateData(IncentiveData);
      setUpdateData(false);
    }
  }, [IncentiveData, updateData]);

  useEffect(() => {
    if (IncentiveData?.length !== 0 || IncentiveData?.length === 0) {
      const filteredResults = IncentiveData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setIncentiveStateData(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [IncentiveData, globalSearchValue, page]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid xs={9}>
          {addIncentive || editIncentive ? (
            <Mui.Stack direction="row">
              <BackArrow
                actionFunction={() => {
                  setEditIncentive(false);
                  setAddNewIncentive(false);
                  dispatch(setGlobalSearchValue(""));
                }}
              />
              <Mui.Typography
                sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
              >
                {editIncentive ? "Edit Incentive" : "Add Incentive"}
              </Mui.Typography>
            </Mui.Stack>
          ) : null}
        </Mui.Grid>
        {addIncentive || editIncentive ? null : (
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={10}></Mui.Grid>
            <Mui.Grid
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => setAddNewIncentive(true)}
                actionName="Add Incentive"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
      {addIncentive ? (
        <AddIncentive
          companyId={companyId}
          setAddNewIncentive={setAddNewIncentive}
          reCallApi={reCallApi}
        />
      ) : loading ? (
        <Loader />
      ) : editIncentive ? (
        <EditIncentive
          incentiveDetails={deleteIncentiveDetails}
          companyId={companyId}
          setEditIncentive={setEditIncentive}
          reCallApi={reCallApi}
        />
      ) : incentiveDetailsStateData?.length === 0 ? (
        <Mui.Alert severity="info" sx={{ width: "98%", margin: 2 }}>
          No data found
        </Mui.Alert>
      ) : (
        <React.Fragment>
          <Mui.TableContainer sx={tableContainerSx}>
            <Mui.Table sx={{ border: `3px solid ${themes.sidebarDivider}` }}>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "6px 30px",
                    },
                  }}
                >
                  {IncentiveHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {incentiveDetailsStateData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((account, index) => (
                    <React.Fragment>
                      <Mui.TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "6px 35px",
                          },
                        }}
                      >
                        {IncentiveHeader.map((head) =>
                          head.name === "Actions" ? (
                            <Mui.TableCell>
                              <Mui.Stack direction="row">
                                <Mui.IconButton
                                  onClick={() => {
                                    setDeleteIncentiveDetails(account);
                                    setEditIncentive(true);
                                    dispatch(setGlobalSearchValue(""));
                                  }}
                                >
                                  <Edit sx={{ fontSize: "10" }} />
                                </Mui.IconButton>
                                <Mui.IconButton
                                  onClick={() => {
                                    setDeleteIncentiveDetails(account);
                                    setOpenDialog(true);
                                  }}
                                >
                                  <Delete />
                                </Mui.IconButton>
                              </Mui.Stack>
                            </Mui.TableCell>
                          ) : (
                            <Mui.TableCell
                            // sx={{
                            //   color: themes.headLine,
                            //   fontWeight: "bold",
                            // }}
                            >
                              {account[head.key] ? account[head.key] : "N/A"}
                            </Mui.TableCell>
                          )
                        )}
                        {/* <Mui.TableCell>{account.bank_name}</Mui.TableCell>
                        <Mui.TableCell>{account.nick_name}</Mui.TableCell>
                        <Mui.TableCell>
                          {account.bank_account_number}
                        </Mui.TableCell>
                        <Mui.TableCell>{account.bank_branch}</Mui.TableCell>
                        <Mui.TableCell>{account.bank_ifsc_code}</Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={() => {
                              setDeleteIncentiveDetails(account);
                              setEditIncentive(true);
                              dispatch(setGlobalSearchValue(""));
                            }}
                          >
                            <Edit />
                          </Mui.IconButton>
                          <Mui.IconButton
                            onClick={() => {
                              setDeleteIncentiveDetails(account);
                              setOpenDialog(true);
                            }}
                          >
                            <Delete />
                          </Mui.IconButton>
                        </Mui.TableCell> */}
                      </Mui.TableRow>
                    </React.Fragment>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {incentiveDetailsStateData?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[10, 20, 50, 100]}
                count={incentiveDetailsStateData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete Incentive"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
          Are you sure ,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {deleteIncentiveDetails.name}
          </span>{" "}
          incentive?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => updateDeleteIncentive()}
              actionName="Yes"
              typeName="button"
              disableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
