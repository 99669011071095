import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { Box, Card } from "@mui/material";
import { Loader } from "components/Loader";
import moment from "moment";

const Monthly = ({ loading, monthlyShiftList, startDate }) => {
  const renderEventContent = (eventInfo) => {
    return (
      <>
        <Mui.Box className="custom-event" sx={{ textAlign: "center" }}>
          {eventInfo.event.title === null ||
          eventInfo.event.title === "Weekoff" ? (
            ""
          ) : eventInfo.event.extendedProps?.shift_start_time &&
            eventInfo.event.extendedProps?.shift_end_time ? (
            <>
              <Mui.Typography color={themes.headLine} sx={{ fontSize: 12 }}>
                {moment(
                  eventInfo.event.extendedProps?.shift_start_time,
                  "HH:mm:SS"
                ).format("h:mm a")}
              </Mui.Typography>
              <Mui.Typography color={themes.headLine} sx={{ fontSize: 12 }}>
                {moment(
                  eventInfo.event.extendedProps?.shift_end_time,
                  "HH:mm:SS"
                ).format("h:mm a")}
              </Mui.Typography>
            </>
          ) : null}
          <Mui.Typography
            variant="body2"
            sx={{
              backgroundColor: "#ffe6e6", // Need to improve after backend update this
              color: themes.headLine,
              padding: "5px 10px",
              borderRadius: "5px",
              fontSize: "14px",
              "@media (max-width: 768px)": {
                fontSize: "10px",
                padding: "3px 8px",
              },
              "@media (max-width: 480px)": {
                fontSize: "10px",
                padding: "2px 5px",
              },
            }}
          >
            {eventInfo.event.title}
          </Mui.Typography>
        </Mui.Box>
      </>
    );
  };

  const handleDayCellDidMount = React.useCallback((info) => {
    const day = info.date.getDay();
    const defaultColor = day === 0 ? themes.whiteColor : "";

    info.el.style.backgroundColor = defaultColor;

    const handleMouseEnter = () =>
      (info.el.style.backgroundColor = themes.primary);
    const handleMouseLeave = () =>
      (info.el.style.backgroundColor = defaultColor);

    info.el.addEventListener("mouseenter", handleMouseEnter);
    info.el.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      info.el.removeEventListener("mouseenter", handleMouseEnter);
      info.el.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  const calendarProps = {
    themeSystem: "bootstrap5",
    height: "85vh",
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    initialDate: startDate ? new Date(startDate) : new Date(),
    key: startDate
      ? new Date(startDate).toISOString()
      : new Date().toISOString(),
    headerToolbar: {
      left: null,
      center: null,
      right: null,
    },
    initialView: "dayGridMonth",
    dayMaxEvents: true,
    events: monthlyShiftList,
    dayCellDidMount: handleDayCellDidMount,
  };

  return loading ? (
    <Box>
      <Loader info="Loading..." />
    </Box>
  ) : (
    <Mui.Grid sx={{ paddingTop: 2.5, paddingRight: 1 }}>
      <Card sx={{ boxShadow: 5 }}>
        <FullCalendar
          {...calendarProps}
          eventContent={renderEventContent}
          eventClassNames="custom-event-container"
          moreLinkClick={(info) => info.jsEvent.preventDefault()}
        />
      </Card>
    </Mui.Grid>
  );
};

export default Monthly;
