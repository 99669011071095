import { themes } from ".";

export const formKeyText = {
  fontSize: { xs: 15, sm: 15, md: 17, lg: 18, xl: 18 },
  fontWeight: "bold",
  color: themes.headLine,
  textAlign: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "right",
    xl: "right",
  },
  paddingRight: 4,
  paddingTop: 1.5,
};
export const formKeyTextRight = {
  fontSize: { xs: 15, sm: 15, md: 17, lg: 18, xl: 18 },
  fontWeight: "bold",
  color: themes.headLine,
  textAlign: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "left",
    xl: "left",
  },
  paddingRight: 4,
  paddingTop: 1.5,
};

export const formRightSideAlign = {
  display: "flex",
  justifyContent: {
    xs: "center",
    sm: "center",
    md: "center",
    lg: "left",
    xl: "left",
  },
  alignItems: "center",
};

export const formHeader = {
  fontSize: { xs: 17, sm: 17, md: 20, lg: 24, xl: 24 },
  fontWeight: "bold",
  color: themes.headLine,
};

export const formBackGroundColor = "#F2F2F7";

export const tableContainerSx = {
  padding: 1,
  width: "100%",
  maxHeight: { xs: 150, sm: 300, md: 400, lg: 700, xl: 750 },
};

export const tableContainerSxWithCompany = {
  padding: 1,
  width: "100%",
  maxHeight: { xs: 150, sm: 300, md: 400, lg: 650, xl: 700 },
};

export const tableHeaderSx = {
  backgroundColor: themes.primary,
  position: "sticky",
  top: -1,
  zIndex: 10,
};

export const tableRowSxWithIcons = {
  "& .MuiTableCell-root": {
    height: "10px",
    padding: "3px 15px",
  },
};

export const tableRowSxWithOutIcons = {
  "& .MuiTableCell-root": {
    height: "10px",
    padding: "3px 15px",
  },
};

export const tableCellFontSize = { xs: 6, sm: 8, md: 10, lg: 12, xl: 15 };

export const scrollTop = (tableContainerRef) => {
  if (tableContainerRef.current) {
    tableContainerRef.current.scrollTop = 0;
  }
};
