import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { useForm } from "react-hook-form";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { AddBoxOutlined, Delete, Info } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setProductAccess } from "services/Redux/userToken";
import { customGetAPI } from "services/utils/CustomAPI";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { BackArrow } from "components/CustomComponents/BackArrow";
import sessionHandling from "services/utils/notificationUtils";
export const AddCompany = ({
  screenName,
  editEompanyDetails: editCompanyDetails,
  setAddCompanyScreen,
  updateData,
  setUpdateData,
  companyDetails,
  setGetCompanyDetails,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
  } = useForm({
    mode: "onBlur",
  });
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [yesCheck, setYesCheck] = useState(false);
  const [noCheck, setNoCheck] = useState(true);
  const [logoFile, setLogoFile] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    if (updateData) {
      setValue("companyname", editCompanyDetails.companyname);
      setValue("companycode", editCompanyDetails.companycode);
      setValue("gstno", editCompanyDetails.gstno);
      setValue("address", editCompanyDetails.address);
      setValue("email", editCompanyDetails.email);
      setValue("phoneno", editCompanyDetails.phonenumber);
      setUpdateData(false);
      if (editCompanyDetails.gstno !== "" && editCompanyDetails.gstno) {
        setYesCheck(true);
        setNoCheck(false);
      }
    }
  }, [
    editCompanyDetails.address,
    editCompanyDetails.companycode,
    editCompanyDetails.companyname,
    editCompanyDetails.email,
    editCompanyDetails.endDate,
    editCompanyDetails.gstno,
    editCompanyDetails.phonenumber,
    setUpdateData,
    setValue,
    updateData,
  ]);

  const getProductAccess = useCallback(
    async (userId) => {
      const res = await customGetAPI(`${domain}products/?user_id=${userId}`);
      if (res.ok) {
        dispatch(setProductAccess(res.responseDetails));
        localStorage.setItem(
          "productAccess",
          JSON.stringify(res.responseDetails)
        );
      }
    },
    [dispatch, domain]
  );

  const onSubmit = async (data) => {
    setButtonLoader(true);
    setTimeout(() => null, 1000);
    if (data) {
      const apiActionMethod =
        screenName === "Edit Company Details" ? "PUT" : "POST";
      const apiUrl =
        screenName === "Edit Company Details"
          ? `${domain}company/${editCompanyDetails?.id}/`
          : `${domain}company/`;
      try {
        const myHeaders = new Headers();
        const formData = new FormData();
        myHeaders.append("Authorization", `token ${token}`);
        if (logoFile) {
          formData.append("company_logo", logoFile, "[PROXY]");
        }
        formData.append("companyname", data.companyname);
        formData.append("company_code", data.companycode);
        formData.append("address", data.address);
        formData.append("gstnumber", data.gstno);
        formData.append("email", data.email);
        formData.append("isactive", true);
        formData.append("phonenumber", data.phoneno);
        formData.append("isprimary", editCompanyDetails.primary);
        const response = await fetch(apiUrl, {
          method: apiActionMethod,
          headers: myHeaders,
          body: formData,
          redirect: "follow",
        });
        const res = await response.json();
        if (response.ok) {
          if (companyDetails.length === 0) {
            showToast("success", "Company created successfully");
            await getProductAccess(userDetails.id);
            reset();
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/user");
          } else {
            showToast(
              "success",
              screenName === "Edit Company Details"
                ? "Company updated successfully"
                : "Company created successfully"
            );
            reset();
            setGetCompanyDetails(true);
            setAddCompanyScreen(false);
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const setYesCheckUpdated = () => {
    if (yesCheck) {
      setNoCheck(true);
      setYesCheck(false);
      setValue("gstno", "");
    } else {
      setYesCheck(true);
      setNoCheck(false);
    }
  };

  const setNoCheckUpdated = () => {
    if (noCheck) {
      setNoCheck(false);
      setYesCheck(true);
    } else {
      setNoCheck(true);
      setYesCheck(false);
      setValue("gstno", "");
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = /^[A-Za-z\s 0-9]$/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };

  const checkSpecialChar = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const removeCompany = async () => {
    try {
      const myHeaders = new Headers();
      const formData = new FormData();
      myHeaders.append("Authorization", `token ${token}`);
      formData.append("isdeleted", true);
      const response = await fetch(
        `${domain}company/${editCompanyDetails?.id}/`,
        {
          method: "PUT",
          headers: myHeaders,
          body: formData,
          redirect: "follow",
        }
      );
      const res = await response.json();
      if (response.ok) {
        setGetCompanyDetails(true);
        setAddCompanyScreen(false);
        showToast("success", "Company updated successfully");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const validateGST = (gstNo) => {
    // Regular expression for validating GST Number
    const regex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return regex.test(gstNo);
  };

  const checkExistingCompanyName = useCallback(
    async (companyName) => {
      setButtonLoader(true);
      try {
        const response = await fetch(
          `${domain}check_company_name/?company_name=${companyName}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            return "That company name is already taken. Please choose a unique name.";
          } else {
            return true;
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    },
    [domain, token]
  );
  const checkExistingCompanyCode = useCallback(
    async (companyCode) => {
      setButtonLoader(true);
      try {
        const response = await fetch(
          `${domain}check_company_name/?company_code=${companyCode}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            return "That company code is already taken.Please choose a unique code.";
          } else {
            return true;
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    },
    [domain, token]
  );

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid xs={12}>
          <Mui.Grid container>
            {companyDetails.length !== 0 ? (
              <BackArrow actionFunction={() => setAddCompanyScreen(false)} />
            ) : null}
            &nbsp;
            <Mui.Typography
              sx={{
                fontSize: 30,
                fontWeight: "bold",
                color: themes.headLine,
                paddingLeft: companyDetails.length !== 0 ? 1 : 0,
              }}
            >
              {screenName}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        {companyDetails.length === 0 ? (
          <Mui.Grid xs={12}>
            <Mui.Typography>
              Atleast one company is required,Fill in the form to create the
              first company
            </Mui.Typography>
          </Mui.Grid>
        ) : null}
        <br />
        <Mui.Grid
          container
          sx={{
            paddingTop: "2%",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 800,
                  font: "bold",
                  textAlign: "center",
                }}
              >
                {screenName === "Edit Company Details"
                  ? "Edit Company"
                  : "Create Company"}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
              borderRadius: "0 0 10px 10px",
            }}
          >
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Company Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  {...register("companyname", {
                    required: "Company name is required",
                    maxLength: {
                      value: 32,
                      message: "Maximum length is 32 characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Minimum length is 3 characters",
                    },
                    validate: {
                      companyName: async (fieldValue) => {
                        if (!/[A-Za-z]/.test(fieldValue)) {
                          return "Company name must contain at least one alphabet character.";
                        } else if (/^\d+$/.test(fieldValue)) {
                          return "Company name should not contain digits.";
                        } else if (
                          fieldValue !== "" &&
                          screenName === "Create Company"
                        ) {
                          return await checkExistingCompanyName(
                            fieldValue.trim()
                          );
                        } else if (
                          fieldValue !== "" &&
                          fieldValue !== editCompanyDetails.companyname &&
                          screenName === "Edit Company Details"
                        ) {
                          return await checkExistingCompanyName(
                            fieldValue.trim()
                          );
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  error={errors.companyname ? true : false}
                  helperText={errors.companyname?.message}
                  sx={{ background: themes.whiteColor }}
                  onKeyPress={handleKeyDown}
                  name="companyname"
                  placeholder="Enter company name"
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Stack direction="row">
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Company Code
                  </Mui.Typography>{" "}
                  &nbsp;
                  <Mui.Tooltip title="A unique 3-character alphanumeric code to identify the company.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  {...register("companycode", {
                    required: "Company code is required",
                    minLength: {
                      value: 3,
                      message: "At least 3 characters required",
                    },
                    validate: {
                      companyCode: async (fieldValue) => {
                        // Clean value before validating
                        const cleanedValue = fieldValue.replace(
                          /[a-zA-Z^-]/g,
                          ""
                        );
                        setValue("companycode", cleanedValue);

                        if (
                          cleanedValue !== "" &&
                          screenName === "Create Company"
                        ) {
                          return await checkExistingCompanyCode(
                            cleanedValue.trim()
                          );
                        } else if (
                          cleanedValue !== "" &&
                          cleanedValue !== editCompanyDetails.companycode &&
                          screenName === "Edit Company Details"
                        ) {
                          return await checkExistingCompanyCode(
                            cleanedValue.trim()
                          );
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  error={errors.companycode ? true : false}
                  helperText={errors.companycode?.message}
                  sx={{ background: themes.whiteColor }}
                  name="companycode"
                  placeholder="Enter company code"
                  onKeyPress={checkSpecialChar}
                  InputProps={{
                    inputProps: { maxLength: 3 },
                  }}
                  fullWidth
                  size="small"
                  onBlur={async () => {
                    await trigger("companycode");
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Is GST Registered
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Stack direction="Row">
                  <Mui.Grid sx={{ marginTop: -1 }}>
                    <Mui.Checkbox
                      checked={yesCheck}
                      onChange={() => setYesCheckUpdated()}
                    />
                  </Mui.Grid>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Yes
                  </Mui.Typography>
                  <Mui.Grid sx={{ marginTop: -1 }}>
                    <Mui.Checkbox
                      checked={noCheck}
                      onChange={() => setNoCheckUpdated()}
                    />
                  </Mui.Grid>
                  <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    No
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: yesCheck ? 1 : 0 }}>
              <Mui.Grid xs={4}></Mui.Grid>
              <Mui.Grid xs={8}>
                {yesCheck ? (
                  <Mui.TextField
                    {...register("gstno", {
                      required: yesCheck ? "Gstno is required" : false,
                      validate: {
                        gstNoValidation: async (fieldValue) => {
                          if (fieldValue !== "" && !noCheck) {
                            const valid = validateGST(fieldValue);
                            return valid
                              ? true
                              : "GSTNO in format 22AAAAA0000A1Z5";
                          }
                        },
                      },
                    })}
                    error={errors.gstno ? true : false}
                    helperText={errors.gstno?.message}
                    sx={{ background: themes.whiteColor }}
                    name="gstno"
                    placeholder="GSTNO in format 00AAAAA0000A0A0"
                    onKeyPress={handleKeyDown}
                    disabled={yesCheck ? false : true}
                    InputProps={{
                      inputProps: { maxLength: 15 },
                    }}
                    size="small"
                    fullWidth
                  />
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Email
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  id="email"
                  variant="outlined"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid e-mail address",
                    },
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message || ""}
                  placeholder="Enter email"
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Phone Number
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  {...register("phoneno", {
                    required: "Phone number is required",
                    minLength: {
                      value: 10,
                      message: "Minimum length is 10 digits",
                    },
                    maxLength: {
                      value: 15,
                      message: "Maximum length is 15 digits",
                    },
                    validate: {
                      // Optionally, you can add further validation rules here if needed
                      formatCheck: (value) => {
                        const cleanedValue = value.replace(/[^\d-]/g, "");
                        setValue("phoneno", cleanedValue); // Update value without non-digit characters
                        return true; // Return true if there's no error
                      },
                    },
                  })}
                  error={errors.phoneno ? true : false}
                  helperText={errors.phoneno?.message}
                  sx={{ background: themes.whiteColor }}
                  name="phoneno"
                  onBlur={async () => {
                    await trigger("phoneno");
                  }}
                  placeholder="Enter phone number"
                  onKeyPress={handleKeyPress}
                  fullWidth
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 15 },
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Address
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  multiline
                  rows={2}
                  maxRows={5}
                  {...register("address", {
                    required: "Address is required",
                  })}
                  error={errors.address ? true : false}
                  helperText={errors.address?.message}
                  sx={{ background: themes.whiteColor }}
                  name="address"
                  placeholder="Enter address"
                  size="small"
                  fullWidth
                />
                {companyDetails.length === 0 ? (
                  <Mui.Stack sx={{ paddingTop: 2 }}>
                    <Mui.Typography>
                      <label style={{ color: themes.redColor, fontSize: 25 }}>
                        *
                      </label>
                      Additional companies can be created under admin page
                    </Mui.Typography>
                  </Mui.Stack>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            {editCompanyDetails.isactive ? (
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ paddingTop: 1 }}
                >
                  <Mui.Button
                    variant="outlined"
                    component="label"
                    sx={{
                      alignItems: "center",
                      textTransform: "capitalize",
                      border: `1px solid ${themes.borderShadowColor}`,
                    }}
                    size="small"
                    fullWidth
                  >
                    <Mui.Stack>
                      <AddBoxOutlined
                        sx={{
                          marginLeft: 3,
                          fontSize: 25,
                          color: themes.headLine,
                        }}
                      />
                      <Mui.Typography
                        fontSize={18}
                        sx={{ textTransform: "none", color: themes.headLine }}
                      >
                        Add Logo
                      </Mui.Typography>
                    </Mui.Stack>
                    <Mui.TextField
                      style={{
                        display: "none",
                        width: 300,
                        height: 300,
                        border: `1px solid ${themes.borderShadowColor}`,
                      }}
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        setLogoFile(e.target.files[0]);
                      }}
                      size="small"
                    />
                  </Mui.Button>
                </Mui.Grid>
              </Mui.Grid>
            ) : (
              <Mui.Box
                elevation={5}
                sx={{ border: "1px solid red", borderRadius: 4, padding: 1 }}
              >
                <Mui.Grid item sx={{ paddingLeft: 2 }}>
                  <Mui.Typography
                    sx={{ fontSize: 25, fontWeight: "bold", paddingBottom: 1 }}
                  >
                    Delete Company
                  </Mui.Typography>
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "normal",
                      paddingBottom: 1,
                    }}
                  >
                    Deleting the company will permanently remove all data and
                    revoke access for all associated users.
                  </Mui.Typography>
                  <Mui.Grid container>
                    <Mui.Grid item xs={5.5}></Mui.Grid>
                    <Mui.Grid item xs={1}>
                      <Mui.Button
                        sx={{
                          backgroundColor: themes.redColor,
                          "&:hover": {
                            backgroundColor: themes.redColor,
                          },
                          color: themes.whiteColor,
                          padding: 1,
                        }}
                        size="small"
                        variant="varient"
                        onClick={() => removeCompany()}
                      >
                        Delete&nbsp;&nbsp;
                        <Delete sx={{ color: themes.headLine }} />
                      </Mui.Button>
                    </Mui.Grid>
                    <Mui.Grid item xs={5.5}></Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Box>
            )}
            {editCompanyDetails.isactive ? (
              <Mui.Grid container sx={{ paddingTop: 2 }}>
                <Mui.Grid xs={12} sm={6} md={6} lg={11}></Mui.Grid>
                <Mui.Grid
                  xs={12}
                  sm={6}
                  md={6}
                  lg={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <CustomButton
                    key={buttonLoader}
                    actionName={
                      screenName === "Create Company" ? "Create" : "Update"
                    }
                    typeName="submit"
                    disableAction={buttonLoader}
                  />
                </Mui.Grid>
              </Mui.Grid>
            ) : null}
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
