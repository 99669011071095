import * as Mui from "@mui/material";
import React from "react";
import { themes } from "services/constants";
import "./loder.css";
import comLogo from "../../assets/img/brand/company_logo.png";
export const Loader = ({ info }) => {
  return (
    <React.Fragment>
      <Mui.Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
      >
        <Mui.Box
          sx={{
            position: "relative",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: 100, // Adjust the size as needed
            height: 100, // Adjust the size as needed
          }}
        >
          {/* The CircularProgress component */}
          <Mui.CircularProgress
            size={80}
            thickness={2}
            sx={{ color: themes.primaryIndicator }}
          />

          {/* The logo in the center */}
          <Mui.Box
            sx={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 50, // Adjust based on your logo size
              height: 50, // Adjust based on your logo size
            }}
          >
            <img
              src={comLogo} // Replace with your logo's URL or import path
              alt="Company Logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Mui.Box>
        </Mui.Box>
      </Mui.Grid>
      {/* <Mui.Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
      >
        <Mui.Grid xs={12} sm={10} md={8} lg={6} xl={4}>
          <div className="spin">
            <img
              src={comLogo}
              alt=""
              style={{ height: "55px", width: "55px" }}
            />
          </div>
        </Mui.Grid> */}
      {/* <Mui.Grid>
          <Mui.Typography
            style={{
              color: themes.primary,
              fontWeight: "bold",
              marginTop: 18,
              fontSize: 28,
            }}
          >
            {info}
          </Mui.Typography>
        </Mui.Grid> */}
      {/* </Mui.Grid> */}
    </React.Fragment>
  );
};
