import React, { useEffect, useState, useCallback } from "react";
import * as Mui from "@mui/material";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { themes } from "services/constants";
import { onlyNumberRegex } from "services/constants/patterns";
import { EditDeduction } from "./EditDeductions";
import { EditEarning } from "./EditEarnings";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
export const EditSalaryGroup = ({
  companyId,
  editSalaryeTemplateDetails,
  setCompanyFilter,
  setEditTemplate,
  companyFilter,
  reCallApi,
  setUpdateDeductionDetails,
  updateDeductionDetails,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [monthlySalary, setMonthlySalary] = useState(0);
  const [salaryType, setSalaryType] = useState(
    editSalaryeTemplateDetails?.salary_type_name
      ? editSalaryeTemplateDetails?.salary_type_name
      : ""
  );
  const [templateName, setTemplateName] = useState(
    editSalaryeTemplateDetails?.name ? editSalaryeTemplateDetails?.name : ""
  );
  const [earningDetailsData, setEarningDetailsData] = useState(
    editSalaryeTemplateDetails?.earnings
      ? editSalaryeTemplateDetails?.earnings
      : []
  );
  const [userDeductionDetails, setUserDeductionDetails] = useState(
    editSalaryeTemplateDetails?.deductions
      ? editSalaryeTemplateDetails?.deductions
      : []
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emptyEarning, setEmptyEarning] = useState(false);
  const [emptyDeduction, setEmptyDeduction] = useState(false);
  const [earningDetailsUpdate, setEarningDetailsUpdate] = useState(false);
  const [amount, setAmount] = useState(
    editSalaryeTemplateDetails?.amount ? editSalaryeTemplateDetails?.amount : ""
  );
  const [updateDefaultEarnings, setUpdateDefaultEarnings] = useState(false);
  // const { result: salaryTypeData, setLoad: recallSalaryApi } = useFetch(
  //   companyId !== 0 ? `${domain}salary-group/?company=${companyId}` : null
  // );
  const { result: salaryTypeData, setLoad: recallSalaryApi } = useFetch(
    companyId !== 0 ? `${domain}salarytype/` : null
  );
  useEffect(() => {
    if (companyFilter) {
      recallSalaryApi(true);
      setCompanyFilter(false);
    }
  }, [companyFilter, recallSalaryApi, setCompanyFilter]);

  useEffect(() => {
    setValue(
      "salary_template_name",
      editSalaryeTemplateDetails?.name ? editSalaryeTemplateDetails?.name : ""
    );
    setValue(
      "amount",
      editSalaryeTemplateDetails?.amount
        ? editSalaryeTemplateDetails?.amount
        : "0"
    );
    setValue(
      "salary_type_id",
      editSalaryeTemplateDetails?.salary_type
        ? editSalaryeTemplateDetails?.salary_type
        : 0
    );
    setValue(
      "salary_type_name",
      editSalaryeTemplateDetails?.salary_type_name
        ? editSalaryeTemplateDetails?.salary_type_name
        : 0
    );
    setUserDeductionDetails(
      editSalaryeTemplateDetails?.deductions
        ? editSalaryeTemplateDetails?.deductions
        : []
    );
    let salary = parseInt(editSalaryeTemplateDetails?.amount);
    setMonthlySalary(salary);
    // if (editSalaryeTemplateDetails?.salary_type_name === "Daily") {
    //   salary = parseInt(editSalaryeTemplateDetails?.amount) * 30;
    //   setMonthlySalary(salary);
    // } else if (editSalaryeTemplateDetails?.salary_type_name === "Weekly") {
    //   salary = parseInt(editSalaryeTemplateDetails?.amount) * 4;
    //   setMonthlySalary(parseInt(editSalaryeTemplateDetails?.amount) * 4);
    // } else if (editSalaryeTemplateDetails?.salary_type_name === "Hourly") {
    //   salary = parseInt(editSalaryeTemplateDetails?.amount) * 30 * 8;
    //   setMonthlySalary(salary);
    // } else {
    //   salary = parseInt(editSalaryeTemplateDetails?.amount);
    //   setMonthlySalary(parseInt(editSalaryeTemplateDetails?.amount));
    // }
  }, [
    editSalaryeTemplateDetails?.amount,
    editSalaryeTemplateDetails?.deductions,
    editSalaryeTemplateDetails?.name,
    editSalaryeTemplateDetails?.salary_type,
    editSalaryeTemplateDetails?.salary_type_name,
    setValue,
  ]);

  const editSalaryeTemplate = async (data) => {
    const zeroAmoutEarnings = earningDetailsData.filter((data) => {
      return (
        data?.amount === 0 ||
        data?.amount === "0" ||
        data.amount === "" ||
        data.amount < 0
      );
    });

    const zeroAmoutDeductions = userDeductionDetails.filter((data) => {
      return (
        data.deduction_type !== "F" &&
        data.employeeContributionAmount === 0 &&
        data.companyContributionAmount === 0
      );
    });
    setButtonDisabled(true);
    if (zeroAmoutEarnings.length === 0 && zeroAmoutDeductions.length === 0) {
      try {
        const response = await fetch(
          `${domain}salary-templates/${editSalaryeTemplateDetails.id}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              id: editSalaryeTemplateDetails.id,
              name: data.salary_template_name,
              salary_type: data.salary_type_id,
              salary_type_name: data.salary_type_name,
              amount: data.amount,
              earnings: earningDetailsData,
              deductions: userDeductionDetails.filter((data) => {
                return data.name !== "";
              }),
              company: companyId,
            }),
          }
        );
        const res = await response.json();
        if (response.ok) {
          setEditTemplate(false);
          reCallApi(true);
          reset();
          showToast("success", "Salary template updated successfully");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonDisabled(false);
      }
    } else {
      setEmptyEarning(true);
      setEmptyDeduction(true);
      setButtonDisabled(false);
    }
  };

  const updateSalaryGroupName = (event) => {
    if (event.target.value.length < 32) {
      setValue("salary_template_name", event.target.value);
      setTemplateName(event.target.value);
    }
  };

  const handleSalaryType = (event, data) => {
    if (data) {
      setSalaryType(data.label);
      setValue("salary_type_id", data.id);
      setValue("salary_type_name", data.label);
      setAmount(0);
      setValue("amount", "0");
      if (earningDetailsData.length !== 0) {
        let updateData = earningDetailsData?.map((element) => {
          return {
            ...element,
            amount: element.name === "Special Allowance" ? 0 : 0,
          };
        });
        setEarningDetailsData(updateData);
        setUserDeductionDetails([]);
      }
    }
  };

  const updateAmount = (event) => {
    if (onlyNumberRegex.test(event.target.value)) {
      let salary = parseInt(event.target.value);
      setAmount(event.target.value);
      setValue("amount", event.target.value);
      setMonthlySalary(salary);
      // if (salaryType === "Daily") {
      //   salary = parseInt(event.target.value) * 30;
      //   setMonthlySalary(salary);
      // } else if (salaryType === "Weekly") {
      //   salary = parseInt(event.target.value) * 4;
      //   setMonthlySalary(parseInt(event.target.value) * 4);
      // } else if (salaryType === "Hourly") {
      //   salary = parseInt(event.target.value) * 30 * 8;
      //   setMonthlySalary(salary);
      // } else {
      //   salary = parseInt(event.target.value);
      //   setMonthlySalary(parseInt(event.target.value));
      // }
      if (earningDetailsData.length !== 0) {
        let updateData = earningDetailsData?.map((element) => {
          return {
            ...element,
            amount:
              element.name === "Special Allowance" ? (salary ? salary : 0) : 0,
          };
        });
        setEarningDetailsData(updateData);
        setUserDeductionDetails([]);
      }
    }
  };

  // const updateAmount = (event) => {
  //   if (
  //     event.target.value.length <= 8 &&
  //     onlyNumberRegex.test(event.target.value)
  //   ) {
  //     setAmount(event.target.value);
  //     setValue("amount", event.target.value);
  //     setEarningDetailsData([]);
  //     setUserDeductionDetails([]);
  //   }
  // };

  const checkSalaryTemplateName = useCallback(
    async (existsName) => {
      try {
        const response = await fetch(
          `${domain}check_salary_template_name/?salary_template_name=${existsName}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            return "That salary template name is already taken.Please choose a unique name.";
          } else if (
            atleastOneCharacterWithSpace.test(existsName) ||
            existsName === ""
          ) {
            return true;
          } else {
            return "Atleast one alphabet required";
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  return (
    <form onSubmit={handleSubmit(editSalaryeTemplate)}>
      <React.Fragment>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <Mui.Grid
            container
            component={Mui.Paper}
            elevation={2}
            sx={{
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: `2px solid ${themes.sidebarDivider}`,
            }}
          >
            <Mui.Grid
              xs={12}
              sx={{
                backgroundColor: themes.primary,
                padding: 1,
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
                borderBottom: `2px solid ${themes.sidebarDivider}`,
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 20, fontWeight: "bold", paddingLeft: 3.5 }}
              >
                Edit Salary Template
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid
              container
              sx={{
                padding: 2,
                paddingLeft: 5,
              }}
            >
              <Mui.Grid item xs={2}>
                <Mui.Typography
                  sx={{
                    fontSize: 17,
                    fontWeight: 700,
                    paddingBottom: 0.5,
                  }}
                >
                  Salary Template Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid item xs={6}>
                <CustomTextBox
                  selectedValue={templateName}
                  rules={{
                    ...register("salary_template_name", {
                      required: "Salary template name is required",
                      validate: {
                        existsName: async (fieldValue) => {
                          if (
                            fieldValue !== "" &&
                            fieldValue !== editSalaryeTemplateDetails.name
                          ) {
                            return await checkSalaryTemplateName(
                              fieldValue.trim()
                            );
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateSalaryGroupName}
                  placeHolder="Enter salary template name"
                  errorMessage={
                    errors?.salary_template_name?.message
                      ? errors?.salary_template_name?.message
                      : ""
                  }
                  textBoxError={errors.salary_template_name ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 2, paddingLeft: 5 }}>
              <Mui.Grid xs={2}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                    paddingBottom: 0.5,
                  }}
                >
                  Salary Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={6}>
                <CustomAutoComplete
                  dataList={salaryTypeData
                    .filter((obj) => obj.salary_type !== salaryType)
                    .map((obj) => ({
                      ...obj,
                      label: obj.salary_type,
                    }))}
                  selectedValue={salaryType}
                  rules={{
                    ...register("salary_type_name", {
                      required: "Salary type name is required",
                    }),
                  }}
                  updateDataFunction={handleSalaryType}
                  placeHolder="Select the salary group"
                  errorMessage={
                    errors?.salary_type_name?.message
                      ? errors?.salary_type_name?.message
                      : ""
                  }
                  textBoxError={errors?.salary_type_name ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 2, paddingLeft: 5 }}>
              <Mui.Grid item xs={2}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  Amount
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={6}>
                <CustomTextBox
                  selectedValue={amount}
                  rules={{
                    ...register("amount", {
                      required: "Amount is required",
                    }),
                  }}
                  updateDataFunction={updateAmount}
                  placeHolder="Enter amount"
                  errorMessage={
                    errors?.amount?.message ? errors?.amount?.message : ""
                  }
                  textBoxError={errors.amount ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Grid
          container
          sx={{ paddingLeft: 5, paddingRight: 5, paddingTop: 3 }}
        >
          <Mui.Grid
            xs={12}
            sx={{
              backgroundColor: themes.primary,
              padding: 1,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: `2px solid ${themes.sidebarDivider}`,
            }}
          >
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{ fontSize: 18, fontWeight: "bold", paddingLeft: 4 }}
              >
                Salary Components
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Grid container sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Mui.Grid
            container
            sx={{ paddingRight: 5, paddingLeft: 5 , border: `2px solid ${themes.sidebarDivider}`, }}
            component={Mui.Paper}
          >
            <EditEarning
              companyId={companyId}
              earningDetailsData={earningDetailsData}
              setEarningDetailsData={setEarningDetailsData}
              emptyEarning={emptyEarning}
              setEmptyEarning={setEmptyEarning}
              updateDefaultEarnings={updateDefaultEarnings}
              setUpdateDefaultEarnings={setUpdateDefaultEarnings}
              monthlySalary={monthlySalary}
              setEarningDetailsUpdate={setEarningDetailsUpdate}
              userDeductionDetails={userDeductionDetails}
            />
            <EditDeduction
              companyId={companyId}
              userDeductionDetails={userDeductionDetails}
              setUserDeductionDetails={setUserDeductionDetails}
              earningDetailsData={earningDetailsData}
              emptyDeduction={emptyDeduction}
              setUpdateDeductionDetails={setUpdateDeductionDetails}
              updateDeductionDetails={updateDeductionDetails}
              monthlySalary={monthlySalary}
              earningDetailsUpdate={earningDetailsUpdate}
              setEarningDetailsUpdate={setEarningDetailsUpdate}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 3,
            paddingTop: 2,
            padding: 2,
          }}
        >
          <CustomButton
            actionFuntion={() => setEditTemplate(false)}
            actionName="Cancel"
            typeName="button"
          />
          &nbsp; &nbsp;
          <CustomButton
            actionName="Update"
            typeName="submit"
            disableAction={buttonDisabled}
          />
        </Mui.Grid>
      </React.Fragment>
    </form>
  );
};
