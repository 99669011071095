import sessionHandling from "./notificationUtils";
import { showToast } from "./Status";
let apiToken = localStorage.getItem("token");

export const customAPI = async (url, method, payload) => {
  let statusCode = "";
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${apiToken}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });
    statusCode = response.status;
    if (response.ok) {
      const res = await response.json();
      let resp = {
        ok: response.ok,
        code: statusCode,
        responseDetails: res,
      };
      return resp;
    } else if (response.status === 409) {
      sessionHandling();
    } else {
      const res = await response.json();
      throw new Error(res.error);
    }
  } catch (error) {
    showToast("error", error.message);
    let res = {
      code: statusCode,
      message: error.message,
    };
    return res;
  }
};

export const customGetAPI = async (url) => {
  let statusCode = "";
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${apiToken}`,
      },
    });
    statusCode = response.status;
    if (response.ok) {
      const res = await response.json();
      let resp = {
        ok: response.ok,
        code: statusCode,
        responseDetails: res,
      };
      return resp;
    } else if (response.status === 409) {
      sessionHandling();
    } else {
      const res = await response.json();
      throw new Error(res.error);
    }
  } catch (error) {
    showToast("error", error.message);
    let res = {
      code: statusCode,
      message: error.message,
    };
    return res;
  }
};
