import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { SettingsMenu } from "./SettingsMenu";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { PaymentBank } from "./Screens/PaymentBank";
import { SalaryAndStatutory } from "./Screens/SalaryAndStatutory";
import { General } from "./Screens/General";
import { SalaryTemplate } from "./Screens/SalaryTemplate";
import { Incentive } from "./Screens/Incentive";
import { PayRollGroup } from "./Screens/PayRollGroup";
import { PayrunCycle } from "./Screens/PayrunCycle";
export const PayrollSettings = () => {
  const [currentScreen, setCurrentScreen] = useState("Salary Component");
  const { domain } = useSelector((state) => state.tokenReducer);
  const { result: companyList } = useFetch(`${domain}get-active-company/`);
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [companyChange, setCompanyChange] = useState(true);
  const [editSalary, setEditSalary] = useState(false);
  const [editBankDetails, setEditBankDetails] = useState(false);
  const [addNewBank, setAddNewBank] = useState(false);
  const [salaryTemplate, setAddNewSalaryTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [addAction, setAddAction] = useState(false);
  const [editIncentive, setEditIncentive] = useState(false);
  const [viewOption, setViewOption] = useState(false);
  const { result: earningDetails, setLoad: reCallEarningApi } = useFetch(
    companyId !== 0 ? `${domain}earnings/?company=${companyId}` : null
  );

  const renderComponent = () => {
    switch (currentScreen) {
      case "General":
        return (
          <General
            currentScreen={currentScreen}
            companyId={companyId}
            companyChange={companyChange}
            setCompanyChange={setCompanyChange}
          />
        );
      case "Salary Component":
        return (
          <SalaryAndStatutory
            currentScreen={currentScreen}
            companyId={companyId}
            companyChange={companyChange}
            setCompanyChange={setCompanyChange}
          />
        );
      case "Payment Bank":
        return (
          <PaymentBank
            addNewBank={addNewBank}
            setAddNewBank={setAddNewBank}
            currentScreen={currentScreen}
            companyId={companyId}
            companyChange={companyChange}
            setCompanyChange={setCompanyChange}
            editBankDetails={editBankDetails}
            setEditBankDetails={setEditBankDetails}
          />
        );
      case "Salary Template":
        return (
          <SalaryTemplate
            companyId={companyId}
            currentScreen={currentScreen}
            companyChange={companyChange}
            setCompanyChange={setCompanyChange}
            editTemplate={editTemplate}
            salaryTemplate={salaryTemplate}
            setAddNewSalaryTemplate={setAddNewSalaryTemplate}
            setEditTemplate={setEditTemplate}
            earningDetails={earningDetails}
            reCallEarningApi={reCallEarningApi}
            viewOption={viewOption}
            setViewOption={setViewOption}
          />
        );
      case "Incentive":
        return (
          <Incentive
            currentScreen={currentScreen}
            companyId={companyId}
            companyChange={companyChange}
            setCompanyChange={setCompanyChange}
            editIncentive={editIncentive}
            setEditIncentive={setEditIncentive}
            setAddAction={setAddAction}
          />
        );
      case "Payrun Cycle":
        return <PayrunCycle />;
      case "Payroll Group":
        return (
          <PayRollGroup
            companyId={companyId}
            companyChange={companyChange}
            setAddAction={setViewOption}
            setCompanyChange={setCompanyChange}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (companyList.length !== 0) {
      const data = companyList.filter((company) => {
        return company.isprimary === true;
      });
      setCompanyId(data[0]?.id);
      setCompanyName(data[0]?.companyname);
    }
  }, [companyList]);

  const handleCompanyName = (event, data) => {
    if (data) {
      setCompanyName(data.companyname);
      setCompanyId(data.id);
      setCompanyChange(true);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          xs={4}
          sm={3}
          md={2}
          lg={2}
          xl={1.5}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          <SettingsMenu
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            setAddNewSalaryTemplate={setAddNewSalaryTemplate}
            setEditTemplate={setEditTemplate}
            setEditSalary={setEditSalary}
            setViewOption={setViewOption}
            setEditBankDetails={setEditBankDetails}
            viewOption={editTemplate || salaryTemplate}
          />
        </Grid>
        <Grid xs={8} sm={9} md={10} lg={10} xl={10.5} sx={{ padding: 1 }}>
          <Paper
            sx={{
              boxShadow: 3,
              height:
                currentScreen === "Salary Template" &&
                (salaryTemplate || editTemplate)
                  ? "100%"
                  : "93vh",
            }}
          >
            <Mui.Grid container>
              <Mui.Grid xs={9.5}></Mui.Grid>
              {editSalary ||
              editBankDetails ||
              addNewBank ||
              salaryTemplate ||
              editTemplate ||
              viewOption ? null : (
                <Mui.Grid
                  xs={2.5}
                  sx={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingRight: 1.5,
                    paddingTop: 2,
                  }}
                >
                  {currentScreen === "Incentive" ||
                  currentScreen === "Payrun Cycle" ||
                  currentScreen === "General" ? null : (
                    <Mui.Typography sx={{ fontWeight: 700 }}>
                      Company Name
                    </Mui.Typography>
                  )}
                  {addAction ? (
                    <Mui.TextField
                      size="small"
                      sx={{ width: 277 }}
                      value={companyName}
                    />
                  ) : currentScreen === "Incentive" ||
                    currentScreen === "Payrun Cycle" ||
                    currentScreen === "General" ? null : (
                    <CustomAutoComplete
                      dataList={companyList.map((obj) => ({
                        ...obj,
                        label: obj?.companyname,
                      }))}
                      selectedValue={companyName}
                      updateDataFunction={handleCompanyName}
                      placeHolder="Please select the company name"
                      fullWith
                    />
                  )}
                </Mui.Grid>
              )}
            </Mui.Grid>
            {renderComponent()}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
