import React, { useState, useEffect, useCallback } from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import { themes } from "services/constants";
import dayjs from "dayjs";
import { showToast } from "services/utils/Status";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { onlyNumberRegex } from "services/constants/patterns";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
let shiftnameChange = "",
  changeAction = false;
export const RotationalShift = ({
  checked,
  shift_grade_id,
  shift_category_name,
  setAllShifts,
  noShift,
  setNoShift,
  setStartTime,
  startTime,
  setRotationalError,
  rotationalError,
  breakMinutes,
  setBreakMinutes,
  setCheckInPolicy,
  checkInPolicy,
  setStartTimeFormat,
  startTimeFormat,
  overTimeStartStartEarly,
  overTimeendEndDelay,
  isBreakAllowed,
  setIsBreakAllowed,
  includeShiftTime,
  setIncludeShiftTime,
  shiftCategoryName,
  setShiftCategoryName,
  shiftGrade,
  screenName,
  updatename,
  firstNameUpdate,
  setUpdateName,
  setFirstNameUpdate,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [startStartEarly, setStartStartEarly] = useState(
    checkInPolicy?.start_early
  );
  const [endStartDelay, setEndStartDelay] = useState(
    checkInPolicy?.start_delay
  );
  const [startEndEarly, setStartEndEarly] = useState(checkInPolicy?.end_early);
  const [endEndDelay, setEndEndDelay] = useState(checkInPolicy?.end_delay);
  // const [shouldValidate, setShouldValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getShiftDetails = useCallback(
    async (time) => {
      if (time && time !== "Invalid Date") {
        const newShiftName = `${shiftGrade}-${startTime}-${noShift}`;
        const shiftCategoryName = changeAction ? shiftnameChange : newShiftName;
        try {
          const response = await fetch(
            `${domain}shift-over-time-details/?shift_category_name=${shiftCategoryName}&shift_grade_id=${shift_grade_id}&start_time=${time}&break_time=${breakMinutes}&no_of_shifts=${
              noShift ? noShift : 0
            }&start_start_early=${
              startStartEarly ? startStartEarly : 0
            }&start_end_delay=${
              endStartDelay ? endStartDelay : 0
            }&end_end_early=${
              startEndEarly ? startEndEarly : 0
            }&end_end_delay=${endEndDelay ? endEndDelay : 0}&start_delay=${
              overTimeStartStartEarly ? overTimeStartStartEarly : 0
            }&end_early=${overTimeendEndDelay ? overTimeendEndDelay : 0}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `token ${token}`,
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            let overAllData = res?.shifts.map((element) => {
              return {
                ...element,
                startTimeDateFormat: dayjs()
                  .set("hour", element?.start_time.split(":")[0])
                  .set("minute", element?.start_time.split(":")[1])
                  .set("second", "00"),
                endTimeDateFormat: dayjs()
                  .set("hour", element?.end_time.split(":")[0])
                  .set("minute", element?.end_time.split(":")[1])
                  .set("second", "00"),
              };
            });
            setAllShifts(overAllData);
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    },
    [
      breakMinutes,
      domain,
      endEndDelay,
      endStartDelay,
      noShift,
      overTimeStartStartEarly,
      overTimeendEndDelay,
      setAllShifts,
      shiftGrade,
      shift_grade_id,
      startEndEarly,
      startStartEarly,
      startTime,
      token,
    ]
  );

  const handleStartTime = (newValue) => {
    if (newValue && newValue.format) {
      const timeWithDate = newValue ? newValue.toDate() : new Date();
      const time = newValue.format("HH:mm");
      setStartTime(time);
      let minTime = moment.utc(timeWithDate);
      setStartTimeFormat(
        dayjs()
          .set("hour", minTime._d.getHours())
          .set("minute", minTime._d.getMinutes())
          .set("second", minTime._d.getSeconds())
      );
      setRotationalError(false);
      if (screenName === "Add") {
        setFirstNameUpdate(true);
      } else {
        setUpdateName(true);
      }
    }
  };

  const handleBreak = (event) => {
    const isChecked = event.target.checked;
    setIsBreakAllowed(isChecked);
    setBreakMinutes("00");
    if (!isChecked) {
      setIncludeShiftTime(false);
    }
  };

  const handleShift = () => {
    setIncludeShiftTime(!includeShiftTime);
  };

  // const updateShiftCategoryName = (event) => {
  //   if (event.target.value.length < 32) {
  //     setValue("shift_category_name", event.target.value);
  //     setShiftCategoryName(event.target.value);
  //   }
  // };

  // let errorMessage = null;

  // if (shiftCategoryName === "" && rotationalError) {
  //   errorMessage = "Shift category name is required";
  // } else if (shiftCategoryName.trim().length < 3 && rotationalError) {
  //   errorMessage = "Minimum length is 3 characters";
  // } else if (!/[a-zA-Z]/.test(shiftCategoryName) && rotationalError) {
  //   errorMessage = "At least one alphabet is required";
  // }

  // if (!shiftCategoryName) {
  //   setErrorMessage("Shift category name is required");
  //   return;
  // }

  const handleName = (event) => {
    changeAction = true;
    shiftnameChange = event.target.value;
    updateShiftCategoryName(event.target.value);
  };

  const updateShiftCategoryName = useCallback(
    async (value) => {
      setShiftCategoryName(value);

      if (value.length < 3) {
        setErrorMessage("Minimum length is 3 characters");
        return;
      } else if (!/[a-zA-Z]/.test(value)) {
        setErrorMessage("At least one alphabet is required");
        return;
      } else if (value.length >= 3) {
        if (
          screenName === "Add" ||
          (screenName === "Edit" && shift_category_name !== value)
        )
          try {
            const response = await fetch(
              `${domain}check_shift_category_name/?shift_category_name=${value}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `token ${token}`,
                },
              }
            );
            const resp = await response.json();
            if (resp.exists) {
              setErrorMessage("Shift category name already exists");
            } else {
              getShiftDetails(startTime);
              setErrorMessage(null);
            }
          } catch (error) {
            showToast("error", error.message);
          }
      }
    },
    [
      domain,
      getShiftDetails,
      screenName,
      setShiftCategoryName,
      shift_category_name,
      startTime,
      token,
    ]
  );

  useEffect(() => {
    if (firstNameUpdate && screenName === "Add") {
      const newShiftName = `${shiftGrade}-${startTime}-${noShift}`;
      setShiftCategoryName(newShiftName);
      updateShiftCategoryName(newShiftName);
      setFirstNameUpdate(false);
      getShiftDetails(startTime);
    }
  }, [
    firstNameUpdate,
    getShiftDetails,
    noShift,
    screenName,
    setFirstNameUpdate,
    setShiftCategoryName,
    shiftGrade,
    startTime,
    updateShiftCategoryName,
  ]);

  useEffect(() => {
    if (updatename && screenName === "Edit") {
      const newShiftName = `${shiftGrade}-${startTime}-${noShift}`;
      setShiftCategoryName(newShiftName);
      updateShiftCategoryName(newShiftName);
      setUpdateName(false);
      getShiftDetails(startTime);
    }
  }, [
    getShiftDetails,
    noShift,
    screenName,
    setShiftCategoryName,
    setUpdateName,
    shiftGrade,
    startTime,
    updateShiftCategoryName,
    updatename,
  ]);

  // useEffect(() => {
  //   const validateShiftCategoryName = async () => {
  //     try {
  //       const response = await fetch(
  //         `${domain}check_shift_category_name/?shift_category_name=${shiftCategoryName}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "content-type": "application/json",
  //             Authorization: `token ${token}`,
  //           },
  //         }
  //       );
  //       const res = await response.json();
  //       if (response.ok) {
  //         setErrorMessage("Shift category name already exists.");
  //       } else if (response.status === 409) {
  //         sessionHandling();
  //       } else {
  //         throw new Error(res.error);
  //       }
  //     } catch (error) {
  //       showToast("error", error.message);
  //     }
  //   };
  //   if (shouldValidate && shiftCategoryName.trim().length >= 3) {
  //     validateShiftCategoryName();
  //     setShouldValidate(false);
  //   }
  // }, [domain, shiftCategoryName, shouldValidate, token]);

  return (
    <>
      <Mui.Grid
        container
        component={Mui.Paper}
        sx={{ padding: 2, marginTop: 1.5 }}
        elevation={2}
      >
        <Mui.Grid container fullWidth sx={{ padding: 1, borderRadius: 3 }}>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              No of Shifts &nbsp;
              <Mui.Tooltip title="This is the No of Shifts field. Specify the number of shifts available within the scheduling cycle, such as 1, 2, or 3, depending on the organization's shift structure.">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid container xs={6}>
            <Mui.Grid item xs={3}>
              <Mui.TextField
                value={noShift}
                placeHolder="Enter the no of shift"
                size="small"
                onChange={(event) => {
                  const value = event.target.value;
                  const isValidNumber = /^[1-6]$/.test(value);
                  if (value === "" || isValidNumber) {
                    changeAction = false;
                    setNoShift(event.target.value);
                  }
                }}
                onBlur={() => {
                  getShiftDetails(startTime);
                }}
                helperText={
                  rotationalError && (noShift === "" || noShift === "0") ? (
                    <h5 style={{ color: themes.txtErrorColor }}>
                      No of shift is required
                    </h5>
                  ) : null
                }
                error={
                  rotationalError && (noShift === "" || noShift === "0")
                    ? true
                    : false
                }
              />
            </Mui.Grid>
            <Mui.Grid item xs={1.5}></Mui.Grid>
            <Mui.Grid container xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 18,
                  color: themes.headLine,
                  fontWeight: "bold",
                  paddingTop: 1.5,
                }}
              >
                First Shift Start Time
                <Mui.Tooltip title="This is the First Shift field. Choose the first shift of the day, which could be Morning, Afternoon, or Night, depending on the schedule.">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={0.5}></Mui.Grid>
            <Mui.Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <div sx={{ width: 4 }}>
                    <TimePicker
                      ampm={false}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      value={startTimeFormat || null}
                      slotProps={{
                        textField: {
                          size: "small",
                          width: 15,
                        },
                      }}
                      onChange={(newValue) => {
                        changeAction = false;
                        handleStartTime(newValue);

                        // if (
                        //   errorHandling === "Use different time " ||
                        //   errorHandling === "Use different time"
                        // ) {
                        //   validateFields();
                        // }
                      }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>

              {(!startTime ||
                startTime === "00:00" ||
                startTime === "Invalid Date") &&
              rotationalError ? (
                <h5 style={{ color: themes.helperTextColor }}>
                  Shift start time is required
                </h5>
              ) : null}
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ padding: 1, borderRadius: 3 }}>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Is break is allowed &nbsp;
              <Mui.Tooltip title="This is the Is Break Allowed field. Choose Yes to allow a break during the shift or No to indicate no break is provided.">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>{" "}
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid container xs={6}>
            <Mui.Grid xs={4.5}>
              <Mui.Switch
                checked={isBreakAllowed}
                onChange={handleBreak}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Mui.Grid>
            {/* <Mui.Grid xs={0.5}></Mui.Grid> */}
            {isBreakAllowed && (
              <>
                <Mui.Grid container xs={3.5} sx={{ paddingTop: 2 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      fontWeight: "bold",
                    }}
                  >
                    Break Duration &nbsp;
                  </Mui.Typography>
                  <Mui.Tooltip title="This is the Break Duration field. Enter the duration of the break (e.g., 30 minutes or 1 hour), if breaks are allowed during the shift.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Grid>
                <Mui.Grid xs={1}></Mui.Grid>

                <Mui.Grid containter xs={3} sx={{ paddingTop: 1 }}>
                  <Mui.TextField
                    size="small"
                    value={breakMinutes}
                    sx={{ textAlign: "center" }}
                    inputProps={{ style: { textAlign: "center" } }}
                    onChange={(event) => {
                      if (
                        onlyNumberRegex.test(event.target.value) &&
                        event.target.value.length <= 3
                      ) {
                        setBreakMinutes(event.target.value);
                      }
                    }}
                    onBlur={() => {
                      getShiftDetails(startTime);
                    }}
                  />
                </Mui.Grid>
              </>
            )}
          </Mui.Grid>
          {isBreakAllowed && (
            <Mui.Grid container>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Is break part of shift hours &nbsp;
                  <Mui.Tooltip title="This is the Is Break Part of Shift Hours field. Choose Yes if the break is included in the total shift hours or No if it is not.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>{" "}
              </Mui.Grid>
              <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                :
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Switch
                  checked={includeShiftTime}
                  onChange={handleShift}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Mui.Grid>
            </Mui.Grid>
          )}

          <Mui.Grid container fullWidth sx={{ borderRadius: 3, paddingTop: 1 }}>
            <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
              <Mui.Typography
                sx={{
                  fontSize: 18,
                  color: themes.headLine,
                  fontWeight: "bold",
                }}
              >
                Checkin/Out Policy &nbsp;
                <Mui.Tooltip title="This is the Check In/Out Policy field. Define the rules for employee check-in and check-out times, such as mandatory check-in time or flexible check-out times, based on the organization’s requirements.">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
              :
            </Mui.Grid>
            <Mui.Grid container xs={6}>
              <Mui.Grid xs={5.9} component={Mui.Paper}>
                <Mui.Grid
                  xs={12}
                  sx={{
                    backgroundColor: themes.primary,
                    textAlign: "center",
                    padding: 1,
                  }}
                >
                  <Mui.Typography
                    sx={{ fontSize: 17, fontWeight: 600, font: "bold" }}
                  >
                    Start
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid item xs={5.7} sx={{ padding: 1 }}>
                    {" "}
                    <Mui.Grid
                      sx={{
                        backgroundColor: themes.primary,
                        textAlign: "center",
                      }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                      >
                        Start Early
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                    <Mui.TextField
                      size="small"
                      value={startStartEarly}
                      inputProps={{ style: { textAlign: "center" } }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 2
                        ) {
                          setStartStartEarly(event.target.value);
                          setCheckInPolicy({
                            ...checkInPolicy,
                            start_early: event.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        getShiftDetails(startTime);
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}></Mui.Grid>

                  <Mui.Grid item xs={5.7} sx={{ padding: 1 }}>
                    {" "}
                    <Mui.Grid
                      xs={12}
                      sx={{
                        backgroundColor: themes.primary,
                        textAlign: "center",
                      }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                      >
                        Late Start
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                    <Mui.TextField
                      size="small"
                      value={endStartDelay}
                      sx={{ textAlign: "center" }}
                      inputProps={{ style: { textAlign: "center" } }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 2
                        ) {
                          setEndStartDelay(event.target.value);
                          setCheckInPolicy({
                            ...checkInPolicy,
                            start_delay: event.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        getShiftDetails(startTime);
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={0.2}></Mui.Grid>
              <Mui.Grid xs={5.9} component={Mui.Paper}>
                <Mui.Grid
                  xs={12}
                  sx={{
                    backgroundColor: themes.primary,
                    textAlign: "center",
                    padding: 1,
                  }}
                >
                  <Mui.Typography
                    sx={{ fontSize: 17, fontWeight: 600, font: "bold" }}
                  >
                    End
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid item xs={5.7} sx={{ padding: 1 }}>
                    {" "}
                    <Mui.Grid
                      sx={{
                        backgroundColor: themes.primary,
                        textAlign: "center",
                      }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                      >
                        Early End
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                    <Mui.TextField
                      size="small"
                      value={startEndEarly}
                      inputProps={{ style: { textAlign: "center" } }}
                      sx={{ textAlign: "center" }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 2
                        ) {
                          setStartEndEarly(event.target.value);
                          setCheckInPolicy({
                            ...checkInPolicy,
                            end_early: event.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        getShiftDetails(startTime);
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}></Mui.Grid>

                  <Mui.Grid item xs={5.7} sx={{ padding: 1 }}>
                    {" "}
                    <Mui.Grid
                      xs={12}
                      sx={{
                        backgroundColor: themes.primary,
                        textAlign: "center",
                      }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                      >
                        End Late
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                    <Mui.TextField
                      size="small"
                      value={endEndDelay}
                      inputProps={{ style: { textAlign: "center" } }}
                      sx={{ textAlign: "center" }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 2
                        ) {
                          setEndEndDelay(event.target.value);
                          setCheckInPolicy({
                            ...checkInPolicy,
                            end_delay: event.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        getShiftDetails(startTime);
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container fullWidth sx={{ borderRadius: 3, paddingTop: 1 }}>
            <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
              <Mui.Typography
                sx={{
                  fontSize: 18,
                  color: themes.headLine,
                  fontWeight: "bold",
                }}
              >
                Shift Category Name &nbsp;
                <Mui.Tooltip title="This is the Shift Category Name field. Enter a unique name for the shift category, such as Day Shift or Night Shift, to organize and categorize different shifts.">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
              :
            </Mui.Grid>
            <Mui.Grid item xs={6}>
              <CustomTextBox
                selectedValue={shiftCategoryName}
                updateDataFunction={handleName}
                placeHolder="Enter shift category name"
              />
              {errorMessage && (
                <h5 style={{ color: themes.helperTextColor }}>
                  {errorMessage}
                </h5>
              )}
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
