import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Approved } from "./Approved";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import { Leave } from "./Apply";
import { parse, format } from "date-fns";
import sessionHandling from "services/utils/notificationUtils";

import { themes } from "services/constants";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import "react-toastify/dist/ReactToastify.css";
import * as Mui from "@mui/material";
export const Leaves = () => {
  const [selectedComponent, setSelectedComponent] = React.useState("apply");
  const [alignment, setAlignment] = useState("Apply Leave");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveChange, setLeaveChange] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const { token, domain } = useSelector((state) => state.tokenReducer);
  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "history":
        return (
          <Approved
            startDate={startDate}
            endDate={endDate}
            leaveChange={leaveChange}
            setLeaveChange={setLeaveChange}
          />
        );
      case "apply":
        return <Leave />;
      default:
        break;
    }
  };

  const updateLeavePeriod = (event, data) => {
    if (data && data.filteredValue) {
      setFilteredLabel(data.label);
      setFilteredValue(data.filteredValue);
      setStartDate(formatDate(data.start_date));
      setEndDate(formatDate(data.end_date));
      setLeaveChange(true);
      dispatch(setGlobalSearchValue(""));
    }
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setStartDate(sDate);
          setEndDate(eDate);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, token]);

  

  return (
    <div className="flex flex-col items-center justify-center">
      <Mui.Grid
        container
        sx={{
          paddingTop: 5,
          paddingLeft: 3,
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Mui.Grid xs={4.4}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 300,
              height: 55,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "30px",
                backgroundColor: themes.lightBrown,
              }}
            >
              <ToggleButton
                value="Apply Leave"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    alignment === "Apply Leave"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    alignment === "Apply Leave"
                      ? themes.headLine
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.headLine,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      alignment === "Apply Leave"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      alignment === "Apply Leave"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                  "&:focus": {
                    backgroundColor:
                      alignment === "Apply Leave"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      alignment === "Apply Leave"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
                onClick={() => {
                  setSelectedComponent("apply");
                  dispatch(setGlobalSearchValue(""));
                }}
              >
                Apply
              </ToggleButton>
              <ToggleButton
                value="History"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  border: "none",
                  borderRadius: "30px",
                  color:
                    alignment === "History"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    alignment === "History"
                      ? themes.headLine
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.headLine,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      alignment === "History"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      alignment === "History"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
                onClick={() => {
                  setSelectedComponent("history");
                  dispatch(setGlobalSearchValue(""));
                }}
              >
                History
              </ToggleButton>
            </ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>
        <Mui.Grid
          item
          xs={2.6}
          sx={{
            padding: 2,
          }}
        >
          {selectedComponent === "history" ? (
            <CustomAutoComplete
              dataList={leaveDate}
              selectedValue={filteredValue}
              updateDataFunction={updateLeavePeriod}
              placeHolder="Select leave period"
              label={filteredLabel ? filteredLabel : "Leave Period"}
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Grid
          xs={selectedComponent === "pending" ? 8.8 : 12}
          sx={{ justifyContent: "center" }}
        >
          {renderComponent()}
        </Mui.Grid>
        <Mui.Grid
          xs={selectedComponent === "pending" ? 0.2 : 0}
          sx={{ borderRight: "4px solid black" }}
        ></Mui.Grid>
      </Mui.Grid>
    </div>
  );
};
