import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import moment from "moment";
import { Loader } from "components/Loader";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { DownloadForOffline } from "@mui/icons-material";
import { AnomalyRepStatus } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { tableRowSx } from "services/constants";

const AnomaliesReport = ({ setShowReport }) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anomalyRepDetails, setAnomalyRepDetails] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [anoSts, setAnoSts] = useState("All");
  const { result: departmentData } = useFetch(
    `${domain}get-department/?user_id=${userDetails.id}`
  );
  const [departmentName, setDepartmentName] = useState("All");
  const [departmentId, setDepartmentId] = useState("99999");

  useEffect(() => {
    const fetchUsertaskDetails = async () => {
      try {
        setIsLoading(true);
        const anostatus = anoSts === "All" ? "" : anoSts;
        let searchStartDate = startDate.split("/").reverse().join("-");

        const response = await fetch(
          `${domain}anomalies-report/?date=${searchStartDate}&status=${anostatus}&department_id=${departmentId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setAnomalyRepDetails(res?.data);
          setMasterDataForSearch(res?.data);
        } else if (response.status === 409) {
          setAnomalyRepDetails([]);
          setMasterDataForSearch([]);
          sessionHandling();
        } else {
          setAnomalyRepDetails([]);
          setMasterDataForSearch([]);
          throw new Error(res.error);
        }
      } catch (error) {
        setAnomalyRepDetails([]);
        setMasterDataForSearch([]);
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsertaskDetails();
  }, [domain, token, startDate, anoSts, departmentId]);

  useEffect(() => {
    const filteredResults = masterDataForSearch?.filter((item) =>
      ["username", "check_in_time", "check_out_time", "status"].some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setAnomalyRepDetails(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAnomaliesReport = () => {
    let dataPreparation = [];
    anomalyRepDetails?.forEach((element) => {
      let overAllData = {
        employeeid: element?.employee_id,
        employeename: element?.username,
        checkin: element?.check_in_time ? element?.check_in_time : "N/A",
        checkout: element?.check_out_time ? element?.check_out_time : "N/A",
        status: element?.status ? element?.status : "N/A",
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const AnomalyReport = ["Attendance Anomalies Report"];
    const Space = [""];
    const Date = ["Date"];
    const Department = ["Department Name"];
    const Status = ["Status"];
    AnomalyReport.push("");
    Date.push(moment(startDate).format("DD/MM/YYYY"));
    Department.push(departmentName);
    Status.push(anoSts);
    Space.push("");
    const items = dataPreparation;
    const TableHead = ["Employee Id"];
    TableHead.push("Employee Name");
    TableHead.push("Check In");
    TableHead.push("Check Out");
    TableHead.push("Status");
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(Space.join(","));
    csv.unshift(Status.join(","));
    csv.unshift(Department.join(","));
    csv.unshift(Date.join(","));
    csv.unshift(AnomalyReport.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "AttendanceAnomaliesReport.csv";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row">
            <BackArrow actionFunction={() => setShowReport(false)} />
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              Attendance Anomalies Report
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.TextField
            id="from-date"
            type="date"
            size="small"
            label="Start Date"
            value={startDate}
            sx={{ background: themes.whiteColor }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setStartDate(event.target.value);
            }}
            fullWidth
            disableOpenPicker
            inputProps={{
              max: "9999-12-31",
            }}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              [{ id: 99999, department_name: "All" }, ...departmentData]?.find(
                (option) => option?.department_name === departmentName
              ) || {
                department_name: departmentName,
              }
            }
            options={[{ id: 99999, department_name: "All" }, ...departmentData]}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options?.filter((option) =>
                option?.department_name
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            getOptionLabel={(option) =>
              option?.department_name ? option?.department_name : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.department_name === value.department_name
            }
            renderOption={(props, option) => {
              return <li {...props}>{option?.department_name}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.department_name) {
                setDepartmentName(value.department_name);
                setDepartmentId(value.id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="Department"
                sx={{ background: "white" }}
                name="department name"
                placeholder="Select Department"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              AnomalyRepStatus?.find((option) => option?.label === anoSts) ||
              anoSts ||
              ""
            }
            options={AnomalyRepStatus}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options.filter((option) =>
                option.label
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.label}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.label) {
                setAnoSts(value.label);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="Status"
                sx={{ background: "white" }}
                name="status"
                placeholder="Select Status"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item lg={4.5}></Mui.Grid>
        <Mui.Grid item xs={2} sm={2} md={2} lg={1.5}>
          <Mui.IconButton
            onClick={() => {
              getAnomaliesReport();
            }}
            disabled={anomalyRepDetails?.length === 0 ? true : false}
          >
            <DownloadForOffline
              size="large"
              sx={{ color: "#25B7D3", fontSize: 40, mt: -1 }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>

      {isLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          {anomalyRepDetails?.length !== 0 ? (
            <Mui.Grid
              container
              sx={{
                paddingRight: 1,
              }}
            >
              <Mui.TableContainer
                component={Mui.Paper}
                style={{ paddingTop: 4, paddingBottom: 2 }}
                sx={{
                  mt: 2,
                  borderBottom: "1.5px solid transparent",
                }}
              >
                <Mui.Table>
                  <Mui.TableHead sx={{ backgroundColor: themes.primary }}>
                    <Mui.TableRow sx={tableRowSx}>
                      <Mui.TableCell
                        sx={{
                          color: themes.headLine,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "25%",
                        }}
                      >
                        Employee Name
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.headLine,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "25%",
                        }}
                      >
                        Check In
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.headLine,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "25%",
                        }}
                      >
                        Check Out
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.headLine,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "25%",
                        }}
                      >
                        Status
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {anomalyRepDetails
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <>
                          <Mui.TableRow sx={tableRowSx}>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.username}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.check_in_time
                                  ? item?.check_in_time
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.check_out_time
                                  ? item?.check_out_time
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.status ? item?.status : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        </>
                      ))}
                  </Mui.TableBody>
                </Mui.Table>
                {anomalyRepDetails?.length > 25 ? (
                  <Mui.TablePagination
                    className="custom-pagination"
                    component="div"
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    count={anomalyRepDetails?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : null}
              </Mui.TableContainer>
            </Mui.Grid>
          ) : (
            <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
              <Mui.Alert severity="info">
                There is a no available data
              </Mui.Alert>
            </Mui.Stack>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default AnomaliesReport;
