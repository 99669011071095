import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";
import * as Mui from "@mui/material";
import { Apply } from "./Apply";
import { History } from "./History";
import { themes } from "services/constants";
export const AdvanceSalary = () => {
  const [advanceSalary, setAdvanceSalary] = useState("Apply Salary");
  const [selectedComponent, setSelectedComponent] = useState("Apply");
  const [leaveChange, setLeaveChange] = useState(true);

  const dispatch = useDispatch();
  const handleChange = (event, newAlignment) => {
    setAdvanceSalary(newAlignment);
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Apply":
        return <Apply />;
      case "History":
        return (
          <History leaveChange={leaveChange} setLeaveChange={setLeaveChange} />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 5,
          paddingLeft: 3,
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Mui.Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: 300,
            height: 55,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Mui.ToggleButtonGroup
            value={advanceSalary}
            exclusive
            onChange={handleChange}
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: "30px",
              backgroundColor: themes.lightBrown,
            }}
          >
            <Mui.ToggleButton
              value="Apply Salary"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                borderRadius: "30px",
                fontWeight: 600,
                border: "none",
                color:
                  advanceSalary === "Apply Salary"
                    ? themes.whiteColor
                    : themes.headLine,
                backgroundColor:
                  advanceSalary === "Apply Salary"
                    ? themes.headLine
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.headLine,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    advanceSalary === "Apply Salary"
                      ? themes.headLine
                      : themes.lightBrown,
                  color:
                    advanceSalary === "Apply Salary"
                      ? themes.whiteColor
                      : themes.headLine,
                },
                "&:focus": {
                  backgroundColor:
                    advanceSalary === "Apply Salary"
                      ? themes.headLine
                      : themes.lightBrown,
                  color:
                    advanceSalary === "Apply Salary"
                      ? themes.whiteColor
                      : themes.headLine,
                },
              }}
              onClick={() => {
                dispatch(setGlobalSearchValue(""));
                setSelectedComponent("Apply");
              }}
            >
              Apply
            </Mui.ToggleButton>
            <Mui.ToggleButton
              onClick={() => {
                dispatch(setGlobalSearchValue(""));
                setSelectedComponent("History");
              }}
              value="History"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                fontWeight: 600,
                borderRadius: "30px",
                border: "none",
                color:
                  advanceSalary === "History"
                    ? themes.whiteColor
                    : themes.headLine,
                backgroundColor:
                  advanceSalary === "History"
                    ? themes.headLine
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.headLine,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    advanceSalary === "History"
                      ? themes.headLine
                      : themes.lightBrown,
                  color:
                    advanceSalary === "History"
                      ? themes.whiteColor
                      : themes.headLine,
                },
              }}
            >
              History
            </Mui.ToggleButton>
          </Mui.ToggleButtonGroup>
        </Mui.Box>
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
