import * as React from "react";
import { useState } from "react";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { format, parse } from "date-fns";
import { leaveSuccess } from "services/constants/SuccessMessages";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import moment from "moment";

export const Leave = () => {
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [departmentDetailsApi, setDepartmentDetailsApi] = useState(true);
  const [selectedHalfDay, setSelectedHalfDay] = useState(null);
  const [checkboxChecked, setcheckboxChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [firstHalfChecked, setFirstHalfChecked] = useState(false);
  const [secondHalfChecked, setSecondHalfChecked] = useState(false);
  const [approverName, setApproverName] = useState("");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveStartDate, setLeaveStartDate] = useState("");
  const [leaveEndDate, setLeaveEndDate] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [leaveTypeDetails, setLeaveTypeDetails] = useState("");

  const { register, handleSubmit, reset, setValue, trigger, formState } =
    useForm({ mode: "onBlur" });
  const { errors, isValid } = formState;
  const { token, domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );

  useEffect(() => {
    const fetchDepartmentId = async () => {
      try {
        const response = await fetch(
          `${domain}get_leads_based_on_departments/?department_id=${userDetails.department_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setApproverName(res[0]?.department_lead_name);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (departmentDetailsApi) {
      fetchDepartmentId();
      setDepartmentDetailsApi(false);
    }
  }, [
    approverName,
    departmentDetailsApi,
    domain,
    setValue,
    token,
    userDetails.department_id,
  ]);

  useEffect(() => {
    const fetchLeaveTypeOptions = async () => {
      try {
        const response = await fetch(
          `${domain}leavetypefilter/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          Array.isArray(setLeaveTypeOptions(res));
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveTypeOptions();
  }, [domain, token, userDetails.id]);

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  const resetForm = () => {
    reset();
    setLeaveTypeDetails("");
    setcheckboxChecked(false);
    setChecked(false);
    setFirstHalfChecked(false);
    setSecondHalfChecked(false);
    setSecondHalfChecked(false);
    const today = dayjs().format("YYYY-MM-DD");
    setStartDate(today);
    setEndDate(today);
    setValue("from_date", today);
    setValue("to_date", today);
  };

  const handleClearButtonClick = () => {
    resetForm();
  };

  const onSubmit = async (data) => {
    setButtonLoader(true);
    try {
      const halfDay = firstHalfChecked || secondHalfChecked;
      const whichHalf = firstHalfChecked
        ? "1st"
        : secondHalfChecked
        ? "2nd"
        : null;
      const currentLeaveType = leaveTypeOptions?.filter((leave) => {
        return leave.leave_type === data.leave_type;
      });
      const response = await fetch(`${domain}create/leave-request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          ...data,
          is_half_day: halfDay,
          half_day_period: whichHalf,
          user: userDetails.id,
          status: "Pending",
          approver: null,
          applied_date: new Date().toISOString().split("T")[0],
          createdby: userDetails.first_name,
          createdat: new Date().toISOString(),
          modifiedby: userDetails.first_name,
          modifiedat: new Date().toISOString(),
          leave_type_id: currentLeaveType[0].id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", leaveSuccess.leaveRequest);
        setButtonLoader(false);
        reset();
        setcheckboxChecked(false);
        setChecked(false);
        setLeaveTypeDetails("");
        setFirstHalfChecked(false);
        setSecondHalfChecked(false);
        if (
          new Date(leaveStartDate).getFullYear() === new Date().getFullYear() ||
          new Date(leaveEndDate).getFullYear() === new Date().getFullYear()
        ) {
          const today = dayjs().format("YYYY-MM-DD");
          setStartDate(today);
          setEndDate(today);
        } else {
          setStartDate(leaveStartDate);
          setEndDate(leaveEndDate);
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const today = dayjs().format("YYYY-MM-DD");
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setLeaveStartDate(sDate);
          setLeaveEndDate(eDate);
          if (
            new Date(
              res[0]?.start_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear() ||
            new Date(
              res[0]?.end_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear()
          ) {
            setStartDate(today);
            setEndDate(today);
          } else {
            setStartDate(sDate);
            setEndDate(eDate);
          }
          setValue("from_date", today);
          setValue("to_date", today);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, setValue, token]);

  const updateLeavePeriod = (event, value) => {
    const today = dayjs().format("YYYY-MM-DD");
    if (value && value.filteredValue) {
      setFilteredLabel(value.label);
      setFilteredValue(value.filteredValue);
      setStartDate(formatDate(value.start_date));
      setEndDate(formatDate(value.end_date));
      setLeaveStartDate(formatDate(value.start_date));
      setLeaveEndDate(formatDate(value.end_date));
      if (
        new Date(
          value.start_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear() ||
        new Date(
          value.end_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear()
      ) {
        let startDateConvertions = moment(new Date()).format("DD-MM-YYYY");
        setStartDate(formatDate(startDateConvertions));
        setEndDate(formatDate(startDateConvertions));
        setValue("from_date", formatDate(startDateConvertions));
        setValue("to_date", formatDate(startDateConvertions));
      } else {
        setStartDate(formatDate(value.start_date));
        setEndDate(formatDate(value.end_date));
        setValue("from_date", formatDate(value.start_date));
        setValue("to_date", formatDate(value.end_date));
      }
    } else {
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate("");
      setLeaveEndDate("");
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate(today);
      setLeaveEndDate(today);
      setStartDate(today);
      setEndDate(today);
    }
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setcheckboxChecked(checked);
    const today = dayjs().format("YYYY-MM-DD");
    const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD");

    if (checked) {
      setStartDate(tomorrow);
      setEndDate(tomorrow);
      setValue("from_date", tomorrow);
      setValue("to_date", tomorrow);
      setSecondHalfChecked(false);
      setFirstHalfChecked(false);
      setChecked(false);
    } else {
      setStartDate(today);
      setEndDate(today);
      setValue("from_date", today);
      setValue("to_date", today);
      setSecondHalfChecked(false);
      setFirstHalfChecked(false);
      setChecked(false);
    }
  };

  const handleCheckboxChanged = (event) => {
    const isChecked = event.target.checked;
    const today = dayjs().format("YYYY-MM-DD");
    setChecked(isChecked);
    if (isChecked) {
      setStartDate(today);
      setEndDate(today);
      setValue("from_date", today);
      setValue("to_date", today);
      setcheckboxChecked(false);
    } else {
      setEndDate(endDate);
      setcheckboxChecked(false);
    }
  };

  const handleStartDateChange = (event) => {
    const date = event.target.value;
    setStartDate(date);
    setEndDate(date);
    setValue("from_date", date);
    setValue("to_date", date);
    trigger("from_date");
  };

  const handleEndDateChange = (event) => {
    const date = event.target.value;
    setEndDate(date);
    setValue("to_date", date);
    trigger("to_date");
  };

  const handleSetValue = () => {
    setValue("from_date", startDate);
    setValue("to_date", endDate);
  };

  const handleFirstHalfCheckboxChanged = (event) => {
    const checked = event.target.checked;
    setFirstHalfChecked(checked);
    if (checked) {
      setSecondHalfChecked(false);
      setSelectedHalfDay("1st-half");
      setcheckboxChecked(false);
    } else {
      setSelectedHalfDay(null);
    }
  };

  const handleSecondHalfCheckboxChanged = (event) => {
    const checked = event.target.checked;
    setSecondHalfChecked(checked);
    if (checked) {
      setFirstHalfChecked(false);
      setSelectedHalfDay("2nd-half");
      setcheckboxChecked(false);
    } else {
      setSelectedHalfDay(null);
    }
  };

  const validateEndDate = (value) => {
    const endDate = format(new Date(value), "yyyy-MM-dd");
    if (startDate && endDate < startDate) {
      return "End date cannot be before the start date";
    }
    return true;
  };

  const updateLeaveTypeDetails = (event, data) => {
    if (data) {
      setLeaveTypeDetails(data?.label);
      setValue("leave_type", data?.label);
      setValue("leave_type_id", data?.id);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container sx={{ paddingLeft: 10, paddingRight: 10, mt: 8 }}>
          <Mui.Grid container>
            <Mui.Grid
              xs={12}
              sx={{
                padding: 1,
                backgroundColor: themes.primary,
                borderRadius: "10px 10px 0 0",
                border: `2px solid ${themes.sidebarDivider}`,
                borderBottom: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
              >
                Apply Leave
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              borderRadius: "0 0 10px 10px",
              border: `2px solid ${themes.sidebarDivider}`,
              padding: 2,
            }}
          >
            <Mui.Grid container justifyContent={"flex-end"}>
              <Mui.Grid xs={3} sx={{ padding: 1 }}>
                <CustomAutoComplete
                  dataList={leaveDate}
                  selectedValue={filteredValue}
                  updateDataFunction={updateLeavePeriod}
                  placeHolder="Select leave period"
                  label={filteredLabel ? filteredLabel : "Leave Period"}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Leave Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <CustomAutoComplete
                  dataList={leaveTypeOptions?.map((obj) => ({
                    ...obj,
                    label: obj?.leave_type,
                  }))}
                  rules={{
                    ...register("leave_type", {
                      required: "Leave type is required",
                    }),
                  }}
                  selectedValue={leaveTypeDetails}
                  updateDataFunction={updateLeaveTypeDetails}
                  placeHolder="Select leave type"
                  errorMessage={
                    errors?.leave_type?.message
                      ? errors?.leave_type?.message
                      : ""
                  }
                  textBoxError={errors?.leave_type ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container sx={{ padding: 1, paddingLeft: 2 }}>
              <Mui.Grid item xs={4}></Mui.Grid>
              <Mui.Grid item xs={4}>
                <Mui.FormControlLabel
                  control={<Mui.Checkbox />}
                  label="Only For Tomorrow"
                  checked={checkboxChecked}
                  onChange={handleCheckboxChange}
                  onClick={handleSetValue}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Mui.Grid>
              <Mui.Grid item xs={4}>
                <Mui.FormControlLabel
                  control={
                    <Mui.Checkbox
                      checked={checked}
                      onChange={handleCheckboxChanged}
                    />
                  }
                  label="Half Day"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            {checked && (
              <Mui.Grid container sx={{ padding: 1, paddingLeft: 2 }}>
                <Mui.Grid item xs={4}></Mui.Grid>
                <Mui.Grid item xs={4}>
                  <Mui.FormControlLabel
                    control={
                      <Mui.Checkbox
                        checked={firstHalfChecked}
                        onChange={handleFirstHalfCheckboxChanged}
                      />
                    }
                    label="1st-half"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: "bold",
                      },
                    }}
                  />
                </Mui.Grid>
                <Mui.Grid item xs={4}>
                  <Mui.FormControlLabel
                    control={
                      <Mui.Checkbox
                        checked={secondHalfChecked}
                        onChange={handleSecondHalfCheckboxChanged}
                      />
                    }
                    label="2nd-half"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: "bold",
                      },
                    }}
                  />
                </Mui.Grid>
              </Mui.Grid>
            )}

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Start Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  size="small"
                  fullWidth
                  type="date"
                  id="from_date"
                  value={startDate}
                  inputProps={{
                    min: leaveStartDate,
                    max: leaveEndDate,
                  }}
                  {...register("from_date", {
                    required: "start date is required.",
                  })}
                  onChange={handleStartDateChange}
                  error={errors.from_date}
                  helperText={errors.from_date && errors.from_date.message}
                  disabled={checkboxChecked}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  End Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  size="small"
                  type="date"
                  id="to_date"
                  fullWidth
                  value={endDate}
                  inputProps={{
                    min: leaveStartDate,
                    max: leaveEndDate,
                  }}
                  {...register("to_date", {
                    required: "End date is required.",
                    validate: {
                      dateNotBeforeStart: validateEndDate,
                    },
                  })}
                  onChange={handleEndDateChange}
                  error={errors.to_date}
                  helperText={errors.to_date && errors.to_date.message}
                  disabled={checked ? checked : false || checkboxChecked}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid item xs={4} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Reason for Leave
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8} sx={{ padding: 1 }}>
                <Mui.TextField
                  fullWidth
                  id="description"
                  {...register("description", {
                    required: "Reason for leave is required",
                  })}
                  placeholder="Provide a reason for your leave"
                  multiline
                  minRows={2}
                  error={errors.description}
                  helperText={errors.description && errors.description.message}
                  onBlur={() => {
                    trigger("description");
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container spacing={3} sx={{ paddingTop: 1 }}>
              <Mui.Grid item xs={8}></Mui.Grid>
              <Mui.Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  padding: 1,
                }}
              >
                <CustomButton
                  actionFuntion={handleClearButtonClick}
                  actionName="Clear"
                  typeName="button"
                />
                &nbsp;&nbsp;
                {buttonLoader ? (
                  <CustomButton
                    disableAction={buttonLoader}
                    type="submit"
                    actionName="Apply"
                  />
                ) : (
                  <Mui.Button
                    sx={{
                      width: "100px",
                      height: "36px",

                      fontWeight: 600,
                      display: "block",
                      backgroundColor: themes.primaryButton,
                      "&:hover": {
                        backgroundColor: themes.primaryButton,
                      },
                      textTransform: "capitalize",
                      color: themes.headLine,
                    }}
                    type="submit"
                    variant="contained"
                    // disabled={
                    //   !isValid ||
                    //   (checked && !selectedHalfDay) ||
                    //   (checked && !firstHalfChecked && !secondHalfChecked) ||
                    //   isLoading
                    // }
                  >
                    Apply
                  </Mui.Button>
                )}
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </>
  );
};
