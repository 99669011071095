import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useFetch } from "services/hooks/useFetch";
import { myZonePermissionHeader } from "../Headers";
import { themes } from "services/constants";
import { Loader } from "components/Loader";
import * as Mui from "@mui/material";
import { tableRowSx } from "services/constants";
const keys = ["date", "from_date", "to_date", "total_time", "status"];

export const History = ({
  startDate,
  endDate,
  setLeaveChange,
  leaveChange,
}) => {
  const { domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const {
    result: permissionDetails,
    loading,
    setLoad: reCallApi,
  } = useFetch(
    `${domain}individual_permisson_request/?user_id=${userDetails?.id}&start_date=${startDate}&end_date=${endDate}`
  );
  const [expandedRow, setExpandedRow] = useState(null);
  const [leavePage, setLeavePage] = useState(0);
  const [page, setPage] = useState(25);
  const [permissionData, setPermissionData] = useState(permissionDetails);

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setPage(parseInt(event.target.permission, 10));
    setLeavePage(0);
  };
  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    const filteredResults = permissionDetails?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setPermissionData(filteredResults);
    setLeavePage(0);
  }, [globalSearchValue, permissionDetails]);

  useEffect(() => {
    if (leaveChange) {
      reCallApi(true);
      setLeaveChange(false);
    }
  }, [leaveChange, reCallApi, setLeaveChange]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Mui.TableContainer component={Mui.Paper} sx={{ mt: 2, width: "100%" }}>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow sx={tableRowSx}>
                {myZonePermissionHeader?.map((value) => (
                  <Mui.TableCell
                    sx={{ color: themes.headLine, fontWeight: "bold" }}
                  >
                    {value?.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {permissionData.length === 0 ? (
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell colSpan={12}>
                    <Mui.Alert severity="info" sx={{ margin: 2 }}>
                      No data available
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                permissionData
                  ?.slice(leavePage * page, leavePage * page + page)
                  .map((permission) => (
                    <>
                      <Mui.TableRow sx={tableRowSx}>
                        {myZonePermissionHeader.map((head) =>
                          head.name !== "" ? (
                            <Mui.TableCell>
                              {permission[head.key]
                                ? permission[head.key]
                                : "N/A"}
                            </Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              {expandedRow === permission.id ? (
                                <Mui.IconButton
                                  onClick={() => {
                                    setExpandedRow(null);
                                  }}
                                >
                                  <KeyboardArrowDownIcon
                                    style={{
                                      color: themes.headLine,
                                      fontWeight: "bold",
                                    }}
                                  />
                                </Mui.IconButton>
                              ) : (
                                <Mui.IconButton
                                  onClick={() => handleRowClick(permission.id)}
                                >
                                  <KeyboardArrowRightIcon
                                    style={{
                                      color: themes.headLine,
                                      fontWeight: "bold",
                                    }}
                                  />
                                </Mui.IconButton>
                              )}
                            </Mui.TableCell>
                          )
                        )}
                      </Mui.TableRow>
                      {expandedRow === permission.id && (
                        <Mui.TableRow>
                          <Mui.TableCell colSpan={12}>
                            {["Description", "Reason for Rejection"].map(
                              (label, index) =>
                                (index === 0 ||
                                  permission.status === "Rejected") && (
                                  <Mui.TableContainer
                                    key={label}
                                    sx={{
                                      mt: index === 1 ? 0.5 : 0,
                                      mx: "auto",
                                      width: "100%",
                                    }}
                                    className="w-96 border rounded"
                                  >
                                    <Mui.Table className="border">
                                      <Mui.Typography
                                        variant="body1"
                                        gutterBottom
                                        sx={{ m: 1 }}
                                      >
                                        <Mui.Grid container>
                                          <Mui.Grid item>
                                            <strong>{label}:</strong>{" "}
                                          </Mui.Grid>
                                          <Mui.Grid item>
                                            <Mui.Typography
                                              fontSize={15}
                                              color="textSecondary"
                                              sx={{ ml: index === 1 ? 2 : 0 }}
                                            >
                                              &nbsp;
                                              {index === 0
                                                ? permission.description
                                                : permission.reason}
                                            </Mui.Typography>
                                          </Mui.Grid>
                                        </Mui.Grid>
                                      </Mui.Typography>
                                    </Mui.Table>
                                  </Mui.TableContainer>
                                )
                            )}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      )}
                    </>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>

          {permissionData?.length > 25 ? (
            <Mui.TablePagination
              sx={{ marginRight: 7 }}
              className="custom-pagination"
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={permissionData?.length}
              rowsPerPage={page}
              page={leavePage}
              onPageChange={handleChangeLeavePage}
              onRowsPerPageChange={handleChangeLeaveRowsPerPage}
            />
          ) : null}
        </Mui.TableContainer>
      </Mui.Grid>
    </React.Fragment>
  );
};
