import * as React from "react";
import PropTypes from "prop-types";
import * as Mui from "@mui/material";
import {Close} from "@mui/icons-material";
import { themes } from "services/constants";

const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    minWidth: "98%",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && title !== "Logout" ? (
        <Mui.IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.headLine }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const Popup = ({ handleClose, open, title, children }) => {
  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ paddingLeft: 30, paddingTop: 20 }}
      >
        <FuncDialogTitle
          id="customized-dialog-title"
          align="center"
          sx={{
            minWidth: "100%",
            color: themes.headLine,
            fontWeight: 1000,
            fontSize: "23px",
            background: themes.primary,
          }}
          onClose={handleClose}
          title={title}
        >
          {title}
        </FuncDialogTitle>
        <Mui.DialogContent>{children}</Mui.DialogContent>
      </BootstrapDialog>
    </div>
  );
};
