import React, { useState } from "react";
import { BackArrow } from "components/CustomComponents/BackArrow";
import * as Mui from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { customAPI } from "services/utils/CustomAPI";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { themes } from "services/constants";
import { Info } from "@mui/icons-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { onlyNumberRegex } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { ShiftDetails } from "./ShiftDetails";
import { formBackGroundColor } from "services/constants/Styles";
import { formHeader } from "services/constants/Styles";
import { formKeyText } from "services/constants/Styles";
import { formRightSideAlign } from "services/constants/Styles";
let shiftTimeDetails = {
  minStartTime: "00:00",
  startTime: "00:00",
  maxStartTime: "00:00",
  minEndTime: "00:00",
  endTime: "00:00",
  maxEndTime: "00:00",
};
let overTimeDetails = {
  startTime: "00:00",
  maxStartTime: "00:00",
  endTime: "00:00",
  maxEndTime: "00:00",
};
export const EditShift = ({
  setCreateShift,
  setShowTopBar,
  setEditShift,
  editShiftDetails,
}) => {
  const [startTimeFormat, setStartTimeFormat] = useState(null);
  const [shiftDurationFormat, setShiftDurationFormat] = useState(null);
  const [shiftName, setShiftName] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [checkInPolicy, setCheckInPolicy] = useState({
    startEarly: "15",
    lateStart: "15",
    earlyEnd: "15",
    endLate: "15",
  });
  const [overTimePolicy, setOverTimePolicy] = useState({
    lateStart: "00",
    earlyEnd: "00",
  });
  const { domain, globalSearchValue, token } = useSelector(
    (state) => state.tokenReducer
  );
  const [lateAbsentMin, setLateAbsentMin] = useState("00");
  const [isBreakAllowed, setIsBreakAllowed] = useState(false);
  // const [startStartEarly, setStartStartEarly] = useState("15");
  // const [endStartDelay, setEndStartDelay] = useState("15");
  // const [startEndEarly, setStartEndEarly] = useState("15");
  // const [endEndDelay, setEndEndDelay] = useState("15");
  const [shiftDuration, setShiftDuration] = useState(null);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = () => {
    console.log("finalSubmission");
  };

  const handleStartTime = (newValue) => {
    if (newValue && newValue.format) {
      const timeWithDate = newValue ? newValue.toDate() : new Date();
      const time = newValue.format("HH:mm");
      setStartTime(time);
      updateWorkingHours(time, shiftDuration, checkInPolicy);
      let minTime = moment.utc(timeWithDate);
      setStartTimeFormat(
        dayjs()
          .set("hour", minTime._d.getHours())
          .set("minute", minTime._d.getMinutes())
          .set("second", minTime._d.getSeconds())
      );
    }
  };

  const handleShiftDuration = (newValue) => {
    if (newValue && newValue.format) {
      const timeWithDate = newValue ? newValue.toDate() : new Date();
      const time = newValue.format("HH:mm");
      setShiftDuration(time);
      let minTime = moment.utc(timeWithDate);

      setShiftDurationFormat(
        dayjs()
          .set("hour", minTime._d.getHours())
          .set("minute", minTime._d.getMinutes())
          .set("second", minTime._d.getSeconds())
      );
    }
  };

  const handleBreak = (event) => {
    setIsBreakAllowed(event.target.checked);
  };

  const handleName = (event) => {
    setShiftName(event.target.value);
  };

  const updateWorkingHours = async (time, shiftDuration, checkin) => {
    const response = await customAPI(`${domain}shift-timings/`, "POST", {
      start_time: startTime,
      duration: shiftDuration,
      start_early: checkInPolicy?.startEarly,
      start_delay: checkInPolicy?.lateStart,
      end_early: checkInPolicy?.earlyEnd,
      end_delay: checkInPolicy?.lateStart,
      ot_start_delay: overTimePolicy?.lateStart,
      ot_end_early: overTimePolicy?.earlyEnd,
    });
    if (response.ok) {
      console.log(response);
      // showToast("success", "User group updated successfully");
      // handleClosePopup();
      // setAnchorEl(null);
      // setButtonLoader(false);
    } else {
      // setAnchorEl(null);
      // setButtonLoader(false);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setCreateShift(false);
            setShowTopBar(true);
            setEditShift(false);
          }}
        />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
        >
          Shift
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 20 }}>
        <Mui.Grid
          container
          sx={{
            paddingTop: "5%",
            paddingLeft: "15%",
            paddingRight: "15%",
            paddingBottom: "5%",
          }}
        >
          <Mui.Grid
            container
            sx={{
              border: `2px solid ${themes.sidebarDivider}`,
              borderRadius: "10px 10px 10px 10px",
            }}
          >
            <Mui.Grid
              xs={12}
              sx={{
                backgroundColor: formBackGroundColor,
                padding: {
                  xs: 0.5,
                  sm: 0.5,
                  md: 0.5,
                  lg: 0.5,
                  xl: 1,
                },
                textAlign: "center",
                borderBottom: `2px solid ${themes.sidebarDivider}`,
              }}
            >
              <Mui.Typography sx={formHeader}>Create Shift</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              container
              sx={{
                padding: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <Mui.Typography
                  sx={formKeyText}
                  // sx={{
                  //   fontSize: 18,
                  //   color: themes.headLine,
                  //   fontWeight: "bold",
                  //   paddingTop: 1.5,
                  //   textAlign: {
                  //     xs: "center",
                  //     sm: "center",
                  //     md: "center",
                  //     lg: "right",
                  //     xl: "right",
                  //   },
                  //   paddingRight: 4,
                  // }}
                >
                  Shift Start Time
                  <Mui.Tooltip title="This is the First Shift field. Choose the first shift of the day, which could be Morning, Afternoon, or Night, depending on the schedule.">
                    &nbsp;
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={formRightSideAlign}
              >
                {/* <Mui.Grid xs={6}> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <div sx={{ width: 4 }}>
                      <TimePicker
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        value={startTimeFormat || null}
                        slotProps={{
                          textField: {
                            size: "small",
                            width: 15,
                          },
                        }}
                        onChange={(newValue) => {
                          handleStartTime(newValue);
                        }}
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
                {/* {(!startTime ||
                  startTime === "00:00" ||
                  startTime === "Invalid Date") &&
                rotationalError ? (
                  <h5 style={{ color: themes.helperTextColor }}>
                    Shift start time is required
                  </h5>
                ) : null} */}
                {/* </Mui.Grid> */}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <Mui.Typography sx={formKeyText}>
                  Shift Duration
                  <Mui.Tooltip title="This is the First Shift field. Choose the first shift of the day, which could be Morning, Afternoon, or Night, depending on the schedule.">
                    &nbsp;
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={formRightSideAlign}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <div sx={{ width: 4 }}>
                      <TimePicker
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        value={shiftDurationFormat || null}
                        slotProps={{
                          textField: {
                            size: "small",
                            width: 15,
                          },
                        }}
                        onChange={(newValue) => {
                          handleShiftDuration(newValue);
                        }}
                      />
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
              </Mui.Grid>
            </Mui.Grid>
            {/* check in check out */}
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={{ paddingTop: 1.5 }}
              >
                <Mui.Typography sx={formKeyText}>
                  Checkin/Out Policy (Min)&nbsp;
                  <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={formRightSideAlign}
              >
                <Mui.Grid container>
                  <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Mui.Grid container>
                      <Mui.Grid xs={5.3}>
                        <Mui.Typography
                          sx={{
                            padding: 0.5,
                            textAlign: "center",
                            backgroundColor: themes.primary,
                          }}
                        >
                          Start Early
                        </Mui.Typography>
                        <Mui.Grid container sx={{ paddingTop: 0.3 }}>
                          <Mui.TextField
                            size="small"
                            value={checkInPolicy?.startEarly}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            onChange={(event) => {
                              if (
                                onlyNumberRegex.test(event.target.value) &&
                                event.target.value.length <= 2
                              ) {
                                setCheckInPolicy({
                                  ...checkInPolicy,
                                  startEarly: event.target.value,
                                });
                              }
                            }}
                            fullWidth
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Grid xs={0.6}></Mui.Grid>
                      <Mui.Grid xs={5.3}>
                        <Mui.Typography
                          sx={{
                            padding: 0.5,
                            textAlign: "center",
                            backgroundColor: themes.primary,
                          }}
                        >
                          Late Start
                        </Mui.Typography>
                        <Mui.Grid container sx={{ paddingTop: 0.3 }}>
                          <Mui.TextField
                            size="small"
                            value={checkInPolicy?.lateStart}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            onChange={(event) => {
                              if (
                                onlyNumberRegex.test(event.target.value) &&
                                event.target.value.length <= 2
                              ) {
                                setCheckInPolicy({
                                  ...checkInPolicy,
                                  lateStart: event.target.value,
                                });
                              }
                            }}
                            fullWidth
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Mui.Grid container>
                      <Mui.Grid xs={5.3}>
                        <Mui.Typography
                          sx={{
                            padding: 0.5,
                            textAlign: "center",
                            backgroundColor: themes.primary,
                          }}
                        >
                          Early End
                        </Mui.Typography>
                        <Mui.Grid container sx={{ paddingTop: 0.3 }}>
                          <Mui.TextField
                            size="small"
                            value={checkInPolicy?.earlyEnd}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            onChange={(event) => {
                              if (
                                onlyNumberRegex.test(event.target.value) &&
                                event.target.value.length <= 2
                              ) {
                                setCheckInPolicy({
                                  ...checkInPolicy,
                                  earlyEnd: event.target.value,
                                });
                              }
                            }}
                            fullWidth
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Grid xs={0.6}></Mui.Grid>
                      <Mui.Grid xs={5.3}>
                        <Mui.Typography
                          sx={{
                            padding: 0.5,
                            textAlign: "center",
                            backgroundColor: themes.primary,
                          }}
                        >
                          End&nbsp; Late
                        </Mui.Typography>
                        <Mui.Grid container sx={{ paddingTop: 0.3 }}>
                          <Mui.TextField
                            size="small"
                            value={checkInPolicy?.endLate}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            onChange={(event) => {
                              if (
                                onlyNumberRegex.test(event.target.value) &&
                                event.target.value.length <= 2
                              ) {
                                setCheckInPolicy({
                                  ...checkInPolicy,
                                  endLate: event.target.value,
                                });
                              }
                            }}
                            fullWidth
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            {/* close of check in check out*/}
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <Mui.Typography sx={formKeyText}>
                  OverTime Shift Hours Policy (Min)&nbsp;
                  <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                    <Info />
                  </Mui.Tooltip>
                  <br />
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.redColor,
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "right",
                      xl: "right",
                    },
                    paddingRight: 7,
                  }}
                >
                  (If Applicable)
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <Mui.Grid container>
                  <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Mui.Grid container>
                      <Mui.Grid xs={5.3}>
                        <Mui.Typography
                          sx={{
                            padding: 0.5,
                            textAlign: "center",
                            backgroundColor: themes.primary,
                          }}
                        >
                          Late Start
                        </Mui.Typography>
                        <Mui.Grid container sx={{ paddingTop: 0.3 }}>
                          <Mui.TextField
                            size="small"
                            value={overTimePolicy?.lateStart}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            onChange={(event) => {
                              if (
                                onlyNumberRegex.test(event.target.value) &&
                                event.target.value.length <= 2
                              ) {
                                setOverTimePolicy({
                                  ...overTimePolicy,
                                  lateStart: event.target.value,
                                });
                              }
                            }}
                            fullWidth
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Grid xs={0.6}></Mui.Grid>
                      <Mui.Grid xs={5.3}>
                        <Mui.Typography
                          sx={{
                            padding: 0.5,
                            textAlign: "center",
                            backgroundColor: themes.primary,
                          }}
                        >
                          Early End
                        </Mui.Typography>
                        <Mui.Grid container sx={{ paddingTop: 0.3 }}>
                          <Mui.TextField
                            size="small"
                            value={overTimePolicy?.earlyEnd}
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            onChange={(event) => {
                              if (
                                onlyNumberRegex.test(event.target.value) &&
                                event.target.value.length <= 2
                              ) {
                                setOverTimePolicy({
                                  ...overTimePolicy,
                                  earlyEnd: event.target.value,
                                });
                              }
                            }}
                            fullWidth
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}></Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            {/* end of over time*/}
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <Mui.Typography sx={formKeyText}>
                  Is lateness Marked as Absent &nbsp;
                  <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={formRightSideAlign}
              >
                <Mui.Switch
                  checked={isBreakAllowed}
                  onChange={handleBreak}
                  inputProps={{ "aria-label": "controlled" }}
                />
                {isBreakAllowed ? (
                  <Mui.Grid
                    container
                    sx={{
                      border: `2px solid ${themes.sidebarDivider}`,
                      padding: 2,
                      borderRadius: 2,
                    }}
                  >
                    <Mui.Grid xs={9.5}>
                      <Mui.Typography
                        sx={{
                          padding: 0.5,
                        }}
                      >
                        Maximum lateness duration being considered as absent
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={2.5}>
                      <Mui.TextField
                        size="small"
                        value={lateAbsentMin}
                        inputProps={{
                          style: { textAlign: "center" },
                        }}
                        onChange={(event) => {
                          if (
                            onlyNumberRegex.test(event.target.value) &&
                            event.target.value.length <= 2
                          ) {
                            setLateAbsentMin(event.target.value);
                          }
                        }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            {/* end of late absent*/}
            <Mui.Grid container>
              <Mui.Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <Mui.Typography sx={formKeyText}>
                  Shift Name &nbsp;
                  <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={formRightSideAlign}
              >
                <CustomTextBox
                  selectedValue={shiftName}
                  updateDataFunction={handleName}
                  placeHolder="Enter shift category name"
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 2 }}>
              <ShiftDetails
                shiftName={shiftName}
                shiftTimeDetails={shiftTimeDetails}
                overTimeDetails={overTimeDetails}
              />
            </Mui.Grid>
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                padding: 1,
              }}
            >
              <CustomButton
                actionName="Create"
                typeName="submit"
                // disableAction={buttonDisabled}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
