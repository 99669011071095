import React from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { EmployeeList } from "./EmployeeList";
export const ViewPayRollGroup = ({
  setDisplayScreen,
  companyId,
  actionRowData,
  setAddAction,
  reCallApi,
}) => {
  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingBottom: 1,
        }}
      >
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => {
              setDisplayScreen("ListView");
              setAddAction(false);
              reCallApi(true);
            }}
          />
          <Mui.Typography
            sx={{
              paddingLeft: 1,
              fontSize: 20,
              fontWeight: "bold",
              paddingTop: 1,
            }}
          >
            Payroll Group
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Mui.Grid
          container
          sx={{ backgroundColor: themes.primary, borderRadius: 2, padding: 2 }}
        >
          <Mui.Grid
            xs={3}
            sx={{
              borderRadius: `2px solid ${themes.sidebarDivider}`,
            }}
          >
            <Mui.Stack>
              <Mui.Typography
                sx={{
                  paddingBottom: 2,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Payroll Group Name
              </Mui.Typography>
              <Mui.Typography sx={{ textAlign: "center" }}>
                <Mui.Tooltip title={actionRowData?.name}>
                  {actionRowData?.name?.length > 40
                    ? actionRowData?.name.substr(0, 40) + "..."
                    : actionRowData?.name}
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid
            xs={3}
            sx={{ borderRadius: `2px solid ${themes.sidebarDivider}` }}
          >
            {" "}
            <Mui.Stack>
              <Mui.Typography
                sx={{
                  paddingBottom: 2,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Payrun Cycle Name
              </Mui.Typography>
              <Mui.Typography sx={{ textAlign: "center" }}>
                <Mui.Tooltip title={actionRowData?.pay_run_cycle_name}>
                  {actionRowData?.pay_run_cycle_name?.length > 40
                    ? actionRowData?.pay_run_cycle_name.substr(0, 40) + "..."
                    : actionRowData?.pay_run_cycle_name}
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid
            xs={2}
            sx={{ borderRadius: `2px solid ${themes.sidebarDivider}` }}
          >
            {" "}
            <Mui.Stack>
              <Mui.Typography
                sx={{
                  paddingBottom: 2,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Payrun Cycle Type
              </Mui.Typography>
              <Mui.Typography sx={{ textAlign: "center" }}>
                <Mui.Tooltip title={actionRowData?.pay_run_cycle_type}>
                  {actionRowData?.pay_run_cycle_type?.length > 40
                    ? actionRowData?.pay_run_cycle_type.substr(0, 40) + "..."
                    : actionRowData?.pay_run_cycle_type}
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={2}>
            <Mui.Stack>
              <Mui.Typography
                sx={{
                  paddingBottom: 2,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Payment Bank
              </Mui.Typography>
              <Mui.Typography sx={{ textAlign: "center" }}>
                <Mui.Tooltip title={actionRowData?.source_bank_detail_name}>
                  {actionRowData?.source_bank_detail_name?.length > 40
                    ? actionRowData?.source_bank_detail_name.substr(0, 40) +
                      "..."
                    : actionRowData?.source_bank_detail_name}
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid
            xs={2}
            sx={{ borderRadius: `2px solid ${themes.sidebarDivider}` }}
          >
            <Mui.Stack>
              <Mui.Typography
                sx={{
                  paddingBottom: 2,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Payment Mode
              </Mui.Typography>
              <Mui.Typography sx={{ textAlign: "center" }}>
                {actionRowData?.mode_of_payment}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Typography
          sx={{ fontSize: 20, fontWeight: "bold", paddingLeft: 1 }}
        >
          Employee List
        </Mui.Typography>
        <EmployeeList actionRowData={actionRowData} companyId={companyId} />
      </Mui.Grid>
    </React.Fragment>
  );
};
