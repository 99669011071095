import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { customAPI } from "services/utils/CustomAPI";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { Loader } from "components/Loader";
import sessionHandling from "services/utils/notificationUtils";
import { IndividualSftGrpMemberHeader } from "pages/Administration/AdministrationHeaders";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import dayjs from "dayjs";
import { tableRowSx } from "services/constants";

const keys = [
  "id",
  "employee_id",
  "username",
  "username",
  "department_name",
  "shift_category_id",
  "shift_category_name",
];

export const EditShiftGroup = ({
  reCallApi,
  setShowTopBar,
  setEditGroup,
  editShiftGroup,
  departmentId,
  setDepartmentId,
  addEmployee,
  setAddEmployee,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    // trigger,
    // getValues,
    // formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [shiftCategoryName, setShiftCategoryName] = useState(
    editShiftGroup.shift_category_name
  );
  const [departmentApi, setDepartmentApi] = useState(true);
  const [nameApi, setNameApi] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [shiftCategoryId, setShiftCategoryId] = useState(
    editShiftGroup.shift_category_id
  );
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // const [departmentError, setDepartmentError] = useState(false);
  // const [userError, setUserError] = useState(false);
  const [autoGeneratedName, setAutoGeneratedName] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [incentiveDetailsStateData, setIncentiveStateData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [shiftGroup, setShiftGroup] = useState([]);
  const [grpName, setGrpName] = useState("");
  const [grpId, setGrpId] = useState("");
  const { result: membersData, setLoad: reCallMemberApi } = useFetch(
    `${domain}unassign-shift-group-user/?department_ids=[${departmentId}]`
  );
  const [error, setError] = useState("");
  const today = dayjs().format("YYYY-MM-DD");

  const { result: userData, loading } = useFetch(
    `${domain}user-shift-group/?shift_group_id=${editShiftGroup.id}`
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const [memberApiUrl, setMemberApiUrl] = useState("");
  // const { result: membersData, setLoad: reCallMemberApi } =
  //   useFetch(memberApiUrl);

  // useEffect(() => {
  //   if (departmentId.length !== 0 && departmentList.length > 0) {
  //     const initialDepartmentIds = departmentList
  //       .map((dept) => dept.id)
  //       .join(",");
  //     setMemberApiUrl(
  //       `${domain}get-department-based-user/?department_ids=[${initialDepartmentIds}]`
  //     );
  //     reCallMemberApi(true);
  //   }
  // }, [departmentId.length, departmentList, domain, reCallMemberApi]);

  useEffect(() => {
    setValue("shift_category_name ", editShiftGroup?.shift_category_name);
    setValue("shift_category_id", editShiftGroup?.shift_category_id);
    setValue("name", editShiftGroup?.name);
    setShiftCategoryName(editShiftGroup?.shift_category_name);
    setAutoGeneratedName(editShiftGroup?.name);
    const departments =
      editShiftGroup.departments?.map((dept) => ({
        id: dept.id,
        department_name: dept.department,
      })) || [];
    const users =
      editShiftGroup?.departments
        ?.flatMap((dept) => dept.users || [])
        .map((user) => ({
          user_id: user.id,
          user_name: user.username,
        })) || [];
    setSelectedDepartments(departments);
    setValue("departments", departments);
    setSelectedUsers(users);
    setValue("users", users);
  }, [
    editShiftGroup.departments,
    editShiftGroup?.name,
    editShiftGroup?.shift_category_id,
    editShiftGroup?.shift_category_name,
    setValue,
  ]);

  const handleBlur = () => {
    if (selectedDepartments.length > 0) {
      let data = [];
      selectedDepartments.forEach((element) => {
        data.push(element.id);
      });
      setDepartmentId(data);
      setTimeout(function () {
        reCallMemberApi(true);
      }, 300);
    }
    // else {
    //   setDepartmentError(true);
    // }
  };

  // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  // const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // const validateDepartments = () => {
  //   if (!selectedDepartments || selectedDepartments.length === 0) {
  //     setDepartmentError(true);
  //     return false;
  //   } else if (!selectedUsers || selectedUsers.length === 0) {
  //     setUserError(true);
  //     return false;
  //   }
  //   setDepartmentError(false);
  //   setUserError(false);
  //   return true;
  // };

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await fetch(
          `${domain}department-based-shiftcategory/?shift_category_id=${shiftCategoryId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentList(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (departmentApi && shiftCategoryId) {
      fetchDepartment();
      setDepartmentApi(false);
    }
  }, [departmentApi, domain, shiftCategoryId, token]);

  const onSubmit = async (data) => {
    if (data) {
      setButtonDisabled(true);
      let departmentId = [];
      selectedDepartments.forEach((element) => {
        departmentId.push(element.id);
      });

      let userID = [];
      selectedUsers.forEach((element) => {
        userID.push(element.user_id);
      });

      let payload = {
        ...data,
        departments: departmentId,
        users: userID,
        shift_group_id: editShiftGroup?.id,
        name: autoGeneratedName,
      };
      const response = await customAPI(`${domain}shift-group/`, "PUT", payload);
      if (response.ok) {
        showToast("success", "Shift group updated successfully");
        setEditGroup(false);
        setShowTopBar(true);
        reCallApi(true);
        reset();
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const moveShiftGrp = async () => {
    if (grpId === "") {
      setError("Shift group name is required");
      setButtonDisabled(false);
      return;
    }
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}move-shift-group/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          old_shift_group_id: editShiftGroup?.id,
          new_shift_group_id: grpId,
          user_ids: [selectedEmployee?.id],
          effective_date: today,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "User moved successfully");
        setOpenDialog(false);
        reCallApi(true);
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const updateCategoryName = (event, data) => {
    if (data) {
      setValue("shift_category", data?.label);
      setValue("shift_category_id", data?.shift_category_id);
      setShiftCategoryId(data?.shift_category_id);
      setShiftCategoryName(data?.label);
      setSelectedDepartments([]);
      setSelectedUsers([]);
      setDepartmentApi(true);
      setNameApi(true);
    }
  };

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await fetch(
          `${domain}create-shift-group-name/?shift_category_id=${shiftCategoryId}&shift_category_name=${shiftCategoryName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setAutoGeneratedName(res.shift_group_name);
          setValue("shift_group_name", res.shift_group_name);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (nameApi && shiftCategoryId) {
      fetchDepartment();
      setNameApi(false);
    }
  }, [domain, nameApi, setValue, shiftCategoryId, shiftCategoryName, token]);

  // const handleDepartmentChange = (event, value) => {
  //   const selectedDeptIds = value.map((item) => item.id); // Ensure matching `id`
  //   setSelectedDepartments(selectedDeptIds);
  //   setDepartmentError(false);

  //   if (selectedDeptIds.length > 0) {
  //     setMemberApiUrl(
  //       `${domain}get-department-based-user/?department_ids=[${selectedDeptIds.join(
  //         ","
  //       )}]`
  //     );
  //     reCallMemberApi(true);
  //   }
  // };

  const nameUpdate = (event) => {
    if (event.target.value.length < 32) {
      setValue("name", event.target.value);
      setAutoGeneratedName(event.target.value);
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (updateData && userData?.length !== 0 && userData) {
      setIncentiveStateData(userData);
      setUpdateData(false);
    }
  }, [userData, updateData]);

  useEffect(() => {
    if (userData?.length !== 0 || userData?.length === 0) {
      const filteredResults = userData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setIncentiveStateData(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, userData]);

  const handleEmployeeDetails = (employeeDetails) => {
    setSelectedEmployee(employeeDetails);
  };

  useEffect(() => {
    const fetchShiftGroup = async () => {
      try {
        const response = await fetch(
          `${domain}shift-group-based-shift-category/?shift_category_id=${editShiftGroup.shift_category_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setShiftGroup(res);
          // setGrpName(res.length !== 0 ? res[0]?.shift_group_name : "");
          // setGrpId(res.length !== 0 ? res[0]?.id : 99999);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (editShiftGroup.shift_category_id) {
      fetchShiftGroup();
    }
  }, [domain, editShiftGroup.id, editShiftGroup.shift_category_id, token]);

  const handlAssignedTo = (event, data) => {
    if (data) {
      setValue("id", data?.id);
      setGrpName(data?.label);
      setValue("name", data?.label);
      setGrpId(data?.id);
    } else {
      setValue("name", "");
      setValue("id", null);
      setGrpName("");
    }
  };

  return (
    <>
      <Mui.Grid container>
        <Mui.Grid>
          <BackArrow
            actionFunction={() => {
              setEditGroup(false);
              setShowTopBar(true);
              reCallApi(true);
            }}
          />
        </Mui.Grid>
        <Mui.Grid>
          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
          >
            Shift Group / Edit Shift Group
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 20 }}>
        <Mui.Grid container>
          <Mui.Grid container elevation={5}>
            <Mui.Grid container>
              <Mui.Grid
                container
                fullWidth
                sx={{ padding: 1, borderRadius: 3 }}
              >
                <Mui.Grid item xs={2} sx={{ paddingTop: 1.5 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      fontWeight: "bold",
                    }}
                  >
                    Shift Category &nbsp;
                    <Mui.Tooltip title="This is the Shift Category field. Update the selected Shift Category by choosing from the list of already created categories.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
                  :
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.Box
                    sx={{
                      width: 500,
                      height: 15,
                      backgroundColor: themes.whiteColor,
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: themes.headLine,
                      p: 2,
                      fontSize: 18,
                      border: `2px solid ${themes.sidebarDivider}`,
                    }}
                  >
                    {shiftCategoryName}
                  </Mui.Box>
                </Mui.Grid>
                {/* <Mui.Grid item xs={6}>
                  <CustomAutoComplete
                    dataList={shiftCategoryList?.map((obj) => ({
                      ...obj,
                      label: obj?.shift_category_name,
                    }))}
                    // rules={{
                    //   ...register("shift_category_name", {
                    //     required: "Shift category name is required",
                    //   }),
                    // }}
                    selectedValue={shiftCategoryName}
                    updateDataFunction={updateCategoryName}
                    placeHolder="Select shift category"
                    errorMessage={
                      errors?.shift_category_name?.message
                        ? errors?.shift_category_name?.message
                        : ""
                    }
                    textBoxError={errors?.shift_category_name ? true : false}
                  />
                </Mui.Grid> */}
              </Mui.Grid>

              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid item xs={2} sx={{ paddingTop: 2 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Department &nbsp;
                    <Mui.Tooltip title="This is the Department field. Modify the department associated with this shift group, ensuring it aligns with the current organizational structure.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 3 }}>
                  :
                </Mui.Grid>
                {/* <Mui.Grid xs={6}>
                  <Mui.Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    size="small"
                    value={selectedDepartments}
                    options={departmentList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.department_name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => {
                      setSelectedDepartments(value);
                      // setDepartmentError(false);
                      reCallMemberApi(true);
                    }}
                    onBlur={handleBlur}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Mui.Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.department_name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        multiline
                        rows={3}
                        maxRows={5}
                        placeholder={
                          selectedDepartments.length === 0
                            ? "Add department"
                            : ""
                        }
                        // error={departmentError}
                        // helperText={
                        //   departmentError
                        //     ? "Select at least one department"
                        //     : ""
                        // }
                      />
                    )}
                    disabled
                  />
                </Mui.Grid> */}
                <Mui.Grid xs={6}>
                  {/* <Mui.Box
                    sx={{
                      width: 500,
                      height: 15,
                      backgroundColor: themes.whiteColor,
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: themes.headLine,
                      p: 2,
                      fontSize: 19,
                      border: `2px solid ${themes.sidebarDivider}`,
                    }}
                  > */}
                  <Mui.Stack direction="row">
                    {selectedDepartments.map((department) => (
                      <Mui.Box
                        key={department.id}
                        sx={{
                          backgroundColor: themes.selectedItemColor,
                          borderRadius: "2px",
                          margin: "2px",
                          fontSize: "16px",
                        }}
                      >
                        <Mui.Chip label={department.department_name} />
                      </Mui.Box>
                    ))}
                  </Mui.Stack>
                  {/* </Mui.Box> */}
                </Mui.Grid>
              </Mui.Grid>
              {/* <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid item xs={2} sx={{ paddingTop: 2 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Employee &nbsp;
                    <Mui.Tooltip
                      title="This is the Employee field. Add or remove employees from this shift group as needed to ensure accurate group assignments.
"
                    >
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>

                <Mui.Grid item xs={1} sx={{ paddingTop: 2 }}>
                  :
                </Mui.Grid>
                <Mui.Grid item xs={6}>
                  <Mui.Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    size="small"
                    options={membersData}
                    value={selectedUsers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.user_name}
                    isOptionEqualToValue={(option, value) =>
                      option.user_id === value.user_id
                    }
                    onChange={(event, value) => {
                      setSelectedUsers(value);
                      // setUserError(false);
                    }}
                    // onBlur={() => {
                    //   if (!selectedUsers.length) {
                    //     setUserError(true);
                    //   }
                    // }}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Mui.Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.user_name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        multiline
                        rows={3}
                        maxRows={5}
                        placeholder={
                          selectedUsers.length === 0 ? "Add user" : ""
                        }
                        // error={userError}
                        // helperText={userError ? "Select at least one user" : ""}
                      />
                    )}
                    disabled
                  />
                </Mui.Grid>
              </Mui.Grid> */}
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid xs={2}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Name &nbsp;
                    <Mui.Tooltip
                      title="This is the Name field. Update the shift group name, ensuring it follows the format (e.g., 0400hr-2wo-10:00-2-P-ShiftGroup-C).
"
                    >
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                  :
                </Mui.Grid>
                {/* <Mui.Grid xs={6}>
                  <CustomTextBox
                    selectedValue={autoGeneratedName}
                    rules={{
                      ...register("name", {
                        required: "Shift group name is required",
                        // validate: {
                        //   shiftGradeAviliable: async (shiftName) => {
                        //     if (shiftName !== "") {
                        //       const response = await fetch(
                        //         `${domain}check-shiftgroup-name/?shift_group_name=${shiftName}`,
                        //         {
                        //           method: "GET",
                        //           headers: {
                        //             "Content-Type": "application/json",
                        //             Authorization: `token ${token}`,
                        //           },
                        //         }
                        //       );
                        //       const resp = await response.json();
                        //       if (resp.exists) {
                        //         return "Shift group name already exists";
                        //       }
                        //     }
                        //   },
                        // },
                      }),
                    }}
                    updateDataFunction={nameUpdate}
                    errorMessage={
                      errors?.name?.message ? errors?.name?.message : ""
                    }
                    textBoxError={errors.name ? true : false}
                  />
                </Mui.Grid> */}
                <Mui.Grid xs={6}>
                  <Mui.Box
                    sx={{
                      width: 500,
                      height: 15,
                      backgroundColor: themes.whiteColor,
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: themes.headLine,
                      p: 2,
                      fontSize: 18,
                      border: `2px solid ${themes.sidebarDivider}`,
                    }}
                  >
                    {autoGeneratedName}
                  </Mui.Box>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1, paddingTop: 3 }}>
                <Mui.Grid xs={2}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Employee &nbsp;
                    <Mui.Tooltip title="This is the Name field. Edit the name of the shift group to better represent its purpose, such as 'Updated Night Shift' or 'Team A Rotational Shift.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={8.8}></Mui.Grid>
                <Mui.Grid xs={1.2}>
                  <CustomButton
                    actionName="Add Employee"
                    typeName="button"
                    actionFuntion={() => {
                      setAddEmployee(true);
                    }}
                  />
                </Mui.Grid>
              </Mui.Grid>

              {/* <Mui.Grid
                container
                sx={{
                  paddingTop: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 10,
                }}
              >
                <CustomButton
                  actionName="Cancel"
                  typeName="button"
                  actionFuntion={() => {
                    setEditGroup(false);
                    setShowTopBar(true);
                  }}
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName="Update"
                  // actionFuntion={() => {
                  //   if (!selectedDepartments.length) {
                  //     setDepartmentError(true);
                  //   } else if (!selectedUsers.length) {
                  //     setUserError(true);
                  //   }
                  // }}
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid> */}
            </Mui.Grid>
            {loading ? (
              <Loader />
            ) : (
              <Mui.TableContainer sx={{ mt: 2, width: "100%", paddingLeft: 1 }}>
                <Mui.Table sx={tableRowSx}>
                  <Mui.TableHead
                    sx={{
                      backgroundColor: themes.primary,
                      position: "sticky",
                      top: -1,
                      zIndex: 10,
                    }}
                  >
                    <Mui.TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "10px",
                          padding: "6px 30px",
                        },
                      }}
                    >
                      {IndividualSftGrpMemberHeader.map((head) => (
                        <Mui.TableCell
                          sx={{ color: themes.headLine, fontWeight: "bold" }}
                        >
                          {head.name}
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {incentiveDetailsStateData?.length === 0 ? (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={8}>
                          <Mui.Alert
                            severity="info"
                            sx={{ width: "100%", margin: 2 }}
                          >
                            No data aviliable
                          </Mui.Alert>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ) : (
                      incentiveDetailsStateData
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((account, index) => (
                          <React.Fragment>
                            <Mui.TableRow
                              sx={{
                                "& .MuiTableCell-root": {
                                  height: "10px",
                                  padding: "6px 35px",
                                },
                              }}
                            >
                              {IndividualSftGrpMemberHeader.map((head) =>
                                head.name === "Action" ? (
                                  <Mui.TableCell>
                                    <Mui.IconButton
                                      onClick={() => {
                                        setOpenDialog(true);
                                        handleEmployeeDetails(account);
                                        // handleMenuClose();
                                        // setEditShiftGroup(account);
                                      }}
                                    >
                                      <MoveDownIcon sx={{ fontSize: "10" }} />
                                    </Mui.IconButton>
                                  </Mui.TableCell>
                                ) : (
                                  <Mui.TableCell>
                                    {account[head.key]
                                      ? account[head.key]
                                      : "N/A"}
                                  </Mui.TableCell>
                                )
                              )}
                            </Mui.TableRow>
                          </React.Fragment>
                        ))
                    )}
                  </Mui.TableBody>
                </Mui.Table>
              </Mui.TableContainer>
            )}
            <Mui.Grid
              container
              sx={{
                paddingTop: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              {incentiveDetailsStateData?.length > 25 && (
                <Mui.TablePagination
                  className="custom-pagination"
                  component="div"
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  count={incentiveDetailsStateData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Mui.Grid>
          </Mui.Grid>

          <GlobalDialogBox
            handleCloseDialog={handleCloseDialogBox}
            open={openDialog}
            title="Move Employee"
            minWidth={700}
          >
            <Mui.Grid containter padding={2}>
              <Mui.Grid container>
                <Mui.Grid item xs={3}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 17.5 }}>
                    Employee Name
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 0.5 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item sx={{ paddingTop: 0.5 }}>
                  <Mui.Typography>{selectedEmployee?.username}</Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.Grid item xs={3}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 17.5 }}>
                    Shift Category
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 0.5 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item sx={{ paddingTop: 0.5 }}>
                  <Mui.Typography>
                    {selectedEmployee?.shift_category_name}
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.Grid item xs={3}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 17.5 }}>
                    Department
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 0.5 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item sx={{ paddingTop: 0.5 }}>
                  <Mui.Typography>
                    {selectedEmployee?.department_name}
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.Grid item xs={3}>
                  <Mui.Typography sx={{ fontWeight: "bold", fontSize: 17.5 }}>
                    Shift Group
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 0.5 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item sx={{ paddingTop: 0.5 }}>
                  <Mui.Typography>{selectedEmployee?.username}</Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container padding={2}>
              <Mui.Grid item xs={3}>
                <Mui.Typography sx={{ fontWeight: "bold", fontSize: 17.5 }}>
                  New Shift Group
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={1}>
                {" "}
                :
              </Mui.Grid>
              <Mui.Grid item xs={7}>
                <CustomAutoComplete
                  dataList={shiftGroup
                    .filter(
                      (obj) => obj.shift_group_name !== editShiftGroup.name
                    )
                    .map((obj) => ({
                      ...obj,
                      label: obj.shift_group_name,
                    }))}
                  updateDataFunction={handlAssignedTo}
                  disablePortal
                  PopperProps={{
                    modifiers: [
                      {
                        name: "preventOverflow",
                        options: {
                          boundary: "viewport",
                        },
                      },
                      {
                        name: "flip",
                        options: {
                          fallbackPlacements: [],
                        },
                      },
                    ],
                  }}
                  placeHolder="Select the shift group name"
                />
                {error && (
                  <Mui.FormHelperText error>{error}</Mui.FormHelperText>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 1,
                }}
              >
                <CustomButton
                  actionFuntion={() => setOpenDialog(false)}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={() => moveShiftGrp()}
                  actionName="Move"
                  typeName="button"
                  disableAction={
                    openDialog && buttonDisabled ? buttonDisabled : false
                  }
                />
              </Mui.Grid>
            </Mui.Grid>
          </GlobalDialogBox>
        </Mui.Grid>
      </form>
    </>
  );
};
