import React, { useState, useEffect } from "react";
import { themes, tableContainerSxWithCompany } from "services/constants";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { earningHeaders } from "pages/PayRoll/PayRollHeaders";
import { MoreVert } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Loader } from "components/Loader";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
const keys = ["name"];
let multiApiCallRestriction = true;
export const Earning = ({
  companyId,
  selectedComponent,
  reloadData,
  setReloadData,
  currentScreen,
  companyChange,
  setCompanyChange,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [editEarning, setEditEarning] = useState(null);
  const [earningDetails, setEarningDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [deleteEarning, setDeleteEarning] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const {
    result: earningData,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}earnings/?company=${companyId}` : null
  );

  useEffect(() => {
    if (reloadData && selectedComponent === "Earning") {
      reCallApi(true);
      setReloadData(false);
    }
  });

  useEffect(() => {
    if (
      companyChange &&
      currentScreen === "Salary Component" &&
      selectedComponent === "Earning"
    ) {
      reCallApi(true);
      setCompanyChange(false);
    }
  }, [
    companyChange,
    currentScreen,
    reCallApi,
    selectedComponent,
    setCompanyChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async (data) => {
    if (multiApiCallRestriction) {
      setButtonLoader(true);
      multiApiCallRestriction = false;
      try {
        const response = await fetch(`${domain}earnings/${editEarning.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: data.component_name,
            company: companyId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setOpenDialog(false);
          handleCloseDialogAndDetails();
          showToast("success", res.message);
          multiApiCallRestriction = true;
          reCallApi(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        multiApiCallRestriction = true;
      } finally {
        setButtonLoader(false);
      }
    }
  };

  const updateComponentName = (event) => {
    if (event.target.value.length <= 64) {
      setComponentName(event.target.value);
      setValue("component_name", event.target.value);
      clearErrors("component_name");
    }
  };

  const handleCloseDialogAndDetails = () => {
    setOpenDialog(false);
    setTimeout(function () {
      setComponentName("");
      reset();
    }, 100);
  };

  useEffect(() => {
    if (updateData && earningData?.length !== 0 && earningData) {
      setEarningDetails(earningData);
      setUpdateData(false);
    }
  }, [earningData, updateData]);

  useEffect(() => {
    if (earningData?.length !== 0 || earningData?.length === 0) {
      const filteredResults = earningData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setEarningDetails(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [earningData, globalSearchValue, page]);

  const handleEarningDelete = async () => {
    setButtonLoader(true);
    setTimeout(() => null, 1000);
    try {
      const response = await fetch(`${domain}earnings/${editEarning.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setDeleteEarning(false);
        showToast("success", res.message);
        reCallApi(true);
        setButtonLoader(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1 }}>
        <Mui.TableContainer sx={tableContainerSxWithCompany}>
          <Mui.Table sx={{ border: `3px solid ${themes.sidebarDivider}` }}>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "10px",
                    padding: "3px 15px",
                  },
                }}
              >
                {earningHeaders.map((data) => (
                  <Mui.TableCell
                    sx={{ color: themes.headLine, fontWeight: "bold" }}
                  >
                    {data?.header}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {earningDetails?.length === 0 ? (
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "3px 15px",
                    },
                  }}
                >
                  <Mui.TableCell colSpan={3}>
                    <Mui.Alert
                      severity="info"
                      sx={{ width: "100%", margin: 2 }}
                    >
                      No data found
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                earningDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((earning, index) => (
                    <React.Fragment>
                      <Mui.TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "3px 15px",
                          },
                        }}
                      >
                        {earningHeaders.map((data) =>
                          data.header !== "Action" ? (
                            <Mui.TableCell>{earning[data.key]}</Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              <Mui.IconButton
                                onClick={(e) => {
                                  setComponentName(earning.name);
                                  setValue("component_name", earning.name);
                                  setAnchorEl(e.currentTarget);
                                  setEditEarning(earning);
                                }}
                                disabled={
                                  earning.name !== "Basic" &&
                                  earning.name !== "Special Allowance"
                                    ? false
                                    : true
                                }
                              >
                                <MoreVert
                                  sx={{
                                    color:
                                      earning.name !== "Basic" &&
                                      earning.name !== "Special Allowance"
                                        ? themes.headLine
                                        : themes.backgroundShadow,
                                    strokeWidth:
                                      earning.name !== "Basic" &&
                                      earning.name !== "Special Allowance"
                                        ? 2
                                        : 0,
                                  }}
                                />
                              </Mui.IconButton>
                              <Mui.Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                elevation={1}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <Mui.MenuItem
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setAnchorEl(null);
                                  }}
                                >
                                  Edit
                                </Mui.MenuItem>
                                <Mui.MenuItem
                                  onClick={() => {
                                    setDeleteEarning(true);
                                    setAnchorEl(null);
                                  }}
                                >
                                  Delete
                                </Mui.MenuItem>
                              </Mui.Menu>
                            </Mui.TableCell>
                          )
                        )}
                      </Mui.TableRow>
                    </React.Fragment>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={() => handleCloseDialogAndDetails()}
        open={openDialog}
        title="Edit Earning"
        minWidth={600}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Component Name</Mui.Typography>
            <CustomTextBox
              selectedValue={componentName}
              rules={{
                ...register("component_name", {
                  required: "Component name is required",
                  minLength: {
                    value: 3,
                    message: "Minium length is 3 characters",
                  },
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    uniqueNameCheck: async (value) => {
                      if (
                        errors?.component_name?.message ===
                        "Earning name already exists"
                      ) {
                        return "Earning name already exists";
                      }
                      if (editEarning.name !== value) {
                        const response = await fetch(
                          `${domain}earning/unique`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `token ${token}`,
                            },
                            body: JSON.stringify({
                              company: companyId,
                              name: value.trim(),
                            }),
                          }
                        );
                        const res = await response.json();
                        if (res.exists) {
                          return "Earning name already exists";
                        }
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateComponentName}
              placeHolder="Enter the component name"
              errorMessage={
                errors?.component_name?.message
                  ? errors?.component_name?.message
                  : ""
              }
              textBoxError={errors.component_name ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  setTimeout(function () {
                    setComponentName("");
                    reset();
                  }, 100);
                }}
                actionName="Cancel"
                typeName="button"
              />{" "}
              &nbsp;
              <CustomButton
                actionName="Update"
                typeName="submit"
                disableAction={
                  openDialog && buttonLoader ? buttonLoader : false
                }
              />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={() => setDeleteEarning(false)}
        open={deleteEarning}
        title="Delete Earning"
        minWidth={600}
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ fontSize: 23 }}>
            Do you want to delete the {editEarning?.name} ?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            actionFuntion={() => setDeleteEarning(false)}
            actionName="No"
            typeName="button"
          />{" "}
          &nbsp;
          <CustomButton
            actionFuntion={() => handleEarningDelete()}
            actionName="Yes"
            typeName="button"
            disableAction={deleteEarning && buttonLoader ? buttonLoader : false}
          />
        </Mui.Grid>
      </GlobalDialogBox>
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {earningDetails.length > 25 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            count={earningDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
