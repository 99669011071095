import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes, leaveCreditingShedule } from "services/constants";
import { Info } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { formKeyText } from "services/constants/Styles";
import { customGetAPI } from "services/utils/CustomAPI";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { customAPI } from "services/utils/CustomAPI";
import { showToast } from "services/utils/Status";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
const leavePayout = [
  { label: "Payout at the end of leave period" },
  { label: "Accumulate and payout at year end" },
  { label: "Accumulate and expire at year end" },
  { label: "Accumulate and carry forward" },
];
export const EditLeaveType = ({ setEditAction, setApiCall, editLeaveType }) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [colorValue, setColorValue] = useState(themes.primaryIndicator);
  const [leavePayoutPolicyName, setLeavePayoutPolicy] = useState("");
  const [leaveCreditingSheduleData, setLeaveCreditingSheduleData] =
    useState("Annually");
  const [proportionate, setProportionate] = useState(false);
  const [carryForwardDays, setCarryForwardDays] = useState("");
  const [paymentFactor, setPaymentFactor] = useState("1");
  const [isPaidLeave, setIsPaidLeave] = useState(false);
  const [updateDefaultValue, setUpdateDefaultValue] = useState(true);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (updateDefaultValue) {
      setColorValue(editLeaveType?.leave_color);
      setValue("leave_type", editLeaveType?.leave_type);
      setValue("leave_color", editLeaveType?.leave_color);
      setLeavePayoutPolicy(editLeaveType?.leave_pay_out_policy);
      setValue("leave_payout_policy", editLeaveType?.leave_pay_out_policy);
      setLeaveCreditingSheduleData(editLeaveType?.leave_crediting_schedule);
      setValue(
        "leave_crediting_schedule",
        editLeaveType?.leave_crediting_schedule
      );
      setProportionate(editLeaveType?.proportionate_leaves_on_joined_date);
      setCarryForwardDays(editLeaveType?.no_of_days_leave_carry_forward);
      setValue(
        "no_of_days_leave_carry_forward",
        editLeaveType?.no_of_days_leave_carry_forward
      );
      setPaymentFactor(editLeaveType?.payment_factor);
      setValue("payment_factor", editLeaveType?.payment_factor);
      setIsPaidLeave(editLeaveType?.is_paid_leave);
      setValue("leave_per_leave_period", editLeaveType?.leave_per_period);
      setValue("expiry_after", editLeaveType?.expiry_after);
      setUpdateDefaultValue(false);
    }
  }, [editLeaveType, setValue, updateDefaultValue]);

  const createLeaveType = async (data) => {
    const payload = {
      ...data,
      leave_crediting_schedule: isPaidLeave
        ? data?.leave_crediting_schedule
        : null,
      leave_payout_policy: isPaidLeave ? data?.leave_payout_policy : null,
      proportionate_leaves_on_joined_date: proportionate,
      is_paid_leave: isPaidLeave,
    };
    const response = await customAPI(
      `${domain}new-leave-type/${editLeaveType?.id}/`,
      "PUT",
      payload
    );
    if (response.ok) {
      setEditAction(false);
      setApiCall(true);
      showToast("success", "Leave Type updated successfully");
      reset();
    }
  };

  const checkSpeacialCharaterWithSpace = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if ((charCode < 48 || charCode > 57) && charCode !== 46) {
      e.preventDefault();
    }
  };

  const updateLeavePayoutPolicy = (event, data) => {
    if (data) {
      setValue("leave_payout_policy", data?.label);
      setLeavePayoutPolicy(data?.label);
      if (data?.label === "Accumulate and carry forward") {
        setValue("no_of_days_leave_carry_forward", "");
      } else {
        setValue("no_of_days_leave_carry_forward", 0);
        setValue("expiry_after", 0);
      }
    }
  };

  const updateProportionate = () => {
    setProportionate(!proportionate);
  };

  const updateCarryForwardDays = (event) => {
    const value = event.target.value;
    if (value === "" || value === "" || /^[0-9]*$/.test(value)) {
      setCarryForwardDays(value);
      setValue("no_of_days_leave_carry_forward", value);
      setValue("expiry_after", 0);
    }
  };

  const handlePaidLeaveOrNot = () => {
    setIsPaidLeave(!isPaidLeave);
    if (getValues("leave_payout_policy") === "Accumulate and carry forward") {
      setValue("leave_payout_policy", "Payout at the end of leave period");
      setLeavePayoutPolicy("Payout at the end of leave period");
      setCarryForwardDays(0);
      setValue("no_of_days_leave_carry_forward", 0);
    }
  };

  const handleLeaveCreditingSchedule = (event, data) => {
    if (data) {
      setLeaveCreditingSheduleData(data?.label);
      setValue("leave_crediting_schedule", data?.label);
    } else {
      setLeaveCreditingSheduleData("");
      setValue("leave_crediting_schedule", "");
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setEditAction(false);
          }}
        />
        <Mui.Typography
          sx={{ paddingTop: 1, fontSize: 25, fontWeight: "bold" }}
        >
          Leave Type
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(createLeaveType)}>
        <Mui.Grid
          container
          sx={{ padding: 2, paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}
        >
          <Mui.Grid
            container
            sx={{
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 24,
                fontWeight: 800,
                font: "bold",
                textAlign: "center",
              }}
            >
              Edit Leave Type
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: "0 0 10px 10px",
              border: `2px solid ${themes.sidebarDivider}`,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
                <Mui.Stack direction="Row">
                  <Mui.Typography sx={formKeyText}>
                    Leave Name &nbsp;
                    <Mui.Tooltip title="This is the type of leave name that can be applied by an employee for the current leave period. Example: Sick leave,Casual leave, other leaves...">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.TextField
                  placeholder="Enter the leave Name"
                  {...register("leave_type", {
                    required: "Leave type name is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                      editLeaveName: async (fieldValue) => {
                        if (fieldValue.trim() === "") {
                          return "Minimum 3 characters are required";
                        } else {
                          if (fieldValue.trim() !== editLeaveType?.leave_type) {
                            const res = await customGetAPI(
                              `${domain}leave-types-namecheck/?leave_type=${fieldValue.trim()}`
                            );
                            if (res?.ok) {
                              if (res?.responseDetails?.exists) {
                                return "Leave name is already exists";
                              } else {
                                return true;
                              }
                            }
                          }
                        }
                      },
                    },
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 32 },
                  }}
                  error={Boolean(errors.leave_type)}
                  helperText={errors.leave_type?.message || ""}
                  onKeyPress={checkSpeacialCharaterWithSpace}
                  onBlur={() => trigger("leave_type")}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={formKeyText}>
                  Leave Color &nbsp;
                  <Mui.Tooltip title="This is the colour that appears in the calendar if this leave type is applied. ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.TextField
                  type="color"
                  value={colorValue}
                  {...register("leave_color", {
                    required: false,
                  })}
                  onChange={(event) => {
                    setValue("leave_color", event.target.value);
                    setColorValue(event.target.value);
                  }}
                  sx={{
                    "& fieldset": { border: "none" },
                    width: 360,
                  }}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={formKeyText}>
                  Proportionate leave on joined date &nbsp;
                  <Mui.Tooltip title="Whether the available number of leaves should be calculated based on number of days employee work in a given leave period. (e.g if an employee joined in end of June, he/she will only get half of the number of leave days specified for given leave type">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>{" "}
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Switch
                  checked={proportionate}
                  onChange={updateProportionate}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={6}>
                <Mui.Stack direction="Row">
                  <Mui.Typography sx={formKeyText}>
                    Is this leavetype paid ? &nbsp;
                    <Mui.Tooltip title="Whether the available number of leaves should be calculated based on number of days employee work in a given leave period. (e.g if an employee joined in end of June, he/she will only get half of the number of leave days specified for given leave type">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Grid container>
                  <Mui.Grid xs={4}>
                    <Mui.Switch
                      checked={isPaidLeave}
                      onChange={handlePaidLeaveOrNot}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Mui.Grid>
                  {isPaidLeave ? (
                    <>
                      <Mui.Grid xs={4}>
                        <Mui.Typography sx={formKeyText}>
                          Payment Factor <Info />
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={4}>
                        <Mui.TextField
                          size="small"
                          {...register("payment_factor", {
                            required: "Payment factor is required",
                          })}
                          error={errors.payment_factor ? true : false}
                          helperText={errors.payment_factor?.message}
                          value={paymentFactor}
                          inputProps={{ style: { textAlign: "center" } }}
                          onChange={(event) => {
                            const rgxWithDot = /^([0-9]{1,2})(\.[0-9]{0,2})?$/;
                            if (
                              (rgxWithDot.test(event.target.value) &&
                                parseInt(event.target.value) < 10 &&
                                event.target.value.length <= 6) ||
                              event.target.value === ""
                            ) {
                              setPaymentFactor(event.target.value);
                              setValue("payment_factor", event.target.value);
                            }
                          }}
                        />
                      </Mui.Grid>
                    </>
                  ) : null}
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            {isPaidLeave ? (
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={6}>
                  <Mui.Typography sx={formKeyText}>
                    Leave Crediting Schedule &nbsp;
                    <Mui.Tooltip title="When quarterly is selected,leaves count count is split and credited to the user at the start of each quarter, similarly works for half yearly and annual.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <CustomAutoComplete
                    dataList={leaveCreditingShedule}
                    selectedValue={leaveCreditingSheduleData}
                    rules={{
                      ...register("leave_crediting_schedule", {
                        required: "Leave creaditing Schedule is required",
                      }),
                    }}
                    updateDataFunction={handleLeaveCreditingSchedule}
                    placeHolder="Please select the leave crediting schedule"
                    errorMessage={
                      errors?.leave_crediting_schedule?.message
                        ? errors?.leave_crediting_schedule?.message
                        : ""
                    }
                    textBoxError={
                      errors.leave_crediting_schedule ? true : false
                    }
                  />
                </Mui.Grid>
              </Mui.Grid>
            ) : null}
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={formKeyText}>
                  Leave Per Leave period &nbsp;
                  <Mui.Tooltip title="This is the number of leave days that can be applied by an employee per year (or the current leave period). If the leave period is less than a Year this is the number of leaves for the leave period.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.TextField
                  placeholder="Enter the leave Count"
                  {...register("leave_per_leave_period", {
                    required: "Leave count is required",
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 5 },
                  }}
                  error={Boolean(errors.leave_per_leave_period)}
                  helperText={errors.leave_per_leave_period?.message || ""}
                  onKeyPress={handleKeyPress}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            {isPaidLeave ? (
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
                  <Mui.Typography sx={formKeyText}>
                    Leave payout policy &nbsp;
                    <Mui.Tooltip title="This is the number of leave days that can be applied by an employee per year (or the current leave period). If the leave period is less than a Year this is the number of leaves for the leave period.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
                  <CustomAutoComplete
                    dataList={leavePayout}
                    rules={{
                      ...register("leave_payout_policy", {
                        required: "Leave payout policy is required",
                      }),
                    }}
                    selectedValue={leavePayoutPolicyName}
                    updateDataFunction={updateLeavePayoutPolicy}
                    placeHolder="Select leave payout policy"
                    errorMessage={
                      errors?.leave_payout_policy?.message
                        ? errors?.leave_payout_policy?.message
                        : ""
                    }
                    textBoxError={errors?.leave_payout_policy ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
            ) : null}
            {leavePayoutPolicyName === "Accumulate and carry forward" ? (
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={6}>
                  <Mui.Typography sx={formKeyText}>
                    Max number of days can be carry forward &nbsp;
                    <Mui.Tooltip title="Whether the available number of leaves should be calculated based on number of days employee work in a given leave period. (e.g if an employee joined in end of June, he/she will only get half of the number of leave days specified for given leave type">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <CustomTextBox
                    selectedValue={carryForwardDays}
                    rules={{
                      ...register("no_of_days_leave_carry_forward", {
                        required: "Carry forward days is required",
                      }),
                    }}
                    updateDataFunction={updateCarryForwardDays}
                    placeHolder="Enter the days"
                    errorMessage={
                      errors?.no_of_days_leave_carry_forward?.message
                        ? errors?.no_of_days_leave_carry_forward?.message
                        : ""
                    }
                    textBoxError={
                      errors?.no_of_days_leave_carry_forward ? true : false
                    }
                  />
                </Mui.Grid>
              </Mui.Grid>
            ) : null}
            {leavePayoutPolicyName === "Accumulate and carry forward" ? (
              <Mui.Grid container sx={{ paddingTop: 1 }}>
                <Mui.Grid xs={6}>
                  <Mui.Typography sx={formKeyText}>
                    Expiry After &nbsp;
                    <Mui.Tooltip title="Whether the available number of leaves should be calculated based on number of days employee work in a given leave period. (e.g if an employee joined in end of June, he/she will only get half of the number of leave days specified for given leave type">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.TextField
                    placeholder="Enter expire days count"
                    {...register("expiry_after", {
                      required: "Expire days is required.",
                    })}
                    size="small"
                    InputProps={{
                      inputProps: { maxLength: 5 },
                    }}
                    error={Boolean(errors.expiry_after)}
                    helperText={errors.expiry_after?.message || ""}
                    onKeyPress={handleKeyPress}
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>
            ) : null}

            {/* loop configuraitons */}
            <Mui.Grid container sx={{ paddingTop: 3, paddingBottom: 1 }}>
              <Mui.Grid xs={9}></Mui.Grid>
              <Mui.Grid
                xs={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionName="Cancel"
                  typeName="button"
                  actionFuntion={() => {
                    setEditAction(false);
                  }}
                />
                &nbsp; &nbsp;
                <CustomButton actionName="Update" typeName="submit" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
