import React, { useState, useEffect, useCallback } from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import dayjs from "dayjs";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { customAPI } from "services/utils/CustomAPI";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import moment from "moment";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { onlyNumberRegex } from "services/constants/patterns";
import {
  formHeader,
  formBackGroundColor,
  formKeyText,
  formKeyTextRight,
} from "services/constants/Styles";

export const EditShiftGrade = ({
  setEditGrade,
  reCallApi,
  setShowTopBar,
  editWorkUnitPay,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [shiftName, setShiftName] = useState(editWorkUnitPay?.name);
  const [isBreakAllowed, setIsBreakAllowed] = useState(
    editWorkUnitPay?.break_allowed
  );
  const [breakMinutes, setBreakMinutes] = useState(
    editWorkUnitPay?.break_duration
  );
  const [shiftDuration, setShiftDuration] = useState(
    dayjs()
      .set("hour", editWorkUnitPay?.shift_duration.split(":")[0])
      .set("minute", editWorkUnitPay?.shift_duration.split(":")[1])
  );
  const [firstHalf, setFirstHalf] = useState(
    dayjs()
      .set("hour", editWorkUnitPay?.first_half_duration.split(":")[0])
      .set("minute", editWorkUnitPay?.first_half_duration.split(":")[1])
  );
  const [secondHalf, setSecondHalf] = useState(
    dayjs()
      .set("hour", editWorkUnitPay?.second_half_duration.split(":")[0])
      .set("minute", editWorkUnitPay?.second_half_duration.split(":")[1])
  );
  const [includeShiftTime, setIncludeShiftTime] = useState(
    editWorkUnitPay?.partof_shift
  );
  const [getWorkingHour, setGetWorkingHour] = useState(
    dayjs()
      .set("hour", editWorkUnitPay?.working_hours.split(":")[0])
      .set("minute", editWorkUnitPay?.working_hours.split(":")[1])
  );
  const [durationHour, setDurationHour] = useState(
    dayjs()
      .set("hour", editWorkUnitPay?.shift_duration_hour.split(":")[0])
      .set("minute", editWorkUnitPay?.shift_duration_hour.split(":")[1])
  );
  const [triggerWorkingHour, setTriggerWorkingHour] = useState(false);
  const [breakError, setBreakError] = useState(false);
  const [halfError, setHalfError] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [firstHalfError, setFirstHalfError] = useState(false);
  const [secondHalfError, setSecondHalftError] = useState(false);
  const [equalError, setEqualError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [breakDurationError, setBreakDurationError] = useState(false);
  let totalMinutes = 0;
  let totalWorkingHour = 0;
  let totalFirstHalf = 0;
  let totalSecondHalf = 0;

  const formatTime = (timestamp) => {
    if (timestamp !== null) {
      const date = new Date(timestamp);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    } else {
      return null;
    }
  };
  const formattedSftDurationHours = formatTime(shiftDuration);
  const formattedFirstHalfDuration = formatTime(firstHalf);
  const formattedSecondHalfDuration = formatTime(secondHalf);
  const formatGetWorkingHour = formatTime(getWorkingHour);
  const formatDurationHour = formatTime(durationHour);

  if (formattedSftDurationHours && formattedSftDurationHours.includes(":")) {
    const [hours, minutes] = formattedSftDurationHours.split(":").map(Number);
    totalMinutes = hours * 60 + minutes;
  }
  if (formatGetWorkingHour && formatGetWorkingHour.includes(":")) {
    const [hours, minutes] = formatGetWorkingHour.split(":").map(Number);
    totalWorkingHour = hours * 60 + minutes;
  }
  if (formattedFirstHalfDuration && formattedFirstHalfDuration.includes(":")) {
    const [hours, minutes] = formattedFirstHalfDuration.split(":").map(Number);
    totalFirstHalf = hours * 60 + minutes;
  }
  if (
    formattedSecondHalfDuration &&
    formattedSecondHalfDuration.includes(":")
  ) {
    const [hours, minutes] = formattedSecondHalfDuration.split(":").map(Number);
    totalSecondHalf = hours * 60 + minutes;
  }

  const TotalFirstSecondHalf = totalFirstHalf + totalSecondHalf;
  const updateShiftGrade = async () => {
    if (
      totalMinutes > breakMinutes &&
      totalWorkingHour >= TotalFirstSecondHalf &&
      formattedFirstHalfDuration !== null &&
      formattedSecondHalfDuration !== null &&
      totalWorkingHour <= TotalFirstSecondHalf &&
      (nameError === "" || nameError === null) &&
      breakMinutes !== "" &&
      breakMinutes !== null
    ) {
      setButtonDisabled(true);
      let payload = {
        name: shiftName,
        shift_duration: formattedSftDurationHours,
        break_allowed: isBreakAllowed,
        break_duration: breakMinutes,
        working_hours: formatGetWorkingHour,
        shift_duration_hour: formatDurationHour,
        first_half_duration: formattedFirstHalfDuration,
        second_half_duration: formattedSecondHalfDuration,
        partof_shift: includeShiftTime,
      };
      const response = await customAPI(
        `${domain}shift-grades/${editWorkUnitPay?.id}`,
        "PUT",
        payload
      );
      if (response.ok) {
        showToast("success", "Shift grade updated successfully");
        setEditGrade(false);
        setShowTopBar(true);
        reCallApi(true);
      } else {
        setButtonDisabled(false);
      }
    } else if (
      formattedSftDurationHours === null &&
      formattedFirstHalfDuration === null &&
      formattedSecondHalfDuration === null
    ) {
      setDurationError(true);
      setFirstHalfError(true);
      setSecondHalftError(true);
    } else if (
      formattedFirstHalfDuration === null &&
      formattedSecondHalfDuration === null
    ) {
      setFirstHalfError(true);
      setSecondHalftError(true);
      setDurationError(false);
    } else if (
      breakMinutes >= totalMinutes &&
      TotalFirstSecondHalf > totalWorkingHour
    ) {
      setDurationError(false);
      setFirstHalfError(false);
      setSecondHalftError(false);
      setBreakError(true);
      setHalfError(true);
    } else if (breakMinutes > totalMinutes) {
      setDurationError(false);
      setFirstHalfError(false);
      setSecondHalftError(false);
      setBreakError(true);
      setHalfError(false);
    } else if (TotalFirstSecondHalf > totalWorkingHour) {
      setDurationError(false);
      setFirstHalfError(false);
      setSecondHalftError(false);
      setBreakError(false);
      setEqualError(false);
      setHalfError(true);
    } else if (totalWorkingHour > TotalFirstSecondHalf) {
      setDurationError(false);
      setFirstHalfError(false);
      setSecondHalftError(false);
      setBreakError(false);
      setHalfError(false);
      setEqualError(true);
    } else if (formattedSecondHalfDuration === null) {
      setDurationError(false);
      setFirstHalfError(false);
      setSecondHalftError(true);
      setBreakError(false);
      setHalfError(false);
      setEqualError(false);
    } else if (formattedFirstHalfDuration === null) {
      setDurationError(false);
      setFirstHalfError(true);
      setSecondHalftError(false);
      setBreakError(false);
      setHalfError(false);
      setEqualError(false);
    } else if (formattedSftDurationHours === null) {
      setDurationError(true);
      setFirstHalfError(false);
      setSecondHalftError(false);
      setBreakError(false);
      setHalfError(false);
      setEqualError(false);
    } else if (breakMinutes === "" || breakMinutes === null) {
      setBreakDurationError(true);
      setDurationError(false);
      setFirstHalfError(false);
      setSecondHalftError(false);
      setBreakError(false);
      setHalfError(false);
      setEqualError(false);
    }
  };

  const handleBreak = (event) => {
    const isChecked = event.target.checked;
    setIsBreakAllowed(isChecked);
    setTriggerWorkingHour(true);
    setBreakDurationError(false);
    setBreakError(false);
    setBreakMinutes("00");
    if (!isChecked) {
      setIncludeShiftTime(false);
    }
  };
  const handleShift = () => {
    setIncludeShiftTime(!includeShiftTime);
    setTriggerWorkingHour(true);
  };

  const nameUpdate = (event) => {
    if (event.target.value.length < 32) {
      setShiftName(event.target.value);
      checkShiftGradeName(event.target.value);
    }
  };

  useEffect(() => {
    const calculateNewDate = () => {
      if (
        isBreakAllowed &&
        includeShiftTime &&
        shiftDuration !== null &&
        durationHour !== null
      ) {
        let newTime = shiftDuration.subtract(breakMinutes, "minute");
        setGetWorkingHour(newTime);
        let newTimes = durationHour.subtract(breakMinutes, "minute");
        setDurationHour(newTimes);
      } else if (isBreakAllowed && shiftDuration !== null) {
        let newTime = shiftDuration.add(breakMinutes, "minute");
        setDurationHour(newTime);
        setGetWorkingHour(shiftDuration);
      } else {
        setGetWorkingHour(shiftDuration);
        setDurationHour(shiftDuration);
      }
    };
    if (triggerWorkingHour) {
      calculateNewDate();
      setTriggerWorkingHour(false);
    }
  }, [
    breakMinutes,
    durationHour,
    getWorkingHour,
    includeShiftTime,
    isBreakAllowed,
    shiftDuration,
    triggerWorkingHour,
  ]);

  const checkShiftGradeName = useCallback(
    async (value) => {
      if (value === "") {
        setNameError("Shift grade name is required");
      } else if (!/[a-zA-Z]/.test(value)) {
        setNameError("At least one alphabet is required");
      } else if (value.length < 3) {
        setNameError("Minimum length is 3 characters");
      } else if (value.length > 3) {
        try {
          const response = await fetch(
            `${domain}check-shiftgrade-name/?shift_grade_name=${value}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `token ${token}`,
              },
            }
          );
          const resp = await response.json();
          if (resp.exists) {
            setNameError("Shift grade name already exists");
          } else {
            setNameError(null);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    },
    [domain, token]
  );

  return (
    <>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setEditGrade(false);
            setShowTopBar(true);
          }}
        />
        <Mui.Typography sx={formHeader}>
          Shift Grade / Edit Shift Grade
        </Mui.Typography>
      </Mui.Grid>

      <Mui.Grid
        container
        sx={{
          paddingTop: "5%",
          paddingLeft: "18%",
          paddingRight: "18%",
          paddingBottom: "5%",
        }}
      >
        <Mui.Grid
          container
          sx={{
            border: `2px solid ${themes.sidebarDivider}`,
            borderRadius: "10px 10px 10px 10px",
          }}
        >
          <Mui.Grid
            xs={12}
            sx={{
              backgroundColor: formBackGroundColor,
              padding: {
                xs: 0.5,
                sm: 0.5,
                md: 0.5,
                lg: 0.5,
                xl: 1,
              },
              textAlign: "center",
              borderBottom: `2px solid ${themes.sidebarDivider}`,
            }}
          >
            <Mui.Typography sx={formHeader}>Edit Shift Grade</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 2 }}>
            <Mui.Grid container sx={{ padding: 1, borderRadius: 3 }}>
              <Mui.Grid item xs={4.5} sx={{ paddingTop: 1.5 }}>
                <Mui.Typography sx={formKeyText}>
                  Shift Hours &nbsp;
                  <Mui.Tooltip title="This is the Shift Duration Hours field. Specify the total number of hours an employee is expected to work during a shift, such as 8 or 12 hours.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.5}></Mui.Grid>
              <Mui.Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      ampm={false}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      value={shiftDuration || null}
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: 700,
                          },
                        },
                      }}
                      onChange={(newValue) => {
                        setTriggerWorkingHour(true);
                        if (newValue) {
                          const timeWithDate = newValue
                            ? newValue.toDate()
                            : new Date();
                          let minTime = moment?.utc(timeWithDate);
                          setShiftDuration(
                            dayjs()
                              .set("hour", minTime._d.getHours())
                              .set("minute", minTime._d.getMinutes())
                              .set("second", minTime._d.getSeconds())
                          );
                        } else {
                          setShiftDuration(null);
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {durationError ? (
                  <h6
                    style={{
                      paddingTop: 2,
                      color: themes.helperTextColor,
                      fontSize: 12,
                    }}
                  >
                    Shift hours is required
                  </h6>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4.5}>
                <Mui.Typography sx={formKeyText}>
                  Is Break Allowed &nbsp;
                  <Mui.Tooltip title="This is the Is Break Allowed field. Choose Yes to allow a break during the shift or No to indicate no break is provided.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.35}></Mui.Grid>
              <Mui.Grid xs={1.1}>
                <Mui.Switch
                  checked={isBreakAllowed}
                  onChange={handleBreak}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Mui.Grid>
            </Mui.Grid>
            {isBreakAllowed && (
              <>
                <Mui.Grid
                  container
                  xs={3}
                  sx={{
                    border: `1px solid ${themes.sidebarDivider}`,
                    padding: 1,
                    borderRadius: 2,
                  }}
                >
                  <Mui.Grid xs={8}>
                    <Mui.Typography
                      sx={{
                        fontSize: { xs: 15, sm: 15, md: 17, lg: 18, xl: 18 },
                        fontWeight: "bold",
                        padding: 1,
                      }}
                    >
                      Break Duration
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={4}>
                    <Mui.TextField
                      size="small"
                      value={breakMinutes}
                      sx={{ textAlign: "center" }}
                      inputProps={{ style: { textAlign: "center" } }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 3
                        ) {
                          setBreakMinutes(event.target.value);
                          setTriggerWorkingHour(true);
                        }
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid xs={breakError ? 6.1 : 6.1}></Mui.Grid>
                {(breakError || breakDurationError) && (
                  <h6
                    style={{
                      paddingTop: 2,
                      color: themes.helperTextColor,
                      fontSize: 12,
                    }}
                  >
                    {breakError
                      ? "Break duration must be less than shift working hour"
                      : "Break duration is required"}
                  </h6>
                )}
              </>
            )}
            {isBreakAllowed && (
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid xs={4.5}>
                  <Mui.Typography sx={formKeyText}>
                    Is break part of shift hours &nbsp;
                    <Mui.Tooltip title="This is the Is Break Part of Shift Hours field. Choose Yes if the break is included in the total shift hours or No if it is not.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={0.35}></Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.Switch
                    checked={includeShiftTime}
                    onChange={handleShift}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Mui.Grid>
              </Mui.Grid>
            )}

            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4.5}>
                <Mui.Typography sx={formKeyText}>
                  Shift Working Hours &nbsp;
                  <Mui.Tooltip title="">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={4} sx={{ paddingTop: 1.5 }}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  {formatGetWorkingHour ? formatGetWorkingHour : "hh:mm"}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4.5}>
                <Mui.Typography sx={formKeyText}>
                  Shift Duration Hours &nbsp;
                  <Mui.Tooltip title="">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={4} sx={{ paddingTop: 1.5 }}>
                <Mui.Typography sx={{ fontWeight: "bold" }}>
                  {formatDurationHour ? formatDurationHour : "hh:mm"}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4.5}>
                <Mui.Typography sx={formKeyText}>
                  Duration For First Half and{" "}
                  <Mui.Tooltip title="">
                    <Info />
                  </Mui.Tooltip>{" "}
                  <Mui.Typography
                    sx={{
                      fontSize: { xs: 15, sm: 15, md: 17, lg: 18, xl: 18 },
                      fontWeight: "bold",
                      color: themes.headLine,
                      textAlign: {
                        xs: "center",
                        sm: "center",
                        md: "center",
                        lg: "left",
                        xl: "left",
                      },
                      paddingLeft: 15.5,
                    }}
                  >
                    Second Half
                  </Mui.Typography>
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid container xs={4}>
                <Mui.Grid xs={5.5}>
                  <Mui.Typography sx={formKeyTextRight}>
                    First Half
                  </Mui.Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <div sx={{ width: 4 }}>
                        <TimePicker
                          ampm={false}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          value={firstHalf || null}
                          slotProps={{
                            textField: {
                              size: "small",
                              width: 15,
                            },
                          }}
                          onChange={(newValue) => {
                            if (newValue) {
                              const timeWithDate = newValue
                                ? newValue.toDate()
                                : new Date();
                              let minTime = moment.utc(timeWithDate);
                              setFirstHalf(
                                dayjs()
                                  .set("hour", minTime._d.getHours())
                                  .set("minute", minTime._d.getMinutes())
                                  .set("second", minTime._d.getSeconds())
                              );
                            } else {
                              setFirstHalf(null);
                            }
                          }}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                  {(equalError || halfError || firstHalfError) && (
                    <h6
                      style={{
                        paddingTop: 2,
                        color: themes.helperTextColor,
                        fontSize: 12,
                      }}
                    >
                      {equalError
                        ? "Both first and second half duration must equal the shift working hour"
                        : halfError
                        ? "Both first and second half duration must be below the shift working hour"
                        : "Firsthalf is required"}
                    </h6>
                  )}
                </Mui.Grid>
                <Mui.Grid xs={1}></Mui.Grid>
                <Mui.Grid xs={5.5}>
                  {" "}
                  <Mui.Typography sx={formKeyTextRight}>
                    Second Half
                  </Mui.Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <div sx={{ width: 4 }}>
                        <TimePicker
                          ampm={false}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          value={secondHalf || null}
                          slotProps={{
                            textField: {
                              size: "small",
                              width: 15,
                            },
                          }}
                          onChange={(newValue) => {
                            if (newValue) {
                              const timeWithDate = newValue
                                ? newValue.toDate()
                                : new Date();
                              let minTime = moment.utc(timeWithDate);
                              setSecondHalf(
                                dayjs()
                                  .set("hour", minTime._d.getHours())
                                  .set("minute", minTime._d.getMinutes())
                                  .set("second", minTime._d.getSeconds())
                              );
                            } else {
                              setSecondHalf(null);
                            }
                          }}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                  {(equalError || halfError || secondHalfError) && (
                    <h6
                      style={{
                        paddingTop: 2,
                        color: themes.helperTextColor,
                        fontSize: 12,
                      }}
                    >
                      {equalError
                        ? "Both first and second half duration must equal the shift working hour"
                        : halfError
                        ? "Both first and second half duration must be below the shift working hour"
                        : "Secondhalf is required"}
                    </h6>
                  )}
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4.5}>
                <Mui.Typography sx={formKeyText}>
                  Shift Grade Name &nbsp;
                  <Mui.Tooltip title="This is the Name field. Enter the shift grade name in the required format, such as 0600hr-1wo-wu. Ensure the name follows this structure to maintain consistency.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>{" "}
              </Mui.Grid>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                <CustomTextBox
                  selectedValue={shiftName}
                  updateDataFunction={nameUpdate}
                />
                {nameError && (
                  <h5 style={{ color: themes.helperTextColor }}>{nameError}</h5>
                )}
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={7}></Mui.Grid>
              <Mui.Grid>
                <CustomButton
                  actionName="Cancel"
                  typeName="button"
                  actionFuntion={() => {
                    setEditGrade(false);
                    setShowTopBar(true);
                  }}
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName="Update"
                  typeName="button"
                  actionFuntion={() => {
                    updateShiftGrade();
                    checkShiftGradeName(shiftName);
                  }}
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
