import React, { useContext } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { MyProjectContext } from "./MyProjectContext";
import { makeStyles, createStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { setDataViewFormat } from "services/Redux/userManagament";
import { useSelector, useDispatch } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { Reorder, CreditCard } from "@mui/icons-material";

export const ProjectTopBar = () => {
  const {
    overAllProjects,
    setProjects,
    setCurrentTap,
    currentTap,
    setGetClientDetails,
  } = useContext(MyProjectContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const dataViewFormat = useSelector(
    (state) => state.userReducer.dataViewFormat
  );
  let userDetails = useSelector((state) => state.tokenReducer.userDetails);
  const role = userDetails.role === "User" ? true : false;

  const filterProjects = (filterStatus) => {
    if (filterStatus === "All") {
      setProjects(overAllProjects);
    } else {
      let filterData = overAllProjects.filter((data) => {
        return data.status.status === filterStatus;
      });
      setProjects(filterData);
    }
    setCurrentTap(filterStatus);
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: themes.whiteColor,
          position: "sticky",
          top: "60px",
          zIndex: 10,
        }}
      >
        <Mui.Grid
          container
          sx={{
            paddingTop: 1,
            top: "60px",
            transition: "opacity 0.3s ease-in-out",
            backgroundColor: "transparent",
          }}
        >
          <Mui.Grid item xs={1} sm={1} md={1} lg={2}></Mui.Grid>
          <Mui.Grid
            item
            xs={8}
            sm={8}
            md={8}
            lg={role ? 8 : 6}
            sx={{ marginTop: -2.5, paddingBottom: 1 }}
          >
            <Mui.Box
              sx={{
                marginTop: 2,
                boxShadow: 3,
                display: "flex",
                p: 1.5,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "#fff",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              <Mui.Grid container>
                <Mui.Grid item xs={2} sx={{ textAlign: "center" }}>
                  <Mui.IconButton
                    size="small"
                    sx={{
                      color:
                        currentTap === "Active"
                          ? themes.primaryIndicator
                          : "none",
                      fontWeight: currentTap === "Active" ? 700 : 400,
                    }}
                    onClick={() => {
                      setGetClientDetails(true);
                      filterProjects("Active");
                    }}
                  >
                    Active
                  </Mui.IconButton>
                </Mui.Grid>
                <Mui.Grid item xs={2} sx={{ textAlign: "center" }}>
                  <Mui.IconButton
                    size="small"
                    sx={{
                      color:
                        currentTap === "Onhold"
                          ? themes.primaryIndicator
                          : "none",
                      fontWeight: currentTap === "Onhold" ? 700 : 400,
                    }}
                    onClick={() => {
                      setGetClientDetails(true);
                      filterProjects("Onhold");
                    }}
                  >
                    Onhold
                  </Mui.IconButton>
                </Mui.Grid>
                <Mui.Grid item xs={2} sx={{ textAlign: "center" }}>
                  <Mui.IconButton
                    size="small"
                    sx={{
                      color:
                        currentTap === "Completed"
                          ? themes.primaryIndicator
                          : "none",
                      fontWeight: currentTap === "Completed" ? 700 : 400,
                    }}
                    onClick={() => {
                      setGetClientDetails(true);
                      filterProjects("Completed");
                    }}
                  >
                    Completed
                  </Mui.IconButton>
                </Mui.Grid>
                <Mui.Grid item xs={2} sx={{ textAlign: "center" }}>
                  <Mui.IconButton
                    size="small"
                    sx={{
                      color:
                        currentTap === "All" ? themes.primaryIndicator : "none",
                      fontWeight: currentTap === "All" ? 700 : 400,
                    }}
                    onClick={() => {
                      setGetClientDetails(true);
                      filterProjects("All");
                    }}
                  >
                    All
                  </Mui.IconButton>
                </Mui.Grid>
                <Mui.Grid item xs={2}></Mui.Grid>
                <Mui.Grid item xs={2} sx={{ textAlign: "right" }}>
                  {dataViewFormat === "CardView" ? (
                    <Mui.Tooltip title="List view">
                      <Mui.IconButton
                        size="small"
                        onClick={() => {
                          dispatch(setDataViewFormat("ListView"));
                        }}
                      >
                        <Reorder size="large" color={themes.headLine} />
                      </Mui.IconButton>
                    </Mui.Tooltip>
                  ) : (
                    <Mui.Tooltip title="Card view">
                      <Mui.IconButton
                        size="small"
                        onClick={() => {
                          dispatch(setDataViewFormat("CardView"));
                        }}
                      >
                        <CreditCard size="large" />
                      </Mui.IconButton>
                    </Mui.Tooltip>
                  )}
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Box>
          </Mui.Grid>
          {!role ? (
            <>
              <Mui.Grid item xs={1} sm={1} md={1} lg={2}></Mui.Grid>
              <Mui.Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={2}
                sx={{
                  paddingBottom: 1,
                  mt: 1,
                  paddingRight: dataViewFormat === "CardView" ? 6 : 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={() => history.push("/admin/new-project/")}
                  actionName="Add Project"
                  typeName="submit"
                  disableAction={role}
                />
              </Mui.Grid>
            </>
          ) : null}
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingRight: 1 }}>
          {dataViewFormat === "ListView" ? (
            <Mui.TableContainer
              component={Mui.Paper}
              style={{ paddingTop: 4, paddingBottom: 2 }}
              sx={{
                mt: 2,
                paddingRight: 1,
                backgroundColor: themes.primary,
                borderBottom: "1.5px solid transparent",
                width: "100%",
              }}
            >
              <Mui.Table
                style={{
                  tableLayout: "auto",
                  borderBottom: "1.5px solid transparent",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                sx={{ backgroundColor: themes.primary }}
              >
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.primary,
                  }}
                >
                  <Mui.TableRow
                    maxWidth="xl"
                    align="left"
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "5px 15px",
                        backgroundColor: themes.primary,
                        borderBottom: "1.5px solid transparent",
                      },
                    }}
                  >
                    <Mui.TableCell
                      sx={{
                        width: "15%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Project Name
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Project Code
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Client Name
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "15%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Project Lead
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Start Date
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Members
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                        maxWidth: 100,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total Task
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Status
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        width: "10%",
                        fontSize: 14,
                        borderRight: 1,
                        borderColor: "transparent",
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Action
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
              </Mui.Table>
            </Mui.TableContainer>
          ) : null}
        </Mui.Grid>
      </div>
    </React.Fragment>
  );
};

export const useStylesIcon = makeStyles(() =>
  createStyles({
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);
