import React from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { tableRowSx } from "services/constants";
import { shiftDetailsHeader } from "pages/Administration/AdministrationHeaders";
export const ShiftDetails = ({
  shiftName,
  shiftTimeDetails,
  overTimeDetails,
}) => {
  return (
    <React.Fragment>
      <Mui.TableContainer sx={{ width: "100%" }}>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow sx={tableRowSx}>
              {shiftDetailsHeader.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  {header.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            <Mui.TableRow sx={tableRowSx}>
              <Mui.TableCell>{shiftName}</Mui.TableCell>
              <Mui.TableCell>{shiftTimeDetails?.minStartTime}</Mui.TableCell>
              <Mui.TableCell>{shiftTimeDetails?.startTime}</Mui.TableCell>
              <Mui.TableCell>{shiftTimeDetails?.maxStartTime}</Mui.TableCell>
              <Mui.TableCell>{shiftTimeDetails?.minEndTime}</Mui.TableCell>
              <Mui.TableCell>{shiftTimeDetails?.endTime}</Mui.TableCell>
              <Mui.TableCell>{shiftTimeDetails?.maxEndTime}</Mui.TableCell>
            </Mui.TableRow>
            <Mui.TableRow sx={tableRowSx}>
              <Mui.TableCell>OverTime</Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell>{overTimeDetails?.startTime}</Mui.TableCell>
              <Mui.TableCell>{overTimeDetails?.maxStartTime}</Mui.TableCell>
              <Mui.TableCell>{overTimeDetails?.endTime}</Mui.TableCell>
              <Mui.TableCell>{overTimeDetails?.maxEndTime}</Mui.TableCell>
              <Mui.TableCell></Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
    </React.Fragment>
  );
};
