export const workingHoursHeader = [
  { header: "Date" },
  { header: "Shift" },
  { header: "Check-In" },
  { header: "Check-Out" },
  { header: "Break" },
  { header: "Shift Hours" },
  { header: "Over Time Hours" },
  { header: "Total Working hours" },
  { header: "Leave Credit Hours" },
  { header: "Status" },
];

export const firstLevelWorkingHours = [
  { header: "Name", key: "username" },
  { header: "No.of Shift", key: "attendant_shift_count" },
  { header: "Total Hours", key: "weekly_working_hours" },
  { header: "Over Time", key: "ot_hours" },
  { header: "Leave", key: "leave_count" },
  { header: "Permission", key: "permissions_taken" },
  { header: "Late", key: "late_count" },
  { header: "Total break in hours", key: "total_break_hours" },
];
