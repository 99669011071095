import React, { useState, useEffect } from "react";
import { BackArrow } from "components/CustomComponents/BackArrow";
import * as Mui from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { customGetAPI } from "services/utils/CustomAPI";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import dayjs from "dayjs";
export const AddEmployee = ({ setAddEmployee, editShiftGroup }) => {
  const {
    // register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { domain, token } = useSelector((state) => state.tokenReducer);
  //   const [departmentName, setDepartmentName] = useState(
  //     editShiftGroup.departments[0].department
  //   );
  const [leftSideEmployee, setLeftSideEmployee] = useState([]);
  const [rightSideEmployee, setRightSideEmployee] = useState([]);
  const [shiftGroup, setShiftGroup] = useState([]);
  const [leftChecked, setLeftChecked] = useState(false);
  const [rightChecked, setRightChecked] = useState(false);
  // const { result: department } = useFetch(
  //   `${domain}department-based-shiftcategory/?shift_category_id=${editShiftGroup.shift_category_id}`
  // );

  const departmentData = editShiftGroup.departments;

  // const { result: departmentData } = useFetch(
  //   `${domain}get_leads_based_on_departments`
  // );

  const [effectiveFrom, setEffectiveFrom] = useState(dayjs());
  const [departmentName, setDepartmentName] = useState(
    departmentData?.[0]?.department || ""
  );
  const [departmentIdValue, setdepartmentIdValue] = useState(
    departmentData?.[0]?.department_id || null
  );
  const [grpName, setGrpName] = useState("");
  const [grpId, setGrpId] = useState("");
  const [effectiveFromError, setEffectiveFromError] = useState(false);

  useEffect(() => {
    const fetchShiftGroup = async () => {
      try {
        const response = await fetch(
          `${domain}shift-group-based-shift-category/?shift_category_id=${editShiftGroup.shift_category_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setShiftGroup(res);
          setGrpName(res.length !== 0 ? res[0]?.shift_group_name : "");
          setGrpId(res.length !== 0 ? res[0]?.id : 99999);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (editShiftGroup.shift_category_id) {
      fetchShiftGroup();
    }
  }, [domain, editShiftGroup.id, editShiftGroup.shift_category_id, token]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const res = await customGetAPI(
        `${domain}unassign-shift-group-user/?shift_group_id=${grpId}&department_id=${departmentIdValue}`
      );
      if (res.ok) {
        let checkKey = res?.responseDetails?.map((data) => ({
          ...data,
          checked: false,
        }));
        setLeftSideEmployee(checkKey);
        setRightSideEmployee([]);
      }
    };

    if (departmentIdValue && grpId) {
      fetchEmployees();
    }
  }, [departmentIdValue, domain, grpId, token]);

  //   const handleDepartment = (event, data) => {
  //     if (data) {
  //       setValue("department_name", data?.label);
  //       setValue("id", data?.id);
  //       setDepartmentName(data?.label);
  //       setdepartmentIdValue(data?.id);
  //     } else {
  //       setValue("department_name", "");
  //       setValue("id", null);
  //       setDepartmentName("");
  //     }
  //   };

  const handleCheckedRight = () => {
    const data = leftSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = leftSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...rightSideEmployee, ...updateAsFalse];
    setRightSideEmployee(updatedData);
    setLeftSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const handleCheckedLeft = () => {
    const data = rightSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = rightSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...leftSideEmployee, ...updateAsFalse];
    setLeftSideEmployee(updatedData);
    setRightSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const onSubmit = async (data) => {
    if (
      rightSideEmployee.length !== 0 &&
      effectiveFrom &&
      effectiveFrom.toString() !== "Invalid Date"
    ) {
      let userId = [];
      rightSideEmployee.forEach((element) => {
        userId.push(element?.user_id);
      });
      setEffectiveFromError(false);
      try {
        const response = await fetch(`${domain}move-shift-group/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            new_shift_group_id: editShiftGroup?.id,
            old_shift_group_id: grpId,
            user_ids: userId,
            effective_date: effectiveFrom.format("YYYY-MM-DD"),
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          setAddEmployee(false);

          reset();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      if (effectiveFrom && effectiveFrom.toString() !== "Invalid Date") {
        showToast("error", "Please select atleast one users");
      } else {
        setEffectiveFromError(true);
      }
    }
  };

  // const handleToggleAllLeft = (value) => {
  //   setLeftChecked(false);
  //   setRightChecked(true);
  //   if (value) {
  //     let updatedData = leftSideEmployee.map((data) => {
  //       return {
  //         ...data,
  //         checked: true,
  //       };
  //     });
  //     setLeftSideEmployee(updatedData);
  //   } else {
  //     let updatedData = leftSideEmployee.map((data) => {
  //       return {
  //         ...data,
  //         checked: false,
  //       };
  //     });
  //     setLeftSideEmployee(updatedData);
  //   }
  // };

  // const numberOfChecked = () => {
  //   let count = 0;
  //   leftSideEmployee.forEach((data) => {
  //     if (data.checked) {
  //       count = count + 1;
  //     }
  //   });
  //   return count;
  // };

  const handleToggleLeft = (value) => () => {
    setLeftChecked(false);
    setRightChecked(true);
    if (value.checked) {
      let updatedData = leftSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: false,
          };
        } else {
          return data;
        }
      });
      setLeftSideEmployee(updatedData);
    } else {
      let updatedData = leftSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: true,
          };
        } else {
          return data;
        }
      });
      setLeftSideEmployee(updatedData);
    }
  };

  const handleToggleRigh = (value) => () => {
    setLeftChecked(true);
    setRightChecked(false);
    if (value.checked) {
      let updatedData = rightSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: false,
          };
        } else {
          return data;
        }
      });
      setRightSideEmployee(updatedData);
    } else {
      let updatedData = rightSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: true,
          };
        } else {
          return data;
        }
      });
      setRightSideEmployee(updatedData);
    }
  };

  const updateDepartmentName = (event, data) => {
    if (data) {
      setdepartmentIdValue(data.department_id);
      setDepartmentName(data.department);
    }
  };

  const handleEffectiveFrom = (value) => {
    setEffectiveFrom(value);
  };

  const handlAssignedTo = (event, data) => {
    if (data) {
      setValue("id", data?.id);
      setGrpName(data?.label);
      setValue("name", data?.label);
      setGrpId(data?.id);
    } else {
      setValue("name", "");
      setValue("id", null);
      setGrpName("");
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container>
          <BackArrow
            actionFunction={() => {
              setAddEmployee(false);
            }}
          />
          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
          >
            Shfit Group / Add Employee
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid container xs={12} spacing={2}>
          <Mui.Grid item xs={3.4}></Mui.Grid>
          <Mui.Grid item xs={3}>
            <Mui.Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
              Shift Category
            </Mui.Typography>
            <CustomTextBox
              selectedValue={editShiftGroup.shift_category_name}
              disabledValue={true}
            />
          </Mui.Grid>
          <Mui.Grid item xs={3}>
            <Mui.Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
              Department
            </Mui.Typography>
            <CustomAutoComplete
              dataList={departmentData?.map((obj) => ({
                ...obj,
                label: obj.department,
              }))}
              selectedValue={departmentName}
              updateDataFunction={updateDepartmentName}
              placeHolder="Select department name"
            />
          </Mui.Grid>

          <Mui.Grid item xs={2}>
            <Mui.Typography sx={{ fontWeight: "bold" }}>
              Effective From
            </Mui.Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  size="small"
                  slotProps={{ textField: { size: "small" } }}
                  sx={{ minWidth: "100%" }}
                  value={effectiveFrom}
                  format="DD/MM/YYYY"
                  onChange={handleEffectiveFrom}
                  renderInput={(params) => (
                    <Mui.TextField {...params} size="small" />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
            {effectiveFromError ? (
              <h6
                style={{
                  paddingTop: 2,
                  color: themes.helperTextColor,
                  fontSize: 12,
                }}
              >
                Effective from is required
              </h6>
            ) : null}
          </Mui.Grid>
        </Mui.Grid>
        <hr></hr>
        <Mui.Grid container>
          <Mui.Grid xs={0.8}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <Mui.Typography sx={{ fontWeight: "bold" }}>
              Assign Employees
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "Center",
            alignItems: "Center",
            paddingLeft: 13,
            paddingRight: 13,
          }}
        >
          <Mui.Grid
            container
            component={Mui.Paper}
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "Center",
              alignItems: "Center",
              padding: 2,
              backgroundColor: "#f7f5f6",
            }}
          >
            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.FormControl fullWidth>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    From Shift Group
                  </Mui.Typography>
                  <CustomAutoComplete
                    dataList={shiftGroup
                      .filter(
                        (obj) => obj.shift_group_name !== editShiftGroup.name
                      )
                      .map((obj) => ({
                        ...obj,
                        label: obj.shift_group_name,
                      }))}
                    selectedValue={grpName}
                    updateDataFunction={handlAssignedTo}
                    placeHolder="Please select the department name"
                    errorMessage={
                      errors?.department_name?.message
                        ? errors?.department_name?.message
                        : ""
                    }
                    textBoxError={errors?.department_name ? true : false}
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth>
                  <Mui.Grid
                    sx={{
                      paddingTop: 2,
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.Card>
                      <Mui.List
                        sx={{
                          width: "100%",
                          height: {
                            xs: 200,
                            sm: 300,
                            md: 300,
                            lg: 488,
                          },
                          bgcolor: "background.paper",
                          overflow: "auto",
                        }}
                        dense
                        component="div"
                        role="list"
                      >
                        {leftSideEmployee.length === 0 ? (
                          <Mui.ListItem>
                            <Mui.Alert sx={{ width: "100%" }} severity="info">
                              No employees available under the shift group
                            </Mui.Alert>
                          </Mui.ListItem>
                        ) : (
                          leftSideEmployee.map((value) => {
                            const labelId = `transfer-list-all-item-${value.user_id}-label`;

                            return (
                              <Mui.ListItemButton
                                key={value.user_id}
                                role="listitem"
                                onClick={handleToggleLeft(value)}
                              >
                                <Mui.ListItemIcon>
                                  <Mui.Checkbox
                                    checked={value.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </Mui.ListItemIcon>
                                <Mui.ListItemAvatar>
                                  <Mui.Avatar
                                    alt={value?.user_name?.charAt(0)}
                                    src={value?.image_url}
                                  />
                                </Mui.ListItemAvatar>
                                <Mui.ListItemText
                                  id={labelId}
                                  primary={`${value.user_name}`}
                                />
                              </Mui.ListItemButton>
                            );
                          })
                        )}
                      </Mui.List>
                    </Mui.Card>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={1.5} lg={1}>
              <Mui.Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Mui.IconButton
                  onClick={handleCheckedRight}
                  disabled={leftChecked}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleRight color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
                <Mui.IconButton
                  onClick={handleCheckedLeft}
                  disabled={rightChecked}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleLeft color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
            {/* Right Side */}
            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.FormControl fullWidth>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    To Shift Group
                  </Mui.Typography>
                  <CustomTextBox
                    selectedValue={editShiftGroup.name}
                    disabledValue={true}
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth>
                  <Mui.Grid
                    sx={{
                      paddingTop: 2,
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.FormControl fullWidth>
                      <Mui.Grid
                        sx={{
                          //   paddingTop: 2,
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                        component={Mui.Paper}
                      >
                        <Mui.Card>
                          <Mui.List
                            sx={{
                              width: "100%",
                              height: {
                                xs: 200,
                                sm: 300,
                                md: 300,
                                lg: 488,
                              },
                              bgcolor: "background.paper",
                              overflow: "auto",
                            }}
                            dense
                            component="div"
                            role="list"
                          >
                            {rightSideEmployee.map((value) => {
                              const labelId = `transfer-list-all-item-${value.user_id}-label`;

                              return (
                                <Mui.ListItemButton
                                  key={value.user_id}
                                  role="listitem"
                                  onClick={handleToggleRigh(value)}
                                >
                                  <Mui.ListItemIcon>
                                    <Mui.Checkbox
                                      checked={value.checked}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </Mui.ListItemIcon>
                                  <Mui.ListItemAvatar>
                                    <Mui.Avatar
                                      alt={value?.user_name?.charAt(0)}
                                      src={value?.image_url}
                                    />
                                  </Mui.ListItemAvatar>
                                  <Mui.ListItemText
                                    id={labelId}
                                    primary={`${value.user_name}`}
                                  />
                                </Mui.ListItemButton>
                              );
                            })}
                          </Mui.List>
                        </Mui.Card>
                      </Mui.Grid>
                    </Mui.FormControl>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid container sx={{ mt: 2 }}>
              <Mui.Grid xs={8.5}></Mui.Grid>
              <Mui.Grid
                xs={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionName="Cancel"
                  typeName="button"
                  actionFuntion={() => {
                    setAddEmployee(false);
                  }}
                />
                &nbsp; &nbsp;
                <CustomButton actionName="Add" typeName="submit" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
