import React, { useCallback, useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { showToast } from "services/utils/Status";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useFetch } from "services/hooks/useFetch";
import dayjs from "dayjs";
export const MoveShiftGroup = ({
  setMoveShiftGroup,
  setShowTopBar,
  reCallApi,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { domain, token } = useSelector((state) => state.tokenReducer);

  const [shiftCategories, setShiftCategories] = useState([]);
  const [shiftCategoryName, setShiftCategoryName] = useState("");
  const { result: departmentData } = useFetch(
    `${domain}get_leads_based_on_departments`
  );
  const [departmentName, setDepartmentName] = useState("");
  const [departmentIdValue, setDepartmentIdValue] = useState("");
  const [RigthgroupName, setRightGroupName] = useState("");
  const [leftSideEmployee, setLeftSideEmployee] = useState([]);
  const [rightSideEmployee, setRightSideEmployee] = useState([]);
  const [leftChecked, setLeftChecked] = useState(false);
  const [rightChecked, setRightChecked] = useState(false);
  const [newGroupId, setNewGroupId] = useState([]);
  const [shiftGroup, setShiftGroup] = useState([]);
  const [grpName, setGrpName] = useState("");
  const [grpId, setGrpId] = useState("");
  const [effectiveFrom, setEffectiveFrom] = useState(dayjs());
  const [effectiveFromError, setEffectiveFromError] = useState(false);

  const onSubmit = async (data) => {
    if (
      rightSideEmployee.length !== 0 &&
      effectiveFrom &&
      effectiveFrom.toString() !== "Invalid Date"
    ) {
      let userId = [];
      rightSideEmployee.forEach((element) => {
        userId.push(element?.user_id);
      });
      setEffectiveFromError(false);
      try {
        const response = await fetch(`${domain}move-shift-group/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            old_shift_group_id: grpId,
            new_shift_group_id: newGroupId,
            user_ids: userId,
            effective_date: effectiveFrom.format("YYYY-MM-DD"),
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setMoveShiftGroup(false);
          setShowTopBar(true);
          showToast("success", res.message);
          reset();
          reCallApi(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      if (effectiveFrom && effectiveFrom.toString() !== "Invalid Date") {
        showToast("error", "Please select atleast one users");
      } else {
        setEffectiveFromError(true);
      }
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch(
          `${domain}assigned-shift-group-user/?shift_group_id=${grpId}&department_id=${departmentIdValue}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          let checkKey = res.map((data) => ({
            ...data,
            checked: false,
          }));
          setLeftSideEmployee(checkKey);
          setRightSideEmployee([]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (grpId && departmentIdValue) {
      fetchEmployees();
    }
  }, [departmentIdValue, domain, grpId, token]);

  const handleToggleLeft = (value) => () => {
    setLeftChecked(false);
    setRightChecked(true);
    if (value.checked) {
      let updatedData = leftSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: false,
          };
        } else {
          return data;
        }
      });
      setLeftSideEmployee(updatedData);
    } else {
      let updatedData = leftSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: true,
          };
        } else {
          return data;
        }
      });
      setLeftSideEmployee(updatedData);
    }
  };

  const handleToggleRigh = (value) => () => {
    setLeftChecked(true);
    setRightChecked(false);
    let updatedData = rightSideEmployee.map((data) =>
      data.user_id === value.user_id
        ? { ...data, checked: !value.checked }
        : data
    );
    setRightSideEmployee(updatedData);
  };

  const handleCheckedRight = () => {
    const data = leftSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = leftSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...rightSideEmployee, ...updateAsFalse];
    setRightSideEmployee(updatedData);
    setLeftSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const handleCheckedLeft = () => {
    const data = rightSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = rightSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...leftSideEmployee, ...updateAsFalse];
    setLeftSideEmployee(updatedData);
    setRightSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const handlAssignedTo = (event, data) => {
    if (data) {
      setValue("id", data?.id);
      setGrpName(data?.label);
      setValue("name", data?.label);
      setGrpId(data?.id);
    } else {
      setValue("name", "");
      setValue("id", null);
      setGrpName("");
    }
  };

  const handleAssignedFrom = (event, data) => {
    if (data) {
      setValue("id", data?.id);
      setRightGroupName(data?.label);
      setValue("workunit_group", data?.label);
      setNewGroupId(data?.id);
    } else {
      setValue("workunit_group", "");
      setValue("id", null);
      setRightGroupName("");
    }
  };

  const updateDepartmentName = (event, data) => {
    if (data) {
      setDepartmentIdValue(data.department_id);
      setDepartmentName(data.department_name);
      setShiftCategories(data?.shift_categories || []);
      setShiftCategoryName(
        data?.shift_categories.length !== 0
          ? data?.shift_categories[0].name
          : ""
      );
      if (data?.shift_categories.length !== 0) {
        fetchShiftGroup(data?.shift_categories[0].id);
      }

      setGrpName("");
      setShiftGroup([]);
    }
  };

  const fetchShiftGroup = useCallback(
    async (shift) => {
      try {
        const response = await fetch(
          `${domain}shift-group-based-shift-category/?shift_category_id=${shift}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setShiftGroup(res);
          setGrpName(res.length !== 0 ? res[0]?.shift_group_name : "");
          setGrpId(res.length !== 0 ? res[0]?.id : 99999);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  const updateShiftCategoryName = (event, data) => {
    if (data) {
      setShiftCategoryName(data.name);
      fetchShiftGroup(data?.id);
    }
  };

  const handleEffectiveFrom = (value) => {
    setEffectiveFrom(value);
  };

  useEffect(() => {
    if (departmentData?.[1]) {
      const department = departmentData[1];
      setDepartmentName(department.department_name || "");
      setDepartmentIdValue(department.department_id || null);
      const shiftCategories = department.shift_categories || [];
      setShiftCategories(shiftCategories);
      if (shiftCategories.length > 0) {
        const defaultShiftCategory = shiftCategories[0];
        setShiftCategoryName(defaultShiftCategory.name || "");

        fetchShiftGroup(defaultShiftCategory.id);
      } else {
        setShiftCategoryName("");
        fetchShiftGroup("99999");
      }
    }
  }, [departmentData, fetchShiftGroup]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container>
          <Mui.Grid item>
            <BackArrow
              actionFunction={() => {
                setMoveShiftGroup(false);
                setShowTopBar(true);
              }}
            />
          </Mui.Grid>
          <Mui.Grid item>
            <Mui.Typography
              sx={{ fontSize: 24, fontWeight: 700, paddingTop: 1 }}
            >
              Shift Group / Change Shift Group
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container xs={12} spacing={2}>
          <Mui.Grid item xs={3.8}></Mui.Grid>
          <Mui.Grid item xs={3}>
            <Mui.Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
              Department
            </Mui.Typography>
            <CustomAutoComplete
              dataList={departmentData
                ?.filter((obj) => obj.department_name !== "All")
                .map((obj) => ({
                  ...obj,
                  label: obj.department_name,
                }))}
              selectedValue={departmentName}
              updateDataFunction={updateDepartmentName}
              placeHolder="Select department name"
            />
          </Mui.Grid>
          <Mui.Grid item xs={3}>
            <Mui.Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
              Shift Category
            </Mui.Typography>
            <CustomAutoComplete
              dataList={shiftCategories?.map((obj) => ({
                ...obj,
                label: obj.name,
              }))}
              disabled={!departmentName}
              selectedValue={shiftCategoryName}
              updateDataFunction={updateShiftCategoryName}
              placeHolder="Select Shift Category Name"
            />
          </Mui.Grid>

          <Mui.Grid item xs={2.2}>
            <Mui.Typography sx={{ fontWeight: "bold" }}>
              Effective From
            </Mui.Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  size="small"
                  slotProps={{ textField: { size: "small" } }}
                  sx={{ minWidth: "100%" }}
                  value={effectiveFrom}
                  format="DD/MM/YYYY"
                  onChange={handleEffectiveFrom}
                  renderInput={(params) => (
                    <Mui.TextField {...params} size="small" />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
            {effectiveFromError ? (
              <h6
                style={{
                  paddingTop: 2,
                  color: themes.helperTextColor,
                  fontSize: 12,
                }}
              >
                Effective from is required
              </h6>
            ) : null}
          </Mui.Grid>
        </Mui.Grid>
        <hr></hr>
        <Mui.Grid container>
          <Mui.Grid xs={0.8}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <Mui.Typography sx={{ fontWeight: "bold" }}>
              Assign Employees
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "Center",
            alignItems: "Center",
            paddingLeft: 13,
            paddingRight: 13,
          }}
        >
          <Mui.Grid
            container
            component={Mui.Paper}
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "Center",
              alignItems: "Center",
              padding: 2,
              backgroundColor: "#f7f5f6",
            }}
          >
            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.FormControl fullWidth>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    From Shift Group
                  </Mui.Typography>
                  <CustomAutoComplete
                    dataList={shiftGroup
                      .filter((obj) => obj.shift_group_name !== RigthgroupName)
                      .map((obj) => ({
                        ...obj,
                        label: obj.shift_group_name,
                      }))}
                    selectedValue={grpName}
                    updateDataFunction={handlAssignedTo}
                    placeHolder="Unassigned Group"
                    errorMessage={
                      errors?.name?.message ? errors?.name?.message : ""
                    }
                    textBoxError={errors?.name ? true : false}
                  />
                  <Mui.Grid
                    sx={{
                      paddingTop: 2,
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.Card>
                      <Mui.List
                        sx={{
                          width: "100%",
                          height: {
                            xs: 200,
                            sm: 300,
                            md: 300,
                            lg: 488,
                          },
                          bgcolor: "background.paper",
                          overflow: "auto",
                        }}
                        dense
                        component="div"
                        role="list"
                      >
                        {leftSideEmployee.length === 0 ? (
                          <Mui.ListItem>
                            <Mui.Alert sx={{ width: "100%" }} severity="info">
                              No employees available under the shift group
                            </Mui.Alert>
                          </Mui.ListItem>
                        ) : (
                          leftSideEmployee.map((value) => {
                            const labelId = `transfer-list-all-item-${value.user_id}-label`;

                            return (
                              <Mui.ListItemButton
                                key={value.user_id}
                                role="listitem"
                                onClick={handleToggleLeft(value)}
                              >
                                <Mui.ListItemIcon>
                                  <Mui.Checkbox
                                    checked={value.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </Mui.ListItemIcon>
                                <Mui.ListItemAvatar>
                                  <Mui.Avatar
                                    alt={value?.user_name?.charAt(0)}
                                    src={value?.image_url}
                                  />
                                </Mui.ListItemAvatar>
                                <Mui.ListItemText
                                  id={labelId}
                                  primary={`${value.user_name}`}
                                />
                              </Mui.ListItemButton>
                            );
                          })
                        )}
                      </Mui.List>
                    </Mui.Card>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={1.5} lg={1}>
              <Mui.Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Mui.IconButton
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleRight color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
                <Mui.IconButton
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleLeft color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.FormControl fullWidth>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    To Shift Group
                  </Mui.Typography>
                  <CustomAutoComplete
                    dataList={shiftGroup
                      .filter((obj) => obj.shift_group_name !== grpName)
                      .map((obj) => ({
                        ...obj,
                        label: obj.shift_group_name,
                      }))}
                    selectedValue={RigthgroupName}
                    rules={{
                      ...register("workunit_group", {
                        required: "Shift group name is required",
                      }),
                    }}
                    updateDataFunction={handleAssignedFrom}
                    placeHolder="Select shift group"
                    errorMessage={
                      errors?.workunit_group?.message
                        ? errors?.workunit_group?.message
                        : ""
                    }
                    textBoxError={errors?.workunit_group ? true : false}
                  />
                  <Mui.Grid
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.Card>
                      <Mui.List
                        sx={{
                          width: "100%",
                          height: {
                            xs: 200,
                            sm: 300,
                            md: 300,
                            lg: 488,
                          },
                          bgcolor: "background.paper",
                          overflow: "auto",
                        }}
                        dense
                        component="div"
                        role="list"
                      >
                        {rightSideEmployee.map((value) => {
                          const labelId = `transfer-list-all-item-${value.user_id}-label`;

                          return (
                            <Mui.ListItemButton
                              key={value.user_id}
                              role="listitem"
                              onClick={handleToggleRigh(value)}
                            >
                              <Mui.ListItemIcon>
                                <Mui.Checkbox
                                  checked={value.checked}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </Mui.ListItemIcon>
                              <Mui.ListItemAvatar>
                                <Mui.Avatar
                                  alt={value?.user_name?.charAt(0)}
                                  src={value?.image_url}
                                />
                              </Mui.ListItemAvatar>
                              <Mui.ListItemText
                                id={labelId}
                                primary={`${value.user_name}`}
                              />
                            </Mui.ListItemButton>
                          );
                        })}
                      </Mui.List>
                    </Mui.Card>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid container sx={{ mt: 2 }}>
              <Mui.Grid xs={10.2}></Mui.Grid>
              <CustomButton
                actionName="Cancel"
                typeName="button"
                actionFuntion={() => {
                  setMoveShiftGroup(false);
                  setShowTopBar(true);
                }}
              />
              &nbsp;&nbsp;
              <CustomButton actionName="Move" typeName="submit" />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
