import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { showToast } from "services/utils/Status";
import { themes } from "services/constants";
import { DeductionDetails } from "./SalaryComponent/DeductionDetails";
// import { salaryType } from "services/constants";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { EarningDetails } from "./SalaryComponent/EarningDetails";
import { numberWithDotNumberRegex } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import Checkbox from "@mui/material/Checkbox";
import { useFetch } from "services/hooks/useFetch";
import { setEditPage } from "services/Redux/HRPortal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { customAPI } from "services/utils/CustomAPI";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const SalaryInfo = ({
  pageno,
  employeeDetails,
  setUpdateUserDetails,
  getSelectedUserDetails,
  updateUserDetails,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { domain } = useSelector((state) => state.tokenReducer);
  const [updateEarningDetails, setUpdateEarningDetails] = useState(true);
  const { result: payrollGroupData } = useFetch(
    employeeDetails.company_id !== 0
      ? `${domain}payroll-group/?company=${employeeDetails.company_id}`
      : null
  );
  const { result: salaryTemplate } = useFetch(
    employeeDetails.company_id !== 0
      ? `${domain}salary-templates/?company=${employeeDetails.company_id}`
      : null
  );
  const { result: salaryType } = useFetch(
    employeeDetails.company_id !== 0 ? `${domain}salarytype/` : null
  );
  const [errorHandling, setErrorHandling] = useState(false);
  const [specialAllowance, setSpecialAllowance] = useState(0);
  const [updateDeductionDetails, setUpdateDeductionDetails] = useState(true);
  const [checked, setChecked] = useState(
    employeeDetails?.template_linked ? employeeDetails?.template_linked : false
  );

  // New updates
  const [salaryComponentDetails, setSalaryComponentDetails] = useState(
    employeeDetails?.deductionDataDetails
      ? employeeDetails?.deductionDataDetails
      : []
  );

  const [salaryTemplateName, setSalaryTemplateName] = useState(
    employeeDetails?.salary_template_name
      ? employeeDetails?.salary_template_name
      : ""
  );
  const [salaryTemplateId, setSalaryTemplateId] = useState(
    employeeDetails?.salary_template_id
      ? employeeDetails?.salary_template_id
      : null
  );
  const [salaryTypeId, setSalaryTypeId] = useState(
    employeeDetails?.salary_type ? employeeDetails?.salary_type : null
  );
  const [emptyEarning, setEmptyEarning] = useState(false);
  const [emptyDeduction, setEmptyDedution] = useState(false);
  const [payrollGrpName, setPayrollGrpName] = useState(
    employeeDetails?.payroll_group_name
      ? employeeDetails?.payroll_group_name
      : ""
  );
  const [cycleName, setCycleName] = useState(
    employeeDetails?.pay_run_cycle_name
      ? employeeDetails?.pay_run_cycle_name
      : ""
  );
  const [payrollGroupId, setPayrollGroupId] = useState(
    employeeDetails?.payroll_group ? employeeDetails?.payroll_group : 0
  );
  //
  const [otherSalaryComponents, setOtherSalaryComponents] = useState(
    employeeDetails?.earnings ? employeeDetails?.earnings : []
  );
  const [updateMonthlySalary, setUpdateMonthlySalary] = useState(true);
  const [userDeductionDetails, setUserDeductionDetails] = useState(
    employeeDetails?.deductions ? employeeDetails?.deductions : []
  );
  const [monthlySalary, setMonthlySalary] = useState(0);
  const [salaryTypeName, setSalaryTypeName] = useState(
    employeeDetails?.salary_type_name
      ? employeeDetails?.salary_type_name
      : "Daily"
  );
  const [amount, setAmount] = useState(
    employeeDetails?.amount ? employeeDetails?.amount : 0
  );

  useEffect(() => {
    const updateMonthlySalaryData = () => {
      let salary = parseInt(employeeDetails?.amount);
      setMonthlySalary(salary);
      // if (employeeDetails?.salary_type === "Daily") {
      //   salary = parseInt(employeeDetails?.amount) * 30;
      //   setMonthlySalary(salary);
      // } else if (employeeDetails?.salary_type === "Weekly") {
      //   salary = parseInt(employeeDetails?.amount) * 4;
      //   setMonthlySalary(parseInt(employeeDetails?.amount) * 4);
      // } else if (employeeDetails?.salary_type === "Hourly") {
      //   salary = parseInt(employeeDetails?.amount) * 30 * 8;
      //   setMonthlySalary(parseInt(employeeDetails?.amount) * 30 * 8);
      // } else {
      //   salary = parseInt(employeeDetails?.amount);
      //   setMonthlySalary(parseInt(employeeDetails?.amount));
      // }
    };
    if (updateMonthlySalary) {
      updateMonthlySalaryData();
      setUpdateMonthlySalary(false);
    }
  }, [
    employeeDetails?.amount,
    employeeDetails?.salary_type,
    updateMonthlySalary,
  ]);

  const selectedSalaryTemplate = (event, data) => {
    if (data) {
      setSalaryTemplateName(data?.name);
      setSalaryTemplateId(data?.id);
      setSalaryTypeId(data?.salary_type);
      setUserDeductionDetails(data?.deductions);
      setSalaryTypeName(data?.salary_type_name);
      setOtherSalaryComponents(data?.earnings);
      setAmount(data?.amount);
      setMonthlySalary(data?.amount);
      setChecked(true);
      setErrorHandling(false);
      setUpdateDeductionDetails(true);
    } else {
      setSalaryTemplateName(null);
      setSalaryTemplateId(null);
    }
  };

  const handleSalaryType = (event, data) => {
    if (data) {
      setSalaryTypeName(data?.label);
      setSalaryTypeId(data?.id);
      setAmount(0);
    }
  };

  const handleAmount = (event) => {
    if (numberWithDotNumberRegex.test(event.target.value)) {
      let salary = parseInt(event.target.value);
      setMonthlySalary(salary);
      setAmount(event.target.value);
      // if (salaryTypeName === "Daily") {
      //   salary = parseInt(event.target.value) * 30;
      //   setMonthlySalary(salary);
      // } else if (salaryTypeName === "Hourly") {
      //   salary = parseInt(event.target.value) * 30 * 8;
      //   setMonthlySalary(salary);
      // } else if (salaryTypeName === "Weekly") {
      //   salary = parseInt(event.target.value) * 4;
      //   setMonthlySalary(parseInt(event.target.value) * 4);
      // } else {
      //   salary = parseInt(event.target.value);
      //   setMonthlySalary(parseInt(event.target.value));
      // }
      if (otherSalaryComponents.length !== 0) {
        let updateData = otherSalaryComponents?.map((element) => {
          return {
            ...element,
            amount:
              element.name === "Special Allowance" ? (salary ? salary : 0) : 0,
          };
        });
        setOtherSalaryComponents(updateData);
      }
    }
  };

  const handlePayrollGrpName = (event, data) => {
    if (data) {
      setPayrollGrpName(data?.name);
      setPayrollGroupId(data?.id);
      setCycleName(data?.pay_run_cycle_name);
    }
  };

  const onSubmit = async () => {
    const zeroAmoutEarnings = otherSalaryComponents.filter((data) => {
      return data?.amount === 0 || data?.amount === "0" || data.amount === "";
    });
    const zeroAmoutDeductions = userDeductionDetails.filter((data) => {
      return (
        data.deduction_type !== "F" &&
        data.employeeContributionAmount !== 0 &&
        data.companyContributionAmount === 0
      );
    });
    if (
      salaryTypeName !== "" &&
      payrollGrpName !== "" &&
      amount !== "" &&
      amount !== 0 &&
      zeroAmoutEarnings.length === 0 &&
      zeroAmoutDeductions.length === 0 &&
      zeroAmoutDeductions &&
      (salaryTemplateName === "" && checked ? false : true)
    ) {
      let payload = {
        salary_template_id: checked ? salaryTemplateId : null,
        payroll_group: payrollGroupId,
        earnings: otherSalaryComponents,
        deductions: userDeductionDetails,
        amount: amount,
        salary_type: salaryTypeId,
        template_linked: checked,
        user_id: employeeDetails.user_id,
      };
      const response = await customAPI(
        `${domain}employee-onboard`,
        "PUT",
        payload
      );
      if (response.ok) {
        showToast("success", "Employee details updated successfully");
        history.push("/admin/hr/employee");
      }
    } else {
      setErrorHandling(true);
      setEmptyEarning(true);
      setEmptyDedution(true);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          border: `1px solid ${themes.sidebarDivider}`,
          borderRadius: 3,
          paddingBottom: 1,
        }}
      >
        <Mui.Grid
          container
          sx={{
            borderBottom: `1px solid ${themes.sidebarDivider}`,
            padding: { xs: 0.4, sm: 0.4, md: 0.4, lg: 0.6, xl: 1, xxl: 1 },
          }}
        >
          <Mui.Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: themes.headLine,
              paddingLeft: 1,
            }}
          >
            Salary Informations
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid
          container
          // sx={{ borderBottom: `1px solid ${themes.sidebarDivider}` }}
        >
          <Mui.Grid container>
            <Mui.Typography
              sx={{ paddingLeft: 2, fontSize: 18, fontWeight: "bold" }}
            >
              Salary & Payroll
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid container xs={6} sx={{ padding: 1, paddingLeft: 2 }}>
              <Mui.Grid item xs={6}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Salary Templates
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={6}>
                <CustomAutoComplete
                  dataList={salaryTemplate?.map((obj) => ({
                    ...obj,
                    label: obj.name,
                  }))}
                  selectedValue={salaryTemplateName}
                  updateDataFunction={selectedSalaryTemplate}
                  placeHolder="Select the salary template"
                  errorMessage={
                    (salaryTemplateName === "" ||
                      salaryTemplateName === null) &&
                    checked
                      ? "Select the salary template"
                      : ""
                  }
                  textBoxError={
                    (salaryTemplateName === "" ||
                      salaryTemplateName === null) &&
                    checked
                      ? true
                      : false
                  }
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid xs={1}></Mui.Grid>
            <Mui.Grid container xs={4} sx={{ padding: 1 }}>
              <Mui.Grid item sx={{ paddingTop: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Link template to employee
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={1.3}></Mui.Grid>
              <Mui.Grid item xs={0.5}>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid container xs={6} sx={{ padding: 1, paddingLeft: 2 }}>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Payroll Group
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container xs={6}>
                <CustomAutoComplete
                  dataList={payrollGroupData.map((obj) => ({
                    ...obj,
                    label: obj.name,
                  }))}
                  selectedValue={payrollGrpName}
                  updateDataFunction={handlePayrollGrpName}
                  placeHolder="Please select the payroll group"
                  errorMessage={
                    errorHandling && payrollGrpName === ""
                      ? "Please select payroll group"
                      : ""
                  }
                  textBoxError={
                    errorHandling && payrollGrpName === "" ? true : false
                  }
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container xs={6} sx={{ padding: 1, paddingLeft: 2 }}>
              <Mui.Grid xs={1.92}></Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Payrun Cycle
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container xs={6}>
                <Mui.Typography sx={{ font: "bold" }}>
                  {cycleName}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              pointerEvents: checked ? "none" : "auto",
              opacity: checked ? 0.8 : 1,
            }}
          >
            <Mui.Grid container xs={6} sx={{ padding: 1, paddingLeft: 2 }}>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Salary Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container xs={6}>
                <CustomAutoComplete
                  dataList={salaryType.map((obj) => ({
                    ...obj,
                    label: obj.salary_type,
                  }))}
                  selectedValue={salaryTypeName}
                  updateDataFunction={handleSalaryType}
                  placeHolder="Please select the salaryType"
                  errorMessage={
                    errorHandling && salaryTypeName === ""
                      ? "Please select salary type"
                      : ""
                  }
                  textBoxError={
                    errorHandling && salaryTypeName === "" ? true : false
                  }
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{
              pointerEvents: checked ? "none" : "auto",
              opacity: checked ? 0.8 : 1,
            }}
          >
            <Mui.Grid container xs={6} sx={{ padding: 1, paddingLeft: 2 }}>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Salary
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container xs={6}>
                <CustomTextBox
                  selectedValue={amount}
                  updateDataFunction={handleAmount}
                  placeHolder="Enter the amount"
                  errorMessage={
                    errorHandling && (amount === "" || amount === 0)
                      ? "Please enter amount"
                      : ""
                  }
                  textBoxError={
                    errorHandling && (amount === "" || amount === 0)
                      ? true
                      : false
                  }
                  disableValue={checked}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid
        container
        sx={{
          paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5.5, xxl: 10 },
        }}
      />
      <Mui.Grid
        container
        sx={{
          pointerEvents: checked ? "none" : "auto",
          opacity: checked ? 0.8 : 1,
        }}
      >
        <EarningDetails
          otherSalaryComponents={otherSalaryComponents}
          salaryComponentDetails={salaryComponentDetails}
          setOtherSalaryComponents={setOtherSalaryComponents}
          existingUserDetails={employeeDetails}
          setSalaryComponentDetails={setSalaryComponentDetails}
          monthlySalary={monthlySalary}
          setUpdateDeductionDetails={setUpdateDeductionDetails}
          setSpecialAllowance={setSpecialAllowance}
          specialAllowance={specialAllowance}
          setUpdateEarningDetails={setUpdateEarningDetails}
          updateEarningDetails={updateEarningDetails}
          emptyEarning={emptyEarning}
          salaryTemplateId={salaryTemplateId}
        />
        <DeductionDetails
          otherSalaryComponents={otherSalaryComponents}
          existingUserDetails={employeeDetails}
          userDeductionDetails={userDeductionDetails}
          setUserDeductionDetails={setUserDeductionDetails}
          setUpdateDeductionDetails={setUpdateDeductionDetails}
          updateDeductionDetails={updateDeductionDetails}
          emptyDeduction={emptyDeduction}
          salaryTemplateId={salaryTemplateId}
        />
      </Mui.Grid>

      <Mui.Grid container sx={{ padding: 2 }}>
        <Mui.Grid xs={2}>
          <CustomButton
            actionFuntion={() => {
              dispatch(setEditPage(2));
            }}
            actionName="Previous"
            typeName="button"
          />
        </Mui.Grid>
        <Mui.Grid xs={8}></Mui.Grid>
        <Mui.Grid
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            actionName="Update"
            typeName="button"
            actionFuntion={() => {
              onSubmit();
            }}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
