import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { AssignGroupUnitHeader } from "pages/Administration/AdministrationHeaders";
import { useSelector } from "react-redux";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useFetch } from "services/hooks/useFetch";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
import { Delete, RemoveRedEye } from "@mui/icons-material";

const keys = [
  "id",
  "name",
  "department",
  "department_name",
  "employee_count",
  "start_date",
  "end_date",
];

export const AssignUnitTable = ({
  setRenderComponentName,
  setViewAssignDetails,
  setShowTopBar,
  viewAssignDetails,
  departmentId,
  selectComponent,
  refreshCall,
  setRefreshCall,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [updateData, setUpdateData] = useState(true);
  const [assignSummaryDetails, setAssignSummaryDetails] = useState([]);
  const [workUnitDataDetails, setWorkUnitDataDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {
    result: assignmentSummary,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}assignment_summary/?department=${departmentId}`);

  useEffect(() => {
    if (updateData && assignmentSummary?.length !== 0 && assignmentSummary) {
      setAssignSummaryDetails(assignmentSummary);
      setUpdateData(false);
    }
  }, [assignmentSummary, updateData]);

  useEffect(() => {
    if (selectComponent === "Assign" && refreshCall) {
      reCallApi(true);
      setRefreshCall(false);
    }
  });

  useEffect(() => {
    if (assignmentSummary?.length !== 0 || assignmentSummary?.length === 0) {
      const filteredResults = assignmentSummary?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setWorkUnitDataDetails(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, assignmentSummary]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const deleteWorkUnit = async (id) => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}work_unit_groups/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", res.message);
        setOpenDialog(false);
        reCallApi(true);
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  if (loading) {
    return (
      <div>
        <Loader info="Loading..." />
      </div>
    );
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <Mui.TableContainer
          sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "10px",
                    padding: "6px 15px",
                  },
                }}
              >
                {AssignGroupUnitHeader.map((head) => (
                  <Mui.TableCell
                    sx={{ color: themes.headLine, fontWeight: "bold" }}
                  >
                    {head.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {workUnitDataDetails.length === 0 ? (
                <Mui.TableRow>
                  <Mui.TableCell colSpan={8} align="center">
                    <Mui.Alert severity="info">No data available</Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                workUnitDataDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((account, index) => (
                    <React.Fragment>
                      <Mui.TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "6px 15px",
                          },
                          cursor: "pointer",
                        }}
                      >
                        {AssignGroupUnitHeader.map((head) =>
                          head.name === "Actions" ? (
                            <Mui.TableCell>
                              <Mui.Stack direction="row">
                                {/* <Mui.IconButton
                                onClick={() => {
                                  // setRenderComponentName("ViewAssignGroup");
                                  setViewAssignDetails(account);
                                  setRenderComponentName("EditAssignGroup");
                                  setShowTopBar(false);
                                }}
                              >
                                <Edit sx={{ fontSize: "10" }} />
                              </Mui.IconButton> */}
                                <Mui.IconButton
                                  onClick={() => {
                                    setViewAssignDetails(account);
                                    setRenderComponentName("ViewAssignGroup");
                                    setShowTopBar(false);
                                  }}
                                >
                                  <RemoveRedEye sx={{ fontSize: "10" }} />
                                </Mui.IconButton>
                                <Mui.IconButton
                                  onClick={() => {
                                    setViewAssignDetails(account);
                                    setOpenDialog(true);
                                  }}
                                >
                                  <Delete />
                                </Mui.IconButton>
                              </Mui.Stack>
                            </Mui.TableCell>
                          ) : (
                            <Mui.TableCell
                              onClick={() => {
                                setViewAssignDetails(account);
                                setRenderComponentName("ViewAssignGroup");
                                setShowTopBar(false);
                                //   history.push("/admin/work-unit/members");
                              }}
                            >
                              {account[head.key] ? account[head.key] : "N/A"}
                            </Mui.TableCell>
                          )
                        )}
                      </Mui.TableRow>
                    </React.Fragment>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
        <GlobalDialogBox
          handleCloseDialog={handleCloseDialogBox}
          open={openDialog}
          title="Delete WorkUnit Assign"
        >
          <Mui.Typography sx={{ paddingBottom: 2, fontSize: 21.5 }}>
            Are you sure,you want to delete the{" "}
            <span style={{ color: themes.redColor }}>
              {viewAssignDetails.name}
            </span>
            ?
          </Mui.Typography>
          <Mui.Grid container>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <CustomButton
                actionFuntion={() => setOpenDialog(false)}
                actionName="No"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={() => deleteWorkUnit(viewAssignDetails.id)}
                actionName="Yes"
                typeName="button"
                disableAction={
                  openDialog && buttonDisabled ? buttonDisabled : false
                }
              />
            </Mui.Grid>
          </Mui.Grid>
        </GlobalDialogBox>
        <Mui.Grid
          container
          sx={{
            paddingTop: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 1,
          }}
        >
          {workUnitDataDetails?.length > 25 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={workUnitDataDetails?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.Grid>
      </React.Fragment>
    </React.Fragment>
  );
};
