export const leaveManagamentPlaceholder = {
  rejectionLeave: "Enter the reason",
  taxyearLeave: "Select the tax year",
  dateRange: "Select a date range",
  dateFormat: "DD/MM/YYYY - DD/MM/YYYY",
};

export const projectPlaceholder = {
  clientName: "Enter client name",
  clientCode: "Enter client code",
  gstNo: "GSTNO in format 22AAAAA0000A1Z5",
  clientAddress: "Enter address",
  clientEmail: "Enter Email",
  phoneNumber: "Enter phone number",
  projectName: "Enter your project name",
  projectCode: "Enter your project code",
  projectLead: "Select Project Lead",
  selectClientName: "Select client name",
  selectCompany: "Select company",
  selectClient: "Select the Client",
  selectClientId: "Select client ID",
  selectUser: "Select the user",
  selectPriority: "Select priority",
  sprintGoal: "Enter your sprint goal",
  selectDuration: "Select the duration",
  selectMaterial: "Select Material",
  enterLabelName: "Please enter label",
  selectProject: "Select project",
  selectSprint: "Select sprint",
  taskName: "Enter task name",
};
