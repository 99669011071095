import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Alert } from "@mui/material";
import { getStatusColor } from "services/utils/Status";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
import { useDispatch } from "react-redux";
import { setLeaveStatus } from "services/Redux/leaveManagement";
import sessionHandling from "services/utils/notificationUtils";

export const Leave = ({ date, departmentid }) => {
  const themes = useSelector((state) => state.themeReducer);
  const { token, domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  let onLeaveStatus = useSelector((state) => state.leaveReducer.onLeaveStatus);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}checkinfilter/?date=${date}&department_id=${departmentid}&status=Leave`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.user_status_details) {
            setData(res?.user_status_details);
            setMasterDataForSearch(res?.user_status_details);
          }
          dispatch(setLeaveStatus(false));
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (onLeaveStatus) {
      fetchData();
    }
  }, [date, departmentid, dispatch, domain, onLeaveStatus, token]);

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setData(FilterDockets());
  }, [globalSearchValue, masterDataForSearch]);

  if (!data || data.length === 0) {
    return <Alert severity="info">No data available</Alert>;
  }
  return (
    <>
      {loading ? (
        <Loader info="Loading..." />
      ) : data.length === 0 ? (
        <Alert severity="info">No data available</Alert>
      ) : (
        <Grid container spacing={0} sx={{ justifyContent: "center" }}>
          {data.length > 0 ? (
            <>
              {data.map((item) => (
                <Grid item key={item.id}>
                  <Card
                    className="max-w-fit mt-5 w-96 flex space-x-1 shadow-lg  "
                    variant="outlined"
                    sx={{
                      borderRadius: 2,
                      marginLeft: 3,
                      marginTop: 5,
                    }}
                  >
                    <CardContent>
                      <Mui.Grid container>
                        <Mui.Grid item xs={2.5}>
                          <Mui.Avatar
                            sx={{
                              width: 40,
                              height: 40,
                              backgroundColor: "#ff4d88",
                            }}
                            alt={item.user_name?.charAt(0).toUpperCase()}
                            src={item.image_url}
                          />
                        </Mui.Grid>
                        <Mui.Grid
                          item
                          xs={9.5}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Mui.Stack>
                            <Mui.Typography
                              fontWeight={"bold"}
                              sx={{
                                fontSize:
                                  item.user_name.length < 15 ? "18px" : "15px",
                              }}
                            >
                              {item.user_name
                                ? item.user_name.length >= 15
                                  ? item.user_name.substr(0, 15) + "..."
                                  : item.user_name
                                : "N/A"}
                            </Mui.Typography>
                            <Mui.Grid container>
                              <Mui.Grid item xs={10}>
                                <Mui.Typography>
                                  {item?.user_code ? item?.user_code : "N/A"}
                                </Mui.Typography>
                              </Mui.Grid>
                              <Mui.Grid item xs={2}>
                                <Mui.Box
                                  sx={{
                                    width: 80,
                                    height: 15,
                                    backgroundColor: getStatusColor(item.type),
                                    borderRadius: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: themes.whiteColor,
                                    p: 2,
                                    fontSize: 13,
                                  }}
                                >
                                  {" "}
                                  {item.type}
                                </Mui.Box>
                              </Mui.Grid>
                            </Mui.Grid>
                          </Mui.Stack>
                        </Mui.Grid>
                        <Mui.Grid item xs={3}></Mui.Grid>
                        <Mui.Grid item xs={0.5}></Mui.Grid>
                      </Mui.Grid>
                      <div className="mt-2 ">
                        <Grid item xs={6} sx={{ marginTop: 1 }}>
                          <Typography
                            sx={{ color: themes.primaryIndicator }}
                            fontSize={18}
                          >
                            Check-In
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{ marginTop: -3.5, marginLeft: 20 }}
                        >
                          <Typography
                            sx={{ color: themes.primaryIndicator }}
                            fontSize={18}
                          >
                            Check-Out
                          </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography sx={{ mt: 0, ml: 2 }} fontSize={16}>
                              {item.check_in_time &&
                              item.check_in_time !== "null"
                                ? new Date(
                                    `2000-01-01T${item.check_in_time}`
                                  ).toLocaleString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                : "N/A"}{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ mt: 0, ml: 5 }} fontSize={16}>
                              {item.check_out_time &&
                              item.check_out_time !== "null"
                                ? new Date(
                                    `2000-01-01T${item.check_out_time}`
                                  ).toLocaleString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                : "N/A"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <br></br>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <Alert sx={{ marginTop: 5 }} severity="info">
              No data available
            </Alert>
          )}
        </Grid>
      )}
    </>
  );
};
