import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { themes } from "services/constants";
import { setCurrentScreen } from "services/Redux/userManagament";
import { Loader } from "components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { tableRowSx } from "services/constants";
import {
  setDepartmentId,
  setDepartmentLeadId,
  setDepartmentName,
} from "services/Redux/userManagament";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";

export const ListView = ({ departmentList, listViewPage, setListViewPage }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, domain } = useSelector((state) => state.tokenReducer);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [expendedId, setExpendedId] = useState(0);
  const [expandedRows, setExpandedRows] = useState(false);
  const [teamMemberApiLoading, setTeamMemberApiLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTeamMembers, setCurrentTeamMembers] = useState([]);
  const fetchTeamMembers = async (teamLeadId, teamId) => {
    setTeamMemberApiLoading(true);
    try {
      const response = await fetch(
        `${domain}get_department_members/${teamLeadId}/${teamId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setCurrentTeamMembers(res.members_data);
        setTeamMemberApiLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setTeamMemberApiLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {departmentList?.length > 0 ? (
        <Mui.TableContainer component={Mui.Paper} sx={{ mt: 2, width: "100%" }}>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell />
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Department Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Manager
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Proxy
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Total
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Planned
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Present
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Over Time
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Absent
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.headLine, fontWeight: "bold" }}
                >
                  Action
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {departmentList
                ?.filter((dep) => dep.department_name !== "All")
                .slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                ?.map((department) => (
                  <React.Fragment
                    key={`${department.department_lead_id}-${department.department_id}`}
                  >
                    <Mui.TableRow sx={tableRowSx}>
                      <Mui.TableCell align="center">
                        {expendedId === department.department_id &&
                        expandedRows ? (
                          <Mui.IconButton
                            onClick={() => {
                              setExpandedRows(false);
                              setExpendedId(0);
                            }}
                          >
                            <KeyboardArrowDownIcon
                              style={{
                                color: themes.headLine,
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() => {
                              setCurrentTeamMembers([]);
                              setExpandedRows(
                                expendedId === department.department_id ||
                                  expendedId === 0
                                  ? !expandedRows
                                  : expandedRows
                              );
                              setExpendedId(department.department_id);
                              fetchTeamMembers(
                                department.department_lead_id,
                                department.department_id
                              );
                            }}
                          >
                            <KeyboardArrowRightIcon
                              style={{
                                color: themes.headLine,
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        )}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department.department_name
                          ? department.department_name
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        <Mui.Stack direction="row">
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt={department?.department_lead_name?.charAt(0)}
                            src={department?.image_url}
                          />
                          <Mui.Typography
                            sx={{
                              paddingLeft: 1,
                            }}
                          >
                            {department.department_lead_name
                              ? department.department_lead_name
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Stack>
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department.proxy_name ? department.proxy_name : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department.total_staff_count ||
                        department.total_staff_count === 0
                          ? department.total_staff_count
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department.staff_planned_count ||
                        department.staff_planned_count === 0
                          ? department.staff_planned_count
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department.staff_present_count ||
                        department.staff_present_count === 0
                          ? department.staff_present_count
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department.staff_overtime_count ||
                        department.staff_overtime_count === 0
                          ? department.staff_overtime_count
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {department.staff_absent_count ||
                        department.staff_absent_count === 0
                          ? department.staff_absent_count
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        <Mui.IconButton
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setExpandedRows(false);
                            setExpendedId(0);
                            localStorage.setItem(
                              "approveLeaveDepartmentId",
                              department.department_id
                            );
                            localStorage.setItem(
                              "approveLeaveDepartmentName",
                              department.department_name
                            );
                            dispatch(setDepartmentId(department.department_id));
                            dispatch(
                              setDepartmentLeadId(department.department_lead_id)
                            );
                            dispatch(
                              setDepartmentName(department.department_name)
                            );
                          }}
                        >
                          <MoreVertIcon />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.Stack>
                            <Mui.Stack>
                              <Mui.MenuItem
                                onClick={() => {
                                  history.push(
                                    "/admin/attendance/view/members"
                                  );
                                }}
                              >
                                View Members
                              </Mui.MenuItem>
                            </Mui.Stack>
                          </Mui.Stack>
                          <Mui.Stack>
                            <Mui.MenuItem
                              onClick={() => {
                                dispatch(setCurrentScreen("Shift"));
                                localStorage.setItem("currentSubMenu", "Shift");
                                history.push("/admin/teamzone/plan-shift");
                              }}
                            >
                              Plan Shift
                            </Mui.MenuItem>
                          </Mui.Stack>
                        </Mui.Menu>
                      </Mui.TableCell>
                    </Mui.TableRow>
                    {expendedId === department.department_id ? (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={12}>
                          <Mui.Collapse
                            in={expandedRows}
                            timeout="auto"
                            unmountOnExit
                          >
                            {expendedId === department.department_id ? (
                              <Mui.Box
                                textAlign="center"
                                sx={{
                                  margin: 2,
                                }}
                              >
                                {expendedId === department.department_id &&
                                currentTeamMembers.length === 0 ? (
                                  teamMemberApiLoading ? (
                                    <Loader info="Loading..." />
                                  ) : (
                                    <Mui.Alert severity="info">
                                      No members have been assigned under this
                                      team.
                                    </Mui.Alert>
                                  )
                                ) : (
                                  expendedId === department.department_id && (
                                    <Mui.Table>
                                      <Mui.TableHead
                                        sx={{
                                          backgroundColor: themes.primary,
                                        }}
                                      >
                                        <Mui.TableRow sx={tableRowSx}>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.headLine,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Name
                                          </Mui.TableCell>

                                          <Mui.TableCell
                                            sx={{
                                              color: themes.headLine,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            User code
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.headLine,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Employment Type
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.headLine,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Designation
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.headLine,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Phone Number
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.headLine,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            System Access
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.headLine,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Status
                                          </Mui.TableCell>
                                        </Mui.TableRow>
                                      </Mui.TableHead>
                                      {expandedRows &&
                                        currentTeamMembers?.map(
                                          (member, index) => (
                                            <Mui.TableBody key={index}>
                                              <Mui.TableRow sx={tableRowSx}>
                                                <Mui.TableCell>
                                                  <Mui.Stack direction="Row">
                                                    <Mui.Avatar
                                                      sx={{
                                                        width: 25,
                                                        height: 25,
                                                      }}
                                                      alt="Remy Sharp"
                                                      src={member.image_url}
                                                    />
                                                    <Mui.Typography
                                                      sx={{
                                                        paddingLeft: 1,
                                                      }}
                                                    >
                                                      {member.department_member_name
                                                        ? member.department_member_name
                                                        : "N/A"}
                                                    </Mui.Typography>
                                                  </Mui.Stack>
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {member.user_code
                                                    ? member.user_code
                                                    : "N/A"}
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {member.employment_type
                                                    ? member.employment_type
                                                    : "N/A"}
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {member.designation
                                                    ? member.designation
                                                    : "N/A"}
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {member.phonenumber
                                                    ? member.phonenumber
                                                    : "N/A"}
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {member.system_access
                                                    ? "Yes"
                                                    : "No"}
                                                </Mui.TableCell>
                                                <Mui.TableCell>
                                                  {member.status
                                                    ? member.status
                                                    : "N/A"}
                                                </Mui.TableCell>
                                              </Mui.TableRow>
                                            </Mui.TableBody>
                                          )
                                        )}
                                    </Mui.Table>
                                  )
                                )}
                              </Mui.Box>
                            ) : null}
                          </Mui.Collapse>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ) : null}
                  </React.Fragment>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {departmentList.length > 25 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={departmentList.length}
              rowsPerPage={rowsPerPage}
              page={listViewPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      ) : teamMemberApiLoading ? null : (
        <Mui.Container maxWidth="xl" sx={{ marginTop: 2 }}>
          <Mui.Alert severity="info">No data available</Mui.Alert>
        </Mui.Container>
      )}
      {/* Loader */}
      {teamMemberApiLoading && (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      )}
    </React.Fragment>
  );
};
