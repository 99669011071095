import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { ShiftGradeViewEmployee } from "pages/Administration/AdministrationHeaders";
import { BackArrow } from "components/CustomComponents/BackArrow";

export const ViewEmployee = ({
  employeeDetails,
  setShowTopBar,
  setViewEmployee,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setViewEmployee(false);
            setShowTopBar(true);
          }}
        />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 24, paddingTop: 1 }}
        >
          Shift Grade / View Employee
        </Mui.Typography>
      </Mui.Grid>
      <Mui.TableContainer
        sx={{
          mt: 2,
          width: "100%",
          paddingLeft: 2,
          paddingRight: 2,
          maxHeight: 750,
        }}
      >
        <Mui.Grid container>
          <Mui.Grid>
            <Mui.Typography sx={{ fontWeight: "bold" }}>
              Total Number of Employee : 2
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow
              sx={{
                "& .MuiTableCell-root": {
                  height: "10px",
                  padding: "3px 15px",
                },
              }}
            >
              {ShiftGradeViewEmployee.map((head) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {head.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {employeeDetails?.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={8} align="center">
                  <Mui.Alert severity="info">No data available</Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              employeeDetails
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((account, index) => (
                  <React.Fragment>
                    <Mui.TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "10px",
                          padding: "6px 35px",
                        },
                      }}
                    >
                      {ShiftGradeViewEmployee.map((head) => (
                        <Mui.TableCell>
                          {account[head.key] ? account[head.key] : "N/A"}
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  </React.Fragment>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {employeeDetails?.length > 25 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            count={employeeDetails?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
