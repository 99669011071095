import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { showToast } from "services/utils/Status";
import { numberWithDecimalRegex } from "services/constants/patterns";
import { customAPI } from "services/utils/CustomAPI";
export const General = () => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const { result: globalSettingData, setLoad: reCallGlobalSettings } = useFetch(
    `${domain}global-settings/`
  );
  const [updateData, setUpdateData] = useState(true);
  const [unApprovedLeaves, setUnApprovedLeaves] = useState(
    globalSettingData ? globalSettingData[0]?.extra_absent_deduction : true
  );
  const [includeLeaveasPaid, setIncludeLeaveasPaid] = useState(
    globalSettingData ? globalSettingData[0]?.include_leave_as_paid : true
  );
  const [absentMultiplier, setAbsentMultiplier] = useState(
    globalSettingData ? globalSettingData[0]?.absent_multiplier : 0
  );

  useEffect(() => {
    if (globalSettingData.length !== 0 && updateData) {
      setUnApprovedLeaves(globalSettingData[0]?.extra_absent_deduction);
      setIncludeLeaveasPaid(globalSettingData[0]?.include_leave_as_paid);
      setAbsentMultiplier(globalSettingData[0]?.absent_multiplier);
      setUpdateData(false);
    }
  }, [globalSettingData, updateData]);

  const updateMultiplier = (value) => {
    if (numberWithDecimalRegex.test(value) && value !== "") {
      if (value) {
        if (parseInt(value) < 10) {
          setAbsentMultiplier(value);
          let payload = {
            include_leave_as_paid: globalSettingData[0]?.include_leave_as_paid,
            extra_absent_deduction:
              globalSettingData[0]?.extra_absent_deduction,
            absent_multiplier: value,
          };
          storeData(payload);
        }
      }
    } else {
      setAbsentMultiplier(value);
    }
  };

  const handleKeyPressOnlyNumber = (e) => {
    const charCode = e.charCode;
    const char = String.fromCharCode(charCode);

    if (!(char >= "0" && char <= "9") && char !== ".") {
      e.preventDefault();
      return;
    }

    const { value } = e.target;
    if (char === "." && value.includes(".")) {
      e.preventDefault();
      return;
    }
    const newValue = value + char;
    const regex = /^\d+(\.5?)?$/;
    if (!regex.test(newValue)) {
      e.preventDefault();
    }
  };

  const storeData = async (payload) => {
    const response = await customAPI(
      `${domain}global-settings/${globalSettingData[0]?.id}/`,
      "PATCH",
      payload
    );
    if (response.ok) {
      reCallGlobalSettings(true);
      showToast("success", "Settings updated successfully");
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1, paddingTop: 2 }}>
        <Mui.Typography
          sx={{ fontSize: 25, paddingLeft: 1, fontWeight: "bold" }}
        >
          Leaves
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid xs={8}>
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{ fontSize: 20, paddingLeft: 3, fontWeight: "bold" }}
              >
                Unapproved Leave/Absent
              </Mui.Typography>
              &nbsp; &nbsp; &nbsp;
              <Mui.Switch
                checked={unApprovedLeaves}
                onChange={(event) => {
                  setUnApprovedLeaves(!unApprovedLeaves);
                  let payload = {
                    include_leave_as_paid:
                      globalSettingData[0]?.include_leave_as_paid,
                    extra_absent_deduction: !unApprovedLeaves,
                    absent_multiplier: globalSettingData[0]?.absent_multiplier,
                  };
                  storeData(payload);
                }}
              />
            </Mui.Stack>
            <Mui.Typography
              sx={{ paddingLeft: 3, color: themes.generalSubHeader }}
            >
              No.of days salary to be deducted for each Absent
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={2}>
            {unApprovedLeaves ? (
              <Mui.TextField
                sx={{
                  background: themes.whiteColor,
                  width: 210,
                }}
                size="small"
                value={absentMultiplier}
                onChange={(e) => updateMultiplier(e.target.value)}
                autoFocus
                onKeyPress={handleKeyPressOnlyNumber}
              />
            ) : null}
          </Mui.Grid>
          <Mui.Grid sx={{ paddingTop: 2.5 }}>
            {unApprovedLeaves ? (
              <Mui.Typography
                xs={{
                  fontSize: 25,
                  paddingTop: 3,
                  color: themes.generalSubHeader,
                }}
              >
                Days
              </Mui.Typography>
            ) : null}
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingButtom: 1, paddingTop: 2 }}>
          <Mui.Grid xs={8}>
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{ fontSize: 20, paddingLeft: 3, fontWeight: "bold" }}
              >
                {/* Exceeding Approved Leaves */}
                Include Leave As Paid
              </Mui.Typography>{" "}
              &nbsp;
              <Mui.Switch
                onChange={(event) => {
                  setIncludeLeaveasPaid(!includeLeaveasPaid);
                  let payload = {
                    include_leave_as_paid: !includeLeaveasPaid,
                    extra_absent_deduction:
                      globalSettingData[0]?.extra_absent_deduction,
                    absent_multiplier: globalSettingData[0]?.absent_multiplier,
                  };
                  storeData(payload);
                }}
                checked={includeLeaveasPaid}
              />
            </Mui.Stack>
            <Mui.Typography
              sx={{ paddingLeft: 3, color: themes.generalSubHeader }}
            >
              All approved leaves are paid leaves
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={4}></Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      {/* <Mui.Grid container sx={{ paddingRight: 1, paddingTop: 2 }}>
        <Mui.Typography
          sx={{ fontSize: 25, paddingLeft: 1, fontWeight: "bold" }}
        >
          Over Time
        </Mui.Typography>
        <Mui.Grid container sx={{ paddingLeft: 3 }}>
          <Mui.Grid xs={1.5}>
            <Mui.Typography sx={{ paddingTop: 1 }}>
              For each overtime
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={2}>
            <CustomAutoComplete
              dataList={overTime}
              selectedValue={overTimeName}
              updateDataFunction={handleOverTimeName}
              placeHolder="Select hours/days"
            />
          </Mui.Grid>
          <Mui.Grid xs={0.5}>
            <Mui.Typography sx={{ paddingLeft: 1, paddingTop: 1 }}>
              rate ={" "}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={1}>
            <CustomTextBox
              selectedValue={rate}
              updateDataFunction={updateRateDetails}
            />
          </Mui.Grid>
          <Mui.Grid xs={2}>
            <Mui.Typography sx={{ paddingLeft: 1, paddingTop: 1 }}>
              times standard rate
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid> */}
    </React.Fragment>
  );
};
