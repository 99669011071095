import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { showToast } from "services/utils/Status";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { customGetAPI } from "services/utils/CustomAPI";
export const MoveEmployee = ({
  setMoveEmployee,
  reCallApi2,
  reCallApi,
  editShiftGroup,
  setUpdateData,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: department } = useFetch(
    `${domain}department-based-shiftcategory/?shift_category_id=${editShiftGroup.shift_category_id}`
  );

  const [groupName, setGroupName] = useState(
    editShiftGroup.departments[0].department
  );
  const [RigthgroupName, setRightGroupName] = useState("");
  const { result: workUnitGroup } = useFetch(
    `${domain}shift-group-based-shift-category/?shift_category_id=${editShiftGroup?.shift_category_id}`
  );
  const [leftSideEmployee, setLeftSideEmployee] = useState([]);
  const [rightSideEmployee, setRightSideEmployee] = useState([]);
  const [leftChecked, setLeftChecked] = useState(false);
  const [rightChecked, setRightChecked] = useState(false);
  const [groupId, setGroupId] = useState(editShiftGroup?.departments[0]?.id);
  const [newGroupId, setNewGroupId] = useState("");

  useEffect(() => {
    setValue("name", editShiftGroup.departments[0].department);
  }, [editShiftGroup.departments, setValue]);

  const onSubmit = async (data) => {
    if (rightSideEmployee.length !== 0) {
      let userId = [];
      rightSideEmployee.forEach((element) => {
        userId.push(element?.user_id);
      });
      try {
        const response = await fetch(`${domain}move-shift-group/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            old_shift_group_id: editShiftGroup?.id,
            new_shift_group_id: newGroupId,
            user_ids: userId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setMoveEmployee(false);
          reCallApi2(true);
          setTimeout(() => {
            setUpdateData(true);
          }, 200);
          showToast("success", res.message);
          reset();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      showToast("error", "Please select atleast one users");
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      const res = await customGetAPI(
        `${domain}assigned-shift-group-user/?shift_group_id=${editShiftGroup?.id}&department_id=${groupId}`
      );
      if (res.ok) {
        let checkKey = res.map((data) => ({
          ...data,
          checked: false,
        }));
        setLeftSideEmployee(checkKey);
        setRightSideEmployee([]);
      }
    };
    if (groupId) {
      fetchEmployees();
    }
  }, [domain, editShiftGroup?.id, groupId, token]);


  const filteredWorkUnitGroup = workUnitGroup.filter(
    (obj) => obj.id !== editShiftGroup?.id
  );

  const handleToggleLeft = (value) => () => {
    setLeftChecked(false);
    setRightChecked(true);
    if (value.checked) {
      let updatedData = leftSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: false,
          };
        } else {
          return data;
        }
      });
      setLeftSideEmployee(updatedData);
    } else {
      let updatedData = leftSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: true,
          };
        } else {
          return data;
        }
      });
      setLeftSideEmployee(updatedData);
    }
  };

  const handleToggleRigh = (value) => () => {
    setLeftChecked(true);
    setRightChecked(false);
    if (value.checked) {
      let updatedData = rightSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: false,
          };
        } else {
          return data;
        }
      });
      setRightSideEmployee(updatedData);
    } else {
      let updatedData = rightSideEmployee.map((data) => {
        if (data.user_id === value.user_id) {
          return {
            ...data,
            checked: true,
          };
        } else {
          return data;
        }
      });
      setRightSideEmployee(updatedData);
    }
  };

  const handleCheckedRight = () => {
    const data = leftSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = leftSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...rightSideEmployee, ...updateAsFalse];
    setRightSideEmployee(updatedData);
    setLeftSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const handleCheckedLeft = () => {
    const data = rightSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = rightSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...leftSideEmployee, ...updateAsFalse];
    setLeftSideEmployee(updatedData);
    setRightSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const handlAssignedTo = (event, data) => {
    if (data) {
      setValue("id", data?.id);
      setGroupName(data?.label);
      setValue("name", data?.label);
      setGroupId(data?.id);
    } else {
      setValue("name", "");
      setValue("id", null);
      setGroupName("");
    }
  };

  const handleAssignedFrom = (event, data) => {
    if (data) {
      setValue("id", data?.id);
      setRightGroupName(data?.label);
      setValue("workunit_group", data?.label);
      setNewGroupId(data?.id);
    } else {
      setValue("workunit_group", "");
      setValue("id", null);
      setRightGroupName("");
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid item>
            <BackArrow
              actionFunction={() => {
                setMoveEmployee(false);
                reCallApi2(true);
                reCallApi(true);
              }}
            />
          </Mui.Grid>
          <Mui.Grid item>
            <Mui.Typography
              sx={{ fontSize: 24, fontWeight: 700, paddingTop: 1 }}
            >
              {editShiftGroup.name} / Move Employee
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Grid
          container
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "Center",
            alignItems: "Center",
            paddingLeft: 25,
            paddingRight: 25,
          }}
        >
          <Mui.Grid
            container
            component={Mui.Paper}
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "Center",
              alignItems: "Center",
              padding: 2,
              backgroundColor: "#f7f5f6",
            }}
          >
            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "Center",
                    alignItems: "Center",
                  }}
                >
                  <Mui.Grid
                    component={Mui.Paper}
                    sx={{
                      backgroundColor: themes.darkPrimary,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <Mui.Typography
                      sx={{
                        padding: 1,
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                    >
                      Assigned From
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>

                <Mui.FormControl fullWidth>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Department
                  </Mui.Typography>
                  <CustomAutoComplete
                    dataList={department.map((obj) => ({
                      ...obj,
                      label: obj.department_name,
                    }))}
                    selectedValue={groupName}
                    rules={{
                      ...register("name", {
                        required: "Department is required",
                      }),
                    }}
                    updateDataFunction={handlAssignedTo}
                    placeHolder="Please select workunit group"
                    errorMessage={
                      errors?.name?.message ? errors?.name?.message : ""
                    }
                    textBoxError={errors?.name ? true : false}
                  />
                  <Mui.Grid
                    sx={{
                      paddingTop: 2,
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.Card>
                      <Mui.List
                        sx={{
                          width: "100%",
                          height: {
                            xs: 200,
                            sm: 300,
                            md: 300,
                            lg: 430,
                          },
                          bgcolor: "background.paper",
                          overflow: "auto",
                        }}
                        dense
                        component="div"
                        role="list"
                      >
                        {leftSideEmployee.map((value) => {
                          const labelId = `transfer-list-all-item-${value.user_id}-label`;

                          return (
                            <Mui.ListItemButton
                              key={value.user_id}
                              role="listitem"
                              onClick={handleToggleLeft(value)}
                            >
                              <Mui.ListItemIcon>
                                <Mui.Checkbox
                                  checked={value.checked}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </Mui.ListItemIcon>
                              <Mui.ListItemAvatar>
                                <Mui.Avatar
                                  alt={value?.user_name?.charAt(0)}
                                  src={value?.image_url}
                                />
                              </Mui.ListItemAvatar>
                              <Mui.ListItemText
                                id={labelId}
                                primary={`${value.user_name}`}
                              />
                            </Mui.ListItemButton>
                          );
                        })}
                      </Mui.List>
                    </Mui.Card>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={1.5} lg={1}>
              <Mui.Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Mui.IconButton
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleRight color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
                <Mui.IconButton
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleLeft color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "Center",
                    alignItems: "Center",
                  }}
                >
                  <Mui.Grid
                    component={Mui.Paper}
                    sx={{
                      backgroundColor: themes.darkPrimary,
                      paddingLeft: 5.5,
                      paddingRight: 5.5,
                    }}
                  >
                    <Mui.Typography
                      sx={{
                        padding: 1,
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                    >
                      Assigned To
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.FormControl fullWidth>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Move to
                  </Mui.Typography>
                  <CustomAutoComplete
                    dataList={filteredWorkUnitGroup.map((obj) => ({
                      ...obj,
                      label: obj.shift_group_name,
                    }))}
                    selectedValue={RigthgroupName}
                    rules={{
                      ...register("workunit_group", {
                        required: "Shift group name is required",
                      }),
                    }}
                    updateDataFunction={handleAssignedFrom}
                    placeHolder="Select shift group"
                    errorMessage={
                      errors?.workunit_group?.message
                        ? errors?.workunit_group?.message
                        : ""
                    }
                    textBoxError={errors?.workunit_group ? true : false}
                  />
                  <Mui.Grid
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.Card>
                      <Mui.List
                        sx={{
                          width: "100%",
                          height: {
                            xs: 200,
                            sm: 300,
                            md: 300,
                            lg: 430,
                          },
                          bgcolor: "background.paper",
                          overflow: "auto",
                        }}
                        dense
                        component="div"
                        role="list"
                      >
                        {rightSideEmployee.map((value) => {
                          const labelId = `transfer-list-all-item-${value.user_id}-label`;

                          return (
                            <Mui.ListItemButton
                              key={value.user_id}
                              role="listitem"
                              onClick={handleToggleRigh(value)}
                            >
                              <Mui.ListItemIcon>
                                <Mui.Checkbox
                                  checked={value.checked}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </Mui.ListItemIcon>
                              <Mui.ListItemAvatar>
                                <Mui.Avatar
                                  alt={value?.user_name?.charAt(0)}
                                  src={value?.image_url}
                                />
                              </Mui.ListItemAvatar>
                              <Mui.ListItemText
                                id={labelId}
                                primary={`${value.user_name}`}
                              />
                            </Mui.ListItemButton>
                          );
                        })}
                      </Mui.List>
                    </Mui.Card>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid container sx={{ mt: 2 }}>
              <Mui.Grid xs={10.7}></Mui.Grid>
              <CustomButton actionName="Update" typeName="submit" />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
