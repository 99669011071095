import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { customAPI } from "services/utils/CustomAPI";
import sessionHandling from "services/utils/notificationUtils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Info } from "@mui/icons-material";
import moment from "moment";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

export const Apply = () => {
  const { domain, userDetails, token } = useSelector(
    (state) => state.tokenReducer
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { result: users } = useFetch(
    `${domain}list/employee?user_id=${userDetails.id}`
  );

  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [weekOffDate, setWeekOffDate] = useState(null);
  const [validDates, setValidDates] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [error, setError] = useState(false);
  const [shiftStartTimeFormated, setShiftStartTimeFormated] = useState(null);
  const [shiftEndTimeFormated, setShiftEndTimeFormated] = useState(null);
  const [shiftStartDate, setShiftStartDate] = useState();
  const [shiftEndDate, setShiftEndDate] = useState();
  const [handleCheckInError, setHandleCheckInError] = useState("");
  const [checkinError, setCheckinError] = useState(false);
  const [sametimeError, setSameTimeError] = useState(false);

  const handleDateOnChange = (value) => {
    if (value) {
      setShiftStartDate(value);
    } else {
      setHandleCheckInError("Invalid check-in format");
    }
  };

  const handleCheckOutDateOnChange = (value) => {
    if (value) {
      setShiftEndDate(value);
    } else {
      setHandleCheckInError("Invalid check-out format");
    }
  };

  const formatTime = (timestamp) => {
    if (timestamp !== null) {
      const date = new Date(timestamp);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return null;
    }
  };

  const formattedCheckIn = formatTime(shiftStartTimeFormated);
  const formattedCheckOut = formatTime(shiftEndTimeFormated);

  const onSubmit = async () => {
    if (
      weekOffDate &&
      shiftStartTimeFormated !== null &&
      shiftEndTimeFormated !== null &&
      formattedCheckIn !== formattedCheckOut &&
      (shiftEndDate.format("DD-MM-YYYY") !== weekOffDate.format("DD-MM-YYYY") ||
        shiftStartTimeFormated < shiftEndTimeFormated)
    ) {
      setSameTimeError(false);
      setCheckinError(false);
      setSameTimeError(false);
      const response = await customAPI(`${domain}weekoff-approval/`, "POST", {
        start_date: shiftStartDate.format("DD-MM-YYYY"),
        end_date: shiftEndDate.format("DD-MM-YYYY"),
        check_in: formattedCheckIn,
        check_out: formattedCheckOut,
        user_id: selectedUserId,
      });
      if (response.ok) {
        showToast("success", response.responseDetails.message);
        setButtonLoader(false);
        setShiftStartTimeFormated(null);
        setShiftEndTimeFormated(null);
      }
    } else {
      if (
        (weekOffDate === null &&
          shiftStartTimeFormated === null &&
          shiftEndTimeFormated === null) ||
        (formattedCheckIn === formattedCheckOut &&
          formattedCheckIn > formattedCheckOut)
      ) {
        setError(true);
        setCheckinError(true);
      } else if (weekOffDate === null) {
        setError(true);
      } else if (
        (shiftStartTimeFormated === null && shiftEndTimeFormated === null) ||
        formattedCheckIn === formattedCheckOut
      ) {
        setCheckinError(true);
      } else if (formattedCheckIn > formattedCheckOut) {
        setSameTimeError(true);
        setCheckinError(false);
      }
    }
    setButtonLoader(false);
  };

  const handleWeekOffDate = (date) => {
    if (date) {
      const isValid = validDates.some((validDate) =>
        validDate.isSame(date, "day")
      );
      if (isValid) {
        setWeekOffDate(date);
        setShiftStartDate(date);
        setShiftEndDate(date);
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  const updateUserName = (event, data) => {
    if (data) {
      setValue("userName", data?.label);
      setValue("user_id", data?.user_id);
      setSelectedUserName(data?.label);
      setSelectedUserId(data?.user_id);
      setApiCall(true);
    } else {
      setValue("userName", null);
      setSelectedUserName(null);
      setSelectedUserId("");
      setApiCall(false);
    }
  };

  useEffect(() => {
    const fetchWeekOffDetais = async () => {
      try {
        const response = await fetch(
          `${domain}weekoff-approval/?user_id=${selectedUserId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setValidDates(res.Week_off_dates.map((date) => dayjs(date)));
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (selectedUserId && apiCall) {
      fetchWeekOffDetais();
      setApiCall(false);
    }
  }, [apiCall, domain, selectedUserId, token]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid
        container
        sx={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
        }}
      >
        <Mui.Grid
          container
          sx={{
            padding: 1,
            backgroundColor: themes.primary,
            borderRadius: "10px 10px 0 0",
            border: `2px solid ${themes.sidebarDivider}`,
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography
            sx={{
              fontSize: 24,
              fontWeight: 800,
              font: "bold",
              textAlign: "center",
            }}
          >
            Apply Week Off Day Attendance
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: -1,
            border: `2px solid ${themes.sidebarDivider}`,
            padding: 2,
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  fontWeight: "bold",
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 1,
                }}
              >
                Employee Name&nbsp;
                <Mui.Tooltip title=".">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <CustomAutoComplete
                dataList={users?.map((obj) => ({
                  ...obj,
                  label: obj.user_name,
                }))}
                selectedValue={selectedUserName}
                rules={{
                  ...register("userName", {
                    required: "User name is required",
                  }),
                }}
                updateDataFunction={updateUserName}
                placeHolder="Select the name"
                errorMessage={
                  errors?.userName?.message ? errors?.userName?.message : ""
                }
                textBoxError={errors.userName ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  paddingTop: 1,
                  paddingBottom: 1,
                  fontWeight: "bold",
                  paddingLeft: 1,
                }}
              >
                WeekOff Date&nbsp;
                <Mui.Tooltip title=".">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    size="small"
                    slotProps={{
                      textField: {
                        size: "small",
                        inputProps: { readOnly: true },
                      },
                    }}
                    value={weekOffDate}
                    format="DD/MM/YYYY"
                    onChange={handleWeekOffDate}
                    shouldDisableDate={(date) =>
                      !validDates.some((validDate) =>
                        dayjs(validDate, "DD/MM/YYYY").isSame(date, "day")
                      )
                    }
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        size="small"
                        fullWidth
                        error={Boolean(error)}
                        helperText={error}
                        sx={{ pointerEvents: "none" }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {error ? (
                <h6
                  style={{
                    paddingTop: 2,
                    color: themes.helperTextColor,
                    fontSize: 12,
                  }}
                >
                  WeekOff Date is required
                </h6>
              ) : null}
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            sx={{ border: `1px solid ${themes.sidebarDivider}`, mt: 2 }}
            container
          >
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 2 }}>
                <Mui.Typography
                  sx={{ fontSize: 20, fontWeight: "bold", paddingLeft: 1 }}
                >
                  Check in&nbsp;
                  <Mui.Tooltip title=".">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Grid container>
                  <Mui.Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          size="small"
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          sx={{ minWidth: "60%" }}
                          value={shiftStartDate}
                          format="DD/MM/YYYY"
                          minDate={weekOffDate}
                          maxDate={weekOffDate}
                          onChange={handleDateOnChange}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              size="small"
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                            />
                          )}
                          disabled={true}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <div sx={{ width: 4 }}>
                          <TimePicker
                            ampm={false}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            value={shiftStartTimeFormated || null}
                            slotProps={{
                              textField: {
                                size: "small",
                                width: 15,
                              },
                            }}
                            onChange={(newValue) => {
                              if (newValue) {
                                const timeWithDate = newValue
                                  ? newValue.toDate()
                                  : new Date();
                                let minTime = moment.utc(timeWithDate);
                                setShiftStartTimeFormated(
                                  dayjs()
                                    .set("hour", minTime._d.getHours())
                                    .set("minute", minTime._d.getMinutes())
                                    .set("second", minTime._d.getSeconds())
                                );
                              } else {
                                setShiftStartTimeFormated(null);
                              }
                            }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                    {checkinError ? (
                      <h6
                        style={{
                          paddingTop: 2,
                          color: themes.helperTextColor,
                          fontSize: 12,
                        }}
                      >
                        Both check_in and check-out are required
                      </h6>
                    ) : null}{" "}
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={4} sx={{ paddingTop: 2 }}>
                <Mui.Typography
                  sx={{ fontSize: 20, fontWeight: "bold", paddingLeft: 1 }}
                >
                  Check out&nbsp;
                  <Mui.Tooltip title=".">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Grid container>
                  <Mui.Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          size="small"
                          slotProps={{
                            textField: {
                              size: "small",
                              inputProps: { readOnly: true },
                            },
                          }}
                          sx={{ minWidth: "80%" }}
                          value={shiftEndDate}
                          minDate={weekOffDate}
                          maxDate={dayjs(weekOffDate).add(1, "day")}
                          format="DD/MM/YYYY"
                          onChange={handleCheckOutDateOnChange}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              size="small"
                              sx={{ pointerEvents: "none" }}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                            />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <Mui.Typography
                      sx={{
                        paddingTop: 1,
                        color: themes.redColor,
                        fontSize: 12,
                      }}
                    >
                      {handleCheckInError ===
                      "The check-out date can only be set to the selected date or the following day."
                        ? "The check-out date can only be set to the selected date or the following day."
                        : null}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <div sx={{ width: 4 }}>
                          <TimePicker
                            ampm={false}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            value={shiftEndTimeFormated || null}
                            slotProps={{
                              textField: {
                                size: "small",
                                width: 15,
                              },
                            }}
                            onChange={(newValue) => {
                              const timeWithDate = newValue
                                ? newValue.toDate()
                                : new Date();
                              let minTime = moment.utc(timeWithDate);
                              setShiftEndTimeFormated(
                                dayjs()
                                  .set("hour", minTime._d.getHours())
                                  .set("minute", minTime._d.getMinutes())
                                  .set("second", minTime._d.getSeconds())
                              );
                            }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                    {checkinError ? (
                      <h6
                        style={{
                          paddingTop: 2,
                          color: themes.helperTextColor,
                          fontSize: 12,
                        }}
                      >
                        Both check_in and check-out are required
                      </h6>
                    ) : null}
                    {sametimeError ? (
                      <h6
                        style={{
                          paddingTop: 2,
                          color: themes.helperTextColor,
                          fontSize: 12,
                        }}
                      >
                        Check-in time to be earlier than check-out time.
                      </h6>
                    ) : null}
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {/* <Mui.Grid item>
              <CustomButton
                actionName="Clear"
                typeName="button"
                actionFuntion={clearAllData}
              />
            </Mui.Grid> */}
            <Mui.Grid item>
              <CustomButton
                actionName="Apply"
                typeName="submit"
                disableAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
