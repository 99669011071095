import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { EditDeduction } from "../EditDeductions";
import { EditEarning } from "../EditEarnings";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";

export const Salary = ({
  companyId,
  editSalaryeTemplateDetails,
  setEditTemplate,
  reCallApi,
  setUpdateDeductionDetails,
  updateDeductionDetails,
  isDisabled,
}) => {
  const { reset, handleSubmit, setValue } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [earningDetailsData, setEarningDetailsData] = useState(
    editSalaryeTemplateDetails?.earnings
      ? editSalaryeTemplateDetails?.earnings
      : []
  );
  const [userDeductionDetails, setUserDeductionDetails] = useState(
    editSalaryeTemplateDetails?.deductions
      ? editSalaryeTemplateDetails?.deductions
      : []
  );
  const [emptyEarning, setEmptyEarning] = useState(false);
  const [emptyDeduction, setEmptyDeduction] = useState(false);

  useEffect(() => {
    setValue(
      "salary_template_name",
      editSalaryeTemplateDetails?.name ? editSalaryeTemplateDetails?.name : ""
    );
    setValue(
      "salary_group_id",
      editSalaryeTemplateDetails?.salary_group
        ? editSalaryeTemplateDetails?.salary_group
        : 0
    );
    setValue(
      "salary_group_name",
      editSalaryeTemplateDetails?.salary_group_name
        ? editSalaryeTemplateDetails?.salary_group_name
        : 0
    );

    setUserDeductionDetails(
      editSalaryeTemplateDetails?.deductions
        ? editSalaryeTemplateDetails?.deductions
        : []
    );
  }, [
    editSalaryeTemplateDetails?.deductions,
    editSalaryeTemplateDetails?.name,
    editSalaryeTemplateDetails?.salary_group,
    editSalaryeTemplateDetails?.salary_group_name,
    setValue,
  ]);

  const editSalaryeTemplate = async (data) => {
    const zeroAmoutEarnings = earningDetailsData.filter((data) => {
      return data?.amount === 0 || data?.amount === "0" || data.amount === "";
    });

    const zeroAmoutDeductions = userDeductionDetails.filter((data) => {
      return (
        data.deduction_type !== "F" &&
        data.employeeContributionAmount === 0 &&
        data.companyContributionAmount === 0
      );
    });

    if (zeroAmoutEarnings.length === 0 && zeroAmoutDeductions.length === 0) {
      try {
        const response = await fetch(
          `${domain}salary-templates/${editSalaryeTemplateDetails.id}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              name: data.salary_template_name,
              salary_group: data.salary_group_id,
              earnings: earningDetailsData,
              deductions: userDeductionDetails.filter((data) => {
                return data.name !== "";
              }),
              company: companyId,
            }),
          }
        );
        const res = await response.json();
        if (response.ok) {
          setEditTemplate(false);
          reCallApi(true);
          reset();
          showToast("success", "Salary template updated successfully");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
      }
    } else {
      setEmptyEarning(true);
      setEmptyDeduction(true);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(editSalaryeTemplate)}>
        <Mui.Grid container sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Mui.Grid
            xs={12}
            sx={{
              backgroundColor: themes.primary,
              padding: 1,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{ fontSize: 18, fontWeight: "bold", paddingLeft: 4 }}
              >
                Salary Components
              </Mui.Typography>{" "}
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Grid container sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Mui.Grid
            container
            sx={{
              paddingRight: 5,
              paddingLeft: 5,
              pointerEvents: isDisabled ? "none" : "auto",
              opacity: isDisabled ? 1 : 1,
            }}
            component={Mui.Paper}
          >
            <EditEarning
              companyId={companyId}
              earningDetailsData={earningDetailsData}
              setEarningDetailsData={setEarningDetailsData}
              emptyEarning={emptyEarning}
              setEmptyEarning={setEmptyEarning}
            />
            <EditDeduction
              companyId={companyId}
              userDeductionDetails={userDeductionDetails}
              setUserDeductionDetails={setUserDeductionDetails}
              earningDetailsData={earningDetailsData}
              emptyDeduction={emptyDeduction}
              setUpdateDeductionDetails={setUpdateDeductionDetails}
              updateDeductionDetails={updateDeductionDetails}
            />
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </>
  );
};
