import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Apply } from "./Apply";
import { History } from "./Approved";
import { themes } from "services/constants";
import ToggleButton from "@mui/material/ToggleButton";
import { setGlobalSearchValue } from "services/Redux/userToken";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "react-toastify/dist/ReactToastify.css";
import * as Mui from "@mui/material";

export const Expense = () => {
  const [selectedComponent, setSelectedComponent] = React.useState("Apply");
  const [alignment, setAlignment] = useState("Apply Expense");
  const dispatch = useDispatch();

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "history":
        return <History />;
      case "Apply":
        return <Apply />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 5,
          paddingLeft: 3,
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Mui.Grid xs={4.4}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 300,
              height: 55,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "30px",
                backgroundColor: themes.lightBrown,
              }}
            >
              <ToggleButton
                value="Apply Expense"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    alignment === "Apply Expense"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    alignment === "Apply Expense"
                      ? themes.headLine
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.headLine,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      alignment === "Apply Expense"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      alignment === "Apply Expense"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                  "&:focus": {
                    backgroundColor:
                      alignment === "Apply Expense"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      alignment === "Apply Expense"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
                onClick={() => {
                  setSelectedComponent("Apply");
                  dispatch(setGlobalSearchValue(""));
                }}
              >
                Apply
              </ToggleButton>
              <ToggleButton
                value="History"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  border: "none",
                  borderRadius: "30px",
                  color:
                    alignment === "History"
                      ? themes.whiteColor
                      : themes.headLine,
                  backgroundColor:
                    alignment === "History"
                      ? themes.headLine
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.headLine,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.headLine,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      alignment === "History"
                        ? themes.headLine
                        : themes.lightBrown,
                    color:
                      alignment === "History"
                        ? themes.whiteColor
                        : themes.headLine,
                  },
                }}
                onClick={() => {
                  setSelectedComponent("history");
                  dispatch(setGlobalSearchValue(""));
                }}
              >
                History
              </ToggleButton>
            </ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
