import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { customAPI } from "services/utils/CustomAPI";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import { DialogWithClose } from "components/GlobalDialogBox/DialogWithClose";

export const ChangeDepartment = ({
  changeDepartment,
  setChangeDepartment,
  editUser,
  departmentList,
  departmentName,
  setAnchorEl,
  setReloadApi,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [newDepartmentId, setNewDepartmentId] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [errorHanle, setErrorHandle] = useState(false);
  const handleClose = () => {
    setChangeDepartment(false);
    setAnchorEl(null);
  };

  const moveNewDepartment = async () => {
    if (newDepartmentId) {
      setButtonLoader(true);
      const response = await customAPI(
        `${domain}move_department_member/`,
        "POST",
        {
          user_id: editUser.id,
          source_department_id: editUser.department_id,
          destination_department_id: newDepartmentId,
        }
      );
      if (response.ok) {
        const newDepartment = departmentList.find(
          (dep) => dep.id === newDepartmentId
        );
        setReloadApi(true);
        showToast(
          "success",
          `${editUser.department_member_name} is moved to ${newDepartment.label} successfully.`
        );
        handleClose();
      } else {
        setButtonLoader(false);
      }
      setErrorHandle(false);
    } else {
      setErrorHandle(true);
    }
  };

  return (
    <React.Fragment>
      <DialogWithClose
        open={changeDepartment}
        handleClose={handleClose}
        title="Change Department"
      >
        <Mui.Grid container spacing={4}>
          <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
            <Mui.Typography>Current Department</Mui.Typography>
            <Mui.TextField
              value={departmentName}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <>
                    <CorporateFareOutlinedIcon
                      style={{
                        margin: "0 8px",
                        color: themes.allIconColor,
                      }}
                    />
                  </>
                ),
              }}
              fullWidth
            />
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
            <Mui.Typography>New Department</Mui.Typography>
            <Mui.Autocomplete
              id="combo-box-demo"
              options={departmentList?.filter(
                (item) => item?.label !== departmentName
              )}
              ListboxProps={{ style: { maxHeight: "100px" } }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.label
                    ?.toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                if (option.label !== departmentName) {
                  return <li {...props}>{option.label}</li>;
                }
              }}
              onChange={(event, value) => {
                if (value) {
                  setNewDepartmentId(value.id);
                } else {
                  setNewDepartmentId(null);
                }
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  placeholder="Select department"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <CorporateFareOutlinedIcon
                          style={{
                            margin: "0 8px",
                            color: themes.allIconColor,
                          }}
                        />{" "}
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
              fullWidth
            />
            {errorHanle && !newDepartmentId ? (
              <span style={{ color: themes.txtErrorColor }}>
                {" "}
                New department is required
              </span>
            ) : null}
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{ paddingTop: 8, paddingBottom: 2 }}
          spacing={4}
        >
          <Mui.Grid xs={7}></Mui.Grid>
          <Mui.Grid xs={5}>
            <CustomButton
              actionFuntion={() => {
                handleClose();
              }}
              actionName="Cancel"
              typeName="button"
            />
            &nbsp; &nbsp;
            <CustomButton
              actionFuntion={() => moveNewDepartment()}
              actionName="Change"
              typeName="button"
              disableAction={
                changeDepartment && buttonLoader ? buttonLoader : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </DialogWithClose>
    </React.Fragment>
  );
};
