import React, { useState } from "react";
import * as Mui from "@mui/material";
import { DownloadForOffline } from "@mui/icons-material";
import { themes } from "services/constants";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { Loader } from "components/Loader";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { summaryDetails } from "../PayRollHeaders";
import { getStatusColor } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { PayrunLog } from "./ApprovalPayroll/PayrunLog";
import sessionHandling from "services/utils/notificationUtils";
import { ConstStructure } from "./CostStructure";
import { OverTime } from "./OverTime";
import { SalaryRevision } from "./SalaryRevision";
import { AttendanceTimeLine } from "./AttendanceTimeLine";
import { useDispatch } from "react-redux";
import { amountNumberFormat } from "services/utils/numberFormat";

export const ViewPayRun = ({
  setViewPayRunScreen,
  companyId,
  companyName,
  viewPayrunDetails,
  setViewPayRunDetails,
  reCallApi,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [summaryName, setSummaryName] = useState("Cost Structure");
  const [openSidePeek, setOpenSidePeek] = useState(false);
  const [payrollCost, setPayrollCost] = useState(0);
  const [employeeNetPay, setEmployeeNetPay] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [status, setStatus] = useState(viewPayrunDetails.status);
  const [loading, setLoading] = useState(false);
  const [downloadReportDetails, setDownloadReportDetails] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [reloadUserPayrollDetails, setReloadUserPayrollDetails] =
    useState(false);
  const dispatch = useDispatch();
  const handleSummaryDetails = (event, data) => {
    if (data) {
      setSummaryName(data?.label);
      dispatch(setGlobalSearchValue(""));
    }
  };

  const actionButtonBasedOnStatusWithInitiate = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${domain}payrun/initiate/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          pay_schedule_id: viewPayrunDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setStatus("InProgress");
        showToast("success", res.message);
        setReloadUserPayrollDetails(true);
        setLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setLoading(false);
    }
  };

  const actionButtonBasedOnStatusWithSubmit = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}payrun/status-update/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          pay_schedule_id: viewPayrunDetails.id,
          status: "Submitted",
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        setViewPayRunScreen(false);
        reCallApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const renderComponent = () => {
    switch (summaryName) {
      case "Cost Structure":
        return (
          <ConstStructure
            viewPayrunDetails={viewPayrunDetails}
            setPayrollCost={setPayrollCost}
            setEmployeeNetPay={setEmployeeNetPay}
            setTotalDeduction={setTotalDeduction}
            setTotalWorkingHours={setTotalWorkingHours}
            companyId={companyId}
            companyName={companyName}
            reloadUserPayrollDetails={reloadUserPayrollDetails}
            setReloadUserPayrollDetails={setReloadUserPayrollDetails}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
            summaryName={summaryName}
            status={status}
          />
        );
      case "Over Time":
        return (
          <OverTime
            viewPayrunDetails={viewPayrunDetails}
            companyName={companyName}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
            summaryName={summaryName}
          />
        );
      case "Attendance Timeline":
        return (
          <AttendanceTimeLine
            viewPayrunDetails={viewPayrunDetails}
            companyName={companyName}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
            summaryName={summaryName}
          />
        );
      case "Salary Revision":
        return (
          <SalaryRevision
            viewPayrunDetails={viewPayrunDetails}
            companyName={companyName}
            setDownloadReportDetails={setDownloadReportDetails}
            downloadReportDetails={downloadReportDetails}
            summaryName={summaryName}
          />
        );
      default:
        break;
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Grid xs={8}>
          <Mui.Stack direction="Row">
            <BackArrow
              actionFunction={() => {
                reCallApi(true);
                setViewPayRunScreen(false);
                dispatch(setGlobalSearchValue(""));
              }}
            />
            <Mui.Typography
              sx={{ paddingTop: 1, fontSize: 25, fontWeight: "bold" }}
            >
              Pay runs - {companyName} -{summaryName}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
        <Mui.Grid xs={4}>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 1,
            }}
          >
            <Mui.Box
              sx={{
                width: 120,
                height: 15,
                backgroundColor: getStatusColor(status),
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: themes.whiteColor,
                p: 2,
                fontSize: 13,
              }}
            >
              {status}
            </Mui.Box>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        {summaryName === "Cost Structure" ? (
          <Mui.Grid xs={5}>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={8}>
                <Mui.Stack direction="row">
                  <Mui.Typography
                    sx={{ fontSize: 20, color: themes.colorShadow }}
                  >
                    Period :{" "}
                  </Mui.Typography>
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      padding: 2,
                    }}
                  >
                    {viewPayrunDetails.start_date +
                      "  to  " +
                      viewPayrunDetails.end_date}
                  </span>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Stack direction="row">
                  <Mui.Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {totalWorkingHours}
                  </Mui.Typography>{" "}
                  &nbsp;
                  <Mui.Typography
                    sx={{
                      paddingTop: 0.3,
                      fontSize: 18,
                      color: themes.colorShadow,
                      textAlign: "center",
                    }}
                  >
                    days
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              component={Mui.Paper}
              elevation={4}
              sx={{ border: `2px solid ${themes.primaryIndicator}` }}
            >
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(payrollCost)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Payroll cost
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(employeeNetPay)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Employee's net pay
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {amountNumberFormat(totalDeduction)}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    fontSize: 18,
                    color: themes.colorShadow,
                    textAlign: "center",
                  }}
                >
                  Total deductions
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        ) : (
          <Mui.Grid xs={5}>
            <Mui.Grid container>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{ fontSize: 18, color: themes.colorShadow }}
                >
                  Payroll Period
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{ fontSize: 18, color: themes.colorShadow }}
                >
                  Total Working Days
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              component={Mui.Paper}
              elevation={4}
              sx={{ border: `2px solid ${themes.primaryIndicator}` }}
            >
              <Mui.Grid
                xs={6}
                sx={{ borderRight: `4px solid ${themes.primaryIndicator}` }}
              >
                <Mui.Stack direction="Row">
                  <Mui.Typography
                    sx={{
                      textAlign: "center",
                      padding: 3,
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {viewPayrunDetails.start_date} {" To "}
                    <span sx={{ paddingLeft: 1 }}>
                      {viewPayrunDetails.end_date}
                    </span>
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography
                  sx={{
                    textAlign: "center",
                    padding: 3,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {totalWorkingHours}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        )}
        <Mui.Grid xs={7}>
          <Mui.Stack>
            {status === "Draft" ? (
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 1,
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    actionButtonBasedOnStatusWithInitiate();
                  }}
                  actionName="Initiate Payroll"
                  typeName="submit"
                  disableAction={loading}
                />
                &nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setOpenSidePeek(true);
                  }}
                  actionName="View Log"
                  typeName="button"
                />
              </Mui.Grid>
            ) : status === "Rejected" ? (
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 1,
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    actionButtonBasedOnStatusWithInitiate();
                    viewPayrunDetails.status = "InProgress";
                    setViewPayRunDetails(viewPayrunDetails);
                  }}
                  actionName="Rerun Payroll"
                  typeName="button"
                  disableAction={loading}
                />{" "}
                &nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setOpenSidePeek(true);
                  }}
                  actionName="View Log"
                  typeName="button"
                />
              </Mui.Grid>
            ) : (
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 1,
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    actionButtonBasedOnStatusWithInitiate();
                  }}
                  actionName="Rerun Payroll"
                  typeName="button"
                  disableAction={loading}
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={() => {
                    actionButtonBasedOnStatusWithSubmit();
                  }}
                  actionName="Submit"
                  typeName="button"
                  disableAction={buttonDisabled}
                />{" "}
                &nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setOpenSidePeek(true);
                  }}
                  actionName="View Log"
                  typeName="button"
                />
              </Mui.Grid>
            )}
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={7}></Mui.Grid>
              <Mui.Grid xs={4}>
                <CustomAutoComplete
                  dataList={summaryDetails}
                  selectedValue={summaryName}
                  updateDataFunction={handleSummaryDetails}
                  placeHolder="Please select the summary"
                  fullWith
                />
              </Mui.Grid>
              <Mui.Grid xs={1}>
                &nbsp;&nbsp;
                <Mui.IconButton
                  size="small"
                  onClick={() => setDownloadReportDetails(true)}
                >
                  <DownloadForOffline
                    size="large"
                    sx={{ color: "#25B7D3", fontSize: 35 }}
                  />
                </Mui.IconButton>
                &nbsp;
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      {openSidePeek ? (
        <PayrunLog
          setOpenSidePeek={setOpenSidePeek}
          openSidePeek={openSidePeek}
          viewPayrunDetails={viewPayrunDetails}
        />
      ) : null}
      <Mui.Grid container>{renderComponent()}</Mui.Grid>
    </React.Fragment>
  );
};
