import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { Salary } from "./Salary";
import { Employee } from "./Employee";

export const View = ({
  companyId,
  editSalaryeTemplateDetails,
  setCompanyFilter,
  setEditTemplate,
  companyFilter,
  reCallApi,
  setViewOption,
  setUpdateDeductionDetails,
  updateDeductionDetails,
  isDisabled,
}) => {
  // const [selectedComponent, setSelectedComponent] = useState("Salary");

  const [buttonNotify, setButtonNotify] = useState("Salary");

  return (
    <>
      <Mui.Grid>
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => {
              reCallApi(true);
              setViewOption(false);
            }}
          />

          <Mui.Typography
            sx={{
              fontWeight: "bold",
              fontSize: 25,
              paddingTop: 1,
            }}
          >
            View / Salary Template
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingLeft: 5, paddingRight: 5 }}>
        <Mui.Grid
          container
          sx={{
            padding: 3,
            backgroundColor: themes.primary,
            mt: 1,
          }}
          component={Mui.Paper}
        >
          <Mui.Grid
            item
            xs={3}
            sx={{ borderRight: "1.5px solid black", textAlign: "center" }}
          >
            <Mui.Typography sx={{ fontSize: 18, fontWeight: 800 }}>
              Salary Template Name
            </Mui.Typography>
            <Mui.Typography sx={{ padding: 0.5 }}>
              {editSalaryeTemplateDetails?.name}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            item
            xs={3}
            sx={{ borderRight: "1.5px solid black", textAlign: "center" }}
          >
            {" "}
            <Mui.Typography sx={{ fontSize: 18, fontWeight: 800 }}>
              Salary Type
            </Mui.Typography>
            <Mui.Typography sx={{ padding: 0.5 }}>
              {" "}
              {editSalaryeTemplateDetails?.salary_type_name}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            item
            xs={3}
            sx={{ borderRight: "1.5px solid black", textAlign: "center" }}
          >
            {" "}
            <Mui.Typography sx={{ fontSize: 18, fontWeight: 800 }}>
              Employees
            </Mui.Typography>
            <Mui.Typography sx={{ padding: 0.5 }}>
              {" "}
              {editSalaryeTemplateDetails?.employee_count}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={3} sx={{ textAlign: "center" }}>
            {" "}
            <Mui.Typography sx={{ fontSize: 18, fontWeight: 800 }}>
              Salary
            </Mui.Typography>
            <Mui.Typography sx={{ padding: 0.5 }}>
              {" "}
              {editSalaryeTemplateDetails?.amount}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container spacing={2} sx={{ paddingTop: 2 }}>
        <Mui.Grid item xs={0.5}></Mui.Grid>
        <Mui.Grid xs={6} sx={{ paddingTop: 2 }}>
          <Mui.ButtonGroup>
            <Mui.Button
              variant={buttonNotify === "Salary" ? "contained" : "outlined"}
              onClick={() => {
                reCallApi(true);
                setButtonNotify("Salary");
              }}
              sx={{
                background:
                  buttonNotify === "Salary" ? themes.primaryButton : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  background:
                    buttonNotify === "Salary"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
            >
              Salary Components
            </Mui.Button>
            <Mui.Button
              variant={buttonNotify === "Employee" ? "contained" : "outlined"}
              onClick={() => {
                setButtonNotify("Employee");
              }}
              sx={{
                background:
                  buttonNotify === "Employee"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  background:
                    buttonNotify === "Employee"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
            >
              Employees
            </Mui.Button>
          </Mui.ButtonGroup>
        </Mui.Grid>
        <Mui.Grid item xs={12} sx={{ marginTop: 2 }}>
          {buttonNotify === "Salary" && (
            <Salary
              companyId={companyId}
              editSalaryeTemplateDetails={editSalaryeTemplateDetails}
              setCompanyFilter={setCompanyFilter}
              setEditTemplate={setEditTemplate}
              companyFilter={companyFilter}
              reCallApi={reCallApi}
              setViewOption={setViewOption}
              setUpdateDeductionDetails={setUpdateDeductionDetails}
              updateDeductionDetails={updateDeductionDetails}
              isDisabled={isDisabled}
            />
          )}
          {buttonNotify === "Employee" && (
            <Employee editSalaryeTemplateDetails={editSalaryeTemplateDetails} />
          )}
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
