import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { MoreVert, Info } from "@mui/icons-material";
import { Loader } from "components/Loader";
import { useForm } from "react-hook-form";
import { leavePeriodHrms } from "services/constants";
import * as Mui from "@mui/material";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { tableRowSx } from "services/constants";
import { tableContainerSx, tableHeaderSx } from "services/constants";
let multiApiCallRestriction = true;
export const LeavePeriod = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [leavePeriod, setleavePeriod] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [editLeavePeriod, seteditLeavePeriod] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [fetchedData, setFetchedData] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [masterLeavePeriod, setmasterLeavePeriod] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleFromDateChange = (event) => {
    const selectedDate = event.target.value;
    if (isValidDate(selectedDate)) {
      setFromDate(selectedDate);
      setValue("from_date", selectedDate, { shouldValidate: true });
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const handleEndDateChange = (event) => {
    const selectedDate = event.target.value;
    if (isValidDate(selectedDate)) {
      setToDate(selectedDate);
      setValue("to_date", selectedDate, { shouldValidate: true });
    } else {
      setToDate("");
    }
  };

  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;

    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    const getLeavePeriodDetails = async () => {
      setLoader(true);
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setleavePeriod(res);
          setmasterLeavePeriod(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoader(false);
      }
    };
    if (fetchedData) {
      getLeavePeriodDetails();
      setFetchedData(false);
    }
  }, [domain, fetchedData, setFetchedData, setValue, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleClose();
    reset();
  };

  const handleClose = () => {
    setOpenDialog(false);
    seteditLeavePeriod("");
    setDeletePopup(false);
    setFromDate("");
    setToDate("");
    reset();
  };

  const checkSpecialChar = (e) => {
    if (!/^[0-9a-zA-Z\- ]$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const checkFromDate = watch("from_date");
  const checkToDate = watch("to_date");

  const validateStartDate = (value) => {
    const startDate = new Date(value);
    const minDate = new Date("2020-01-01");
    const endDate = checkToDate ? new Date(checkToDate) : null;
    if (startDate < minDate) {
      return "Start date must be 01/01/2020 or later";
    }
    if (endDate) {
      if (startDate <= endDate) {
        if (startDate.getTime() !== endDate.getTime()) {
          return true;
        } else {
          return "Start date cannot be the same as end date";
        }
      } else {
        return "Start date cannot be after the end date";
      }
    }

    return true;
  };

  const validateEndDate = (value) => {
    const endDate = new Date(value);
    const minDate = new Date("2020-01-01");
    const startDate = checkFromDate ? new Date(checkFromDate) : null;
    if (endDate < minDate) {
      return "End date must be 01/01/2020 or later";
    }
    if (startDate) {
      if (endDate >= startDate) {
        if (endDate.getTime() !== startDate.getTime()) {
          return true;
        } else {
          return "End date cannot be the same as start date";
        }
      } else {
        return "End date cannot be before the start date.";
      }
    }
    return true;
  };

  const createNewLeavePeriod = async (data) => {
    if (popupTitle === "Add Leave Period" && multiApiCallRestriction) {
      setButtonDisabled(true);
      multiApiCallRestriction = false;
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: data.name,
            start_date: data.from_date,
            end_date: data.to_date,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setValue("uniquename_check", "");
          showToast("success", `${data.name} created successfully`);
          multiApiCallRestriction = true;
          handleClose();
          reset();
          setFetchedData(true);
          setButtonDisabled(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.non_field_errors[0]);
        }
      } catch (error) {
        showToast("error", error.message);
        setButtonDisabled(false);
        multiApiCallRestriction = true;
      }
    } else if (popupTitle === "Edit Leave Period" && multiApiCallRestriction) {
      setButtonDisabled(true);
      multiApiCallRestriction = false;
      try {
        const response = await fetch(`${domain}leave-years/${data.id}/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            name: data.name,
            start_date: data.from_date,
            end_date: data.to_date,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setValue("uniquename_check", "");
          seteditLeavePeriod("");
          showToast("success", `${data.name} updated successfully`);
          multiApiCallRestriction = true;
          setButtonDisabled(false);
          handleClose();
          reset();
          setFetchedData(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.non_field_errors[0]);
        }
      } catch (error) {
        showToast("error", error.message);
        multiApiCallRestriction = true;
        setButtonDisabled(false);
      }
    }
  };

  const deleteLeavePeriod = async () => {
    if (multiApiCallRestriction) {
      const id = getValues("id");
      multiApiCallRestriction = false;
      try {
        setButtonDisabled(true);
        const response = await fetch(`${domain}leave-years/${id}/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            isdeleted: true,
            start_date: fromDate,
            end_date: toDate,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("error", `${res.name} deleted successfully`);
          multiApiCallRestriction = true;
          setButtonDisabled(false);
          handleClose();
          reset();
          setFetchedData(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        multiApiCallRestriction = true;
        setButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    const filteredResults = masterLeavePeriod.filter((item) =>
      leavePeriodHrms.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setleavePeriod(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterLeavePeriod, page]);

  const addButtonAction = () => {
    setPopupTitle("Add Leave Period");
    setDeletePopup(false);
    setFromDate("");
    setToDate("");
    setOpenDialog(true);
    reset();
  };

  return loader ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingTop: 2,
        }}
      >
        <CustomButton
          actionFuntion={addButtonAction}
          actionName="Add Leave Period"
          typeName="button"
        />
      </Mui.Grid>
      <Mui.TableContainer sx={tableContainerSx}>
        <Mui.Table>
          <Mui.TableHead sx={tableHeaderSx}>
            <Mui.TableRow sx={tableRowSx}>
              <Mui.TableCell
                sx={{ color: themes.headLine, fontWeight: "bold" }}
              >
                Name
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.headLine, fontWeight: "bold" }}
              >
                Period Start
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.headLine, fontWeight: "bold" }}
              >
                Period End
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.headLine, fontWeight: "bold" }}
              >
                Actions
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {leavePeriod.length === 0 && !loader ? (
              <Mui.TableRow sx={tableRowSx}>
                <Mui.TableCell colSpan={2}>
                  <Mui.Alert sx={{ marginTop: 3 }} severity="info">
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              leavePeriod
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Mui.TableRow sx={tableRowSx}>
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.name ? item?.name : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.start_date ? item?.start_date : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.end_date ? item?.end_date : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.headLine, fontWeight: "bold" }}
                    >
                      <Mui.IconButton
                        onClick={(event) => {
                          reset();
                          setFromDate(
                            item.start_date.split("-").reverse().join("-")
                          );
                          setToDate(
                            item.end_date.split("-").reverse().join("-")
                          );
                          setValue(
                            "from_date",
                            item.start_date.split("-").reverse().join("-")
                          );
                          setValue(
                            "to_date",
                            item.end_date.split("-").reverse().join("-")
                          );
                          setValue("name", item.name);
                          setValue("id", item.id);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVert />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setDeletePopup(false);
                            setPopupTitle("Edit Leave Period");
                            seteditLeavePeriod(getValues("name"));
                            setOpenDialog(true);
                            setAnchorEl(false);
                          }}
                        >
                          Edit
                        </Mui.MenuItem>
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(false);
                            setDeletePopup(true);
                            setOpenDialog(true);
                            setPopupTitle("Delete Leave Period");
                          }}
                        >
                          Delete
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {leavePeriod.length > 25 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            count={leavePeriod.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog}
        title={popupTitle}
      >
        {deletePopup ? (
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ fontSize: 22, paddingBottom: 2 }}>
                Are you sure you want to delete{" "}
                <b style={{ color: themes.redColor }}>{getValues("name")}</b>?
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid
                container
                sx={{
                  paddingTop: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-center",
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setOpenDialog(false);
                    handleClose();
                  }}
                  actionName="No"
                  typeName="submit"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setOpenDialog(false);
                    deleteLeavePeriod();
                  }}
                  disableAction={
                    openDialog && buttonDisabled ? buttonDisabled : false
                  }
                  actionName="Yes"
                  typeName="submit"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        ) : popupTitle === "Add Leave Period" ||
          popupTitle === "Edit Leave Period" ? (
          <form onSubmit={handleSubmit(createNewLeavePeriod)}>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "flex-end",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Name</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="The leave period defines when the leave cycle starts and ends for an year, renewing the leaves allowed per year and triggers the carryovers.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={1}></Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  placeholder="Enter the leave period name"
                  {...register("name", {
                    required: "Name is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      nameAvailable: async (fieldValue) => {
                        if (
                          popupTitle !== "Edit Leave Period" &&
                          getValues("uniquename_check") !== fieldValue
                        ) {
                          setValue("uniquename_check", fieldValue);
                          if (fieldValue.trim().length < 3) {
                            return "Minimum 3 characters are required";
                          }
                          const response = await fetch(
                            `${domain}leave-period-namecheck/?name=${fieldValue.trim()}`,
                            {
                              method: "GET",
                              headers: {
                                "content-type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "Name is already exists";
                          } else {
                            return true;
                          }
                        } else if (
                          errors.name &&
                          popupTitle !== "Edit Leave Period"
                        ) {
                          return errors?.name?.message;
                        } else {
                          return true;
                        }
                      },
                      editName: async (fieldValue) => {
                        if (
                          popupTitle === "Edit Leave Period" &&
                          editLeavePeriod !== getValues("name")
                        ) {
                          setValue("uniquename_check", fieldValue);
                          if (fieldValue.trim().length < 3) {
                            return "Minimum 3 characters are required";
                          }
                          const response = await fetch(
                            `${domain}leave-period-namecheck/?name=${fieldValue.trim()}`,
                            {
                              method: "GET",
                              headers: {
                                "content-type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "Name already exists";
                          } else {
                            return true;
                          }
                        } else if (
                          errors.name &&
                          popupTitle === "Edit Leave Period"
                        ) {
                          return errors?.name?.message;
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 32 },
                  }}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message || ""}
                  onKeyPress={checkSpecialChar}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={4} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "flex-end",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Period Start</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="Period start helps to select the start date of one leave cycle.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>

              <Mui.Grid xs={8}>
                <Mui.TextField
                  size="small"
                  fullWidth
                  type="date"
                  id="from_date"
                  value={fromDate}
                  {...register("from_date", {
                    required: "Period start date is required.",
                    validate: {
                      dateNotBeforeEnd: validateStartDate,
                    },
                  })}
                  onChange={handleFromDateChange}
                  error={errors.from_date ? true : null}
                  helperText={errors.from_date && errors.from_date.message}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={4} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "flex-end",
                    paddingRight: 1.75,
                  }}
                >
                  <Mui.Typography>Period End</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="Period end helps to select the end date of one leave cycle.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>

              <Mui.Grid xs={8}>
                <Mui.TextField
                  size="small"
                  fullWidth
                  type="date"
                  id="to_date"
                  value={toDate}
                  {...register("to_date", {
                    required: "Period end date is required",
                    validate: {
                      dateNotBeforeStart: validateEndDate,
                    },
                  })}
                  onChange={handleEndDateChange}
                  error={errors.to_date ? true : null}
                  helperText={errors.to_date && errors.to_date.message}
                />
              </Mui.Grid>
              <Mui.Grid xs={1}></Mui.Grid>
            </Mui.Grid>
            <Mui.Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  handleClose();
                }}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={() => {
                  handleSubmit(createNewLeavePeriod);
                }}
                actionName={
                  popupTitle === "Add Leave Period" ? "Add" : "Update"
                }
                typeName="submit"
                disableAction={
                  openDialog && buttonDisabled ? buttonDisabled : false
                }
              />
            </Mui.Stack>
          </form>
        ) : null}
      </GlobalDialogBox>
    </React.Fragment>
  );
};
