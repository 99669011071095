import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { tableContainerSx, tableHeaderSx } from "services/constants";
import { customAPI } from "services/utils/CustomAPI";
import {
  Edit,
  Delete,
  Close,
  Check,
  Info,
  AddCircleOutline,
} from "@mui/icons-material";
import { showToast } from "services/utils/Status";
const punchHeader = [
  {
    name: "Device Name",
    key: "device_name",
  },
  {
    name: "Location",
    key: "location",
  },
  {
    name: "Mode",
    key: "Mode",
  },
  {
    name: "Action",
    key: "action",
  },
];
const modeDetails = [
  {
    label: "BI-DIRECTIONAL",
  },
  {
    label: "IN",
  },
  {
    label: "OUT",
  },
];
export const PunchDetails = ({ setShowPunchDetails, editDetails }) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [editRowId, setEditRowId] = useState(0);
  const [editLocation, setEditLocation] = useState("");
  const [editDeviceName, setEditDeviceName] = useState("");
  const [editMode, setEditMode] = useState("");
  const [editDeviceId, setEditDeviceId] = useState(0);
  const [editErrorHandle, setEditErrorHandle] = useState(false);
  const {
    result: companyDeviceDetails,
    loading,
    setLoad,
  } = useFetch(`${domain}add-device-details/?company_id=${editDetails?.id}`);
  const { result: companyAllDetails } = useFetch(
    `${domain}device-details/?company_id=${editDetails?.id}`
  );
  const [editRowDetails, setEditRowDetails] = useState({});
  const [overAllDeviceDetails, setOverAllDeviceDetails] = useState([]);
  const [overAllDevice, setOverAllDevice] = useState(companyDeviceDetails);
  const [editActionWithOldData, setEditActionWithOldData] = useState(false);
  const {
    // register,
    handleSubmit,
    setValue,
    // reset,
    // formState: { errors },
  } = useForm();

  const onSubmit = () => {};

  useEffect(() => {
    if (companyDeviceDetails?.length !== 0 && !loading) {
      setOverAllDevice(companyDeviceDetails);
    }
  }, [companyDeviceDetails, loading]);

  useEffect(() => {
    if (companyAllDetails?.length !== 0 && !loading) {
      let deduction = companyAllDetails;
      companyDeviceDetails.forEach((earn) => {
        deduction = deduction.filter((data) => {
          return data.device_name !== earn.device_name;
        });
      });
      setOverAllDeviceDetails(deduction);
    }
  }, [
    companyAllDetails,
    companyAllDetails?.length,
    companyDeviceDetails,
    loading,
  ]);

  const updateDeviceName = (event, data) => {
    if (data) {
      setEditDeviceName(data.label);
      setValue("device_name", data?.label);
      setEditLocation(data?.location);
      setEditDeviceId(data?.device_id);
    } else {
      setEditDeviceName("");
      setValue("device_name", "");
      setEditLocation("");
      setEditDeviceId(0);
    }
  };

  const updateMode = (event, data) => {
    if (data) {
      setEditMode(data.label);
    } else {
      setEditMode("");
    }
  };

  const AddnewRow = () => {
    setEditDeviceName("");
    setEditMode("");
    const data = {
      id: overAllDevice.length + 1,
      device_id: overAllDevice.length + 1,
      device_name: "",
      mode: "",
      location: "",
      newlyAdded: true,
    };
    setOverAllDevice([...overAllDevice, data]);
    setEditRowDetails(data);
    setEditRowId(overAllDevice.length + 1);
  };

  const updateEditDetails = async () => {
    if (editDeviceName !== "" && editMode !== "") {
      if (editRowDetails?.newlyAdded) {
        const response = await customAPI(
          `${domain}add-device-details/`,
          "POST",
          {
            device_id: editDeviceId,
            device_name: editDeviceName,
            location: editLocation,
            mode: editMode,
            company_id: editDetails?.id,
          }
        );
        if (response.ok) {
          setEditErrorHandle(false);
          setEditDeviceName("");
          setEditMode("");
          setEditRowId(0);
          setLoad(true);
          showToast("success", "New device added successfully");
        }
      } else {
        const response = await customAPI(
          `${domain}add-device-details/`,
          "PUT",
          {
            device_id: editDeviceId,
            device_name: editDeviceName,
            location: editLocation,
            mode: editMode,
            company_id: editDetails?.id,
            id: editRowDetails?.id,
          }
        );
        if (response.ok) {
          setEditErrorHandle(false);
          setEditDeviceName("");
          setEditMode("");
          setEditRowId(0);
          setLoad(true);
          setEditActionWithOldData(false);
          showToast("success", "Device details updated successfully");
        }
      }
    } else {
      setEditErrorHandle(true);
    }
  };

  const deleteDeviceDetails = async (deviceDe) => {
    const response = await customAPI(`${domain}add-device-details/`, "PUT", {
      id: deviceDe?.id,
      mode: deviceDe?.mode,
      company_id: deviceDe?.company_id,
      is_deleted: true,
    });
    if (response.ok) {
      setEditErrorHandle(false);
      setEditDeviceName("");
      setEditMode("");
      setEditRowId(0);
      setLoad(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid
        container
        sx={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
        }}
      >
        <Mui.Grid
          container
          sx={{
            padding: 1,
            backgroundColor: themes.primary,
            borderRadius: "10px 10px 0 0",
            border: `2px solid ${themes.sidebarDivider}`,
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Mui.Typography
            sx={{
              fontSize: 24,
              fontWeight: 800,
              font: "bold",
              textAlign: "center",
            }}
          >
            Attendance Device
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: -1,
            border: `2px solid ${themes.sidebarDivider}`,
            padding: 2,
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  fontWeight: "bold",
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 1,
                }}
              >
                Company&nbsp;
                <Mui.Tooltip title="The punching device setup and the details provided below are specific to this company only">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8} sx={{ paddingTop: 1 }}>
              <Mui.Typography>{editDetails?.companyname}</Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{ paddingRight: 1, overflow: "hidden", paddingBottom: 10 }}
          >
            <Mui.TableContainer sx={tableContainerSx}>
              <Mui.Table>
                <Mui.TableHead sx={tableHeaderSx}>
                  <Mui.TableRow
                    maxWidth="xl"
                    align="left"
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "10px",
                        padding: "3px 15px",
                      },
                    }}
                  >
                    {punchHeader?.map?.((value) => (
                      <Mui.TableCell
                        sx={{
                          color: themes.headLine,
                          fontWeight: "bold",
                          fontSize: 17,
                        }}
                      >
                        {value?.name}
                      </Mui.TableCell>
                    ))}
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {overAllDevice?.length === 0 ? (
                    <Mui.TableCell colSpan={12}>
                      <Mui.Alert sx={{ mt: 1 }} severity="info">
                        No data available
                      </Mui.Alert>
                    </Mui.TableCell>
                  ) : (
                    overAllDevice?.map((data, index) => (
                      <Mui.TableRow
                        key={data?.device_id}
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "3px 15px",
                          },
                        }}
                      >
                        {editRowId !== data?.device_id ? (
                          <>
                            <Mui.TableCell>{data?.device_name}</Mui.TableCell>
                            <Mui.TableCell>{data?.location}</Mui.TableCell>
                            <Mui.TableCell>{data?.mode}</Mui.TableCell>
                            <Mui.TableCell>
                              <Mui.IconButton
                                onClick={() => {
                                  deleteDeviceDetails(data);
                                }}
                              >
                                <Delete />
                              </Mui.IconButton>
                              <Mui.IconButton
                                onClick={() => {
                                  setEditRowDetails(data);
                                  setEditErrorHandle("");
                                  setEditLocation(data?.location);
                                  setEditDeviceName(data?.device_name);
                                  setEditMode(data?.mode);
                                  setEditRowId(data?.device_id);
                                  setEditActionWithOldData(true);
                                }}
                              >
                                <Edit />
                              </Mui.IconButton>
                            </Mui.TableCell>
                          </>
                        ) : (
                          <>
                            <Mui.TableCell>
                              {editActionWithOldData ? (
                                <Mui.TextField
                                  size="small"
                                  value={editDeviceName}
                                  disabled={true}
                                  fullWidth
                                />
                              ) : (
                                <>
                                  <CustomAutoComplete
                                    dataList={overAllDeviceDetails?.map(
                                      (obj) => ({
                                        ...obj,
                                        label: obj.device_name,
                                      })
                                    )}
                                    selectedValue={editDeviceName}
                                    updateDataFunction={updateDeviceName}
                                    placeHolder="Select the Device"
                                  />
                                  {editDeviceName === "" && editErrorHandle ? (
                                    <span
                                      style={{ color: themes.txtErrorColor }}
                                    >
                                      Device name is required
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              <Mui.TextField
                                value={editLocation}
                                size="small"
                                fullWidth
                                disabled
                              />
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {" "}
                              <CustomAutoComplete
                                dataList={modeDetails}
                                selectedValue={editMode}
                                updateDataFunction={updateMode}
                                placeHolder="Select the mode"
                              />
                              {editMode === "" && editErrorHandle ? (
                                <span style={{ color: themes.txtErrorColor }}>
                                  Device mode is required
                                </span>
                              ) : null}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              <Mui.IconButton
                                onClick={() => {
                                  updateEditDetails();
                                }}
                              >
                                <Check />
                              </Mui.IconButton>
                              <Mui.IconButton
                                onClick={() => {
                                  if (editRowDetails?.newlyAdded) {
                                    let data = overAllDevice.filter(
                                      (details) => {
                                        return (
                                          details?.device_id !==
                                          editRowDetails?.device_id
                                        );
                                      }
                                    );
                                    setOverAllDevice(data);
                                    setEditRowId(0);
                                    setEditActionWithOldData(false);
                                  } else {
                                    setEditRowId(0);
                                    setEditActionWithOldData(false);
                                  }
                                }}
                              >
                                <Close />
                              </Mui.IconButton>
                            </Mui.TableCell>
                          </>
                        )}
                      </Mui.TableRow>
                    ))
                  )}
                </Mui.TableBody>
                <Mui.TableBody>
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell></Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.IconButton
                      onClick={() => {
                        AddnewRow();
                      }}
                      disabled={
                        editRowId === 0 && overAllDeviceDetails?.length !== 0
                          ? false
                          : true
                      }
                    >
                      <AddCircleOutline />
                    </Mui.IconButton>
                  </Mui.TableCell>
                </Mui.TableBody>
              </Mui.Table>
            </Mui.TableContainer>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionName="Cancel"
                typeName="button"
                actionFuntion={() => {
                  setShowPunchDetails(false);
                }}
                // disableAction={buttonLoader}
              />
            </Mui.Grid>
            <Mui.Grid item>
              <CustomButton
                actionName="Save"
                typeName="submit"
                // disableAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
