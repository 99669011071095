import React, { useState } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { onlyNumberRegex } from "services/constants/patterns";
import { showToast } from "services/utils/Status";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { customAPI } from "services/utils/CustomAPI";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
export const Apply = () => {
  const { domain, userDetails } = useSelector((state) => state.tokenReducer);
  const [dateError, setDateError] = useState(false);
  const [previousDate, setPreviousDate] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [date, setDate] = useState(dayjs());
  const [selectedExpenseType, setSelectedExpenseType] = useState("");
  const [selectedAdvanceLoanAmount, setSelectedAdvanceLoanAmount] =
    useState("");
  const [selectedReasonForAmount, setSelectedReasonForAmount] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const today = dayjs();

  const advanceType = [
    { id: 1, type: "Business" },
    { id: 2, type: "Personal" },
  ];

  const handleDate = (value) => {
    setDate(value);
  };

  const onSubmit = async (data) => {
    if (date && date.toString() !== "Invalid Date" && today < date) {
      setButtonLoader(true);
      const response = await customAPI(`${domain}advances/`, "POST", {
        user: userDetails?.id,
        amount: data?.advance_amount,
        repayment_date: date.format("YYYY-MM-DD"),
        description: data?.reason_for_amount,
        advance_type: data?.advance_type,
      });
      if (response.ok) {
        showToast(
          "success",
          "Advance request received! You'll get a notification when it's approved."
        );
        reset();
        setSelectedExpenseType("");
        setDate(dayjs());
        setSelectedAdvanceLoanAmount("");
        setSelectedReasonForAmount("");
        setDateError("");
        setPreviousDate("");
        setButtonLoader(false);
      }
    } else {
      if (date === null) {
        setDateError(true);
      } else if (today.format("DD-MM-YYYY") > date.format("DD-MM-YYYY")) {
        setPreviousDate(true);
      }
    }
    setButtonLoader(false);
  };

  const updateAdvanceType = (event, data) => {
    if (data) {
      setValue("advance_type", data?.label);
      setSelectedExpenseType(data?.label);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedExpenseType("");
    setDate(dayjs());
    setSelectedAdvanceLoanAmount("");
    setSelectedReasonForAmount("");
  };

  const updateAdvanceAmount = (event) => {
    if (
      event.target.value.length <= 8 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("advance_amount", event.target.value);
      setSelectedAdvanceLoanAmount(event.target.value);
    }
  };

  const updateReasonForAmount = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("reason_for_amount", event.target.value);
      setSelectedReasonForAmount(event.target.value);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid sx={{ paddingLeft: 10, paddingRight: 10, mt: 8 }}>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              mt: 5,
              padding: 1,
              backgroundColor: themes.primary,
              borderRadius: "10px 10px 0 0",
              border: `2px solid ${themes.sidebarDivider}`,
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Mui.Typography
              sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
            >
              Apply Advance Salary
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          sx={{
            borderRadius: "0 0 10px 10px",
            border: `2px solid ${themes.sidebarDivider}`,
            padding: 2,
          }}
        >
          <Mui.Grid container>
            <Mui.Grid item xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                  fontWeight: "bold",
                }}
              >
                Advance Type
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={8} sx={{ padding: 1 }}>
              <CustomAutoComplete
                dataList={advanceType?.map((obj) => ({
                  ...obj,
                  label: obj?.type,
                }))}
                rules={{
                  ...register("advance_type", {
                    required: "Advance type is required",
                  }),
                }}
                selectedValue={selectedExpenseType}
                updateDataFunction={updateAdvanceType}
                placeHolder="Select the advance type"
                errorMessage={
                  errors?.advance_type?.message
                    ? errors?.advance_type?.message
                    : ""
                }
                textBoxError={errors?.advance_type ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid item xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                  fontWeight: "bold",
                }}
              >
                Advance Amount
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={8} sx={{ padding: 1 }}>
              <CustomTextBox
                rules={{
                  ...register("advance_amount", {
                    required: "Amount is required",
                  }),
                }}
                selectedValue={selectedAdvanceLoanAmount}
                updateDataFunction={updateAdvanceAmount}
                placeHolder="Enter advance amount"
                errorMessage={
                  errors?.advance_amount?.message
                    ? errors?.advance_amount?.message
                    : ""
                }
                textBoxError={errors?.advance_amount ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid item xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                  fontWeight: "bold",
                }}
              >
                Payment Date
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={8} sx={{ padding: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    size="small"
                    slotProps={{ textField: { size: "small" } }}
                    value={date}
                    format="DD/MM/YYYY"
                    onChange={handleDate}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField {...params} size="small" fullWidth />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>

              {dateError ? (
                <Mui.Typography
                  variant="body2"
                  color="error"
                  sx={{ fontSize: 13, padding: 0.8, paddingRight: 0.5 }}
                >
                  Date is required
                </Mui.Typography>
              ) : previousDate ? (
                <Mui.Typography
                  variant="body2"
                  color="error"
                  sx={{ fontSize: 13, padding: 0.8, paddingRight: 0.5 }}
                >
                  Date cannot be in the past.
                </Mui.Typography>
              ) : null}
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid item xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                  fontWeight: "bold",
                  paddingTop: 2,
                }}
              >
                Reason for amount
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={8} sx={{ padding: 1 }}>
              <CustomTextBox
                type="multiline"
                rules={{
                  ...register("reason_for_amount", {
                    required: "Reason for amount is required",
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else if (value?.trim().length > 255 && value !== "") {
                          return "Maximum length is 255 characters";
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                selectedValue={selectedReasonForAmount}
                updateDataFunction={updateReasonForAmount}
                placeHolder="Provide a reason for advance salary"
                errorMessage={
                  errors?.reason_for_amount?.message
                    ? errors?.reason_for_amount?.message
                    : ""
                }
                textBoxError={errors?.reason_for_amount ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionFuntion={handleClearButtonClick}
                actionName="Clear"
                typeName="button"
              />
            </Mui.Grid>
            <Mui.Grid item>
              <CustomButton
                actionName="Apply"
                typeName="submit"
                disableAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
