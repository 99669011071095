import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import dayjs from "dayjs";
import { Info } from "@mui/icons-material";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { onlyNumberRegex } from "services/constants/patterns";
import sessionHandling from "services/utils/notificationUtils";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { showToast } from "services/utils/Status";
export const NonRotationalShift = ({
  setAllShifts,
  shift_category_name,
  setStartTime,
  startTime,
  shift_grade_id,
  checkInPolicy,
  setCheckInPolicy,
  rotationalError,
  setStartTimeFormat,
  startTimeFormat,
  overTimeStartStartEarly,
  overTimeendEndDelay,
  setRotationalError,
  isBreakAllowed,
  setIsBreakAllowed,
  includeShiftTime,
  setIncludeShiftTime,
  shiftCategoryName,
  setShiftCategoryName,
  breakMinutes,
  setBreakMinutes,
  shiftGrade,
  screenName,
  firstNameUpdate,
  updatename,
  setUpdateName,
  setFirstNameUpdate,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [startStartEarly, setStartStartEarly] = useState(
    checkInPolicy?.start_early
  );
  const [endStartDelay, setEndStartDelay] = useState(
    checkInPolicy?.start_delay
  );
  const [startEndEarly, setStartEndEarly] = useState(checkInPolicy?.end_early);
  const [endEndDelay, setEndEndDelay] = useState(checkInPolicy?.end_delay);
  // const [startStartEarly, setStartStartEarly] = useState("15");
  // const [endStartDelay, setEndStartDelay] = useState("15");
  // const [startEndEarly, setStartEndEarly] = useState("15");
  // const [endEndDelay, setEndEndDelay] = useState("15");
  const [errorMessage, setErrorMessage] = useState("");

  const getShiftDetails = useCallback(
    async (time) => {
      if (time && time !== "Invalid Date") {
        const newShiftName = `${shiftGrade}-${startTime}`;
        try {
          const response = await fetch(
            `${domain}shift-over-time-details/?shift_category_name=${newShiftName}&shift_grade_id=${shift_grade_id}&start_time=${time}&break_time=${breakMinutes}&no_of_shifts=1&start_start_early=${
              startStartEarly ? startStartEarly : 0
            }&start_end_delay=${
              endStartDelay ? endStartDelay : 0
            }&end_end_early=${
              startEndEarly ? startEndEarly : 0
            }&end_end_delay=${endEndDelay ? endEndDelay : 0}&start_delay=${
              overTimeStartStartEarly ? overTimeStartStartEarly : 0
            }&end_early=${overTimeendEndDelay ? overTimeStartStartEarly : 0}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `token ${token}`,
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            console.log(res?.shifts);
            let overAllData = res?.shifts.map((element) => {
              return {
                ...element,
                startTimeDateFormat: dayjs()
                  .set("hour", element?.start_time.split(":")[0])
                  .set("minute", element?.start_time.split(":")[1])
                  .set("second", "00"),
                endTimeDateFormat: dayjs()
                  .set("hour", element?.end_time.split(":")[0])
                  .set("minute", element?.end_time.split(":")[1])
                  .set("second", "00"),
              };
            });
            setAllShifts(overAllData);
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    },
    [breakMinutes, domain, endEndDelay, endStartDelay, overTimeStartStartEarly, overTimeendEndDelay, setAllShifts, shiftGrade, shift_grade_id, startEndEarly, startStartEarly, startTime, token]
  );

  const handleStartTime = (newValue) => {
    if (newValue && newValue.format) {
      const timeWithDate = newValue ? newValue.toDate() : new Date();
      const time = newValue.format("HH:mm");
      setStartTime(time);
      getShiftDetails(time);
      let minTime = moment.utc(timeWithDate);
      setStartTimeFormat(
        dayjs()
          .set("hour", minTime._d.getHours())
          .set("minute", minTime._d.getMinutes())
          .set("second", minTime._d.getSeconds())
      );
      setRotationalError(false);
      if (screenName === "Add") {
        setFirstNameUpdate(true);
      } else {
        setUpdateName(true);
      }
    }
  };

  const handleBreak = (event) => {
    const isChecked = event.target.checked;
    setIsBreakAllowed(isChecked);
    setBreakMinutes("00");
    if (!isChecked) {
      setIncludeShiftTime(false);
    }
  };

  const handleShift = () => {
    setIncludeShiftTime(!includeShiftTime);
  };

  const shiftCategoryNameCheck = (event) => {
    const value = event.target.value;
    updateShiftCategoryName(value);
  };

  const updateShiftCategoryName = useCallback(
    async (value) => {
      setShiftCategoryName(value);
      if (value.length < 3) {
        setErrorMessage("Minimum length is 3 characters");
        return;
      } else if (!/[a-zA-Z]/.test(value)) {
        setErrorMessage("At least one alphabet is required");
        return;
      } else if (value.length >= 3) {
        if (
          screenName === "Add" ||
          (screenName === "Edit" && shift_category_name !== value)
        )
          try {
            const response = await fetch(
              `${domain}check_shift_category_name/?shift_category_name=${value}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `token ${token}`,
                },
              }
            );
            const resp = await response.json();
            if (resp.exists) {
              setErrorMessage("Shift category name already exists");
            } else {
              setErrorMessage(null);
              getShiftDetails(startTime);
            }
          } catch (error) {
            showToast("error", error.message);
          }
      }
    },
    [
      domain,
      getShiftDetails,
      screenName,
      setShiftCategoryName,
      shift_category_name,
      startTime,
      token,
    ]
  );

  useEffect(() => {
    if (firstNameUpdate && screenName === "Add") {
      const newShiftName = `${shiftGrade}-${startTime}`;
      setShiftCategoryName(newShiftName);
      updateShiftCategoryName(newShiftName);
      setFirstNameUpdate(false);
    }
  }, [
    firstNameUpdate,
    screenName,
    setFirstNameUpdate,
    setShiftCategoryName,
    shiftGrade,
    startTime,
    updateShiftCategoryName,
  ]);

  useEffect(() => {
    if (updatename && screenName === "Edit") {
      const newShiftName = `${shiftGrade}-${startTime}`;
      setShiftCategoryName(newShiftName);
      updateShiftCategoryName(newShiftName);
      setUpdateName(false);
    }
  }, [
    screenName,
    setShiftCategoryName,
    setUpdateName,
    shiftGrade,
    startTime,
    updateShiftCategoryName,
    updatename,
  ]);

  return (
    <React.Fragment>
      <Mui.Grid container component={Mui.Paper}>
        <Mui.Grid container fullWidth sx={{ padding: 1, borderRadius: 3 }}>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              First Shift Start Time &nbsp;
              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid container xs={6}>
            <Mui.Grid item xs={4.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <div sx={{ width: 4 }}>
                    <TimePicker
                      ampm={false}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      value={startTimeFormat || null}
                      slotProps={{
                        textField: {
                          size: "small",
                          width: 15,
                        },
                      }}
                      onChange={(newValue) => {
                        handleStartTime(newValue);
                      }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
              {(!startTime ||
                startTime === "00:00" ||
                startTime === "Invalid Date") &&
              rotationalError ? (
                <h5 style={{ color: themes.helperTextColor }}>
                  Shift start time is required
                </h5>
              ) : null}
            </Mui.Grid>
            <Mui.Grid xs={0.5}></Mui.Grid>
          </Mui.Grid>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Is break is allowed &nbsp;
              <Mui.Tooltip title="This is the Is Break Allowed field. Choose Yes to allow a break during the shift or No to indicate no break is provided.">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>{" "}
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid container xs={6}>
            <Mui.Grid xs={4.5}>
              <Mui.Switch
                checked={isBreakAllowed}
                onChange={handleBreak}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Mui.Grid>

            {isBreakAllowed && (
              <>
                <Mui.Grid container xs={3.8} sx={{ paddingTop: 2 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      fontWeight: "bold",
                    }}
                  >
                    Break Duration &nbsp;
                  </Mui.Typography>
                  <Mui.Tooltip title="This is the Break Duration field. Enter the duration of the break (e.g., 30 minutes or 1 hour), if breaks are allowed during the shift.">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Grid>

                <Mui.Grid containter xs={3} sx={{ paddingTop: 1 }}>
                  <Mui.TextField
                    size="small"
                    value={breakMinutes}
                    sx={{ textAlign: "center" }}
                    inputProps={{ style: { textAlign: "center" } }}
                    onChange={(event) => {
                      if (
                        onlyNumberRegex.test(event.target.value) &&
                        event.target.value.length <= 3
                      ) {
                        setBreakMinutes(event.target.value);
                      }
                    }}
                    onBlur={() => {
                      getShiftDetails(startTime);
                    }}
                  />
                </Mui.Grid>
              </>
            )}
          </Mui.Grid>
          {isBreakAllowed && (
            <Mui.Grid container>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Is break part of shift hours &nbsp;
                  <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Typography>{" "}
              </Mui.Grid>
              <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                :
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Switch
                  checked={includeShiftTime}
                  onChange={handleShift}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Mui.Grid>
            </Mui.Grid>
          )}
        </Mui.Grid>
        {/* Checkin checpu */}
        <Mui.Grid container fullWidth sx={{ padding: 1, borderRadius: 3 }}>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              Checkin/Out Policy &nbsp;
              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid container xs={6}>
            <Mui.Grid xs={5.9}>
              <Mui.Grid
                xs={12}
                sx={{
                  backgroundColor: themes.primary,
                  textAlign: "center",
                  padding: 1,
                }}
              >
                <Mui.Typography
                  sx={{ fontSize: 17, fontWeight: 600, font: "bold" }}
                >
                  Start (Minutes)
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid
                  item
                  xs={5.7}
                  sx={{
                    padding: 1,
                    border: `1px solid ${themes.sidebarDivider}`,
                  }}
                >
                  {" "}
                  <Mui.Grid
                    sx={{
                      backgroundColor: themes.primary,
                      textAlign: "center",
                    }}
                  >
                    <Mui.Typography
                      sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                    >
                      Early Start
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                  <Mui.TextField
                    size="small"
                    value={startStartEarly}
                    inputProps={{ style: { textAlign: "center" } }}
                    onChange={(event) => {
                      if (
                        onlyNumberRegex.test(event.target.value) &&
                        event.target.value.length <= 2
                      ) {
                        setStartStartEarly(event.target.value);
                        setCheckInPolicy({
                          ...checkInPolicy,
                          start_early: event.target.value,
                        });
                      }
                    }}
                    onBlur={() => {
                      getShiftDetails(startTime);
                    }}
                  />
                </Mui.Grid>
                <Mui.Grid xs={0.5}></Mui.Grid>

                <Mui.Grid
                  item
                  xs={5.7}
                  sx={{
                    padding: 1,
                    border: `1px solid ${themes.sidebarDivider}`,
                  }}
                >
                  {" "}
                  <Mui.Grid
                    xs={12}
                    sx={{
                      backgroundColor: themes.primary,
                      textAlign: "center",
                    }}
                  >
                    <Mui.Typography
                      sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                    >
                      Start Late
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                  <Mui.TextField
                    size="small"
                    value={endStartDelay}
                    sx={{ textAlign: "center" }}
                    inputProps={{ style: { textAlign: "center" } }}
                    onChange={(event) => {
                      if (
                        onlyNumberRegex.test(event.target.value) &&
                        event.target.value.length <= 2
                      ) {
                        setEndStartDelay(event.target.value);
                        setCheckInPolicy({
                          ...checkInPolicy,
                          start_delay: event.target.value,
                        });
                      }
                    }}
                    onBlur={() => {
                      getShiftDetails(startTime);
                    }}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid xs={0.2}></Mui.Grid>
            <Mui.Grid xs={5.9}>
              <Mui.Grid
                xs={12}
                sx={{
                  backgroundColor: themes.primary,
                  textAlign: "center",
                  padding: 1,
                }}
              >
                <Mui.Typography
                  sx={{ fontSize: 17, fontWeight: 600, font: "bold" }}
                >
                  End (Minutes)
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid
                  item
                  xs={5.7}
                  sx={{
                    padding: 1,
                    border: `1px solid ${themes.sidebarDivider}`,
                  }}
                >
                  {" "}
                  <Mui.Grid
                    sx={{
                      backgroundColor: themes.primary,
                      textAlign: "center",
                    }}
                  >
                    <Mui.Typography
                      sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                    >
                      Early End
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                  <Mui.TextField
                    size="small"
                    value={startEndEarly}
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{ textAlign: "center" }}
                    onChange={(event) => {
                      if (
                        onlyNumberRegex.test(event.target.value) &&
                        event.target.value.length <= 2
                      ) {
                        setStartEndEarly(event.target.value);
                        setCheckInPolicy({
                          ...checkInPolicy,
                          end_early: event.target.value,
                        });
                      }
                    }}
                    onBlur={() => {
                      getShiftDetails(startTime);
                    }}
                  />
                </Mui.Grid>
                <Mui.Grid xs={0.5}></Mui.Grid>

                <Mui.Grid
                  item
                  xs={5.7}
                  sx={{
                    padding: 1,
                    border: `1px solid ${themes.sidebarDivider}`,
                  }}
                >
                  <Mui.Grid
                    xs={12}
                    sx={{
                      backgroundColor: themes.primary,
                      textAlign: "center",
                    }}
                  >
                    <Mui.Typography
                      sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                    >
                      End Delay
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                  <Mui.TextField
                    size="small"
                    value={endEndDelay}
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{ textAlign: "center" }}
                    onChange={(event) => {
                      if (
                        onlyNumberRegex.test(event.target.value) &&
                        event.target.value.length <= 2
                      ) {
                        setEndEndDelay(event.target.value);
                        setCheckInPolicy({
                          ...checkInPolicy,
                          end_delay: event.target.value,
                        });
                      }
                    }}
                    onBlur={() => {
                      getShiftDetails(startTime);
                    }}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ borderRadius: 3, padding: 1 }}>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              Shift Category Name &nbsp;
              <Mui.Tooltip title="This is the First Shift field. Choose the first shift of the day, which could be Morning, Afternoon, or Night, depending on the schedule.">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid item xs={6}>
            <CustomTextBox
              selectedValue={shiftCategoryName}
              updateDataFunction={shiftCategoryNameCheck}
              placeHolder="Enter shift category name"
            />
            {errorMessage && (
              <h5 style={{ color: themes.helperTextColor }}>{errorMessage}</h5>
            )}
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
