import React, { useEffect, useState } from "react";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import TablePagination from "@mui/material/TablePagination";
import { essPermissions } from "services/constants";
import { Loader } from "components/Loader";
import { tableRowSx } from "services/constants";
import { customAPI } from "services/utils/CustomAPI";
import { useFetch } from "services/hooks/useFetch";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  DialogActions,
  Grid,
  Alert,
  IconButton,
} from "@mui/material";

export const PermissionsDetails = ({
  selectedUser,
  loadPermissionApi,
  setLoadPersmissionApi,
  taxYearStartDateUser,
  taxYearEndDateUser,
  setTotalPermission,
}) => {
  const { domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [expandedRow, setExpandedRow] = useState(null);
  const [leavePage, setLeavePage] = useState(0);
  const [leaverowsPerPage, setLeaveRowsPerPage] = useState(25);
  const [cancelPermission, setCancelPermission] = useState(null);
  const [deletePermission, setDeletePermission] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const {
    result: permissionDetails,
    setLoad: reCallPermissionApi,
    loading,
  } = useFetch(
    `${domain}individual_permisson_request/?user_id=${selectedUser.user_id}&start_date=${taxYearStartDateUser}&end_date=${taxYearEndDateUser}`
  );
  const [permissionData, setPermissionData] = useState(permissionDetails);

  useEffect(() => {
    if (loadPermissionApi) {
      setTotalPermission(
        permissionDetails.length > 0
          ? permissionDetails[0].approved_permissions_count
          : 0
      );
      reCallPermissionApi(true);
      setLoadPersmissionApi(false);
    }
  }, [
    loadPermissionApi,
    permissionDetails,
    reCallPermissionApi,
    setLoadPersmissionApi,
    setTotalPermission,
  ]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Completed":
        return "primary";
      case "Approved":
        return "success";
      case "Pending":
        return "warning";
      case "Rejected":
        return "error";
      case "cancel":
        return "error";
      default:
        return "default";
    }
  };

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeaveRowsPerPage(0);
  };
  const handleDeleteCancel = (event) => {
    setDeletePermission(null);
  };

  const cancelPermissionRequest = async (permission) => {
    setButtonLoader(true);
    const response = await customAPI(`${domain}permisson_request/`, "PUT", {
      isdeleted: true,
      id: permission?.id,
    });
    if (response.ok) {
      reCallPermissionApi(true);
      showToast("success", response.message);
      setButtonLoader(false);
    }
    setButtonLoader(false);
  };

  const handleDeletePermission = async (id) => {
    setButtonLoader(true);
    const response = await customAPI(`${domain}permisson_request/`, "PUT", {
      isdeleted: true,
      id: id,
    });
    if (response.ok) {
      reCallPermissionApi(true);
      showToast("success", response.message);
      setButtonLoader(false);
    }
    setButtonLoader(false);
    setDeletePermission(null);
  };

  const handleCancelConfirmation = (permission) => {
    setCancelPermission(permission);
  };
  const handleCancelCancel = (event) => {
    setCancelPermission(null);
  };

  const handleDeleteConfirmation = (id) => {
    setDeletePermission(id);
  };

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    const filteredResults = permissionDetails.filter((item) =>
      essPermissions.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setPermissionData(filteredResults);
    setLeavePage(globalSearchValue ? 0 : leavePage);
  }, [globalSearchValue, leavePage, permissionDetails]);

  return (
    <React.Fragment>
      {loading === false ? (
        <TableContainer
          sx={{
            height: "100%",
            overflowY: "auto",
            marginTop: 2,
            marginLeft: "auto",
            marginRight: "auto",
            minWidth: "90%",
            maxWidth: "100%",
          }}
          className="w-96 border  rounded "
        >
          <Table className="border" sx={{ borderRadius: "20px" }}>
            <TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <TableRow sx={tableRowSx}>
                <TableCell className="column-cell"></TableCell>
                <TableCell className="column-cell">
                  <Typography fontWeight={"bold"}>Date</Typography>
                </TableCell>
                <TableCell className="column-cell">
                  <Typography fontWeight={"bold"}>Start Time</Typography>
                </TableCell>
                <TableCell className="column-cell">
                  <Typography fontWeight={"bold"}>End Time</Typography>
                </TableCell>
                <TableCell className="column-cell">
                  <Typography fontWeight={"bold"}>Duration</Typography>
                </TableCell>
                <TableCell className="column-cell">
                  <Typography fontWeight={"bold"}>Status</Typography>
                </TableCell>
                {userDetails.id === parseInt(selectedUser.user_id) ? (
                  <TableCell className="column-cell">
                    <Typography fontWeight="bold">Action</Typography>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            {permissionData.length > 0 ? (
              <TableBody>
                {" "}
                {permissionData
                  .slice(
                    leavePage * leaverowsPerPage,
                    leavePage * leaverowsPerPage + leaverowsPerPage
                  )
                  .map((permission) => (
                    <>
                      <TableRow key={permission.id} sx={tableRowSx}>
                        <TableCell className="column-cell">
                          {expandedRow === permission.id ? (
                            <IconButton
                              onClick={() => {
                                setExpandedRow(null);
                              }}
                            >
                              <KeyboardArrowDownIcon
                                style={{
                                  color: themes.headLine,
                                  fontWeight: "bold",
                                }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => handleRowClick(permission.id)}
                            >
                              <KeyboardArrowRightIcon
                                style={{
                                  color: themes.headLine,
                                  fontWeight: "bold",
                                }}
                              />
                            </IconButton>
                          )}
                        </TableCell>

                        <TableCell className="column-cell">
                          {permission.date}
                        </TableCell>
                        <TableCell className="column-cell">
                          {permission.start_time}
                        </TableCell>
                        <TableCell className="column-cell">
                          {permission.end_time}
                        </TableCell>
                        <TableCell className="column-cell">
                          {permission.total_time.split(":")[0] +
                            "h " +
                            permission.total_time.split(":")[1] +
                            "m"}
                        </TableCell>
                        <TableCell className="column-cell">
                          {permission.status}
                        </TableCell>
                        {userDetails.id === parseInt(selectedUser.user_id) ? (
                          <TableCell className="column-cell">
                            <Button
                              sx={{ maxWidth: 110, borderRadius: 3 }}
                              variant="contained"
                              color={getStatusColor("cancel")}
                              className="cancel-button"
                              onClick={() => {
                                if (permission.action === "Cancel") {
                                  // setCancelLeaveData(permission);
                                  handleCancelConfirmation(permission.id);
                                } else {
                                  handleDeleteConfirmation(permission.id);
                                }
                              }}
                              disabled={
                                permission.status === "Pending" ||
                                permission.status === "Approved"
                                  ? false
                                  : true
                              }
                            >
                              {permission.status === "Pending"
                                ? "Delete"
                                : "Cancel"}
                            </Button>
                          </TableCell>
                        ) : null}
                      </TableRow>
                      {expandedRow === permission.id && (
                        <TableRow sx={tableRowSx}>
                          <TableCell colSpan={12}>
                            <TableContainer
                              sx={{
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Table className="border">
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 1 }}
                                >
                                  <strong>Description :</strong>{" "}
                                  <Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {permission.description}
                                  </Typography>
                                </Typography>
                              </Table>
                            </TableContainer>
                            {permission.status === "Rejected" ? (
                              <TableContainer
                                sx={{
                                  marginTop: 0.5,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                <Table className="border">
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ marginLeft: 1, marginTop: 1 }}
                                  >
                                    <strong>Reason for Rejection :</strong>{" "}
                                    <Typography
                                      fontSize={15}
                                      color="textSecondary"
                                      sx={{ marginLeft: 13 }}
                                    >
                                      {permission.reason}
                                    </Typography>
                                  </Typography>
                                </Table>
                              </TableContainer>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
              </TableBody>
            ) : null}
          </Table>
          {permissionData.length === 0 && loading === false ? (
            <Alert sx={{ marginTop: 3 }} severity="info">
              No data available
            </Alert>
          ) : null}
        </TableContainer>
      ) : null}
      {permissionData.length > 25 ? (
        <TablePagination
          sx={{ marginRight: 7 }}
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={permissionDetails.length}
          rowsPerPage={leaverowsPerPage}
          page={leavePage}
          onPageChange={handleChangeLeavePage}
          onRowsPerPageChange={handleChangeLeaveRowsPerPage}
        />
      ) : null}

      {loading ? (
        <div>
          <Loader info="Loading..." />
        </div>
      ) : null}

      <GlobalDialogBox
        open={Boolean(deletePermission)}
        handleCloseDialog={handleDeleteCancel}
        title=" Delete Confirmation"
      >
        <Grid>
          <Typography sx={{ fontSize: 21 }}>
            {" "}
            Are you sure you want to delete this permission?
          </Typography>
        </Grid>
        <DialogActions>
          <CustomButton
            actionFuntion={handleDeleteCancel}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => {
              handleDeletePermission(deletePermission);
            }}
            disableAction={
              deletePermission && buttonLoader ? buttonLoader : false
            }
            actionName="Yes"
            typeName="button"
          />
        </DialogActions>
      </GlobalDialogBox>

      <GlobalDialogBox
        open={Boolean(cancelPermission)}
        handleCloseDialog={handleCancelCancel}
        title="Cancel Confirmation"
      >
        <Grid>
          <Typography sx={{ fontSize: 21 }}>
            Are you sure you want to cancel your permission request?
          </Typography>
        </Grid>
        <Grid></Grid>
        <DialogActions>
          <CustomButton
            actionFuntion={handleCancelCancel}
            actionName="No"
            typeName="button"
          />
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={() => {
              cancelPermissionRequest({ id: cancelPermission });
              handleCancelCancel();
            }}
            disableAction={
              cancelPermission && buttonLoader ? buttonLoader : false
            }
            actionName="Yes"
            typeName="button"
          />
        </DialogActions>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
