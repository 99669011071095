import React, { useState } from "react";
import * as Mui from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import { themes } from "services/constants";
import { projectReports } from "services/constants";
import { ProRepContextProvider } from "./ProRepContext/projectContext";
import ProjectTaskReport from "./ProReports/ProjectTaskReport";
import UserTaskReport from "./ProReports/UserTaskReport";
import { InprogressTaskReport } from "./ProReports/InprogressTaskReport";
import { tableRowSx } from "services/constants";

export const ProjectReports = () => {
  const [reportName, setReportName] = useState("");
  const [showReports, setShowReport] = useState(false);

  return (
    <React.Fragment>
      <ProRepContextProvider>
        {!showReports ? (
          <Mui.TableContainer sx={{ mt: 8, paddingLeft: 2, paddingRight: 2 }}>
            <Mui.Table sx={{ border: `2px solid ${themes.sidebarDivider}` }}>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow sx={tableRowSx}>
                  <Mui.TableCell
                    sx={{ color: themes.headLine, fontWeight: "bold" }}
                  >
                    Report Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.headLine, fontWeight: "bold" }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {projectReports.map((name) => (
                  <Mui.TableRow sx={tableRowSx}>
                    <Mui.TableCell sx={{ color: themes.headLine }}>
                      <Mui.Typography>{name.label}</Mui.Typography>
                    </Mui.TableCell>

                    <Mui.TableCell sx={{ color: themes.headLine }}>
                      <Mui.IconButton
                        onClick={() => {
                          setReportName(name.label);
                          setShowReport(true);
                        }}
                      >
                        <RemoveRedEye />
                      </Mui.IconButton>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        ) : reportName === "Project Task" && showReports ? (
          <>
            <ProjectTaskReport updateShowReport={setShowReport} />
          </>
        ) : reportName === "User Task" && showReports ? (
          <>
            <UserTaskReport updateShowReport={setShowReport} />
          </>
        ) : reportName === "Inprogress Task" && showReports ? (
          <>
            <InprogressTaskReport updateShowReport={setShowReport} />
          </>
        ) : null}
      </ProRepContextProvider>
    </React.Fragment>
  );
};
