import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { Daily } from "./Daily";
import { Weekly } from "./Weekly";
import { themes } from "services/constants";
import { useSelector, useDispatch } from "react-redux";
// import {
//   setDepartmentId,
//   setDepartmentName,
// } from "services/Redux/userManagament";
import { setShowShiftBack } from "services/Redux/TeamZone";
import { MoveShiftGroup } from "./MoveShiftGroup";
import { setPlanShiftScreen } from "services/Redux/TeamZone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { startOfMonth, endOfMonth } from "date-fns";
import moment from "moment";
// import { CustomButton } from "components/CustomComponents/CustomButton";
import { Loader } from "components/Loader";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { defaultAllSelection } from "services/constants";
import { showToast } from "services/utils/Status";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";
import { Monthly } from "./Monthly";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import dayjs from "dayjs";
import { BackArrow } from "components/CustomComponents/BackArrow";

export const PlanShift = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { planShiftScreen } = useSelector((state) => state.teamZoneReducer);
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { showShiftBack } = useSelector((state) => state.teamZoneReducer);
  const { result: departmentData } = useFetch(
    `${domain}get-department/?user_id=${userDetails.id}`
  );
  const [departmentIdValue, setDepartmentIdValue] = useState("");
  const [departmentName, setDepartmentName] = useState("All");
  const [dataVisualMethod, setDataVisualMethod] = useState("Daily");
  const [lastViewedScreens, setLastViewedScreens] = useState("Daily");
  const [shiftData, setShiftData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [listOfDate, setListOfDate] = useState([]);
  const [selectCurrentDate, setSelectCurrentDate] = useState(dayjs());
  const [monthlyselectedDate, setMonthlySelectedDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(new Date(), "yyyy-MM");
  const [loading, isLoading] = useState(false);
  const [fetchWeekly, setFetchWeekly] = useState(true);
  const [presentScreen, setPresentScreen] = useState(false);
  const [weeklyShiftList, setWeeklyShiftList] = useState([]);
  const [monthlyShiftList, setMonthlyShiftList] = useState([]);
  const [dailyShiftList, setDailyShiftList] = useState([]);
  const [filterChanges, setFilterChanges] = useState(false);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const minDate = new Date(2020, 0, 1);
  const [filterShifts, setFilterShifts] = useState(true);

  useEffect(() => {
    if (presentScreen !== true && departmentData?.length !== 0 && !loadData) {
      setDepartmentIdValue(departmentData[0]?.id);
      setDepartmentName(departmentData[0]?.department_name);
      setLoadData(true);
    }
  }, [
    dataVisualMethod,
    departmentData,
    departmentName,
    dispatch,
    loadData,
    presentScreen,
  ]);

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days =
      Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)) +
      startDate.getDay();
    const weekNumber = Math.ceil(days / 7);
    const weekValue = `${currentDate.getFullYear()}-W${weekNumber
      .toString()
      .padStart(2, "0")}`;
    return weekValue;
  };
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek);
  // const [tempWeek, setTempWeek] = useState(getCurrentWeek);

  const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startDate);
      day.setDate(startDate.getDate() + i);
      days.push(day);
    }
    return days;
  };

  const handleDateOnChange = (value) => {
    setPresentScreen(true);
    setSelectCurrentDate(value);
    setFetchWeekly(true);
  };

  const handleMonthlyDateOnChange = (date) => {
    if (
      date instanceof Date &&
      !isNaN(date) &&
      date.getFullYear().toString().length === 4 &&
      date >= minDate
    ) {
      setPresentScreen(true);
      setMonthlySelectedDate(date);
    } else if (date.getFullYear().toString().length === 4 && date < minDate) {
      showToast("error", "Year must be 01/01/2020 or later");
    }
  };

  const handleWeeklyDateOnChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  // const handleWeeklyDateBlur = () => {
  //   const year = parseInt(tempWeek.split("-W")[0], 10);
  //   if (year.toString().length < 4) {
  //     showToast("error", "Please enter a valid week in YYYY-WXX format");
  //   } else if (year < 2020) {
  //     showToast("error", "Year must be 01/01/2020 or later");
  //   } else {
  //     setSelectedWeek(tempWeek);
  //   }
  // };

  useEffect(() => {
    if (selectedWeek && departmentIdValue !== 99999 && dataVisualMethod) {
      if (dataVisualMethod === "Weekly") {
        const startDate = moment(selectedWeek).startOf("isoWeek").toDate();
        const endDate = moment(selectedWeek).endOf("isoWeek").toDate();
        const dates = getWeekDays(startDate);
        const formatDates = dates?.map((value) =>
          moment(value).format("YYYY-MM-DD")
        );
        setListOfDate([]);
        setStartDate("");
        setEndDate("");
        setListOfDate(formatDates);
        setStartDate(moment(startDate).format("YYYY-MM-DD"));
        setEndDate(moment(endDate).format("YYYY-MM-DD"));
        if (startDate !== "" && endDate !== "") {
          setFetchWeekly(true);
        }
      } else if (
        selectCurrentDate &&
        departmentIdValue !== 99999 &&
        dataVisualMethod === "Daily"
      ) {
        if (startDate !== "") {
          setFetchWeekly(true);
        }
      } else {
        const start = startOfMonth(monthlyselectedDate);
        const end = endOfMonth(monthlyselectedDate);
        const dates = getMonthDays(start);
        const formatDates = dates?.map((value) =>
          moment(value).format("YYYY-MM-DD")
        );
        setListOfDate([]);
        setStartDate("");
        setEndDate("");
        setListOfDate(formatDates);
        setStartDate(moment(start).format("YYYY-MM-DD"));
        setEndDate(moment(end).format("YYYY-MM-DD"));
        if (start !== "" && end !== "") {
          setFetchWeekly(true);
        }
      }
    }
  }, [
    dataVisualMethod,
    departmentIdValue,
    monthlyselectedDate,
    selectCurrentDate,
    selectedWeek,
    startDate,
  ]);

  const getMonthDays = (startDate) => {
    const days = [];
    const year = startDate.getFullYear();
    const month = startDate.getMonth();

    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    let currentDate = firstDayOfMonth;

    while (currentDate <= lastDayOfMonth) {
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  };

  useEffect(() => {
    const formatDate = () => {
      if (monthlyselectedDate) {
        const formattedDate =
          monthlyselectedDate?.getFullYear() +
          "-" +
          ("0" + (monthlyselectedDate?.getMonth() + 1)).slice(-2);
        setFormattedDate(formattedDate);
      }
    };
    formatDate();
    if (
      formattedDate &&
      departmentIdValue !== 99999 &&
      dataVisualMethod === "Monthly"
    ) {
      setFetchWeekly(true);
    }
  }, [dataVisualMethod, departmentIdValue, formattedDate, monthlyselectedDate]);

  const updateShiftBasedOnRange = async (userId, shift_assignments) => {
    try {
      const response = await fetch(`${domain}shift_assignments/daily/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          shift_assignments: [
            {
              user: userId,
              daily_assignments: shift_assignments,
            },
          ],
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Shift assigned successfully");
        setFetchWeekly(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    const fetchShiftData = async () => {
      try {
        const response = await fetch(
          `${domain}department_filter/?id=${departmentIdValue}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const shifts = res?.reduce((key, value) => {
            return [
              ...key,
              ...value.department_shift_categories.reduce(
                (keys, values) => [...keys, ...values.shifts],
                []
              ),
            ];
          }, []);
          setShiftData([]);
          let shiftDataWithLabel = [];
          shiftDataWithLabel = shifts?.map((obj) => ({
            ...obj,
            label: obj.name,
          }));
          const allShifts = {
            id: defaultAllSelection,
            label: "Weekoff",
            name: "Weekoff",
          };
          setShiftData(
            [allShifts, ...shiftDataWithLabel].filter(
              (obj, index, self) =>
                index === self?.findIndex((t) => t?.id === obj?.id)
            )
          );
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (departmentName !== "All" && filterShifts) {
      fetchShiftData();
      setFilterShifts(false);
    }
  }, [
    dataVisualMethod,
    departmentIdValue,
    departmentName,
    domain,
    filterShifts,
    shiftData?.length,
    token,
  ]);

  useEffect(() => {
    const fetchWeeklyShiftList = async () => {
      isLoading(true);
      const formattedDate = dayjs(selectCurrentDate).format("YYYY-MM-DD");
      try {
        let url =
          dataVisualMethod === "Daily"
            ? `${domain}daily_user_shift_assignments/?department_id=${departmentIdValue}&from_date=${formattedDate}&to_date=${formattedDate}`
            : `${domain}daily_user_shift_assignments/?department_id=${departmentIdValue}&from_date=${startDate}&to_date=${endDate}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setMasterDataForSearch([]);
          dataVisualMethod === "Monthly"
            ? setMonthlyShiftList([])
            : setWeeklyShiftList([]);
          dataVisualMethod === "Daily" && setDailyShiftList(res?.data);
          dataVisualMethod === "Monthly"
            ? setMonthlyShiftList(res?.data)
            : setWeeklyShiftList(res?.data);

          setMasterDataForSearch(res?.data);
          isLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        isLoading(false);
      }
    };
    if (fetchWeekly && departmentIdValue !== "" && loadData) {
      fetchWeeklyShiftList();
      setFetchWeekly(false);
    }
  }, [
    dataVisualMethod,
    departmentIdValue,
    domain,
    endDate,
    fetchWeekly,
    loadData,
    selectCurrentDate,
    startDate,
    token,
  ]);

  useEffect(() => {
    const FilterWeeklyShiftData = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setWeeklyShiftList(FilterWeeklyShiftData());
    setFilterChanges(true);
  }, [globalSearchValue, masterDataForSearch]);

  useEffect(() => {
    const FilterMonthlyShiftData = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setMonthlyShiftList(FilterMonthlyShiftData());
    setFilterChanges(true);
  }, [globalSearchValue, masterDataForSearch]);

  const updateDepartmentName = (event, data) => {
    if (data) {
      setDepartmentIdValue(data.id);
      setDepartmentName(data.department_name);
      setFetchWeekly(true);
      setFilterShifts(true);
    }
  };

  const renderComponent = () => {
    switch (dataVisualMethod) {
      case "Daily":
        return (
          <Daily
            dailyShiftList={dailyShiftList}
            loading={loading}
            shiftData={shiftData}
            updateDailyShift={updateShiftBasedOnRange}
          />
        );
      case "Monthly":
        return (
          <Monthly
            loading={loading}
            monthlyShiftList={monthlyShiftList}
            filterChanges={filterChanges}
            setFilterChanges={setFilterChanges}
            shiftData={shiftData}
            listOfDate={listOfDate}
            updateMonthlyShift={updateShiftBasedOnRange}
          />
        );
      case "Weekly":
        return (
          <Weekly
            loading={loading}
            weeklyShiftList={weeklyShiftList}
            filterChanges={filterChanges}
            setFilterChanges={setFilterChanges}
            shiftData={shiftData}
            listOfDate={listOfDate}
            updateWeeklyShift={updateShiftBasedOnRange}
            setFetchWeekly={setFetchWeekly}
          />
        );
      case "MoveShiftGroup":
        return (
          <MoveShiftGroup
            lastViewedScreens={lastViewedScreens}
            setDataVisualMethod={setDataVisualMethod}
            reCallApi={setLoadData}
          />
        );
      default:
        break;
    }
  };

  return departmentName === "All" ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      {dataVisualMethod === "MoveShiftGroup" ? null : (
        <>
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            {showShiftBack ? (
              <BackArrow
                actionFunction={() => {
                  dispatch(setGlobalSearchValue(""));
                  history.push("/admin/shift");
                  dispatch(setShowShiftBack(false));
                }}
              />
            ) : null}
            <Mui.Grid xs={2}>
              <Mui.Typography>Select department name</Mui.Typography>
              <CustomAutoComplete
                dataList={departmentData?.map((obj) => ({
                  ...obj,
                  label: obj.department_name,
                }))}
                selectedValue={departmentName}
                updateDataFunction={updateDepartmentName}
                placeHolder="Select department name"
                // label="Select Department Name"
              />
            </Mui.Grid>
            <Mui.Grid xs={6.1}></Mui.Grid>
            <Mui.Grid xs={2}>
              <Mui.Stack>
                {dataVisualMethod === "Daily" ? (
                  <Mui.Typography>Select Date</Mui.Typography>
                ) : dataVisualMethod === "Monthly" ? (
                  <Mui.Typography>Select Month</Mui.Typography>
                ) : (
                  <Mui.Typography>Select Week</Mui.Typography>
                )}
                {dataVisualMethod === "Daily" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        size="small"
                        slotProps={{ textField: { size: "small" } }}
                        sx={{ minWidth: "100%" }}
                        value={selectCurrentDate}
                        format="DD/MM/YYYY"
                        onChange={handleDateOnChange}
                        renderInput={(params) => (
                          <Mui.TextField {...params} size="small" />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                ) : dataVisualMethod === "Monthly" ? (
                  <Mui.Stack>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={["year", "month"]}
                        size="small"
                        sx={{ minWidth: "100%" }}
                        value={monthlyselectedDate}
                        minDate={minDate}
                        onChange={handleMonthlyDateOnChange}
                        slotProps={{ textField: { size: "small" } }}
                        renderInput={(params) => (
                          <Mui.TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Mui.Stack>
                ) : (
                  <Mui.TextField
                    type="week"
                    value={selectedWeek}
                    size="small"
                    sx={{ minWidth: "100%" }}
                    onChange={handleWeeklyDateOnChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              xs={1.5}
              sx={{
                paddingTop: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Mui.Button
                variant={"contained"}
                sx={{
                  background: themes.primaryButton,
                  textTransform: "capitalize",
                  mr: 2,
                  border: "none",
                  "&:hover": {
                    border: "none",
                    backgroundColor: themes.primaryButton,
                  },
                  color: themes.headLine,
                }}
                onClick={() => {
                  setDataVisualMethod("MoveShiftGroup");
                  // history.push("/admin/teamzone/shift-group");
                  dispatch(setGlobalSearchValue(""));
                }}
              >
                Change Shift Group
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={3}>
              <Mui.ButtonGroup
                size="medium"
                fullWidth
                sx={{ minWidth: "100%" }}
                aria-label="Large button group"
              >
                <Mui.Button
                  variant={
                    dataVisualMethod === "Daily" ? "contained" : "outlined"
                  }
                  sx={{
                    background:
                      dataVisualMethod === "Daily"
                        ? themes.primaryButton
                        : "outlined",
                    textTransform: "capitalize",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      backgroundColor:
                        dataVisualMethod === "Daily"
                          ? themes.primaryButton
                          : "outlined",
                    },
                    color: themes.headLine,
                  }}
                  onClick={() => {
                    setPresentScreen(true);
                    setFetchWeekly(true);
                    setSelectedWeek(getCurrentWeek);
                    setDataVisualMethod("Daily");
                    setLastViewedScreens("Daily");
                    dispatch(setPlanShiftScreen("Daily"));
                    localStorage.setItem("planShiftScreen", "Daily");
                  }}
                >
                  Daily
                </Mui.Button>
                <Mui.Button
                  variant={
                    dataVisualMethod === "Weekly" ? "contained" : "outlined"
                  }
                  sx={{
                    background:
                      dataVisualMethod === "Weekly"
                        ? themes.primaryButton
                        : "outlined",
                    textTransform: "capitalize",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      backgroundColor:
                        dataVisualMethod === "Weekly"
                          ? themes.primaryButton
                          : "outlined",
                    },
                    color: themes.headLine,
                  }}
                  onClick={() => {
                    setPresentScreen(true);
                    setSelectedWeek(getCurrentWeek);
                    setDataVisualMethod("Weekly");
                    setLastViewedScreens("Weekly");
                    dispatch(setPlanShiftScreen("Weekly"));
                    localStorage.setItem("planShiftScreen", "Weekly");
                  }}
                >
                  Weekly
                </Mui.Button>
                <Mui.Button
                  variant={
                    dataVisualMethod === "Monthly" ? "contained" : "outlined"
                  }
                  sx={{
                    background:
                      dataVisualMethod === "Monthly"
                        ? themes.primaryButton
                        : "outlined",
                    textTransform: "capitalize",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      backgroundColor:
                        dataVisualMethod === "Monthly"
                          ? themes.primaryButton
                          : "outlined",
                    },
                    color: themes.headLine,
                  }}
                  onClick={() => {
                    setPresentScreen(true);
                    setDataVisualMethod("Monthly");
                    setLastViewedScreens("Monthly");
                    dispatch(setPlanShiftScreen("Monthly"));
                    localStorage.setItem("planShiftScreen", "Monthly");
                  }}
                >
                  Monthly
                </Mui.Button>
              </Mui.ButtonGroup>
            </Mui.Grid>
            <Mui.Grid xs={9}></Mui.Grid>
          </Mui.Grid>
        </>
      )}
      <Mui.Grid>{renderComponent()}</Mui.Grid>
    </React.Fragment>
  );
};
